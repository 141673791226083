<section class="content-header" style="padding:5px !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Trang chủ</a></li>
                <li class="breadcrumb-item "><a [routerLink]="['/SearchObject']">Tra cứu phân phối</a></li>
                <li class="breadcrumb-item "><a  [routerLink]="['/SearchObjectList']" [queryParams]="{ID_doi_tuong: ID, ID_Dot:ID_dot_danh_gia,Category:Category}">Danh sách phiếu</a></li>
                <li class="breadcrumb-item active">Chi tiết phiếu</li>
            </ol>
            </div>
          
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content cus-body" >
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <label>Mã {{Ma}}</label>
                    <input type="text" class="form-control"  [(ngModel)]="MaFilter" (keyup)="filterById()" placeholder="Tìm kiếm theo mã...">
                </div>  
                <div class="col-md-3">
                    <label>Họ và tên</label>
                    <input type="text"  class="form-control"  [(ngModel)]="HoVaTenFilter" (keyup)="filterById()"  placeholder="Tìm kiếm theo Họ tên...">
                </div> 
                <div class="col-md-3" *ngIf="Layout">
                    <label>Môn học</label>
                    <input type="text"  class="form-control"  [(ngModel)]="MonHocFilter" (keyup)="filterById()"  placeholder="Tìm kiếm theo môn học">
                </div>  
                <div class="col-md-3">
                    <label>Trạng thái</label>
                    <select class="form-control"  [(ngModel)]="TrangThaiFilter" (change)="filterById()">
                        <option value="" selected>Tất cả</option>
                        <option value="true">Đã đánh giá</option>
                        <option value="false">Chưa đánh giá</option>
                    </select>
                </div>  
            </div>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <span style="font-weight: bold;">Loại đối tượng:</span><span> {{Loai_doi_tuong}} </span>
                </div>
                <div class="col-md-3" *ngIf="Layout">
                    <span style="font-weight: bold;">Người được đánh giá:</span><span> {{Doi_tuong}} </span>
                </div>
                <div class="col-md-3" *ngIf="!Layout">
                    <span style="font-weight: bold;">Đối tượng đánh giá:</span><span> {{Doi_tuong}} </span>
                </div>
                <div class="col-md-3">
                    <span style="font-weight: bold;">Đợt đánh giá:</span><span> {{Ten_dot}} </span>
                </div>
                <div class="col-md-3">
                    <span style="font-weight: bold;">Tên phiếu đánh giá:</span><span> {{Ten_phieu}}</span>
                </div>
            </div>
        </div><!-- /.card-header -->
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable id="example" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class=" table-bordered dataTables_wrapper no-footer ">
                                    <thead>
                                        <tr>
                                            <td style="width:20px">STT</td>
                                            <td *ngIf="isDisplay">Mã {{Ma}}</td>
                                            <td *ngIf="isDisplay">Họ và tên</td>
                                            <td *ngIf="Layout">Tên môn học</td>
                                            <td>Đã đánh giá</td>
                                            <td style="display: none;">Đã đánh giá</td>
                                            <td>Thời gian đánh giá</td>
                                            <td>Thao tác</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of TableData; index as i">
                                            <td style="text-align: center;">{{ i+1  }}</td>
                                            <td *ngIf="isDisplay">{{ p.Ma_doi_tuong }}</td>
                                            <td *ngIf="isDisplay">{{ p.Ho_ten }}</td>
                                            <td *ngIf="Layout">{{ p.Ten_mon }}</td>
                                            <td *ngIf="!p.Da_danh_gia" class="dt-center">    
                                                 <input type="checkbox" style="width:20px;height: 20px;"  disabled>
                                            </td>
                                            <td *ngIf="p.Da_danh_gia" class="dt-center">                                                       
                                                <input type="checkbox" style="width:20px;height: 20px;" disabled checked>                                                       
                                            </td>
                                            <td style="display: none;">{{p.Da_danh_gia}}</td>
                                            <td>{{!p.Ngay_danh_gia ? '' : p.Ngay_danh_gia | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                                            <td class="dt-center">
                                                <button (click)="open(View,'xl',p)" class="btn btn-primary btn-sm"><i
                                                        class="far fa-eye"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
<ng-template #View let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px;padding-bottom: 0px;">
        <h4 class="modal-title h-title w-100">Kết quả đánh giá</h4>
        <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body">
        <div class="cus-box">
            <div class="row">
                <div class=" form-group  col-md-6">
                    <label class="control-label require" for="inputSuccess"><i class="fas fa-poll-h"></i>&nbsp;
                        Tên phiếu đánh giá
                    </label>
                    <textarea id="Note"  class="cus-texeria" readonly>{{Ten_cua_phieu}}</textarea>
                </div>
                <div class="form-group col-md-6 form-group">
                    <label class="control-label " for="inputSuccess"><i class="fas fa-sort-amount-down"></i>&nbsp;
                        Loại đánh giá :</label>
                        <p class="form-control cus-fs13" >{{  Nhom_danh_gia}}</p>
                      
                </div>
            </div>
            <div class="row">
                <div class=" col-md-6 form-group">
                    <label class="control-label text-left usfont_lable " for="inputSuccess"><i
                            class="fas fa-file-alt"></i>&nbsp;Nội dung</label>
                    <br>
                    <textarea name="Content" readonly class="cus-texeria" id="Content"
                       >{{Noi_dung_phieu}}</textarea>
                </div>
                <div class=" col-md-6 form-group">
                    <label class="control-label text-left usfont_lable " for="inputSuccess"><i
                            class="far fa-comment-alt"></i>&nbsp;Ghi chú</label>
                    <br>
                    <textarea id="Note"  class="cus-texeria" readonly
                       >{{Ghi_chu_phieu}}</textarea>
                </div>
            </div>
        </div>
        <div class="container" id="View_Question">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-danger" (click)="modal.close('Save click')"> <i
                class="far fa-times-circle"></i> Đóng</button>
    </div>
</ng-template>
<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">
            <form [formGroup]="loginForm" class="login100-form validate-form" style="padding-top: 70px !important;">
                
                <img src="/assets/img/thienan.png" style=" display: block;
                margin-left: auto;
                margin-right: auto;
                width: 50%;" class="center">
                <span class="login100-form-title p- -43 "style="margin-bottom: 30px;">
                    Cổng đánh giá dành cho sinh viên và nhà tuyển dụng
                </span>
                <label class="label_login"><i class="fas fa-user"></i> &nbsp; Tài khoản  </label>
                <div class="wrap-input100 validate-input" style="height: 45px;">
                    <input class="input100" formControlName="UserName" value="1753801070371"  type="text"style="border-radius:90px">
                    <span class="focus-input100"></span>
                    <span class="label-input100"></span>
                </div>
                <label class="label_login"><i class="fas fa-key"></i> &nbsp; Mật khẩu  </label>
                <div class="wrap-input100 validate-input" data-validate="Password is required" style="height: 45px;">
                    <input class="input100" type="password"   formControlName="Password" style="border-radius:90px">
                    <span class="focus-input100"></span>
                    <span class="label-input100"></span>
                </div>
     
                <div class="container-login100-form-btn " style="margin-top: 30px;">
                    <button class="login100-form-btn" (click)="logIn()">
                        Đăng nhập
                    </button>
                </div>
                <div>
                    <p [routerLink]="['/verification-student']" class="registertxt" style="text-align: center;">
                        Đăng nhập bằng CAS
                    </p>
                </div>
                    <div>
                        <p [routerLink]="['/register']" class="registertxt" style="text-align: center;">
                            Quên mật khẩu?
                        </p>
                    </div>
                    <div>
                        <p [routerLink]="['/login']" class="registertxt" style="text-align: center;">
                            Trang đăng nhập quản trị viên
                        </p>
                    </div>
            </form>
            <div class="login100-more" style="background-image: url('/assets/img/bg-login.jpg');">
            </div>
        </div>
    </div>
</div>
import { Injectable, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, filter, catchError } from "rxjs/operators";
import { ResponseLogin } from "src/app/Models/output.model/ResponseLogin";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class Account  extends BaseService{
  public cookieService: CookieService;
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }
  Login(req: any) {
    return this.http
      .post<any>(this.appConfig.UtilsUrl + "Account/LoginWithRoles", req, {})
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  AutoLogin(req: any) {
    return this.http
      .post<any>(this.appConfig.UtilsUrl + "Account/AutoLogin", req, {})
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  ChangePass(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UtilsUrl + "Account/ChangePassword", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}

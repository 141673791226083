<section class="content-header" style="padding: 5px !important">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-md-6">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/']"
                ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
              >
            </li>
            <li class="breadcrumb-item active">Loại đánh giá</li>
            <!-- <div class="{{TitleClass}}"></div> -->
          </ol>
        </div>
        <div class="col-md-6">
          <button
            class="btn btn-success btn-sm"
            (click)="open(detailModal, null, 'insert')"
            style="float: right"
          >
            <i class="fas fa-plus-circle"></i> Thêm
          </button>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <section class="content cus-body">
    <div class="card">
      <div class="card-body">
          <form [formGroup]="filterForm">
        <div class="row">
         
          <div class="col-md-3">
            <label class="col-md-10">Mã loại đánh giá:</label>
            <input class="form-control" formControlName="Ma_loai_danh_gia" type="text" (change)="filterById()"/>
          </div>
          <div class="col-md-3">
            <label class="col-md-10">Tên loại đánh giá</label>
            <input class="form-control" formControlName="Ten_loai_danh_gia" type="text" (change)="filterById()"/>
          </div>
      
        </div>
      </form>
      </div>
    </div>
  </section>
  
  <section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane" id="TeachInfo">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
                >
                  <table
                    datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class="table-bordered dataTables_wrapper no-footer"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let p of TableData; let i = index">
                        <td>{{ p.ID_loai_danh_gia }}</td>
                        <td>{{ p.Ma_loai_danh_gia }}</td>
                        <td>{{ p.Ten_loai_danh_gia }}</td>
                        <td>{{ p.Ghi_chu }}</td>
                        <td>
                          <button
                            class="btn btn-sm btn-warning"
                            (click)="open(detailModal, p, 'update')"
                          >
                            <i class="fas fa-edit" title="Sửa loại đánh giá"></i>
                          </button>
                          <button
                            class="btn btn-sm btn-danger"
                            style="margin-left: 4px"
                            (click)="openDelete(deleteModal,p)"
                          >
                            <i class="fas fa-trash-alt" title="Xóa loại đánh giá"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- /.col -->
            </div>
          </div>
        </div>
        <!-- /.tab-content -->
      </div>
      <!-- /.card-body -->
    </div>
  
    <!-- /.row -->
  </section>
  
  <ng-template #detailModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container">
        <form [formGroup]="dataForm">
          <div
            class="modal-body cus-fs"
            style="padding-top: 5px; padding-bottom: 5px"
          >
            <div class="cus-box">
              <div class="row">
                <div class="col-md-6">
                  <label class="col-md-10" for="Ma_loai_danh_gia"
                    >Mã loại đánh giá:</label
                  >
                  <input
                    class="form-control"
                    required
                    type="text"
                    id="Ma_loai_danh_gia"
                    formControlName="Ma_loai_danh_gia"
                    placeholder=""
                    [ngClass]="{
                      'is-invalid': submitted && checkvalue.Ma_loai_danh_gia.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && checkvalue.Ma_loai_danh_gia.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="checkvalue.Ma_loai_danh_gia.errors.required">
                      Mã loại đánh giá không được để trống
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="col-md-10 require" for="Ten_loai_danh_gia"
                    >Tên loại đánh giá:</label
                  >
                  <input
                    class="form-control"
                    required
                    type="text"
                    id="Ten_loai_danh_gia"
                    formControlName="Ten_loai_danh_gia"
                    [ngClass]="{
                      'is-invalid': submitted && checkvalue.Ten_loai_danh_gia.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && checkvalue.Ten_loai_danh_gia.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="checkvalue.Ten_loai_danh_gia.errors.required">
                      Tên loại đánh giá không được để trống
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label class="col-md-10 require" for="Ghi_chu">Ghi chú:</label>
                  <textarea
                    class="form-control"
                    id="Ghi_chu"
                    formControlName="Ghi_chu"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-primary" (click)="QuestionEdit()">Lưu</button>
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Đóng</button> -->
      <button
        type="button"
        class="btn btn-sm btn-success"
        (click)="onSubmit()"
      >
        <i class="fas fa-save"></i> Lưu
      </button>
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="modal.close('Save click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </ng-template>
  
  <ng-template #deleteModal let-modal >
      <div class="modal-content" >
        <div class="modal-body">
            <div class="icon-box" style="text-align: center;">
                <i class="fas fa-trash-alt"
                    style="color: #f15e5e;font-size: 46px;display: inline-block;margin-top: 13px;"></i>
            </div>
            <p
                style="color: inherit; text-decoration: none;font-size: 20px; text-align: center;  margin-top: 10px;">
                Bạn có muốn xoá dữ liệu này không?
            </p>
            <div class="text-center">
                <button type="button" class="btn btn-md btn-success" (click)="delete()">
                    Đồng ý
                </button>
                <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                    style="margin-left: 1em;">
                    Hủy bỏ
                </button>
    
            </div>
        </div>
    </div>
    </ng-template>
  
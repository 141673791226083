import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class SearchObjectDetailService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }
  SearchObjectDetail(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "SurveySend/SearchObjectDetail",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        map((SearchObjectDetail) => {
          return SearchObjectDetail;
        })
      );
  }
  getSurveyDetailbyIdGui(ID_gui, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.SurveyURL + "SurveySend/getSurveyDetailbyIdGui?ID_gui="+ID_gui,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        map((SearchObjectDetail) => {
          return SearchObjectDetail;
        })
      );
  }
}

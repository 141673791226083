<section class="content-header" style="padding:5px !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item active"><i class="fas fa-home"></i> &nbsp;Trang chủ
                    </li>
                    <div class="{{TitleClass}}"></div>
                </ol>
            </div>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-3 col-xs-6">
                        <div class="small-box bg-aqua" style="background-color:bisque">
                            <div class="inner">
                                <h5>
                                    <i class="fas fa-chalkboard-teacher"></i> Tổng số câu hỏi:
                                </h5>
                                <h5>
                                    <span style="font-weight:700;font-size:35px;">{{QuestionsNumber}}</span>
                                </h5>
                            </div>
                            <div class="icon">
                                <i class="far fa-address-book"></i>
                            </div>
                        </div>
                    </div>
            
                    
                    <div class="col-lg-3 col-xs-6">
                        <div class="small-box bg-green">
                            <div class="inner">
                                <h5>
                                    <i class="far fa-address-book"></i> Tổng số phiếu:
                                </h5>
                                <h5>
                                    <span style="font-weight:700;font-size:35px;">{{SurveysNumber}}</span>
                                </h5>
                            </div>
                            <div class="icon">
                                <i class="far fa-address-card"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-6">
                        <div class="small-box " style="background: #4a90b9;">
                            <div class="inner">
                                <h5>
                                    <i class="fas fa-user-graduate"></i> Tổng số phân phối:
                                </h5>
                                <h5>
                                    <span style="font-weight:700;font-size:35px;">{{SendsNumber}}</span>
                                </h5>
                            </div>
                            <div class="icon">
                                <i class="fas fa-server"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-6">
                        <div class="small-box  bg-yellow">
                            <div class="inner">
                                <h5>
                                    <i class="fas fa-users"></i> Tổng số phản hồi:
                                </h5>
                                <h5>
                                    <span style="font-weight:700;font-size:35px;">{{AnsNumber}}</span>
                                </h5>
                            </div>
                            <div class="icon">
                                <i class="fas fa-id-card"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="content">
    <div class="container-fluid">
        <div class="card-header">
            <div class="row">
                <div class="col-lg-5">
                    <div class="card">
                        <div class="card-header d-flex p-0">
                            <h3 class="card-title p-3" style="font-size: larger;">
                              <i class="fa fa-search mr-1"></i>
                              Thống kê phân phối
                            </h3>
                            <ul class="nav nav-pills ml-auto p-2">
                              <li class="nav-item">
                                <a class="nav-link active" (click)="open(mymodal)" data-toggle="tab"><i class="fas fa-cogs"></i></a>
                              </li>
                            </ul>
                          </div>

                        <div class="card-body">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class=" table-bordered dataTables_wrapper no-footer ">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData">
                                            <td>{{ p.Ten_khoa }}</td>
                                            <td>{{ p.Khoa_hoc }}</td>
                                            <td>{{ p.SoPhieu }}</td>
                                            <td>{{ p.PhanHoi }}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="card ">
                        <div class="card-header d-flex p-0">
                            <h3 class="card-title p-3" style="font-size: larger;">
                              <i class="fa fa-search mr-1"></i>
                              Biểu đồ thống kê kết quả khảo sát
                            </h3>
                            <ul class="nav nav-pills ml-auto p-2">
                              <li class="nav-item">
                                <a class="nav-link active" (click)="open(BieuDoModal)" data-toggle="tab"><i class="fas fa-cogs"></i></a>
                              </li>
                            </ul>
                          </div>
                        <div class="card-body">
                            <div id="chartContainer" style="height: 370px; width: 100%; margin-left:auto;margin-right:auto;"></div>
                        </div>
                    </div>
                </div>
                <!-- /.col-md-6 -->
                <!-- /.col-md-6 -->
            </div>
        </div>
    </div>
</section>
<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Cấu hình thống kê</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Đợt đánh giá</label>
                    <select  class="form-control" ID="DotThongKe" (change)="DotThongKeChange()">
                        <option *ngFor="let p of DotThongKe" [value]="p.ID_Dot" [selected]="p.ID_Dot == p.ID_Dot">{{ p.Ten_Dot }}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Phiếu đánh giá</label>
                    <select  class="form-control" ID="PhieuThongKe">
                        <option *ngFor="let p of PhieuThongKe" [value]="p.ID_Phieu" [selected]="p.ID_Phieu == p.ID_Phieu">{{ p.Ten_Phieu }}</option>
                    </select>
                </div>
            </div>
            
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="ThongKe()" >Thống kê</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Đóng</button>
    </div>
</ng-template>
<ng-template #BieuDoModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Cấu hình biểu đồ thống kê</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Đợt đánh giá</label>
                    <select  class="form-control" ID="DotBieuDo" (change)="DotBieuDoChange()">
                        <option *ngFor="let p of DotBieuDo" [value]="p.ID_Dot" [selected]="p.ID_Dot == p.ID_Dot">{{ p.Ten_Dot }}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Phiếu đánh giá</label>
                    <select  class="form-control" ID="PhieuBieuDo">
                        <option *ngFor="let p of PhieuBieuDo" [value]="p.ID_Phieu" [selected]="p.ID_Phieu == p.ID_Phieu">{{ p.Ten_Phieu }}</option>
                    </select>
                </div>
            </div>
            
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="BieuDo()" class="btn btn-primary" >Kết xuất biểu đồ</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Đóng</button>
    </div>
</ng-template>
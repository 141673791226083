import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { LoaiDanhGiaService } from "src/app/Utils/service/loai-danh-gia.service";
import { common } from "src/app/app.common";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-loai-danh-gia',
  templateUrl: './loai-danh-gia.component.html',
  styleUrls: ['./loai-danh-gia.component.scss']
})

export class LoaiDanhGiaComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  closeResult: string;
  dtOptions: DataTables.Settings;
  token: string;
  dataForm: FormGroup;
  filterForm: FormGroup;
  submitted: boolean;
  curID: number = 0;
  constructor(
    private loaiDanhGiaService: LoaiDanhGiaService,
    private toastr: ToastrService,
    public router: Router,
    private modalService: NgbModal
  ) {}
  TableData: any;
  dtTrigger = new Subject();
  public com: common;
  modalTitle: string = "";
  ngOnInit() {
    this.filterForm = new FormGroup({
      Ten_loai_danh_gia: new FormControl(null),
      Ma_loai_danh_gia: new FormControl(null)
    });

    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let Ma_loai_danh_gia = data[1];
      let Ten_loai_danh_gia = data[2];
      let bool1 = true
      let bool2 = true
      let fillter = true;
      if (this.filterForm.value.Ten_loai_danh_gia != null) {
        bool1 = this.xoa_dau(Ten_loai_danh_gia).toLowerCase().includes(this.xoa_dau(this.filterForm.value.Ten_loai_danh_gia.trim()).toLowerCase())
      }
      if (this.filterForm.value.Ma_loai_danh_gia != null) {
        bool2 = this.xoa_dau(Ma_loai_danh_gia).toLowerCase().includes(this.xoa_dau(this.filterForm.value.Ma_loai_danh_gia.trim()).toLowerCase())
      }
      fillter = bool1 && bool2;
      return fillter;
    });

    this.dataForm = new FormGroup({
      ID_loai_danh_gia: new FormControl(0),
      Ten_loai_danh_gia: new FormControl(null, Validators.required),
      Ma_loai_danh_gia: new FormControl(null, Validators.required),
      Ghi_chu: new FormControl(null),
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var user = this.com.getUserinfo();
    this.token = user.access_token;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "ID loại đánh giá",
          visible: false,
        },
        {
          title: "Mã loại đánh giá",
        },
        {
          title: "Tên loại đánh giá",
        },
        {
          title: "Ghi chú",
        },
        {
          title: "Thao tác",
          width: "15%",
          className: "dt-center",
        },
      ],
    };
    this.getAll();
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  getAll() {
    this.loaiDanhGiaService.getAll(this.token).subscribe((z) => {
      if (z.Status == 1) {
        this.TableData = z.ListData;
        this.dtTrigger.next();
      } else {
        this.toastr.error(z.Message);
      }
    });
  }

  open(content, data, type) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        scrollable: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

    if (type == "insert") {
      this.modalTitle = "Thêm loại đánh giá";
      this.dataForm.patchValue({
        ID_loai_danh_gia: 0,
        Ma_loai_danh_gia: "",
        Ten_loai_danh_gia: "",
        Ghi_chu: "",
      });
    }

    if (type == "update") {
      this.modalTitle = "Sửa loại đánh giá";
      this.dataForm.patchValue({
        ID_loai_danh_gia: data.ID_loai_danh_gia,
        Ma_loai_danh_gia: data.Ma_loai_danh_gia,
        Ten_loai_danh_gia: data.Ten_loai_danh_gia,
        Ghi_chu: data.Ghi_chu,
      });
    }
  }

  openDelete(content, data) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        scrollable: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

      this.dataForm.patchValue({
        ID_loai_danh_gia: data.ID_loai_danh_gia,
        Ma_loai_danh_gia: data.Ma_loai_danh_gia,
        Ten_loai_danh_gia: data.Ten_loai_danh_gia,
        Ghi_chu: data.Ghi_chu,
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.dataForm.invalid) {
      return;
    }
    let data = this.dataForm.value;
    if (data.ID_loai_danh_gia == 0) {
      this.loaiDanhGiaService.insert(this.token, data).subscribe((z) => {
        if (z.Status == 1) {
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          this.modalService.dismissAll('detailModal');
          this.toastr.success("Tác vụ thành công");
          this.getAll();
        } else {
          this.toastr.error(z.Message);
        }
      });
    } else {
      this.loaiDanhGiaService.update(this.token, data).subscribe((z) => {
        if (z.Status == 1) {
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          this.modalService.dismissAll('detailModal');
          this.toastr.success("Tác vụ thành công");
          this.getAll();
        } else {
          this.toastr.error(z.Message);
        }
      });
    }
  }

  delete(){
    this.loaiDanhGiaService.delete(this.token, this.dataForm.value.ID_loai_danh_gia).subscribe((z) => {
      if (z.Status == 1) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.modalService.dismissAll('deleteModal');
        this.toastr.success("Tác vụ thành công");
        this.getAll();
      } else {
        this.toastr.error(z.Message);
      }
    });
  }

  get checkvalue() {
    return this.dataForm.controls;
  }

  xoa_dau(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ|ị/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // mũ â (ê), mũ ă, mũ ơ (ư)
    return str;
  }
}


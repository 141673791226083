import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/Utils/service/app.service';
import { common } from 'src/app/app.common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SupportService } from 'src/app/Utils/service/support.service';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
  public searchForm: FormGroup;
  com: common;
  Token:any;
  UserName:string="Đang lấy dữ liệu..."
  constructor(
    @Inject(AppConfig) 
    private readonly appConfig: AppConfiguration,
    private appService: AppService,
     public router: Router,
    private spinner: NgxSpinnerService,
    public supportService: SupportService,
    private toastr: ToastrService,
    ) {}
    SupportLink : any;
  ngOnInit() {
    this.com = new common(this.router);
    var User = this.com.getUserinfo();
    this.UserName = "";
    // this.UserName = User.Info.FullName
    this.Token=User.access_token;
    this.searchForm = new FormGroup({
      search: new FormControl(null)
    });
    
  }
  getSupportLink() {
    this.spinner.show();
    this.supportService.GetSupportLink(this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.SupportLink = z;
        const urlSupport = this.appConfig.SupportUrl+"?UniCode="+z.Message+"&code_security="+this.appConfig.code_security;
        window.open(urlSupport, '_blank');
      }
      else {
        this.toastr.warning(z.Message)
      }
      this.spinner.hide();
    });
  }

  logout() {
    var r = confirm("Bạn có muốn đăng xuất không?");
    if (r) {
      if (this.appConfig.AuthorizeType == 1 || (this.appConfig.sso != null && this.appConfig.AuthorizeType== null)) {
        // Đăng xuất SSO
        localStorage.removeItem("UserInfo");
        window.location.href =
          this.appConfig.sso.ssoServer +
          "connect/endsession?post_logout_redirect_uri=" +
          this.appConfig.sso.redirect_uri;
      } 

      if (this.appConfig.AuthorizeType == 0 || ((this.appConfig.sso == null && this.appConfig.AuthorizeType == null))) {
        localStorage.removeItem("UserInfo");
        this.com.redirectToLoginSystem();
      }

      if (this.appConfig.AuthorizeType == 2) {
        localStorage.removeItem("UserInfo");
        window.location.href= this.appConfig.CAS.Logout;
      }
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from 'src/app/Components/main/main.component';
import { LoginComponent } from 'src/app/Components/login/login.component';
import { HeaderComponent } from 'src/app/Components/main/header/header.component';
import { FooterComponent } from 'src/app/Components/main/footer/footer.component';
import { MenuSidebarComponent } from 'src/app/Components/main/menu-sidebar/menu-sidebar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from 'src/app/Components/register/register.component';
import { DashboardComponent } from 'src/app/Components/pages/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesDropdownMenuComponent } from 'src/app/Components/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import { NotificationsDropdownMenuComponent } from 'src/app/Components/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule }   from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HttpClientModule } from '@angular/common/http';
import { MessageComponent } from './Components/pages/message/message.component';
import { CreateComponent } from './Components/pages/message/create/create.component';
import { ReadComponent } from './Components/pages/message/read/read.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { UserDropdownMenuComponent } from './Components/main/header/user-dropdown-menu/user-dropdown-menu.component';
import { ChangePassComponent } from './Components/pages/change-pass/change-pass.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { QuestionsBankComponent } from './Components/pages/questions-bank/questions-bank.component';
import { SurveyComponent } from './Components/pages/survey/survey.component';
import { SeasonComponent } from './Components/pages/season/season.component';
import { EnterpriseComponent } from './Components/pages/enterprise/enterprise.component';
import { SearchObjectComponent } from './Components/pages/search-object/search-object.component';
import { PortalEnterpriseComponent } from './Components/pages/portal-enterprise/portal-enterprise.component';
import { ReportComponent } from './report/report.component';
import { ReportManagementComponent } from './report-management/report-management.component';
import { SearchObjectListComponent } from './Components/pages/search-object-list/search-object-list.component';
import { ObjectListComponent } from './Components/pages/object-list/object-list.component';
import { LoginUniSurveyPortalComponent } from './Components/login-uni-survey-portal/login-uni-survey-portal.component';
import { PortalComponent } from './Components/portal/portal.component';
import { HeaderPortalComponent } from './Components/portal/header-portal/header-portal.component';
import { MenuSidebarPortalComponent } from './Components/portal/menu-sidebar-portal/menu-sidebar-portal.component';
import { FooterPortalComponent } from './Components/portal/footer-portal/footer-portal.component';
import { UserDropdownMenuPortalComponent } from './Components/portal/header-portal/user-dropdown-menu-portal/user-dropdown-menu-portal.component';
import { HomeComponent } from './Components/portal/home/home.component';
import { EnterprisePortalComponent } from './Components/portal/enterprise-portal/enterprise-portal.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy, registerLocaleData  } from '@angular/common';
import { TeacherComponent } from './Components/portal/teacher/teacher.component';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { SearchStudentTeacherComponent } from './Components/pages/search-student-teacher/search-student-teacher.component';
import { BaseComponent } from './base/base.component';
import { VersionDetailComponent } from './Components/pages/version-detail/version-detail.component';
import { VerificationComponent } from './Components/login/verification/verification.component';
import { UserVerificationComponent } from './Components/login-uni-survey-portal/user-verification/user-verification.component';
import { SingleSignOnVerificationComponent } from './Components/login/single-sign-on-verification/single-sign-on-verification.component';
import { VerificationStudentComponent } from './Components/login-uni-survey-portal/verification-student/verification-student.component';
import { LoaiDanhGiaComponent } from './Components/pages/loai-danh-gia/loai-danh-gia.component';
import { NhomCauHoiComponent } from './Components/pages/nhom-cau-hoi/nhom-cau-hoi.component';
import { UserComponent } from './Components/portal/user/user.component';
import { SearchObjectUserComponent } from './Components/pages/search-object-user/search-object-user.component';
import { PhanPhoiTinChiSinhVienGiangVienComponent } from './Components/pages/phan-phoi/phan-phoi-tin-chi-sinh-vien-giang-vien/phan-phoi-tin-chi-sinh-vien-giang-vien.component';
import { KhaoSatGiangVienComponent } from './Components/pages/phan-phoi/giang-vien/khao-sat-giang-vien/khao-sat-giang-vien.component';
import { KhaoSatCanBoComponent } from './Components/pages/phan-phoi/khao-sat-can-bo/khao-sat-can-bo.component';
import { KhaoSatDoanhNghiepComponent } from './Components/pages/phan-phoi/khao-sat-doanh-nghiep/khao-sat-doanh-nghiep.component';
import { KhaoSatCoVanHocTapComponent } from './Components/pages/phan-phoi/khao-sat-co-van-hoc-tap/khao-sat-co-van-hoc-tap.component';
import { PhanPhoiPhieuLopHanhChinhComponent } from './Components/pages/phan-phoi/phan-phoi-phieu-lop-hanh-chinh/phan-phoi-phieu-lop-hanh-chinh.component';
import { PhanPhoiLopNienCheComponent } from './Components/pages/phan-phoi/phan-phoi-lop-nien-che/phan-phoi-lop-nien-che.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';

registerLocaleData(en);



@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    MessageComponent,
    CreateComponent,
    ReadComponent,
    UserDropdownMenuComponent,
    ChangePassComponent,
    QuestionsBankComponent,
    SurveyComponent,
    SeasonComponent,
    EnterpriseComponent,
    SearchObjectComponent,
    PortalEnterpriseComponent,
    ReportComponent,
    ReportManagementComponent,
    SearchObjectListComponent,
    ObjectListComponent,
    LoginUniSurveyPortalComponent,
    PortalComponent,
    HeaderPortalComponent,
    MenuSidebarPortalComponent,
    FooterPortalComponent,
    UserDropdownMenuPortalComponent,
    HomeComponent,
    EnterprisePortalComponent,
    TeacherComponent,
    SearchStudentTeacherComponent,
    BaseComponent,
    VersionDetailComponent,
    VerificationComponent,
    SingleSignOnVerificationComponent,
    VerificationStudentComponent,
    LoaiDanhGiaComponent,
    NhomCauHoiComponent,
    UserComponent,
    UserVerificationComponent,
    SearchObjectUserComponent,
    PhanPhoiTinChiSinhVienGiangVienComponent,
    KhaoSatGiangVienComponent,
    KhaoSatCanBoComponent,
    KhaoSatDoanhNghiepComponent,
    KhaoSatCoVanHocTapComponent,
    PhanPhoiPhieuLopHanhChinhComponent,
    PhanPhoiLopNienCheComponent
  ],
  imports: [
    CKEditorModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DataTablesModule,
    FullCalendarModule ,
    NgbModule,
    NgxSpinnerModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    // import HttpClientModule after BrowserModule.
    HttpClientModule,
    FormsModule,
    NzSelectModule,
    NzFormModule
  ],
  providers: [CookieService,
     {provide : LocationStrategy, useClass: PathLocationStrategy},
     { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

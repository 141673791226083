import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnterpriseService } from 'src/app/Utils/service/enterprise.service';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup ,FormBuilder, Validators, MaxLengthValidator } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PhoneValidator } from 'src/Shared/Customvalidators'
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportService } from 'src/app/Utils/service/export.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.scss']
})
export class EnterpriseComponent implements OnInit {
  dtOptions: DataTables.Settings
  Token: string
  Res: any;
  enterpriseData : any
  dataEnterprise :any = []
  dataEnterpriseDetail :any = []
  closeResult: string
  ID_doanh_nghiep: any
  ClassData: any;
  fileUploaded: File;
  storeData: any;
  worksheet: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  checkInsert :boolean
  submitted = false
  NameTitle:string
  Title: string = "Đang tải danh sách đợt đánh giá..."
  TitleClass: string = "spinner-border text-muted"

  //data enterprise
  EnterpriseFormInsert = new FormGroup({
    ID_doanh_nghiep: new FormControl(null),
    Ten_doanh_nghiep: new FormControl(null, [Validators.required ,Validators.maxLength(100)]),
    Dia_chi: new FormControl(null ,[ Validators.required, Validators.maxLength(250)]),
    So_dien_thoai: new FormControl(null ,[Validators.required]),
    Nguoi_lien_he: new FormControl(null ,Validators.maxLength(50)),
    SDT_nguoi_lien_he: new FormControl(null ,[Validators.required]),
    Chuc_vu: new FormControl(null ,Validators.maxLength(50)),
    Email_1: new FormControl(null ,[Validators.required, Validators.email ]),
    Email_2: new FormControl(null ,[ Validators.email ]),
    Email_3: new FormControl(null ,[Validators.email ]),
    Ma_so_thue: new FormControl(null, [Validators.required])
  })

  constructor(
    @Inject(AppConfig) 
    private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    private EnterpriseService: EnterpriseService,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public exportService: ExportService,
    ) { }
    dtTrigger = new Subject();
    dt2Trigger = new Subject();
    public com: common;
ngOnInit() {
  let element, name, arr;
  element = document.getElementById("menu4");
  arr = element.className.split(" ");
  name = "menu-open";
  if (arr.indexOf(name) == -1) {
      element.className += " " + name;
  }
      this.EnterpriseFormInsert;

      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.Token = a.access_token;
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        ordering:false,
        language: {
          processing: "Đang xử lý...",
          lengthMenu: "Xem _MENU_ mục",
          emptyTable: "Không có dữ liệu!",
          info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
          infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
          infoFiltered: "(được lọc từ _MAX_ mục)",
          infoPostFix: "",
          search: "Tìm kiếm nhanh:",
          url: "",
          searchPlaceholder: "Nhập từ khóa cần tìm...",
          paginate: {
            first: "Đầu",
            previous: "Trước",
            next: "Tiếp",
            last: "Cuối"
          }
        },
        columns: [
          {
            title: 'Mã số thuế',
            data: 'ID_doanh_nghiep',

          },
          {
            title: 'Tên doanh nghiệp',
            data: 'Ten_doanh_nghiep',

          },
          {
            title: 'Số điện thoại',
            data: 'So_dien_thoai',

          },
          {
            title: 'Địa chỉ',
            data: 'Dia_chi',

          },
          {
            title:'Người liên hệ',
            data: 'Nguoi_lien_he',

          },
          {
            title:"SĐT liên hệ",
            data: 'SDT_nguoi_lien_he',

          },
          {
            title:'Chức vụ',
            data:'Chuc_vu',

          },
          {
            title: 'Email',
            data: 'Email_1',
            visible: false,
          },
          {
            title: 'Tác vụ',
            width: "14%",
            className: "dt-center",
          },
        ],
      };
      this.getEnterpriseAll(a.access_token);
    }
//display enterprise All
getEnterpriseAll(token) {
    this.EnterpriseService.getListEnterprise(token)
    .subscribe(z => {
      this.Res = z
      this.dataEnterprise = this.Res.Data
      this.dtTrigger.next();
      this.Title = ""
      this.TitleClass = ""
     } );
  }
  //get
openModal(content,sizm, Loai, Data ) {
  this.submitted = false;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    if (Loai == "Sua") {
      this.EnterpriseFormInsert.get("Ma_so_thue").setValue(Data.Ma_so_thue);
      this.EnterpriseFormInsert.get("ID_doanh_nghiep").setValue(Data.ID_doanh_nghiep);
      this.EnterpriseFormInsert.get("Ten_doanh_nghiep").setValue(Data.Ten_doanh_nghiep.trim());
      this.EnterpriseFormInsert.get("Dia_chi").setValue(Data.Dia_chi.trim());
      this.EnterpriseFormInsert.get("So_dien_thoai").setValue(Data.So_dien_thoai.trim());
      this.EnterpriseFormInsert.get("Nguoi_lien_he").setValue(Data.Nguoi_lien_he.trim());
      this.EnterpriseFormInsert.get("Chuc_vu").setValue(Data.Chuc_vu.trim());
      this.EnterpriseFormInsert.get("SDT_nguoi_lien_he").setValue(Data.SDT_nguoi_lien_he.trim());
      this.EnterpriseFormInsert.get("Email_1").setValue(Data.Email_1.trim());
      this.EnterpriseFormInsert.get("Email_2").setValue(Data.Email_2.trim());
      this.EnterpriseFormInsert.get("Email_3").setValue(Data.Email_2.trim());
      this.ID_doanh_nghiep = Data.ID_doanh_nghiep;
      this.NameTitle = "Sửa Doanh Nghiệp";
      this.checkInsert = true;
    } else if (Loai == "Xoa") {
      this.ID_doanh_nghiep = Data.ID_doanh_nghiep;
    }else if(Loai == "Them"){
      this.EnterpriseFormInsert.reset();
      this.NameTitle = "Thêm Doanh Nghiệp";
      this.checkInsert = false;
    }else if(Loai == "ChiTiet") {
      this.NameTitle = "Thông Tin Chi Tiết";
      this.dataEnterpriseDetail = null;
      this.dataEnterpriseDetail = Data;
    }
    else if(Loai == "Import_Excel")
    {

    }
    else{
      this.toastr.warning("Lỗi hệ thống");
    }

  }
//check valu
get checkvalu(){ return this.EnterpriseFormInsert.controls; }

onSubmit(){
  this.submitted = true;
  console.log(this.checkvalu)
  if (this.EnterpriseFormInsert.invalid) {
    return;
  }
  this.createEnterprise();
}

onReset() {
  this.submitted = false;
  this.EnterpriseFormInsert.reset();
}
// create enterprise
createEnterprise(){
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let  EnterpriseDatas = {
        ID_doanh_nghiep: this.EnterpriseFormInsert.get("ID_doanh_nghiep").value,
        Ten_doanh_nghiep:$('#Ten_doanh_nghiep').val(),
        Dia_chi : $('#Dia_chi').val(),
        So_dien_thoai: $('#So_dien_thoai').val(),
        Nguoi_lien_he:$('#Nguoi_lien_he').val(),
        Chuc_vu: $('#Chuc_vu').val(),
        SDT_nguoi_lien_he: $('#SDT_nguoi_lien_he').val(),
        Email_1: $('#Email_1').val(),
        Email_2: $('#Email_2').val(),
        Email_3: $('#Email_3').val(),
        Ma_so_thue: $('#Ma_so_thue').val()
     };
     console.log(EnterpriseDatas)
     if(this.checkInsert == false ){
      let req={EnterpriseData : EnterpriseDatas}
        this.EnterpriseService.InsertEnterprise(req, a.access_token)
        .subscribe(z => {
          if (z.Status == 2) {
            this.toastr.warning(z.Message)
          } else if (z.Status == 1) {
            this.toastr.success(z.Message)
            this.EnterpriseFormInsert.reset();
            this.submitted = false;
          } else {
            this.toastr.error(z.Message)
          }
          this.modalService.dismissAll('DeleteModal');
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          this.getEnterpriseAll(a.access_token)
        }, 
        error=>{
          if(error.status = 401)
          {
            this.toastr.error("Bạn không có quyển thực hiện chức năng này !")
          }else{
            this.toastr.error(error.Message)
          }
        });
     }else{
      let req={EnterpriseData : EnterpriseDatas}
        this.EnterpriseService.UpdateEnterprise(req, a.access_token)
        .subscribe(z => {
          this.ID_doanh_nghiep = '';
          if (z.Status == 2) {
            this.toastr.warning(z.Message)
          } else if (z.Status == 1) {
            this.toastr.success(z.Message)
          } else {
            this.toastr.error(z.Message)
          }
          this.modalService.dismissAll('DeleteModal');
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          this.getEnterpriseAll(a.access_token)
        }, 
        error=>{
          if(error.status = 401)
          {
            this.toastr.error("Bạn không có quyển thực hiện chức năng này !")
          }else{
            this.toastr.error(error.Message)
          }
        });
     }
}
//delete enterorise
Delete(id){
  var Cf = confirm("Bạn có chắc chắn muốn xóa?");
  if (Cf) {
     this.com = new common(this.router);
     this.com.CheckLogin();
     var a = this.com.getUserinfo();
     let RequestBase = {
      RequestID: id
    }
    this.EnterpriseService.DeleteEnterPrise(RequestBase, a.access_token)
      .subscribe(z => {
        this.ID_doanh_nghiep = '';
        if (z.Status == 2) {
          this.toastr.warning(z.Message)
        } else if (z.Status == 1) {
          this.toastr.success(z.Message)
        } else {
          this.toastr.error(z.Message)
        }
        this.modalService.dismissAll('DeleteModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getEnterpriseAll(a.access_token)
      }, 
      error=>{
        if(error.status = 401)
        {
          this.toastr.error("Bạn không có quyển thực hiện chức năng này !")
        }else{
          this.toastr.error(error.Message)
        }
      });
  }}
//modal
private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setStyle(x:any) {
        let floorElements = document.getElementById(x);
        floorElements.setAttribute("style","border: 10px");
  }
  help() {
    const urlHelp = this.appConfig.SurveyURL_Support+"?"+this.appConfig.document_SeasonURL ;
    window.open(urlHelp,'_blank');
  }
  XuatExcel() {
    this.spinner.show();
    this.EnterpriseService.Export({ListData: this.dataEnterprise}, this.Token).subscribe((z) => {
       if (z.Status == 1) {
         this.exportService.exportExcelByte(z.FileData, "Danh_sach_doanh_nghiep");
         this.toastr.success("File đã được tải xuống", "Tác vụ thành công");
         this.spinner.hide();
       } else {
         this.toastr.error(z.Message);
         this.spinner.hide();
       }
    })
  }
  uploadedFile(event) {
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {
    let readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      var data = new Uint8Array(this.storeData);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });
      var first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }
  ImportExcel() {
    this.spinner.show();
    var lstCols = ['A', 'B', 'C', 'D', 'E', 'G', 'H', 'I', 'J'];
    if (this.worksheet == undefined) {
      this.toastr.warning('Bạn phải chọn file để ấn lưu.!');
      this.spinner.hide();
    } else {
      var ref = this.worksheet['!ref'];
      var row = ref.substring(4, ref.lenght);
      let lstData: any = [];
      for (let index = 4; index <= row; index++) {
        var obj = {
          Ma_so_thue: "",
          Ten_doanh_nghiep: "",
          Dia_chi: '',
          So_dien_thoai: '',
          Nguoi_lien_he: '',
          Chuc_vu: '',
          SDT_nguoi_lien_he: '',
          Email_1: "",
          Email_2: "",
          Email_3: ""
        };
        if (
          this.worksheet['B' + index] != undefined ||
          this.worksheet['B' + index] != null
        ) {
          obj.Ma_so_thue = this.worksheet['B' + index].v;
        }
        if (
          this.worksheet['C' + index] != undefined ||
          this.worksheet['C' + index] != null
        ) {
          obj.Ten_doanh_nghiep = this.worksheet['C' + index].v;
        }
        if (
          this.worksheet['D' + index] != undefined ||
          this.worksheet['D' + index] != null
        ) {
          obj.Dia_chi = this.worksheet['D' + index].v;
        }

        if (
          this.worksheet['E' + index] != undefined ||
          this.worksheet['E' + index] != null
        ) {
          obj.So_dien_thoai = this.worksheet['E' + index].v;
        }
        if (
          this.worksheet['F' + index] != undefined ||
          this.worksheet['F' + index] != null
        ) {
          obj.Nguoi_lien_he = this.worksheet['F' + index].v;
        }
        if (
          this.worksheet['G' + index] != undefined ||
          this.worksheet['G' + index] != null
        ) {
          obj.SDT_nguoi_lien_he = this.worksheet['G' + index].v;
        }
        if (
          this.worksheet['H' + index] != undefined ||
          this.worksheet['H' + index] != null
        ) {
          obj.Email_1 = this.worksheet['H' + index].v;
        }
        if (
          this.worksheet['I' + index] != undefined ||
          this.worksheet['I' + index] != null
        ) {
          obj.Chuc_vu = this.worksheet['I' + index].v;
        }

        if(obj.Ma_so_thue != "")
        {
          lstData.push(obj);
        }
        // this.modalService.dismissAll('Import_Excel');
      }
      console.log(lstData);
      if(lstData.length > 0)
      {
        this.EnterpriseService.Import({ListData: lstData}, this.Token).subscribe(
          (z) => {
            if(z.Status === 1)
            {
              this.spinner.hide();
              this.modalService.dismissAll('Import_Excel');
              this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              });
              this.getEnterpriseAll(this.Token);
              this.toastr.success(z.Message);
            }
            else{
              this.spinner.hide();
              this.modalService.dismissAll('Import_Excel');
              this.toastr.error(z.Message);
            }
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err.Message);
            this.modalService.dismissAll('Import_Excel');
          }
        );
      }
      else{
        this.spinner.hide();
        this.toastr.warning("Danh sách không chứa doanh nghiệp cần thêm mới");
        this.modalService.dismissAll('Import_Excel');
      }
    }
  }
}

<li class="nav-item dropdown">
  <a class="nav-link" (click)="toggleDropdownMenu()">
    <i class="fas fa-envelope cs-i-w"></i>
    <span class="badge badge-danger navbar-badge" id="UnReadNum">{{UnReadNum}}</span>
  </a>
  <div #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right" >
    <div *ngFor="let p of MessageLst">
      <a [routerLink]="['/read']" [queryParams]="{ID: p.ID}" queryParamsHandling="merge" class="dropdown-item">
        <div class="media">
          <div class="media-body">
            <h3 class="dropdown-item-title">
             {{p.FromUserName}}: {{p.Title}}
          </h3>
          </div>
        </div>
      </a>
      <div class="dropdown-divider"></div>
    </div>
    <a [routerLink]="['/message']"  class="dropdown-item dropdown-footer">Xem tất cả trong tin nhắn</a>
  </div>
</li>

import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
@Injectable({
  providedIn: "root",
})
export class BaseService {
  access_code: any = "";
  constructor() {
    var us= JSON.parse(localStorage.getItem("UserInfo"));
    this.access_code = (us!=null && us.access_code !=null)?us.access_code : "";
  }
  public getUserinfo() {
    return JSON.parse(localStorage.getItem("UserInfo"));
  }

  public handleError(error: HttpErrorResponse) {
    console.log(error.status);
    if (error.status == 0) {
      localStorage.removeItem('UserInfo');
      window.location.href="/";
    };
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}

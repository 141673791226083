import { Component, OnInit, ViewChild } from "@angular/core";
import { AppService } from "src/app/Utils/service/app.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { SearchObjectService } from "src/app/Utils/service/search-object.service";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { common } from "src/app/app.common";
import { SeasonService } from "src/app/Utils/service/season.service";
import { ReportService } from "src/app/Utils/service/report.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BaseComponent } from "../../../base/base.component";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ExportService } from "src/app/Utils/service/export.service";
import { FormControl, FormGroup } from "@angular/forms";
import { PhongBanService } from "src/app/Utils/service/phong-ban.service";
@Component({
  selector: "app-search-object-user",
  templateUrl: "./search-object-user.component.html",
  styleUrls: ["./search-object-user.component.scss"],
})
export class SearchObjectUserComponent implements OnInit {
  dtOptions: DataTables.Settings;
  dataTable: any = [];
  Res: any;
  Req: any;
  TableData: any;
  constructor(
    public router: Router,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
    public seasonService: SeasonService,
    public searchObjectService: SearchObjectService,
    public ReportService: ReportService,
    public exportService: ExportService,
    public toastr: ToastrService,
    private phongBanService: PhongBanService,
    private SeasonService: SeasonService
  ) {}

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  TieuDe: string;
  Title: string = "Đang tải danh sách thông báo...";
  TitleClass: string = "spinner-border text-muted";
  public Ma_sv: string = "";
  public Ma_cb: string = "";
  public Da_danh_gia: any = 0;
  public ID_dot_danh_gia: any = 0;
  public ID_he: any = 0;
  public ID_khoa: any = 0;
  public Khoa_hoc: any = 0;
  public ID_lop: any = 0;
  public ID_phieu_danh_gia: any = 0;
  public ListSeason: any;
  public ListPhieu: any;
  public ListPhieuDanhGia: any;
  public ListHe: any;
  public ListKhoa: any;
  public ListKhoa2: any;
  public ListKhoaHoc: any;
  public ListKhoaHoc2: any;
  public ListChuyenNganh: any;
  public ListChuyenNganh2: any;
  public ListLop: any;
  public ListLop2: any;
  public He: any = 0;
  public Khoa: any = 0;
  public KhoaHoc: any = 0;
  public Lop: any = 0;
  Token: any;
  public arrNumberPage: any = [];
  arrNumberPage_chil: any = [];
  numberPage: any;
  page: any = 1;
  public pageSize: any = 10;
  public pageSizes: any = [10, 20, 50, 100, 200, 500, 1000, "Tất cả"];
  public getNull = false;
  public totalItem: number;
  totalItemFilter: any;
  isDisplay: boolean = true;
  submitted = false;
  closeResult: string;
  dataPopup: any = {};
  lstPhongBan: any = [];
  lstKhoas: any = [];
  public com: common;
  IDRecord: any;
  ID_gui: any;
  filterForm: FormGroup;
  ngOnInit() {
    this.filterForm = new FormGroup({
      Ma_cb: new FormControl(null),
      Ho_ten: new FormControl(null),
      ID_phong: new FormControl(0),
      ID_phieu: new FormControl(0),
      ID_dot: new FormControl(0),
      Danh_gia: new FormControl(0),
      ID_khoa: new FormControl(0),
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token;
    this.getList();
    this.getCount();
    this.getPhongBan();
    this.getKhoa();

    this.seasonService.getListSeason(a.access_token).subscribe((z) => {
      this.ListSeason = z.Data;
    });

    this.ReportService.PhieuDanhGia_Load_List_Dot(a.access_token).subscribe(
      (z) => {
        const ls = new Array();
      if (z.Data) {
        const uniqueSurveys: Set<number> = new Set();
        for (var i = 0; i < z.Data.length; i++) {
          const survey = z.Data[i].Survey;
          
          if (!uniqueSurveys.has(survey.ID_phieu_danh_gia)) {
            uniqueSurveys.add(survey.ID_phieu_danh_gia);
            ls.push(survey);
          }
        }
      }
      this.ListPhieuDanhGia = ls;
      }
    );
  }

  getPhongBan() {
    this.phongBanService.getAll(this.Token).subscribe((z) => {
      this.lstPhongBan = z.sort((a, b) => (a.Phong > b.Phong ? 1 : -1));
    });
  }

  HeChange() {
    let ID_he = this.He;
    if (ID_he > 0) {
      this.ListKhoa2 = this.ListKhoa.filter((g) => g.ID_he == ID_he);
      this.ListKhoaHoc2 = this.ListKhoaHoc.filter((g) => g.ID_he == ID_he);
      this.ListLop2 = this.ListLop.filter((g) => g.ID_he == ID_he);
    } else {
      this.ListKhoa2 = [];
      this.ListKhoaHoc2 = [];
      this.ListLop2 = [];
    }
  }
  open(content, sizm, Loai, Data) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == "Delete") {
      this.ID_gui = Data.ID_gui;
    }

    if (Loai == "Delete") {
      this.ID_gui = Data.ID_gui;
    }
  }

  getKhoa() {
    this.SeasonService.getKhoa(this.Token).subscribe((z) => {
      this.lstKhoas = z.sort((a, b) => (a.Ten_khoa > b.Ten_khoa ? 1 : -1));
    });
  }

  ngOnDestroy(): void {
    $.fn["dataTable"].ext.search.pop();
  }

  public getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  createNumberPage(totalItem, pageSize) {
    let numberPage = 0;
    let arrNumberPage = [];
    if (totalItem % pageSize == 0) {
      numberPage = Math.floor(totalItem / pageSize);
    } else {
      numberPage = Math.floor(totalItem / pageSize) + 1;
    }
    for (var i = 1; i <= numberPage; i++) {
      arrNumberPage.push(i);
    }
    let arrNumberPage_chil = [];
    if (arrNumberPage.length > 4) {
      for (var i = 1; i <= 4; i++) {
        arrNumberPage_chil.push(i);
      }
    } else {
      arrNumberPage_chil = arrNumberPage;
    }
    return {
      numberPage: numberPage,
      arrNumberPage_chil: arrNumberPage_chil,
    };
  }

  buttonFilter() {
    this.isDisplay = !this.isDisplay;
  }

  refesh() {
    this.filterForm.reset();
    this.getList();
  }

  handlePageChange(event) {
    if (event == "pre") {
      this.page = this.page - 1;
    } else if (event == "next") {
      this.page = this.page + 1;
    } else {
      this.page = event;
      this.arrNumberPage_chil = [];
      for (var i = event - 3; i <= event + 3; i++) {
        if (i > 0 && i <= this.numberPage) {
          this.arrNumberPage_chil.push(i);
        }
      }
    }
  }

  XuatExcel() {
    this.spinner.show();
    let dataFilter = this.filterForm.value;
    let param = {
      So_ban_ghi: 1000000,
      Trang: this.page,
      dataFilter: dataFilter,
    };
    this.searchObjectService.ExcelUser(param, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(z.FileData, "Phan_phoi_can_bo");
        this.toastr.success("File đã được tải xuống", "Tác vụ thành công");
        this.spinner.hide();
      } else {
        this.toastr.error(z.Message);
        this.spinner.hide();
      }
    });
  }

  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = this.filterForm.value;

    let param = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      dataFilter: dataFilter,
    };

    this.searchObjectService
      .getTraCuuCanBo(param, this.Token)
      .subscribe((z) => {
        this.dataTable = z.ListData;
        this.spinner.hide();
      });
  }

  xoa_dau(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ|ị/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)
    return str;
  }

  getCount() {
    this.searchObjectService.getCountCanBo(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
  }

  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = this.filterForm.value;
    let param = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      dataFilter: dataFilter,
    };
    this.searchObjectService
      .getTraCuuCanBo(param, this.Token)
      .subscribe((z) => {
        this.dataTable = z.ListData;
        this.searchObjectService
          .getCountFilterCanBo(param, this.Token)
          .subscribe((z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          });
      });
  }

  handlePageSizeChange(event) {
    if (event.target.value == "Tất cả") {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }

  searchObject(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  Search() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = this.filterForm.value;
    let param = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      dataFilter: dataFilter,
    };
    this.searchObjectService
      .getTraCuuCanBo(param, this.Token)
      .subscribe((z) => {
        this.dataTable = z.ListData;
        this.searchObjectService
          .getCountFilterCanBo(param, this.Token)
          .subscribe((z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          });
      });
  }

  Delete() {
    this.spinner.show();
    this.searchObjectService
    .Delete(this.ID_gui, this.Token)
    .subscribe((res) => {
      this.spinner.hide();
        if (res.Status == 1) {
          this.getList();
          this.getCount();
          this.toastr.success(res.Message);
          this.modalService.dismissAll("");
        } else {
          this.toastr.warning(res.Message);
        }
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem("UserInfo");
          this.router.navigate(["/login"]);
        }
        if (err.status == 401) {
          this.toastr.warning(
            "Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!"
          );
        }
      }
    );
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
}


<ng-template #mymodal let-modal>
  <div class="modal-header m-header text-center"style="
  padding-top: 0px;
  padding-bottom: 0px;
">
    <h4 class="modal-title h-title w-100" >Danh sách người nhận</h4>
    <button type="button" class="close" aria-label="Close" style="font-size:30px;" data-dismiss="modal" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="input-group mb-3">
        <input type="text" id="auto" class="form-control" (keyup.enter)="onKey()"
            (input)="Search=$event.target.value" placeholder="Nhập tài khoản người nhận">
        <div class="input-group-append">
            <button class="btn btn-outline-primary" (click)="onKey()" type="button"
                id="button-addon2">Tìm người nhận</button>
        </div>
    </div>
    <div style="max-height: 200px;overflow: scroll;">
        <ul id="ToUser" *ngFor="let p of SeacherUserNames">
            <li>
                <div class="row">
                    <div class="col-md-8" style="max-width:400px ;"> {{ p }} </div>
                    <div class="col-md-4"><button class="btn btn-primary" (click)="Select(p)" type="button">Chọn</button></div>
                </div>
            </li>
        </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Đóng</button>
  </div>
</ng-template>

<section class="content-header" style="padding:0.5% !important">
    <div class="container-fluid">
        <div class="row">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['/']">Trang chủ</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="['/message']">Tin nhắn</a></li>
                    <li class="breadcrumb-item active">Soạn thư</li>
                </ol>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <!-- /.col -->
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-header" style="padding: 4px;">
                        <div class="row" >
                            <div class="col-md-6">
                                <!-- <h3 class="card-title" style="text-align: center;"><i class="fas fa-envelope-open-text"></i>&nbsp; Tạo tin nhắn</h3> -->
                                <a (click)="backClicked()" class="btn btn-block btn-default btn-sm" ><i
                                    class="fas fa-arrow-left"></i> Quay lại</a>
                            </div>
                            <div class="col-md-6">
                                <button class="btn btn-block btn-primary btn-sm" (click)="open(mymodal)"><i class="fas fa-search" ></i> Thêm người nhận</button>
                            </div>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="form-group">
                            <div class="row">
                                <label style="font-size: 15px;">Gửi đến ({{SelectedUserNames.length}}) : </label>
                                        <ul id="ToUser" *ngFor="let p of SelectedUserNames" style="padding-left:5px;">
                                                <span class=" btn btn-block btn-default btn-xs"> 
                                                    <label > {{ p }} </label>&nbsp;
                                                    <a  (click)="Remove(p)" ><i class="far fa-window-close" style="color: red;"></i></a>
                                                </span>
                                        </ul>
                            </div>


                        </div>
                        <div class="form-group">
                            <label>Tiêu đề</label>
                            <input class="form-control" (input)="Title=$event.target.value"  placeholder="Tiêu đề:">
                        </div>
                        <div class="form-group">
                            <label>Nội dung</label>
                            <ckeditor [editor]="Editor"  (change)="onChange($event)"></ckeditor>
                        </div>
                        <div class="form-group">
                            <div class="btn btn-default btn-file">
                                <i class="fa fa-paperclip"></i> Đính kèm tệp
                                <input type="file"  (change)="handleFileInput($event.target.files)" id="fileattack3" class="form-control" />
                            </div>
                            <p class="help-block" id="fileName">{{fileName}}</p>
                        </div>
                    </div>
                    <!-- /.card-body -->
                    <div class="card-footer">
                        <div class="float-right">
                            <button type="button" (click)="SendMessage()" class="btn btn-block btn-success btn-sm"><i class="fas fa-paper-plane"></i> Gửi</button>
                        </div>
                    </div>
                    <!-- /.card-footer -->
                </div>
                <!-- /. box -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
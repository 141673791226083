<section class="content-header" style="padding:0.5%  !important">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item"><a [routerLink]="['/']"> <i  class="fa-home fas"></i>Trang chủ</a></li>
          <li class="breadcrumb-item active" [routerLink]="['/Enterprise']">Doanh nghiệp</li> &nbsp;
        </ol>
      </div><!-- /.col -->
      <div class="col-sm-6"style="text-align: right;">
        <button class="btn btn-warning btn-sm" (click)="help()"  type="submit" style="float: right;margin-right: 5px; ">
          <i class="fas fa-info-circle"></i>
          Hướng dẫn
        </button>
        <button (click)="openModal(AddModal ,'xl','Them','' )" style="float: right;margin-right:10px; " class=" btn btn-sm btn-success"> <i class="fa-plus-circle fas"></i> Thêm mới</button>
        <button (click)="openModal(Import_Excel ,'xs','Import_Excel','' )" style="float: right;margin-right:10px; background-color: #008b8b;" class=" btn btn-sm btn-success"><i class="icon fas fa-file-excel"></i> Import</button>
        <button (click)="XuatExcel()" style="float: right;margin-right:10px; background-color: #d2691e; color: #fff;" class=" btn btn-sm"> <i class="icon fas fa-download"></i> Xuất excel</button>
         
      </div><!-- /.col -->
    </div><!-- /.row -->
  </div><!-- /.container-fluid -->
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
            <div class="row">
              <!-- <div class="{{TitleClass}}"></div>
              <div class=" col-md-3">
                  <h3 class="card-title">{{Title}}</h3>
              </div>
            </div> -->
            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table-bordered dataTables_wrapper no-footer">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of dataEnterprise">
                    <td>{{ p.Ma_so_thue }}</td>
                    <td>{{ p.Ten_doanh_nghiep }}</td>
                    <td>{{ p.So_dien_thoai }}</td>
                    <td>{{ p.Dia_chi }}</td>
                    <td>{{ p.Nguoi_lien_he }}</td>
                    <td>{{ p.SDT_nguoi_lien_he }}</td>
                    <td>{{ p.Chuc_vu }}</td>
                    <td>{{ p.Email_1 }}</td>
                    <td>
                      <button class="btn btn-sm btn-primary" (click)="openModal(Details,'xl','ChiTiet',p)" >
                          <i class="fa-eye fas" title="Xem chi tiết"></i>
                      </button>
                      <button class="btn btn-sm btn-warning" (click)="openModal(AddModal,'xl','Sua',p)"  style="margin-left: 7px; margin-right: 7px;" >
                          <i class="fas fa-edit" title="Sửa câu hỏi"></i>
                      </button>
                      <button class="btn btn-sm btn-danger" (click)="Delete(p.ID_doanh_nghiep)"   >
                          <i class="fas fa-trash-alt" title="Xóa câu hỏi"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.mail-box-messages -->
          </div>
          <!-- /.card-body -->
        </div>
      </div>
      <!-- /.col -->
    </div>
  </div>
</section>
<!-- tao moi doanh nghiep --><!-- sua doanh nghiep -->
<ng-template #AddModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px;padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100">{{ NameTitle }}</h4>
    <p type="button" class="close t" aria-label="Close" style="font-size:30px;" data-dismiss="modal"
      (click)="modal.dismiss('Cross click')"  (click)="onReset()">
      <span aria-hidden="true" style="color: red">×</span>
  </p>
  </div>
  <form [formGroup]="EnterpriseFormInsert">
    <div class="modal-body cus-fs " style="padding-top:5px;padding-bottom: 0px;">
    <div class="cus-box">
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10 require" for="Ma_so_thue">Mã số thuế:</label>
          <input class="form-control" type="text" id="Ma_so_thue" placeholder="Nhập mã số thuế.." formControlName="Ma_so_thue"
            [ngClass]="{ 'is-invalid': submitted && checkvalu.Ma_so_thue.errors }">
          <div *ngIf="submitted && checkvalu.Ma_so_thue.errors" class="invalid-feedback">
              <div *ngIf="checkvalu.Ma_so_thue.errors.required">Mã số thuế không được để trống</div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require" for="Ten_doanh_nghiep">Tên doanh nghiệp:</label>
          <input class="form-control" required type="text" id="Ten_doanh_nghiep" formControlName="Ten_doanh_nghiep" placeholder="Nhập tên .."
             [ngClass]="{ 'is-invalid': submitted && checkvalu.Ten_doanh_nghiep.errors }">
          <div *ngIf="submitted && checkvalu.Ten_doanh_nghiep.errors" class="invalid-feedback">
              <div *ngIf="checkvalu.Ten_doanh_nghiep.errors.required">Tên doanh nghiệp không được để trống</div>
              <div *ngIf="checkvalu.Ten_doanh_nghiep.errors.maxlength">Tên doanh nghiệp đã đạt giới hạn ký tự là : 100</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10 require" for="Dia_chi">Địa chỉ:</label>
          <input class="form-control" type="text" id="Dia_chi" formControlName="Dia_chi" placeholder="Nhập địa chỉ.."
          [ngClass]="{ 'is-invalid': submitted && checkvalu.Dia_chi.errors }">
          <div *ngIf="submitted && checkvalu.Dia_chi.errors" class="invalid-feedback">
              <div *ngIf="checkvalu.Dia_chi.errors.required">Địa chỉ doanh nghiệp không được để trống</div>
              <div *ngIf="checkvalu.Dia_chi.errors.maxlength">Địa chỉ doanh nghiệp đã đạt giới hạn ký tự là : 250</div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require" for="So_dien_thoai">Số điên thoại liên hệ:</label>
          <input class="form-control" type="text" id="So_dien_thoai" formControlName="So_dien_thoai"
            placeholder="Nhập số điện thoại.."
            [ngClass]="{ 'is-invalid': submitted && checkvalu.So_dien_thoai.errors }">
            <div *ngIf="submitted && checkvalu.So_dien_thoai.errors" class="invalid-feedback">
                <div *ngIf="checkvalu.So_dien_thoai.errors.required">Số điên thoại doanh nghiệp không được để trống</div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10" for="Nguoi_lien_he">Người liên hệ:</label>
          <input class="form-control" type="text" id="Nguoi_lien_he" formControlName="Nguoi_lien_he"
            placeholder="Nhập người liên hệ.." [ngClass]="{ 'is-invalid': submitted && checkvalu.Nguoi_lien_he.errors }">
            <div *ngIf="submitted && checkvalu.Nguoi_lien_he.errors" class="invalid-feedback">
              <div *ngIf="checkvalu.Nguoi_lien_he.errors.maxlength">Tên người liên hệ đã đạt giới hạn ký tự là: 50</div>
          </div>
        </div>

        <div class="col-md-6">
          <label class="col-md-10 require" for="Email_1">Email thứ nhất:</label>
          <input class="form-control" type="text" id="Email_1" formControlName="Email_1" placeholder="Nhập email 1.."
          [ngClass]="{ 'is-invalid': submitted && checkvalu.Email_1.errors }">
            <div *ngIf="submitted && checkvalu.Email_1.errors" class="invalid-feedback">
                <div *ngIf="checkvalu.Email_1.errors.required">Email không được để trống</div>
                <div *ngIf="checkvalu.Email_1.errors.email">Email không đúng định dạng</div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10" for="Chuc_vu">Chức vụ người liên hệ:</label>
          <input class="form-control" type="text" id="Chuc_vu" formControlName="Chuc_vu" placeholder="Nhập chức vụ.."
                [ngClass]="{ 'is-invalid': submitted && checkvalu.Chuc_vu.errors }">
          <div *ngIf="submitted && checkvalu.Chuc_vu.errors" class="invalid-feedback">
            <div *ngIf="checkvalu.Chuc_vu.errors.maxlength">chức vụ đã đạt giới hạn ký tự là: 50</div>
        </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10" for="Email_2">Email thứ hai:</label>
          <input class="form-control" type="text" id="Email_2" formControlName="Email_2" placeholder="Nhập email 2..">          
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10 require" for="SDT_nguoi_lien_he">Số điện thoại người liên hệ:</label>
          <input class="form-control" type="text" id="SDT_nguoi_lien_he" formControlName="SDT_nguoi_lien_he"
            placeholder="Nhập số điên thoại.."
            [ngClass]="{ 'is-invalid': submitted && checkvalu.SDT_nguoi_lien_he.errors }">
            <div *ngIf="checkvalu.SDT_nguoi_lien_he.invalid" class="invalid-feedback">             
            </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10" for="Email_3">Email thứ ba:</label>
          <input class="form-control" type="text" id="Email_3" formControlName="Email_3" placeholder="Nhập email 3..">
          
        </div>
      </div>
    </div>
  </div>
      <div class="modal-footer ">
        <button type="button" class="btn btn-sm btn-success" (click)="onSubmit()" ><i  class="fa-plus-circle fas"></i> Lưu</button>
        <button type="button" class="btn btn-sm btn-danger" (click)="onReset()" (click)="modal.dismiss('Cross click')"><i class="far fa-times-circle"></i> Đóng</button>
      </div>
    <!-- [disabled]="EnterpriseFormInsert.status=='INVALID'" -->
    <!-- <p> from value :{{ EnterpriseFormInsert.value | json }}</p>
    <p> from value :{{ EnterpriseFormInsert.status | json }}</p>
    <p> from value :{{ EnterpriseFormInsert.untouched | json }}</p>
    <p> from value :{{ EnterpriseFormInsert.controls.dirty | json }}</p> -->
    <!-- <p> from value2 so doanh nghiep :{{ checkvalu.So_dien_thoai.errors.error  | json }}</p> -->
  </form>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-body cus-fs">
      <i class="fas fa-times-circle" style="font-size: 250px;text-align: center;color: #c82333;"></i>
      <p style="text-align: center;font-weight: bold;">Bạn có muốn xóa thông tin doanh nghiệp này !</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-sm btn-success" (click)="Delete()">Đồng ý</button>
      <button type="button" class="btn btn-sm btn-default" (click)="modal.dismiss('Cross click')">Hủy bỏ</button>
  </div>
</ng-template>
<ng-template #Details  let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px;padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100  " style="font-weight: 700;">{{ NameTitle }}</h4>
    <p type="button" class="close" aria-label="Close" style="font-size:30px;" data-dismiss="modal"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true" style="color: red">×</span>
   </p>
  </div>
  <div class="modal-body cus-fs">
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Mã số thuế :</label>
          <p class="form-control" type="text" > {{ dataEnterpriseDetail.Ma_so_thue }}</p>
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Tên doanh nghiệp :</label>
          <p class="form-control" required type="text" >{{ dataEnterpriseDetail.Ten_doanh_nghiep }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Đia Chỉ :</label>
          <p class="form-control" type="text" > {{ dataEnterpriseDetail.Dia_chi }}</p>
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Số điên thoại liên hệ:</label>
          <p class="form-control" required type="text" >{{ dataEnterpriseDetail.So_dien_thoai }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Người liên hệ :</label>
          <p class="form-control" type="text"> {{ dataEnterpriseDetail.Nguoi_lien_he }}</p>
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Email thứ nhất :</label>
          <p class="form-control" required type="text" >{{ dataEnterpriseDetail.Email_1 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Chức vụ :</label>
          <p class="form-control" type="text"> {{ dataEnterpriseDetail.Chuc_vu }}</p>
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Email thứ hai:</label>
          <p class="form-control" required type="text" >{{ dataEnterpriseDetail.Email_2 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Số điện thoại người liên hệ :</label>
          <p class="form-control" type="text"> {{ dataEnterpriseDetail.SDT_nguoi_lien_he }}</p>
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Email thứ ba :</label>
          <p class="form-control" required type="text" >{{ dataEnterpriseDetail.Email_3 }}</p>
        </div>
      </div>
  </div>
  <div class="modal-footer" style="text-align: right">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')"><i class="far fa-times-circle"></i> Đóng</button>
  </div>
</ng-template>

<ng-template #Import_Excel let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="container">
        <h5 style="text-align: center;">
          Import danh sách doanh nghiệp
        </h5>
        <a
          style="text-align: center; text-decoration-line: underline;"
          download="Import_danh_sach_doanh_nghiep"
          target="_blank"
          href="/assets/Mau_danh_sach_doanh_nghiep.xlsx"
        >
          File mẫu Excel
        </a>

        <div class="row">
          <div class="col-md-12 form-group">
            <input
              type="file"
              class="form-control"
              (change)="uploadedFile($event)"
              placeholder="Upload file"
              accept=".xlsx"
              style="padding-bottom: 36px;"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <button
              type="button"
              style="float: right;"
              class="btn btn-md btn-success"
              (click)="ImportExcel()"
            >
              <i class="fa-plus-circle fas"></i> Lưu
            </button>
          </div>
          <div class="col-md-6 form-group">
            <button
              type="button"
              class="btn btn-md btn-danger"
              (click)="modal.dismiss('Cross click')"
            >
              <i class="far fa-times-circle"></i> Hủy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportManager } from '../Utils/service/reportmanager.service';
import { common } from '../app.common';

@Component({
  selector: 'app-report-management',
  templateUrl: './report-management.component.html',
  styleUrls: ['./report-management.component.scss']
})
export class ReportManagementComponent implements OnInit {

  dtOptions: any  = {}
  TitleClass: string = "spinner-border text-muted"
  res: any
  ListReport: any
  public com: common
  // @ViewChild(DataTableDirective, { static: false })
  // datatableElement: DataTableDirective;
  requestObject: RequestBase = new RequestBase()
  token: any;
  constructor(
    public router: Router,
    private ReportManager: ReportManager,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    let element, name, arr;
    element = document.getElementById("menu3");
    arr = element.className.split(" ");
    name = "menu-open";
    if (arr.indexOf(name) == -1) {
        element.className += " " + name;
    }
    this.com = new common(this.router);
    this.com.CheckLogin();
    const a = this.com.getUserinfo();
    this.requestObject.UserName = a.Info.UserName
    this.token = a.access_token
    this.getListReport(this.token)

    this.dtOptions = {
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }

      },
      columns: [

        {
          title: 'Tên báo cáo',

        },

        {
          title: 'Loại phiếu',
        },
        {
          title: 'Trạng thái',
          width: "14%",
        },
      ],
      select: true,

    };
  }


  getListReport(token) {
    const list = new Array();
    // const listoption = new Array();
    this.ReportManager.getListReport(token)
      .subscribe(z => {
        this.res = z
        for (var i = 0; i < this.res.Data.length; i++) {
          list.push(z.Data[i]);
        }
        this.ListReport = list;
      });
    this.TitleClass = "";

  }
  

  UpdateIsChange(id_bc,ischange) {
    const list = new Array();
    let data  = {
      Id_Bao_Cao : id_bc,
      Is_Change: ischange
    };
    this.ReportManager.EditReport(data ,this.token)
      .subscribe(z => {
        this.res = z
        if(this.res.Status == 0)
        {
        return  this.toastr.success("Cập nhật thành công")
        }
        return  this.toastr.warning("Cập nhật thất bại")
      });
      this.getListReport(this.token);
  }

  replaceSurvey(dt)
  {
    if (dt.Is_Change == 1) {
      dt.Is_Change = 0;
  }
  else {
    dt.Is_Change = 1;
  }
  this.UpdateIsChange(dt.Id_Bao_Cao, dt.Is_Change);
  }

}

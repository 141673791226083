import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from 'src/app/Components/main/main.component';
import { LoginComponent } from 'src/app/Components/login/login.component';
import { RegisterComponent } from 'src/app/Components/register/register.component';
import { DashboardComponent } from 'src/app/Components/pages/dashboard/dashboard.component';
import { AuthGuard } from './Utils/guards/auth.guard';
import { NonAuthGuard } from './Utils/guards/non-auth.guard';
import { BrowserModule }    from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MessageComponent } from './Components/pages/message/message.component';
import { CreateComponent } from './Components/pages/message/create/create.component';
import { ReadComponent } from './Components/pages/message/read/read.component';
import { ChangePassComponent } from './Components/pages/change-pass/change-pass.component';
import { QuestionsBankComponent } from './Components/pages/questions-bank/questions-bank.component';
import { SurveyComponent } from './Components/pages/survey/survey.component';
import { SeasonComponent } from './Components/pages/season/season.component';
import { EnterpriseComponent } from './Components/pages/enterprise/enterprise.component';
import { SearchObjectComponent } from './Components/pages/search-object/search-object.component';
import { SearchObjectListComponent } from './Components/pages/search-object-list/search-object-list.component';
import { ObjectListComponent } from './Components/pages/object-list/object-list.component';
import { ReportComponent } from './report/report.component';
import { ReportManagementComponent } from './report-management/report-management.component';
import { LoginUniSurveyPortalComponent } from './Components/login-uni-survey-portal/login-uni-survey-portal.component';
import { PortalComponent } from './Components/portal/portal.component';
import { HomeComponent } from './Components/portal/home/home.component';
import { PortalEnterpriseComponent } from './Components/pages/portal-enterprise/portal-enterprise.component';
import { EnterprisePortalComponent } from './Components/portal/enterprise-portal/enterprise-portal.component';
import {TeacherComponent} from './Components/portal/teacher/teacher.component';
import { VersionDetailComponent } from './Components/pages/version-detail/version-detail.component';
import { VerificationComponent } from './Components/login/verification/verification.component';
import { SingleSignOnVerificationComponent } from './Components/login/single-sign-on-verification/single-sign-on-verification.component';
import { VerificationStudentComponent } from './Components/login-uni-survey-portal/verification-student/verification-student.component';
import { LoaiDanhGiaComponent } from './Components/pages/loai-danh-gia/loai-danh-gia.component';
import { NhomCauHoiComponent } from './Components/pages/nhom-cau-hoi/nhom-cau-hoi.component';
import { UserComponent } from './Components/portal/user/user.component';
import { UserVerificationComponent } from './Components/login-uni-survey-portal/user-verification/user-verification.component';
import { SearchObjectUserComponent } from './Components/pages/search-object-user/search-object-user.component';
import { PhanPhoiTinChiSinhVienGiangVienComponent } from './Components/pages/phan-phoi/phan-phoi-tin-chi-sinh-vien-giang-vien/phan-phoi-tin-chi-sinh-vien-giang-vien.component';
import { KhaoSatGiangVienComponent } from './Components/pages/phan-phoi/giang-vien/khao-sat-giang-vien/khao-sat-giang-vien.component';
import { KhaoSatCanBoComponent } from './Components/pages/phan-phoi/khao-sat-can-bo/khao-sat-can-bo.component';
import { KhaoSatDoanhNghiepComponent } from './Components/pages/phan-phoi/khao-sat-doanh-nghiep/khao-sat-doanh-nghiep.component';
import { KhaoSatCoVanHocTapComponent } from './Components/pages/phan-phoi/khao-sat-co-van-hoc-tap/khao-sat-co-van-hoc-tap.component';
import { PhanPhoiPhieuLopHanhChinhComponent } from './Components/pages/phan-phoi/phan-phoi-phieu-lop-hanh-chinh/phan-phoi-phieu-lop-hanh-chinh.component';
import { PhanPhoiLopNienCheComponent } from './Components/pages/phan-phoi/phan-phoi-lop-nien-che/phan-phoi-lop-nien-che.component';
const routes: Routes = [
  {
    path: 'admin',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'QuestionsBank',
        component: QuestionsBankComponent
      },
      {
        path: 'loai-danh-gia',
        component: LoaiDanhGiaComponent
      },
      {
        path: 'nhom-cau-hoi',
        component: NhomCauHoiComponent
      },
      {
        path: 'Survey',
        component: SurveyComponent
      },
      {
        path: 'Season',
        component: SeasonComponent
      },
      {
        path: 'Enterprise',
        component: EnterpriseComponent
      },
      {
        path: 'SearchObject',
        component: SearchObjectComponent
      },
      {
        path: 'search-users',
        component: SearchObjectUserComponent
      },
      {
        path: 'SearchObjectList',
        component: SearchObjectListComponent
      },
      {
        path: 'ObjectList',
        component: ObjectListComponent
      },
      {
        path: 'Report',
        component: ReportComponent
      },
      {
        path: 'ReportManagement',
        component: ReportManagementComponent
      },
      {
        path: 'message',
        component: MessageComponent
      },
      {
        path:'create',
        component:CreateComponent
      },
      {
        path:'read',
        component:ReadComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'changepass',
        component: ChangePassComponent
      },
      {
        path: 'version-detail',
        component: VersionDetailComponent
      },
      {
        path: 'phan-phoi-tin-chi',
        component: PhanPhoiTinChiSinhVienGiangVienComponent
      },
      {
        path: 'khao-sat-giang-vien',
        component: KhaoSatGiangVienComponent
      },
      {
        path: 'khao-sat-can-bo',
        component: KhaoSatCanBoComponent
      },
      {
        path: 'khao-sat-doanh-nghiep',
        component: KhaoSatDoanhNghiepComponent
      }, {
        path: 'khao-sat-co-van-hoc-tap',
        component: KhaoSatCoVanHocTapComponent
      },
      {
        path: 'phan-phoi-phieu-lop-hanh-chinh',
        component: PhanPhoiPhieuLopHanhChinhComponent
      },
      {
        path: 'phan-phoi-phieu-lop-nien-che',
        component: PhanPhoiLopNienCheComponent
      },
    ]
  },
  {
    path: 'portal',
    component: PortalComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'Student',
        component: HomeComponent
      },
      {
        path: 'EnterprisePortal',
        component: EnterprisePortalComponent
      },
      {
        path: 'Teacher',
        component: TeacherComponent
      },
      {
        path: 'user',
        component: UserComponent
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: '',
    component: VerificationComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'verification-student',
    component: VerificationStudentComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'user-verification',
    component: UserVerificationComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'single-sign-on-verification',
    component: SingleSignOnVerificationComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'login-portal',
    component: LoginUniSurveyPortalComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NonAuthGuard]
  },
  
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    BrowserModule,
    HttpClientModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}

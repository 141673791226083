import { Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { common } from "src/app/app.common";
import { SsoService } from "src/app/Utils/service/sso.service";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  public sidebarMenuOpened = true;
  @ViewChild("contentWrapper", { static: false }) contentWrapper;
  constructor(
    private renderer: Renderer2,
    public router: Router,
    public route: ActivatedRoute,
    public ssoService: SsoService
  ) {}
  public com: common;
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var usData = this.com.getUserinfo();
    if (usData.refresh_token != null) {
      this.refreshToken();
      setInterval(() => {this.refreshToken()}, 600000);
    }
  }

  refreshToken() {
    var usData = this.com.getUserinfo();
    this.ssoService.refreshToken(usData.refresh_token).subscribe((z) => {
      if (z.Status == 1) {
        localStorage.setItem("UserInfo", JSON.stringify(z.Data));
      } else {
        localStorage.removeItem("UserInfo");
      }
    });
  }

  mainSidebarHeight(height) {
    // this.renderer.setStyle(
    //   this.contentWrapper.nativeElement,
    //   'min-height',
    //   height - 114 + 'px'
    // );
  }

  toggleMenuSidebar() {
    console.log("sidebarMenuCollapsed", this.sidebarMenuOpened);
    if (this.sidebarMenuOpened) {
      this.renderer.removeClass(document.body, "sidebar-open");
      this.renderer.addClass(document.body, "sidebar-collapse");
      this.sidebarMenuOpened = false;
    } else {
      this.renderer.removeClass(document.body, "sidebar-collapse");
      this.renderer.addClass(document.body, "sidebar-open");
      this.sidebarMenuOpened = true;
    }
  }
}

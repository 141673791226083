import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/Utils/service/app.service';
import { CasService } from 'src/app/Utils/service/cas.service';
import { SsoService } from 'src/app/Utils/service/sso.service';
import { common } from 'src/app/app.common';
import { AppConfig, AppConfiguration } from 'src/configuration';

@Component({
  selector: 'app-verification-student',
  templateUrl: './verification-student.component.html',
  styleUrls: ['./verification-student.component.scss']
})
export class VerificationStudentComponent  implements OnInit {
  public com: common;
  Token: any;
  code: any = null;
  ticket: any = null;
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    public route: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public ssoService: SsoService,
    public casService: CasService,
    private appService: AppService,
  ) {}

  ngOnInit() {
    this.com = new common(this.router);
    let userData = this.com.getPortalInfo();
    if (userData == null) {
      this.route.queryParams.subscribe((params) => {
        this.code = params.code;
          this.ticket = params.ticket;
        this.spinner.show();
       
  
        if (this.appConfig.AuthorizeType == 0 || ((this.appConfig.sso == null && this.appConfig.AuthorizeType == null))) {
          // đăng nhập sử dụng UniSystemAPI đối với hệ thống không sử dụng cơ chế SSO
          this.spinner.hide();
          this.com.redirectToLoginPortalSystem();
        }
  
        if (this.appConfig.AuthorizeType == 2) {
          // Đăng nhập qua CAS
          if (this.ticket == null) {
            this.casService.PortalRedirectCAS();
          } else {
            this.spinner.show();
            this.casService.validateStudent(this.ticket).subscribe((z) => {
              this.spinner.hide();
              if (z.Status == 1) {
                this.spinner.hide();
                localStorage.setItem("PortalInfo", JSON.stringify(z));
                this.appService.loginStudent();
              } else {
                this.toastr.warning(z.Message);
              }
            });
          }
        }
      });
    }
    else{
      this.appService.loginStudent();
    }
    
  }


}

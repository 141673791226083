import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {
  @ViewChild('contentWrapper', { static: false }) contentWrapper;
  public sidebarMenuOpened = true;
  constructor(private renderer: Renderer2,public router: Router,) {}
  public com: common;
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLoginPortal();
    this.sidebarMenuOpened=true;
    this.toggleMenuSidebar();
  }

  mainSidebarHeight(height) {
    this.renderer.setStyle(
      this.contentWrapper.nativeElement,
      'min-height',
      height - 114 + 'px'
    );
  }

  toggleMenuSidebar() {
    if (this.sidebarMenuOpened) {
      this.renderer.removeClass(document.body, 'sidebar-open');
      this.renderer.addClass(document.body, 'sidebar-collapse');
      this.sidebarMenuOpened = false;
    } else {
      this.renderer.removeClass(document.body, 'sidebar-collapse');
      this.renderer.addClass(document.body, 'sidebar-open');
      this.sidebarMenuOpened = true;
    }
  }
}

import { RequestBase } from './RequestBase';
export class RequestTeachingScheduleList extends RequestBase {
    UserName: string;
    FromDay: Date;
    ToDay: Date;
}
export class RequestClassInfo extends RequestBase {
    IDLop: number;
    NgayThang: Date;
    CaHoc: number;
    Tiet: number;
}
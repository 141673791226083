import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class ReportService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }
  DotDanhGia_LoadNam(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Report/DotDanhGia_LoadNam", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DotDanhGia_LoadHocKy(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Report/DotDanhGia_LoadHocKy", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getListReport(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Report/BaoCao_Load_ListAll", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getListReportDN(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Report/BaoCao_Load_ListAllDN", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getListReportSV(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Report/BaoCao_Load_ListAllSV", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getListReportFilter(token, Loai_danh_gia): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Report/BaoCao_Load_ListByLoai?Loai_danh_gia="+Loai_danh_gia, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getListReportKSGV(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Report/BaoCao_Load_ListAllGV", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  PhieuDanhGia_Load_List_Dot(token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.SurveyURL + "Survey/PhieuDanhGia_Load_List_Dot",
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  PhieuDanhGiaTheoDot(ID_dot_danh_gia: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.SurveyURL + "Survey/PhieuDanhGiaTheoDot?ID_dot_danh_gia="+ ID_dot_danh_gia,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  UpdateSeason(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Season/Update", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetHe(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/He_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ListGV(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Report/ListAllGV", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ListGVDetail(token, idKhoa): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Report/ListAllGV?idKhoa="+idKhoa, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetKhoa(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/Khoa_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetKhoaHoc(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/KhoaHoc_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetChuyenNganh(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/ChuyenNganh_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  SendSeason(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Season/DotDanhGiaGui", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKe_Khoa(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/KhaoSatSinhVienM2", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKe_ChuyenNganh(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/KhaoSatSinhVienM3", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKe(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/KhaoSatSinhVienM1", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKe_CauHoi(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/ThongKe_CauHoi", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKe_PhanHoi(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/KhaoSatChatLuong", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKeGV(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/KhaoSatGiangVien", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  ThongKeGV_DTB(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/KhaoSatGiangVien_DTB",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKeNienCHe_ChiTiet_GiangVien(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/NienChe_ChiTietGiangVien",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  ThongKeNienCHe_ChiTiet_SinhVien(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/NienChe_ChiTietSinhVien",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  ThongKeNienCHe_SinhVienToanTruong(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/NienChe_SinhVienToanTruong",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  ThongKeNienCHe_TongHop_GiangVien(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/NienChe_TongHopGiangVien",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  ThongKeGVTieuChiNhom3(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/ThongKeGVTieuChiNhom3",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  KhaoSatGiangVienM2(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/KhaoSatGiangVienM2", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DanhGiaGiangVien_NamHoc(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/DanhGiaGiangVien_NamHoc",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  KhaoSatGiangVienM3(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/KhaoSatGiangVienM3", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  KetQuaDanhGiaToanTruong(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/KetQuaDanhGiaToanTruong",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  KhaoSatToanTruong(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/KhaoSatToanTruong", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKegiaovienKs(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/ThongKegiaovienKs", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKeHV_TraLoi(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/ThongKeHV_TraLoi", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKeTieu_Chi(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/ThongKeTieu_Chi", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKeNhomTieu_Chi(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/ThongKeNhomTieu_Chi", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKeketquatheodonvi(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/ThongKeketquatheodonvi",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  KhaoSatGiangVien_NienChe(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/KhaoSatGiangVien_NienChe",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  KhaoSatGiangVienM2_NienChe(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/KhaoSatGiangVienM2_NienChe",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKeGiangVienTheoNhomTieuChi(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/ThongKeGiangVienTheoNhomTieuChi",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  chitiettraloisinhvien_pks(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/chitiettraloisinhvien_pks",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  BaoCaoChiTietSVDG(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/BaoCaoChiTietSVDG", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  BaoCaoChiTietSVKS(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/BaoCaoChiTietSVKS", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  BaoCaoChiTietDN(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/BaoCaoChiTietDN", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThongKeGiangVienTheoTieuChi(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Report/Thong_Ke_Tieu_Chi", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  ThongKehocvientraloitheolop(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/ThongKeSoLuotHV_TraLoi",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  KHAOSATGIANGVIENCTGD(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Report/KHAOSATGIANGVIENCTGD",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  PrintBaoCao(req: any, token, URL_api): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + URL_api, req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }


  TraCuuDanhGia_SinhVien_Export(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "/SurveySend/SearchObject_Export", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}

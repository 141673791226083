<section class="content-header" style="padding:5px !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
            <ol class="breadcrumb ">
                <li class="breadcrumb-item"><a [routerLink]="['/']">Trang chủ</a></li>
                <li class="breadcrumb-item "><a [routerLink]="['/SearchObject']">Tra cứu phân phối</a></li>
                <li class="breadcrumb-item active">Danh sách phiếu</li>
            </ol>
        </div>
         
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content cus-body" >
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <label >Tên phiếu</label>
                    <input type="text" class="form-control" [(ngModel)]="TenPhieu" (keyup)="filterById()" placeholder="Tên phiếu...">
                </div>  
                <div class="col-md-3">
                    <label >Nội dung phiếu</label>
                    <input type="text" class="form-control" [(ngModel)]="NoiDung" (keyup)="filterById()" placeholder="Nội dung phiếu...">
                </div>
                <div class="col-md-3">
                    <label >Số phiếu đã đánh giá</label>
                    <input type="text" class="form-control" [(ngModel)]="DaDanhGia" (keyup)="filterById()" placeholder="Số phiếu đã đánh giá...">
                </div> 
                <div class="col-md-3">
                    <label >Tổng số phiếu</label>
                    <input type="text" class="form-control" [(ngModel)]="TongSoPhieu" (keyup)="filterById()" placeholder="Tổng số phiếu">
                </div>   
             
            </div>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <span style="font-weight: bold;">Loại đối tượng:</span><span> {{Loai_doi_tuong}} </span>             
                 </div>
                <div class="col-md-4" *ngIf="!Layout">
                    <span style="font-weight: bold;">Người đánh giá:</span><span> {{Nguoi_danh_gia}}</span>
                </div>
                <div class="col-md-4" *ngIf="Layout">
                    <span style="font-weight: bold;">Đối tượng đánh giá:</span><span> {{Nguoi_danh_gia}}</span>
                </div>
                <div class="col-md-4">
                    <span style="font-weight: bold;">Đợt đánh giá:</span><span> {{Ten_dot}} </span>      
                </div>
            </div>
        </div><!-- /.card-header -->
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable  id="example" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class=" table-bordered dataTables_wrapper no-footer ">
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td ></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of TableData; index as i">
                                            <td style="text-align: center;">{{ i+1  }}</td>
                                            <td>{{ p.Ten_phieu }}</td>
                                            <td>{{ p.Noi_dung_phieu }}</td>
                                            <td>{{ p.Da_danh_gia }}/{{ p.Tong_doi_tuong }}</td>
                                            <td>
                                                <button [routerLink]="['/admin/ObjectList']" [queryParams]="{ID_doi_tuong: p.ID_doi_tuong, ID_Dot:p.ID_dot_danh_gia,ID_phieu_danh_gia:p.ID_phieu_danh_gia,Category:p.Category}" queryParamsHandling="merge" class="btn btn-success btn-sm"><i class="fas fa-list-alt"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
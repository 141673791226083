import { ResponseBase } from './ResponseBase';

export class ResponseClassListAll extends ResponseBase{
    Data: any;
}
export class ClassInfo {
    public Hoc_ky: number;
    public  Nam_hoc: string;
    public ID_mon: number;
    public Ky_hieu: string;
    public Ten_mon: string;
    public ID_lop: number;
    public Ten_lop: string;
    public So_sv: number;
    public Loai_lop: number;
    public Ten_loai_lop: string;
}
export class ResponseStudentsClass extends ResponseBase{
    Data: Student[];
}
export class Student {
    ID_sv: number;
    ID_dt: string;
    Ma_sv: string;
    Ho_ten: string;
    Ngay_sinh: string;
    Gioi_tinh: string;
    Ten_lop: string;
    Ten_khoa: string;
    ID_lop: number;
}
export class ResponseClassListAllOnline extends ResponseBase{
    Data: any;
}
export class ClassStudentOnline {
    public So_sv: number;
    public  So_lop: number;
}
export class StudentTruant {
     Ho_ten: string;
     So_tiet_nghi: number;
     Ten_Lop: string;
     ID_sv: number;
     ID_lop: number;
     Ma_sv: string;
  
}
import { Component, OnInit, OnDestroy, Renderer2, Inject } from "@angular/core";
import { AppService } from "src/app/Utils/service/app.service";
import { Account } from "src/app/Utils/service/Account.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { RequestLogin } from "src/app/Models/input.model/RequestLogin";
import { ResponseLogin } from "src/app/Models/output.model/ResponseLogin";
import { CookieService } from "ngx-cookie-service";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppConfig, AppConfiguration } from "src/configuration";
@Component({
  selector: "app-login-uni-survey-portal",
  templateUrl: "./login-uni-survey-portal.component.html",
  styleUrls: ["./login-uni-survey-portal.component.scss", "./main.css"],
})
export class LoginUniSurveyPortalComponent implements OnInit {
  public loginForm: FormGroup;
  public m: RequestLogin;
  public LoginResult: ResponseLogin;
  public TALoginCode: string;
  cat: any;
  isShowLoginCAS: boolean = false;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private http: HttpClient,
    private cookieService: CookieService,
    private Acc: Account,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService
  ) {}
  ngOnInit() {
    this.renderer.addClass(document.body, "login-page");
    this.m = new RequestLogin();
    this.cookieService.deleteAll;
    this.LoginResult = new ResponseLogin();
    this.loginForm = new FormGroup({
      UserName: new FormControl(null, Validators.required),
      Password: new FormControl(null, Validators.required),
    });
    this.route.queryParams.subscribe((params) => {
      
      this.TALoginCode = params.TALoginCode;
      this.cat = params.cat;
      if (this.TALoginCode != null && this.cat == "Enterprise") {
        this.logInEnterprise(this.TALoginCode);
      }
      if (this.TALoginCode != null && this.cat == "Teacher") {
        this.logInTeacher(this.TALoginCode);
      }
      if (this.TALoginCode != null && this.cat == "Student") {
        this.logInStudent(this.TALoginCode);
      }
      if (this.TALoginCode != null && this.cat == "User") {
        this.logInUser(this.TALoginCode);
      }
    });
    this.isShowLoginCAS = this.appConfig.AuthorizeType == 2;

    if( this.isShowLoginCAS && this.TALoginCode == null)
      {
        this.appService.casStudent();
      }
  }

  Ridrect() {
    this.appService.login();
  }

  logInEnterprise(code) {
    let req = {
      UserName: "",
      Password: "",
      UserCategory: 4,
      TALoginCode: code,
    };
    this.spinner.show();
    this.Acc.AutoLogin(req).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        z.UserCategory = 4;
        localStorage.setItem("PortalInfo", JSON.stringify(z));
        this.appService.loginEnterprise();
      } else {
        this.toastr.error(z.Message, "Tác vụ thất bại");
        localStorage.removeItem("PortalInfo");
      }
    });
  };

  logInStudent(code) {
    let req = {
      UserName: "",
      Password: "",
      UserCategory: 3,
      TALoginCode: code,
    };
    this.spinner.show();
    this.Acc.AutoLogin(req).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        z.UserCategory = 3;
        localStorage.setItem("PortalInfo", JSON.stringify(z));
        this.appService.loginStudent();
      } else {
        this.toastr.error(z.Message, "Tác vụ thất bại");
        localStorage.removeItem("PortalInfo");
      }
    });
  }

  logInTeacher(code) {
    let req = {
      UserName: "",
      Password: "",
      UserCategory: 2,
      TALoginCode: code,
    };
    this.spinner.show();
    this.Acc.AutoLogin(req).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        z.UserCategory = 2;
        localStorage.setItem("PortalInfo", JSON.stringify(z));
        this.appService.loginTeacher();
      } else {
        this.toastr.error(z.Message, "Tác vụ thất bại");
        localStorage.removeItem("PortalInfo");
      }
    });
  }
  logInUser(code) {
    let req = {
      UserName: "",
      Password: "",
      UserCategory: 5,
      TALoginCode: code,
    };
    this.spinner.show();
    this.Acc.AutoLogin(req).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        z.UserCategory = 5;
        localStorage.setItem("PortalInfo", JSON.stringify(z));
        this.appService.loginUserPortal();
      } else {
        this.toastr.error(z.Message, "Tác vụ thất bại");
        localStorage.removeItem("PortalInfo");
      }
    });
  }
  logIn() {
    if (this.loginForm.valid) {
      this.m.UserName = this.loginForm.controls.UserName.value;
      this.m.Password = this.loginForm.controls.Password.value;
      let req = {
        UserName: this.m.UserName,
        Password: this.m.Password,
        UserCategory: 3,
      };
      this.Acc.Login(req).subscribe((z) => {
        if (z.Status == 1) {
          z.UserCategory = 3;
          localStorage.setItem("PortalInfo", JSON.stringify(z));
          this.appService.loginStudent();
        } else {
          this.toastr.error(z.Message, "Tác vụ thất bại");
          localStorage.removeItem("PortalInfo");
        }
      });
    } else {
      this.toastr.error("Vui lòng nhập đầy đủ thông tin", "Tác vụ thất bại");
    }
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, "login-page");
  }
}

import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AppService } from 'src/app/Utils/service/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { Account } from 'src/app/Utils/service/Account.service';
import { DataTableDirective } from 'angular-datatables';
import { RequestLogin } from 'src/app/Models/input.model/RequestLogin';
import { ResponseLogin } from 'src/app/Models/output.model/ResponseLogin';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent implements OnInit {
  public ChangePassForm: FormGroup;
  public m: RequestLogin;
  public LoginResult: ResponseLogin;
  public UserName: any;
  public Password: any;
  public Password2: any;
  public Password3: any;
  public com: common;
  requestObject: RequestLogin = new RequestLogin()
  constructor(
    public AccountServirce: Account,
    private toastr: ToastrService,
    private appService: AppService,
    public router: Router
  ) { }

  ngOnInit() {
    this.ChangePassForm = new FormGroup({
      Password: new FormControl(null, Validators.required),
      Password2: new FormControl(null, Validators.required),
      Password3: new FormControl(null, Validators.required)
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
  }
  ChangePass() {
    if (this.ChangePassForm.valid) {
      let a = this.com.getUserinfo();
      this.UserName = a.Info.UserName;
      this.Password = this.ChangePassForm.controls.Password.value;
      this.Password2 = this.ChangePassForm.controls.Password2.value;
      this.Password3 = this.ChangePassForm.controls.Password3.value;
      this.requestObject.UserName = a.Info.UserName;
      if (/^[0-9a-zA-Z]+$/.test(this.Password2)) {
        if ($.trim(this.Password2).length === 0|| this.Password2.length > 5) {
          if (this.Password2 == this.Password3) {
            let req = {
              UserName: this.UserName,
              Password: this.Password,
              NewPassword: this.Password2,
              UserCategory: 1
            }
            this.AccountServirce.ChangePass(req, a.access_token)
              .subscribe(z => {
                if (z.Status == 1) {
                  this.toastr.success('Đổi mật khẩu thành công');
                  this.router.navigate(['/']);
                } else {
                  this.toastr.error(z.Message, 'Tác vụ thất bại');
                }
              })
          } else { this.toastr.warning('Mật khẩu mới của bạn không trùng khớp', 'Tác vụ thất bại'); }
        } else { this.toastr.warning('Mật khẩu mới của bạn không được chứa khoảng trắng và phải lớn hơn 6 ký tự', 'Tác vụ thất bại'); }
      }else{this.toastr.warning('Mật khẩu mới của bạn không được chứa ký tự đặc biệt và khoảng trắng', 'Tác vụ thất bại');}
    } else {
      this.toastr.error('Vui lòng nhập đầy đủ thông tin', 'Tác vụ thất bại');
    };
  }

}

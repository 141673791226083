import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
@Injectable({
  providedIn: "root",
})
export class PortalService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {}
  StudentGetList(data: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Portal/StudentGetList", data, {
        headers: new HttpHeaders(),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  EnterpriseGetList(data: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Portal/EnterpriseGetList", data, {
        headers: new HttpHeaders(),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  TeacherGetList(data: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Portal/TeacherGetList", data, {
        headers: new HttpHeaders(),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  
  UserGetList(data: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Portal/UserGetList", data, {
        headers: new HttpHeaders(),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  StudentObjectDetail(data: any): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Portal/StudentObjectDetail",
        data,
        {
          headers: new HttpHeaders(),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
  StudentObjectInsest(data: any): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Portal/StudentObjectInsest",
        data,
        {
          headers: new HttpHeaders(),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

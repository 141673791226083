import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/Utils/service/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router ,ActivatedRoute} from '@angular/router';
import { SearchObjectListService } from 'src/app/Utils/service/search-object-list.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { common } from 'src/app/app.common';
@Component({
  selector: 'app-search-object-list',
  templateUrl: './search-object-list.component.html',
  styleUrls: ['./search-object-list.component.scss']
})
export class SearchObjectListComponent implements OnInit {

  dtOptions: DataTables.Settings
  Res: any
  Req:any;
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  TieuDe: string;
  Title: string="Đang tải danh sách thông báo..."
  TitleClass: string="spinner-border text-muted"
  Nguoi_danh_gia: string ="";
  Loai_doi_tuong: string="";
  Ten_dot: string="";
  public RequestSearchObjectList:any;
  public ListSeason:any;
  public TableData:any;
  public ID:any;
  public Category:any;
  public ID_dot_danh_gia:any;
  public Layout:any;
  public TenPhieu: string;
  public NoiDung: string;
  public DaDanhGia: string;
  public TongSoPhieu: string;
  show: boolean = true;
  constructor(
    public appService: AppService,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    public router: Router,
    private Service: SearchObjectListService,
  ) {
  }
  dtTrigger= new Subject();
  public com: common;
  ngOnInit() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let TenPhieudt = data[1]; 
      let NoiDungdt = data[2]; 
      let sum = data[3].split("/");
      let DaDanhGiadt = sum[0];
      let TongSoPhieu = sum[1]
       let bool1 = true
       let bool2 = true
       let bool3=true
       let bool4=true
       let fillter=true;
       if (this.TenPhieu != undefined) {
        bool1 = TenPhieudt.includes(this.TenPhieu)
      }
      if (this.NoiDung != undefined) {
        bool2 = NoiDungdt.includes(this.NoiDung)
      }
      if (this.DaDanhGia != undefined) {
        bool3 = DaDanhGiadt.includes(this.DaDanhGia)
      }
      if (this.TongSoPhieu != undefined) {
        bool4 = TongSoPhieu.includes(this.TongSoPhieu)
      }
      fillter = bool1 && bool2 && bool3 && bool4
      return fillter;
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
   
    this.route.queryParams
      .subscribe(params => {
        
        this.ID=params.ID_doi_tuong,
        this.Category=params.Category,
        this.ID_dot_danh_gia=params.ID_Dot
      });
    this.RequestSearchObjectList={
      ID:this.ID,
      Category:this.Category,
      ID_dot_danh_gia:this.ID_dot_danh_gia
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Hiển thị _MENU_ kết quả",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ kết quả",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 kết quả",
        infoFiltered: "(được lọc từ _MAX_ kết quả)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }

      },
      columns: [
        {
          title: 'STT',
          width:'25px'
        },
        {
          title: 'Tên phiếu',
        },
        {
          title: 'Nội dung phiếu',
        },
        {
          title: 'Đã đánh giá/Tổng số phiếu',
          width:'200px',
          className: "dt-center",
        },
        {
          title: 'Thao tác',
          width:'100px',
          className: "dt-center",
        }
      ],
    };
    this.getData(this.RequestSearchObjectList, a.access_token)
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }
  getData(input: any, token) {
    this.Service.SearchObjectList(input, token)
    .subscribe(z => {
      this.TableData = z.SurveySendData
      this.dtTrigger.next();
      this.Title="Danh sách phiếu"
      this.TitleClass=""
      this.Nguoi_danh_gia = z.SurveySendData[0]['Ho_ten'] + ' - ' + z.SurveySendData[0]['Ma_sv']
      if(z.SurveySendData[0]['Category'] == 1){
        this.Loai_doi_tuong = "Sinh viên"
        this.Layout ="true"
      }else if(z.SurveySendData[0]['Category'] == 2){
        this.Loai_doi_tuong = "Giáo viên"
        this.Layout ="false"
      }else if(z.SurveySendData[0]['Category'] == 4){
        this.Loai_doi_tuong = "Giáo viên"
        this.Layout ="false"
      }else if(z.SurveySendData[0]['Category'] == 3){
        this.Loai_doi_tuong = "Doanh nghiệp"
        this.Layout ="false"
      }
      this.Ten_dot = z.SurveySendData[0]['Ten_dot']

    })
    ;
  }
  searchObjectList(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      
    });
  } 
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
}


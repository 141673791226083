import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class SupportService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  GetSupportLink(token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UtilsUrl +
          "ThamSoHeThong/Read_ThamsoHeThong?ID_ph=13&Gia_tri=Don_vi_su_dung",
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AppService } from 'src/app/Utils/service/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ObjectListService } from 'src/app/Utils/service/object-list.service';
import { SearchObjectDetailService } from 'src/app/Utils/service/search-object-detail.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { common } from 'src/app/app.common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-object-list',
  templateUrl: './object-list.component.html',
  styleUrls: ['./object-list.component.scss']
})
export class ObjectListComponent implements OnInit {

  dtOptions: DataTables.Settings
  Res: any
  Req: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  TieuDe: string;
  Title: string = "Đang tải danh sách thông báo..."
  TitleClass: string = "spinner-border text-muted"
  Ten_phieu: string;
  closeResult: string;
  Nhom_danh_gia: string;
  public ListSeason: any;
  public TableData: any;
  public DetailData: any;
  public Detail: any;
  public ID: any;
  public Category: any;
  public ID_dot_danh_gia: any;
  public ID_phieu_danh_gia: any;
  public Token: any;
  public Input: any;
  public Ghi_chu_phieu: any;
  public Ten_cua_phieu: any;
  public PhuongAns: any;
  public CauHois: any;
  public KetQuas: any;
  public Layout: any;
  public Loai_doi_tuong: any;
  public Noi_dung_phieu: any;
  public Ten_dot: any;
  public Doi_tuong: any;
  public DataAnwser: any;
  public DataDaDanhGia: any;
  public Ma: any;
  public show: boolean = true;
  public MaFilter: string;
  public HoVaTenFilter: string;
  public MonHocFilter: string;
  public TrangThaiFilter: string = '';
  isDisplay: any
  url_back: string;
  constructor(
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    private modalService: NgbModal,
    public route: ActivatedRoute,
    private Service: ObjectListService,
    private ServiceDetail: SearchObjectDetailService,


  ) {
    // 

  }
  dtTrigger = new Subject();
  public com: common;
  ngOnInit() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {

      let bool1 = true
      let bool2 = true
      let bool3 = true
      let bool4 = true
      let fillter = true;
      let MaFilterdt = data[1];
      let HoVaTenFilterdt = data[2];
      let MonHocFilterdt = data[3];
      if (data.length == 7) {
        let MaFilterdt = data[1];
        let HoVaTenFilterdt = data[2];
        let MonHocFilterdt = data[3];
        let TrangThaiFilterdt = data[5];
        if (this.MaFilter != undefined) {
          bool1 = MaFilterdt.includes(this.MaFilter)
        }
        if (this.HoVaTenFilter != undefined) {
          bool2 = HoVaTenFilterdt.includes(this.HoVaTenFilter)
        }
        if (this.MonHocFilter != undefined) {
          bool3 = MonHocFilterdt.includes(this.MonHocFilter)
        }
        if (this.TrangThaiFilter != undefined) {
          bool4 = TrangThaiFilterdt.includes(this.TrangThaiFilter)
        }

        fillter = bool1 && bool2 && bool3 && bool4
        return fillter;
      } else {
        let MaFilterdt = data[1];
        let HoVaTenFilterdt = data[2];
        let MonHocFilterdt = data[3];
        let TrangThaiFilterdt = data[4];
        if (this.MaFilter != undefined) {
          bool1 = MaFilterdt.includes(this.MaFilter)
        }
        if (this.HoVaTenFilter != undefined) {
          bool2 = HoVaTenFilterdt.includes(this.HoVaTenFilter)
        }
        if (this.MonHocFilter != undefined) {
          bool3 = MonHocFilterdt.includes(this.MonHocFilter)
        }
        if (this.TrangThaiFilter != undefined) {
          bool4 = TrangThaiFilterdt.includes(this.TrangThaiFilter)
        }
        fillter = bool1 && bool2 && bool3 && bool4
        return fillter;
      }
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.route.queryParams
      .subscribe(params => {
        this.ID = params.ID_doi_tuong,
          this.Category = params.Category,
          this.ID_dot_danh_gia = params.ID_Dot,
          this.ID_phieu_danh_gia = params.ID_phieu_danh_gia
      });
    this.url_back = 'SearchObjectList?ID_doi_tuong=' + this.ID + '&ID_Dot=' + this.ID_dot_danh_gia + '&Category=' + this.Category
    var RequestObjectList = {
      Category: this.Category,
      ID: this.ID,
      ID_dot_danh_gia: this.ID_dot_danh_gia,
      ID_phieu_danh_gia: this.ID_phieu_danh_gia
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }

      },

    };
    this.getData(RequestObjectList, a.access_token)
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }
  getData(input: any, token) {
    this.Service.ObjectList(input, token)
      .subscribe(z => {
        this.TableData = z.ObjectData
        this.dtTrigger.next();
        this.Title = "Danh sách phiếu"
        this.TitleClass = ""
        this.Ten_phieu = z.ObjectData[0]['Ten_phieu']
        if (z.ObjectData[0]['Category'] == 1) {
          this.Layout = false
          this.Loai_doi_tuong = "Giáo viên"
          this.Ma = "sinh viên"
        } else if (z.ObjectData[0]['Category'] == 2) {
          this.Layout = true
          this.Loai_doi_tuong = "Sinh viên"
          this.Ma = "giảng viên"
        } else if (z.ObjectData[0]['Category'] == 4) {
          this.Layout = true
          this.Loai_doi_tuong = "Giáo viên"
          this.Ma = "giảng viên"
        } else if (z.ObjectData[0]['Category'] == 3) {
          this.Loai_doi_tuong = "Doanh nghiệp"
          this.Layout = false
        }
        if (z.ObjectData[0].Ma_doi_tuong != null && z.ObjectData[0].Ho_ten != null) {
          this.isDisplay = true
          this.Layout = true
        } else {
          this.isDisplay = false
          this.Layout = false
        }

        this.Doi_tuong = z.ObjectData[0]['Ho_ten_tra_cuu'] + ' - ' + z.ObjectData[0]['Ma_tra_cuu']
        this.Ten_dot = z.ObjectData[0]['Ten_dot']
      })
      ;
  }
  getDetail(input: any, token) {
    this.ServiceDetail.SearchObjectDetail(input, token)
      .subscribe(z => {
        this.DetailData = z.Data.SurveyData.Questions
        this.Detail = z.Data.SurveyData.Survey

        this.Noi_dung_phieu = z.Data.SurveyData.Survey['Noi_dung_phieu'] != null ? z.Data.SurveyData.Survey['Noi_dung_phieu'] : ''
        this.Ghi_chu_phieu = z.Data.SurveyData.Survey['Ghi_chu'] != null ? z.Data.SurveyData.Survey['Ghi_chu'] : ''
        this.Ten_cua_phieu = z.Data.SurveyData.Survey['Ten_phieu'] != null ? z.Data.SurveyData.Survey['Ten_phieu'] : ''

        if (z.Data.SurveyData.Questions[0]['QuestionInfo'] != null) {
          this.Nhom_danh_gia = z.Data.SurveyData.Questions[0]['QuestionInfo']['Ten_nhom_cau_hoi']
        } else {
          this.Nhom_danh_gia = ''
        }
        let rs = '';
        for (var i = 0; i < z.Data.SurveyData.Questions.length; i++) {
          this.CauHois = z.Data.SurveyData;
          this.PhuongAns = z.Data.SurveyData.Questions[i].QuestionInfo;
          this.KetQuas = z.Data.SurveyData.Questions[i].OptionsData;
          rs += this.question_render(z.Data.SurveyData.Questions[i], i + 1, z.Data.AnwsersDetail);
        }
        document.getElementById("View_Question").innerHTML = rs;
      })
      ;
  }
  ObjectList(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();

    });
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  open(content, sizm, Data) {
    this.DataAnwser = Data['ID_gui'];
    this.DataDaDanhGia = Data['Da_danh_gia'];
    var a = this.com.getUserinfo();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    if (Data['ID_doi_tuong'] == null || Data['ID_doi_tuong'] == "") {
      var RequestSearchObjectDetail = {
        Category: this.Category,
        ID: this.ID,
        ID_phieu_danh_gia: Data['ID_phieu_danh_gia'],
        TALoginCode: 1
      }
    } else {
      var RequestSearchObjectDetail = {
        Category: Data['Category'],
        ID: Data['ID_doi_tuong'],
        ID_phieu_danh_gia: Data['ID_phieu_danh_gia'],
        TALoginCode: 0
      }
    }
    this.getDetail(RequestSearchObjectDetail, a.access_token)
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  question_render(h, stt, anwser) {
    if (h.QuestionInfo.Ghi_chu == null) {
      h.QuestionInfo.Ghi_chu = "Không có ghi chú"
    }
    if (h.QuestionInfo.Noi_dung_cau_hoi == null) {
      h.QuestionInfo.Noi_dung_cau_hoi = ""
    }
    // render câu hỏi
    var rs = '';
    var list_phuong_an_cau_hoi = h.OptionsData
    var BatBuoc = '';
    if (h.QuestionInfo.Bat_buoc == true) {
      BatBuoc = '<h5><i><mark>Câu hỏi bắt buộc trả lời</mark></i></h5>';
    }
    if (this.DataDaDanhGia == true) {
      if (anwser.length > 0) {
        anwser.forEach(element => {

          var AnwserData = element.AnwserData
          if (element.ID_gui == this.DataAnwser) {
            // 1: Câu hỏi dạng trắc nghiệm
            if (h.QuestionInfo.Loai_cau_hoi == 1) {
              rs += '<form> <div class="form-group">';
              rs += '<label style="color:red;"> ' + h.QuestionInfo.Noi_dung_cau_hoi + '</label> <br />';

              rs += '<p> Ghi chú: ' + h.QuestionInfo.Ghi_chu + '</p> <br />';
              AnwserData.forEach(value => {
                for (let j = 0; j < list_phuong_an_cau_hoi.length; j++) {
                  if (value.ID_cau_hoi == list_phuong_an_cau_hoi[j].ID_cau_hoi) {
                    if (value.ID_phuong_an == list_phuong_an_cau_hoi[j].ID_phuong_an) {
                      rs += '<div  style="margin-left: 20%"><input type="radio" checked disabled id="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '" name="' + h.QuestionInfo.ID_cau_hoi + '" value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '"/>' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '<br/></div>';
                    } else {
                      rs += '<div  style="margin-left: 20%"><input type="radio"  disabled  id="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '" name="' + h.QuestionInfo.ID_cau_hoi + '" value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '"/>' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '<br/></div>';
                    }
                  };
                }
              });
              rs += '</div></form > <hr/>'
            }
            // 2: Câu hỏi dạng hộp kiểm           
            if (h.QuestionInfo.Loai_cau_hoi == 2) {
              rs += '<form> <div class="form-group">';
              rs += '<label style="color:red;">' + h.QuestionInfo.Noi_dung_cau_hoi + '</label> <br />';

              rs += '<p> Ghi chú: ' + h.QuestionInfo.Ghi_chu + '</p> <br />';
              var idphuongan = 0;

              for (let j = 0; j < list_phuong_an_cau_hoi.length; j++) {
                var check = jQuery.grep(AnwserData, function (pa: any, i) {
                  return pa.ID_phuong_an == list_phuong_an_cau_hoi[j].ID_phuong_an && pa.ID_cau_hoi == list_phuong_an_cau_hoi[j].ID_cau_hoi;
                });
                var checked = "";
                if (check.length > 0) {
                  checked = "checked";
                }
                //   if (AnwserData[i].ID_cau_hoi == list_phuong_an_cau_hoi[j].ID_cau_hoi && AnwserData[i].ID_phuong_an == list_phuong_an_cau_hoi[j].ID_phuong_an) {
                rs += '<div  style="margin-left: 20%"><input type="checkbox" ' + checked + ' disabled  id="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '" name="' + h.QuestionInfo.ID_cau_hoi + '" value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '"/>' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '<br/></div>';
                //  }
                //   else {
                //    rs += '<div  style="margin-left: 20%"><input type="checkbox" disabled  id="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '" name="' + h.QuestionInfo.ID_cau_hoi + '" value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '"/>' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '<br/></div>';
                //  }
              }
              // if (idphuongan == 0) {
              //   for (let j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
              //     rs += '<div  style="margin-left: 20%"><input type="checkbox" disabled id="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '" name="' + h.QuestionInfo.ID_cau_hoi + '" value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '"/>' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '<br/></div>';
              //   }
              // }
              rs += '</div></form > <hr/>'
            }
            //3: câu hỏi dạng menu thả xuống
            if (h.QuestionInfo.Loai_cau_hoi == 3) {
              rs += '<form> <div class="form-group">';
              rs += '<label style="color:red;">' + h.QuestionInfo.Noi_dung_cau_hoi + '</label>' + BatBuoc +
                ' <p> Ghi chú:  ' + h.QuestionInfo.Ghi_chu + '</p > <br /><select disabled style="margin-left: 4%" id="' + h.QuestionInfo.ID_cau_hoi + '">';
              AnwserData.forEach(value => {
                for (let j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
                  if (value.ID_phuong_an == list_phuong_an_cau_hoi[j].ID_phuong_an) {
                    rs += ' <option selected value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '">' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '</option>';
                  }
                }
              });
              rs += '</select> </div> </form> <hr/>';
            }
            // 4: câu hỏi dạng lưới trắc nghiệm
            if (h.QuestionInfo.Loai_cau_hoi == 4) {
              rs += '<form> <div class="form-group">';
              rs += '<label style="color:red;">' + h.QuestionInfo.Noi_dung_cau_hoi + '</label> <br />';
              rs += '<p> Ghi chú: ' + h.QuestionInfo.Ghi_chu + '</p> <br />';
              rs += '<table class="table table-bordered dataTables_wrapper no-footer" ><thead ><tr><th scope="col">#</th>';
              var list_hang = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
                return !pa.La_cot;
              });
              var list_cot = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
                return pa.La_cot;
              });
              list_cot = this.sortByKeyAsc(list_cot, "Stt_cot");
              list_hang = this.sortByKeyAsc(list_hang, "Stt_cot");
              for (let j = 0; j <= list_cot.length - 1; j++) {
                rs += ' <th scope="col">' + list_cot[j].Noi_dung_phuong_an + '</th>';
              }
              rs += '</tr> </thead>  <tbody>';
              AnwserData.forEach(value => {
                for (let j = 0; j <= list_hang.length - 1; j++) {
                  if (value.ID_phuong_an == list_hang[j].ID_phuong_an) {
                    rs += ' <tr> <th scope="row">' + list_hang[j].Noi_dung_phuong_an + '</th>';
                    for (let i = 0; i <= list_cot.length - 1; i++) {
                      if (value.Stt_muc == list_cot[i].Stt_cot) {
                        rs += '<td> <input type="radio" checked disabled id="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" name="' + list_hang[j].ID_phuong_an + '" value="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" /></td>';
                      } else {
                        rs += '<td> <input type="radio" disabled id="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" name="' + list_hang[j].ID_phuong_an + '" value="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" /></td>';
                      }
                    }
                    rs += '</tr>';
                  }
                }
              });

              rs += '</tbody> </table>';
              rs += '</div></form > <hr/>';
            }
            // 5: câu hỏi dạng lưới hộp kiểm
            if (h.QuestionInfo.Loai_cau_hoi == 5) {
              rs += '<form > <div class="form-group">';
              rs += '<label style="color:red">' + h.QuestionInfo.Noi_dung_cau_hoi + '</label> <br />';
              rs += '<p> Ghi chú: ' + h.QuestionInfo.Ghi_chu + '</p> <br />';
              rs += '<table class="table table-bordered dataTables_wrapper no-footer"><thead ><tr><th scope="col">#</th>';
              var list_hang = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
                return !pa.La_cot;
              });
              var list_cot = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
                return pa.La_cot;
              });
              list_cot = this.sortByKeyAsc(list_cot, "Stt_cot");
              list_hang = this.sortByKeyAsc(list_hang, "Stt_cot");
              for (let j = 0; j <= list_cot.length - 1; j++) {
                rs += ' <th scope="col">' + list_cot[j].Noi_dung_phuong_an + '</th>';
              }
              rs += '</tr> </thead>  <tbody>';
              var Idpa = 0;


              for (var j = 0; j <= list_hang.length - 1; j++) {
                rs += ' <tr> <th scope="row">' + list_hang[j].Noi_dung_phuong_an + '</th>';
                for (var i = 0; i <= list_cot.length - 1; i++) {
                  var check = jQuery.grep(AnwserData, function (pa: any, x) {
                    return pa.ID_phuong_an == list_hang[j].ID_phuong_an && pa.Stt_muc == list_cot[i].Stt_cot;
                  });
                  var checked = "";
                  if (check.length > 0) {
                    checked = "checked";
                  }
                  rs += '<td> <input ' + checked + ' disabled type="checkbox" id="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" name="' + list_hang[j].ID_phuong_an + '" value="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" /></td>';
                }
                rs += '</tr>';
              }
              // AnwserData.forEach(value => {                
              //   for (let j = 0; j <= list_hang.length - 1; j++) {
              //     if (value.ID_phuong_an == list_hang[j].ID_phuong_an) {
              //       Idpa=value.ID_phuong_an;
              //       rs += ' <tr> <th scope="row">' + list_hang[j].Noi_dung_phuong_an + '</th>';
              //       for (let i = 0; i <= list_cot.length - 1; i++) {
              //         if (value.Stt_muc == list_cot[i].Stt_cot) {
              //           rs += '<td> <input type="checkbox" checked disabled id="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" name="' + list_hang[j].ID_phuong_an + '" value="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" /></td>';
              //         } else {
              //           rs += '<td> <input type="checkbox" disabled id="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" name="' + list_hang[j].ID_phuong_an + '" value="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" /></td>';
              //         }
              //       }
              //       rs += '</tr>';
              //     }
              //   }               
              // });
              // if(Idpa==0){
              //   for (let j = 0; j <= list_hang.length - 1; j++) {
              //     rs += ' <tr> <th scope="row">' + list_hang[j].Noi_dung_phuong_an + '</th>';
              //     for (let i = 0; i <= list_cot.length - 1; i++) {
              //       rs += '<td> <input type="checkbox" disabled id="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" name="' + list_hang[j].ID_phuong_an + '" value="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" /></td>';
              //     }
              //     rs += '</tr>';
              //   }
              // }
              rs += '</tbody> </table>';
              rs += '</div></form > <hr/>';
            }
            // 6: Câu hỏi dạng văn bản
            var AnwserData = element.AnwserData
            AnwserData.forEach(value => {
              if (value.Noi_dung == null) {
                value.Noi_dung = ''
              }
              if (h.QuestionInfo.ID_cau_hoi == value.ID_cau_hoi) {
                if (h.QuestionInfo.Loai_cau_hoi == 6) {
                  rs += '<form> <div class="form-group">';
                  rs += '<label style="color:red">' + h.QuestionInfo.Noi_dung_cau_hoi + '</label> <br />';
                  rs += ' <textarea id="' + h.QuestionInfo.ID_cau_hoi + '" rows="5"  style=" width: 100%" disabled>' + value.Noi_dung + '</textarea>';
                  rs += '</div></form > <hr/>';
                }
              }
            });
          }
        });
      }
    } else {
      // 1: Câu hỏi dạng trắc nghiệm
      if (h.QuestionInfo.Loai_cau_hoi == 1) {
        rs += '<form> <div class="form-group">';
        rs += '<label style="color:red"> ' + h.QuestionInfo.Noi_dung_cau_hoi + '</label> <br />';

        rs += '<p> Ghi chú: ' + h.QuestionInfo.Ghi_chu + '</p> <br />';
        for (let j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
          rs += '<input type="radio" disable style="margin-left: 20%" disabled id="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '" name="' + h.QuestionInfo.ID_cau_hoi + '" value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '"/>' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '<br/>';
        }
        rs += '</div></form > <hr/>'

      }
      // 2: Câu hỏi dạng hộp kiểm
      if (h.QuestionInfo.Loai_cau_hoi == 2) {
        rs += '<form> <div class="form-group">';
        rs += '<label style="color:red">' + h.QuestionInfo.Noi_dung_cau_hoi + '</label> <br />';
        for (let j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
          rs += '<input type="checkbox" style="margin-left: 20%" disabled id="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '" name="' + h.QuestionInfo.ID_cau_hoi + '" value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '"/>' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '<br/>';
        }
        rs += '</div></form > <hr/>'
      }
      //3: câu hỏi dạng menu thả xuống
      if (h.QuestionInfo.Loai_cau_hoi == 3) {
        rs += '<form> <div class="form-group">';
        rs += '<label style="color:red">' + h.QuestionInfo.Noi_dung_cau_hoi + '</label>' + BatBuoc +
          ' <p> Ghi chú:  ' + h.QuestionInfo.Ghi_chu + '</p > <br /><select style="margin-left: 4%" id="' + h.QuestionInfo.ID_cau_hoi + '">';
        rs += '    <option selected value="0">Chọn một đáp án</option>';
        for (let j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
          rs += ' <option value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '">' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '</option>';
        }
        rs += '</select> </div> </form> <hr/>';
      }

      // 4: câu hỏi dạng lưới trắc nghiệm
      if (h.QuestionInfo.Loai_cau_hoi == 4) {
        rs += '<form> <div class="form-group">';
        rs += '<label style="color:red;">' + h.QuestionInfo.Noi_dung_cau_hoi + '</label> <br />';
        rs += '<table class="table table-bordered dataTables_wrapper no-footer" ><thead ><tr><th scope="col">#</th>';
        var list_hang = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
          return !pa.La_cot;
        });
        var list_cot = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
          return pa.La_cot;
        });
        list_cot = this.sortByKeyAsc(list_cot, "Stt_cot");
        list_hang = this.sortByKeyAsc(list_hang, "Stt_cot");
        for (let j = 0; j <= list_cot.length - 1; j++) {
          rs += ' <th scope="col">' + list_cot[j].Noi_dung_phuong_an + '</th>';
        }
        rs += '</tr> </thead>  <tbody>';
        for (let j = 0; j <= list_hang.length - 1; j++) {
          rs += ' <tr> <th scope="row">' + list_hang[j].Noi_dung_phuong_an + '</th>';
          for (let i = 0; i <= list_cot.length - 1; i++) {

            rs += '<td> <input type="radio" disabled id="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" name="' + list_hang[j].ID_phuong_an + '" value="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" /></td>';
          }
          rs += '</tr>';
        }
        rs += '</tbody> </table>';
        rs += '</div></form > <hr/>';
      }
      // 5: câu hỏi dạng lưới hộp kiểm
      if (h.QuestionInfo.Loai_cau_hoi == 5) {
        rs += '<form> <div class="form-group">';
        rs += '<label style="color:red;">' + h.QuestionInfo.Noi_dung_cau_hoi + '</label> <br />';
        rs += '<table class="table table-bordered dataTables_wrapper no-footer"><thead ><tr><th scope="col">#</th>';
        var list_hang = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
          return !pa.La_cot;
        });
        var list_cot = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
          return pa.La_cot;
        });
        list_cot = this.sortByKeyAsc(list_cot, "Stt_cot");
        list_hang = this.sortByKeyAsc(list_hang, "Stt_cot");
        for (let j = 0; j <= list_cot.length - 1; j++) {
          rs += ' <th scope="col">' + list_cot[j].Noi_dung_phuong_an + '</th>';
        }
        rs += '</tr> </thead>  <tbody>';
        for (let j = 0; j <= list_hang.length - 1; j++) {
          rs += ' <tr> <th scope="row">' + list_hang[j].Noi_dung_phuong_an + '</th>';
          for (let i = 0; i <= list_cot.length - 1; i++) {
            rs += '<td> <input type="checkbox" id="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" name="' + list_hang[j].ID_phuong_an + '" value="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" /></td>';
          }
          rs += '</tr>';
        }
        rs += '</tbody> </table>';
        rs += '</div></form > <hr/>';
      }
      //6 Noi dung
      if (h.QuestionInfo.Loai_cau_hoi == 6) {
        rs += '<form> <div class="form-group">';
        rs += '<label style="color:red;">' + h.QuestionInfo.Noi_dung_cau_hoi + '</label> <br />';
        rs += ' <textarea id="' + h.QuestionInfo.ID_cau_hoi + '" rows="5"  style=" width: 100%" disabled>Chưa trả lời câu hỏi!</textarea>';
        rs += '</div></form > <hr/>';
      }
    }
    return rs;
  }
  sortByKeyAsc(array, key) {
    return array.sort(function (a, b) {
      var x = parseInt(a[key]); var y = parseInt(b[key]);
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
}


<section class="content-header" style="padding: 0.5% !important">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-md-6">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
            </li>
            <li class="breadcrumb-item active">
              Khảo sát doanh nghiệp
            </li>
          </ol>
        </div>
        <div class="col-md-6">
          <button class="btn btn-success btn-sm" style="float: right" (click)="guiPhieu()">
            <i class="fa fa-paper-plane"></i> Gửi phiếu
          </button>
          <button class="btn btn-primary btn-sm" style="float: right; margin-right: 5px;" (click)="getPhanPhoiDoanhNghiep()">
            <i class="fa fa-search"></i> Tìm kiếm
          </button>
        </div>
      </div>
    </div>
  </section>
  
  <section class="content cus-body">
    <div class="card">
      <form class="form-horizontal" [formGroup]="Fillter">
        <div class="card-header p-2" style="align-content: flex-end">
          <div class="row" style="padding-left: 10px; padding-right: 10px">
            <div class="col-sm">
              <label class="col-md-10">Đợt đánh giá</label>
              <select class="form-control" formControlName="ID_dot_danh_gia">
                <option *ngFor="let p of dotDanhGia" [value]="p.ID_dot_danh_gia">
                  {{ p.Ten_dot }}
                </option>
              </select>
            </div>
            <div class="col-sm">
              <label class="col-md-10">Loại phiếu đánh giá</label>
              <select class="form-control" formControlName="ID_loai_danh_gia" (change)="filterPhieuDanhGia()">
                <option value="-1">Tất cả</option>
                <option value="0">Chưa phân loại</option>
                <option *ngFor="let p of loaiDanhGia" [value]="p.ID_loai_danh_gia">
                  {{ p.Ten_loai_danh_gia }}
                </option>
              </select>
            </div>
            <div class="col-md-4">
              <nz-form-item nz-col [nzSpan]="24">
                  <label>Phiếu đánh giá</label>
                  <nz-form-control>
                      <nz-select class="custom-nz-select" formControlName="ID_phieu_danh_gia" nzShowSearch nzAllowClear nzDropdownMatchSelectWidth="false" nzSize="large">
                      <nz-option [nzValue]="0" nzLabel="Chọn phiếu đánh giá" style="width: 100%;"></nz-option>
                      <nz-option 
                          *ngFor="let p of phieuDanhGiaFilter" 
                          [nzValue]="p.ID_phieu_danh_gia" 
                          [nzLabel]="p.Ma_phieu_danh_gia ? '[' + p.Ma_phieu_danh_gia + '] ' + p.Ten_phieu : p.Ten_phieu">
                      </nz-option>
                      </nz-select>
                  </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div style="padding-left: 10px;padding-right: 10px; margin-top: 40px;">
            <div class="row">
              <div class="col-sm" >
                <label class="col-md-10">Mã số thuế</label>
                <input class="form-control" type="text"  placeholder="Mã số thuế..." (keyup.enter)="getPhanPhoiDoanhNghiep()" formControlName="Ma_so_thue" />
              </div>
              <div class="col-sm">
                <label class="col-md-10">Tên doanh nghiệp</label>
                <input class="form-control" type="text" formControlName="Ten_doanh_nghiep" (keyup.enter)="getPhanPhoiDoanhNghiep()" placeholder="Tên doanh nghiệp..." />
              </div>
              <div class="col-sm">
                <label class="col-md-10">Tìm kiếm nhanh</label>
                <input class="form-control" type="text"  formControlName="Tim_kiem_nhanh" (keyup.enter)="getPhanPhoiDoanhNghiep()" placeholder="Tìm kiếm nhanh..." />
              </div>
            </div>
           
          </div>
          <hr />
        </div>
      </form>
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane" id="TeachInfo">
            <div class="row">
              <div class="col-md-12" style="margin-bottom: 5px">
               
                <button class="btn btn-primary btn-sm" (click)="checkAll()">
                  Chọn tất cả (Đã chọn: {{ ChonCount }})
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" style="height: 60vh; overflow: scroll;">
                <table class="table table-bordered ">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center">Chọn</th>
                      <th scope="col" class="text-center"  style="width: 10vW;">Mã số thuế</th>
                      <th scope="col" class="text-center " style="width: 30vW;">Tên doanh nghiệp</th>
                      <th scope="col" class="text-center" >Từ ngày</th>
                      <th scope="col" class="text-center" >Đến ngày</th>
                      <th scope="col" class="text-center" >Đã gửi</th>
                      <th scope="col" class="text-center" >Đã đánh giá</th>
                      <th scope="col" class="text-center">Tác vụ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of phanPhoiDoanhNghiep; let i = index">
                      <td style="text-align: center;">
                        <input type="checkbox" [(checked)]="p.Chon" (change)="checkOne(i)" />
                      </td>
                      <td class="text-center">{{ p.Ma_so_thue }}</td>
                      <td>{{ p.Ten_doanh_nghiep}}</td>
                      <td class="text-center">{{ p.Ngay_bat_dau | date : 'dd/MM/yyyy' }}</td>
                      <td class="text-center">{{ p.Ngay_ket_thuc | date : 'dd/MM/yyyy'}}</td>
                      <td class="text-center">{{ p.Da_gui }}</td>
                      <td class="text-center">{{ p.Da_danh_gia}}</td>
                      <td style="text-align: center; white-space: nowrap;">
                        <button title="Xoá phiếu đánh giá" (click)="deleteMulti(p.lstPhieu)" class="btn btn-sm btn-danger" style="margin-right: 5px">
                            <i class="fas fa-trash-alt"></i></button>
                        <button title="Xem chi tiết" (click)="open(modalPhieuDaGui, p)" class="btn btn-primary btn-sm">
                            <i class="far fa-eye"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
  
      </div>
  
    </div>
  </section>

  <ng-template #modalPhieuDaGui let-modal>

    <h3 class="mx-auto" style="margin-top: 20px; margin-bottom: 20px;">Danh sách phiếu đã gửi</h3>
  
    <section class="content cus-body">
      <div class="card">
        <div class="row" style="padding: 10px; height: 50px;">
          <div class="col-sm">
            <p class="col-md-10" style="width: 600px; font-size: 19px; text-shadow: 1px 1px 1px rgba(0,0,0,0.2);">
              {{ phieuDanhGiaMotDoanhNghiep.Ten_dot }}
            </p>
          </div>
          <div class="col-sm">
            <p><span> Ngày bắt đầu: </span> {{ phieuDanhGiaMotDoanhNghiep.Ngay_bat_dau }}</p>
          </div>
          <div class="col-sm">
            <p><span> Ngày kết thúc: </span> {{ phieuDanhGiaMotDoanhNghiep.Ngay_ket_thuc }}</p>
          </div>
        </div>
        <div style="max-height: 400px; overflow-y: auto; padding-right: 10px; padding-left: 10px;">
          <table class="table table-bordered" style="width:100%">
            <thead>
              <tr>
                <th scope="col" class="text-center">STT</th>
                <th scope="col" class="text-center">Tên phiếu</th>
                <th scope="col" class="text-center">Ngày gửi phiếu</th>
                <th scope="col" class="text-center">Trạng thái</th>
                <th scope="col" class="text-center" style="width: 300px">Địa chỉ đánh giá</th>
              </tr>
            </thead>
            <tbody class="dataTables_scrollBody">
              <tr *ngFor="let p of phieuDanhGiaMotDoanhNghiep.Phieu_danh_gia; let i = index">
                <td class="text-center align-middle">{{i+1}}</td>
                <td>{{p.Ten_phieu}}</td>
                <td class="text-center align-middle">{{p.Ngay_gui | date : 'dd/MM/yyyy'}}</td>
                <td class="text-center align-middle">{{p.Da_danh_gia ? "Đã đánh giá" : "Chưa đánh giá" }}</td>
                <td><a href="{{p.Dia_chi_danh_gia}}">{{p.Dia_chi_danh_gia}}</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <div class="modal-footer" style="height: 50px; padding-top: 10px;">
      <button type="button" class="btn btn-sm btn-danger" (click)="modal.close('Save click')"> <i
          class="far fa-times-circle"></i> Đóng</button>
    </div>
  </ng-template>
  
import { InjectionToken } from '@angular/core';

export interface AppConfiguration {
  production: boolean;
  UtilsUrl: string;
  TeacherUrl: string;
  SupportUrl: string;
  code_security: string;
  SurveyURL:string;
  SurveyURL_Support:string;
  document_SurveyURL:string;
  document_SeasonURL:string;
  document_Questions:string;
  document_Enterprise:string
  AuthorizeType: number;
  sso: {
    ssoServer: string;
    client_id: string;
    redirect_uri: string;
    response_type: string;
    scope: string;
    code_challenge_method: string;
    response_mode: string;
  };
  CAS: {
    ServerUrl: string;
    Service: string;
    Logout: string;
  };
}

export const AppConfig = new InjectionToken<AppConfiguration>('@@appConfiguration');

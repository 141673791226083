<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-3">
      </div>
      <div class="col-md-9">
        <button class="btn btn-success btn-sm" style="float: right; margin-right: 7px;" (click)="XuatExcel()">
          <i class="fas fa-file-excel"></i> Xuất excel
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="row" style="padding-left:10px; padding-right:10px">
        <div class="col-md-3">
          <label>Mã sinh viên</label>
          <input type="text" class="form-control" id="Ma_sv" [(ngModel)]="Ma_sv" placeholder="Nhập mã sinh viên ..." />
        </div>
        <div class="col-md-3">
          <label>Mã giáo viên</label>
          <input type="text" class="form-control" id="Ma_cb" [(ngModel)]="Ma_cb" placeholder="Nhập mã giáo viên ..." />
        </div>
        <div class="col-md-3">
          <label>Trạng thái</label>
          <select class="form-control" id="Da_danh_gia" [(ngModel)]="Da_danh_gia">
            <option value="0">Chưa đánh giá</option>
            <option value="1">Đã đánh giá</option>
          </select>
        </div>
        <div class="col-md-3" style="padding-top: 30px;">
          <button style="float: right;" class="btn-success btn-sm" (click)="Search()"><i class="fas fa-search"></i><span
              style="font-weight: bold;"> Tìm kiếm</span></button>
        </div>
      </div>
      <div class="row" style="padding:10px">
        <div class="col-md-3">
          <label>Đợt đánh giá</label>
          <select name="" class="form-control" id="ID_dot_danh_gia" [(ngModel)]="ID_dot_danh_gia" (change)="DotChange()">
            <option value="0">Chọn đợt đánh giá</option>
            <option *ngFor="let p of ListSeason" [value]="p.ID_dot_danh_gia">{{p.Ten_dot}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <nz-form-item nz-col [nzSpan]="24">
            <label>Phiếu đánh giá</label>
            <nz-form-control>
              <nz-select class="custom-nz-select" [(ngModel)]="ID_phieu_danh_gia" nzShowSearch nzAllowClear nzDropdownMatchSelectWidth="false" nzSize="large">
                <nz-option [nzValue]="0" nzLabel="Chọn phiếu đánh giá" style="width: 100%;"></nz-option>
                <nz-option *ngFor="let p of ListPhieuDanhGia" [nzValue]="p.ID_phieu_danh_gia" [nzLabel]="p.Ten_phieu"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 ">
          <label class="col-md-10">Hệ:</label>
          <select class="form-control" id="He" [(ngModel)]="He" (change)="HeChange()">
            <option [value]="0" selected>Tất cả</option>
            <option *ngFor="let p of ListHe" [value]="p.ID_he">{{p.Ten_he}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Khoa:</label>
          <select class="form-control" id="Khoa" [(ngModel)]="Khoa" (change)="KhoaChange()">
            <option [value]="0" selected>Tất cả</option>
            <option *ngFor="let p of ListKhoa2" [value]="p.ID_khoa">{{p.Ten_khoa}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Khoá học:</label>
          <select class="form-control" id="KhoaHoc" [(ngModel)]="KhoaHoc" (change)="KhoahocChange()">
            <option [value]="0" selected>Tất cả</option>
            <option *ngFor="let p of ListKhoaHoc2" [value]="p.Khoa_hoc">{{p.Khoa_hoc}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Lớp học:</label>
          <select class="form-control" id="ChuyenNganh" [(ngModel)]="Lop">
            <option [value]="0" selected>Tất cả</option>
            <option *ngFor="let p of ListLop2" [value]="p.ID_lop">{{p.Ten_lop}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              Hiển thị
              <select (change)="handlePageSizeChange($event)">
                <option *ngFor="let size of pageSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
              kết quả
            </div>
            <div class="col-md-6">
              <button title="Ẩn/hiện bộ lọc" style="float: right; margin: 0 0 5px 5px;" (click)="buttonFilter()">
                <i class="fas fa-filter"></i>
              </button>
              <button title="Tải lại" style="float: right; margin-bottom: 5px;" (click)="refesh()">
                <i class="fas fa-redo-alt"></i>
              </button>
            </div>
          </div>
          <div class="table-responsive" style="max-height: 60vh; margin-bottom: 15px;">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Tên đợt</th>
                  <th>Tên phiếu</th>
                  <th>Tên lớp</th>
                  <th>Tên môn</th>
                  <th>Mã sinh viên</th>
                  <th>Tên sinh viên</th>
                  <th>Mã giáo viên</th>
                  <th>Tên giáo viên</th>
                  <th>Trạng thái</th>
                  <th>Ngày đánh giá</th>
                  <th>Thao tác</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of dataTable; let i=index; let odd = odd">
                  <td>{{ p.Ten_dot }}</td>
                  <td>{{ p.Ten_phieu }}</td>
                  <td>{{ p.Ten_lop }}</td>
                  <td>{{ p.Ten_mon }}</td>
                  <td>{{ p.Ma_sv }}</td>
                  <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                  <td>{{ p.Ma_cb }}</td>
                  <td style="white-space: nowrap;">{{p.Giao_vien }}</td>
                  <td style="text-align: center;">{{p.TrangThai}}
                  </td>
                  <td>{{ p.NgayDanhGia == '0001-01-01T00:00:00' ? '' : p.NgayDanhGia | date: 'dd/MM/yyyy'}}</td>
                  <td>
                    <button class="btn btn-sm btn-danger" style="margin-right: 7px;"
                      (click)="open(DeleteModal, 'xs', 'Delete', p)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="float: left; font-weight: bold;">
                Tổng số {{ totalItem|number }} kết quả, đã lọc được
                {{ totalItemFilter|number }} kết quả
              </p>
            </div>
            <div class="col-md-6">
              <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage(1)">Đầu</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == 1">
                    <a class="page-link" (click)="changPage('pre')">Trước</a>
                  </li>
                  <ng-container *ngIf="page > 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <ng-container *ngFor="let element of arrNumberPage_chil">
                    <li class="page-item" [class.active]="page == element">
                      <a class="page-link" (click)="changPage(element)">{{ element }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="page < numberPage - 4">
                    <li class="page-item"><a class="page-link">...</a></li>
                  </ng-container>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage('next')">Sau</a>
                  </li>
                  <li class="page-item" [class.disabled]="page == numberPage">
                    <a class="page-link" (click)="changPage(numberPage)">Cuối</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #DeleteModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i class="fas fa-trash-alt" style="
            color: #f15e5e;
            font-size: 46px;
            display: inline-block;
            margin-top: 13px;
          "></i>
      </div>
      <p style="
          color: inherit;
          text-decoration: none;
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          margin-top: 3em;
        ">
        Bạn có chắc chắn muốn xóa
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-md btn-success" (click)="Delete()">
          Đồng ý
        </button>
        <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!-- <section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="active tab-pane">
        <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Tên đợt</th>
                <th>Tên phiếu</th>
                <th>Mã sinh viên</th>
                <th>Tên sinh viên</th>
                <th>Mã giáo viên</th>
                <th>Tên giáo viên</th>
                <th>Tài khoản gửi</th>
                <th>Trạng thái</th>
                <th>Ngày đánh giá</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let p of dataTable; let i=index; let odd = odd">
                <td>{{ p.Ten_dot }}</td>
                <td>{{ p.Ten_phieu }}</td>
                <td>{{ p.Ma_sv }}</td>
                <td style="white-space: nowrap;">{{ p.Ho_ten }}</td>
                <td>{{ p.Ma_cb }}</td>
                <td style="white-space: nowrap;">{{ p.Giao_vien }}</td>
                <td>{{ p.Tai_khoan_gui }}</td>
                <td>{{ p.TrangThai }}</td>
                <td>{{ p.NgayDanhGia == '0001-01-01T00:00:00' ? '' : p.NgayDanhGia | date: 'dd/MM/yyyy'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section> -->
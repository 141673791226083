<section class="content-header" style="padding: 0.5% !important">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">
            Phân phối phiếu đánh giá lớp tín chỉ
          </li>
        </ol>
      </div>
      <div class="col-md-6">
        <button class="btn btn-success btn-sm" style="float: right" (click)="guiPhieuLopTinChi()">
          <i class="fa fa-paper-plane"></i> Gửi phiếu
        </button>
        <button class="btn btn-primary btn-sm" style="float: right; margin-right: 5px;" (click)="getLopTinChi()">
          <i class="fa fa-search"></i> Tìm kiếm
        </button>
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content cus-body">
  <div class="card">
    <form class="form-horizontal" [formGroup]="Fillter">
      <div class="card-header p-2" style="align-content: flex-end">
        <div class="row" style="padding-left: 10px; padding-right: 10px">
          <div class="col-md-2">
            <label class="col-md-10">Kỳ đăng ký</label>
            <select
              class="form-control"
              formControlName="ID_hoc_ky_dang_ky"
            >
            
              <option *ngFor="let p of hocKyDangKy" [value]="p.Ky_dang_ky">
               Đợt {{ p.Dot }} - Học kỳ {{ p.Hoc_ky }} - Năm học {{ p.Nam_hoc }}
              </option>
            </select>
          </div>
          <div class="col-md-2">
            <label class="col-md-10">Đợt đánh giá</label>
            <select
              class="form-control"
              formControlName="ID_dot_danh_gia"
            >
            
              <option *ngFor="let p of dotDanhGia" [value]="p.ID_dot_danh_gia">
                {{ p.Ten_dot }}
              </option>
            </select>
          </div>
          <div class="col-md-2">
            <label class="col-md-10">Loại đánh giá</label>
            <select
              class="form-control"
              formControlName="ID_loai_danh_gia"
              (change)="filterPhieuDanhGia()"
            >
              <option value="0">Chọn loại đánh giá</option>
              <option *ngFor="let p of loaiDanhGia" [value]="p.ID_loai_danh_gia">
               {{ p.Ten_loai_danh_gia }}
              </option>
            </select>
          </div>
          <div class="col-md-2">
            <nz-form-item nz-col [nzSpan]="24">
              <label>Phiếu đánh giá</label>
                <nz-form-control>
                  <nz-select class="custom-nz-select" formControlName="ID_phieu_danh_gia" nzShowSearch nzAllowClear nzDropdownMatchSelectWidth="false" nzSize="large">
                    <nz-option  [nzValue]="0" nzLabel="Chọn phiếu đánh giá" style="width: 100%;"></nz-option>
                    <nz-option 
                      *ngFor="let p of phieuDanhGiaFilter" 
                      [nzValue]="p.ID_phieu_danh_gia" 
                      [nzLabel]="p.Ma_phieu_danh_gia ? '[' + p.Ma_phieu_danh_gia + '] ' + p.Ten_phieu : p.Ten_phieu">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-md-2">
            <label class="col-md-10">Trạng thái gửi phiếu</label>
            <select
              class="form-control"
              formControlName="Trang_thai_gui"
            >
              <option value="0">Chọn trạng thái gửi</option>
              <option *ngFor="let p of trangThaiGui" [value]="p.value">
                {{ p.trangThai }}
              </option>
            </select>
          </div>
          <div class="col-md-2">
            <label class="col-md-10">Tìm kiếm nhanh</label>
            <input
              class="form-control"
              type="text"
              (keyup.enter)="getLopTinChi()"
              formControlName="Text_search"
              placeholder="Tên môn/ tên lớp/ tên giảng viên..."
            />
          </div>
        </div>
        <div  class="row" style="padding-left: 10px; padding-right: 10px">
          <div class="col-md-2">
            <label class="col-md-10">Hệ</label>
            <select class="form-control" formControlName="ID_he" (change)="changeKhoa()" (change)="changeKhoaHoc()" (change)="changeChuyenNganh()">
                <option value="0">Tất cả</option>
                <option *ngFor="let p of ListHe" [value]="p.ID_he">
                    {{  p.Ten_he }}
                </option>

            </select>
          </div>
          <div class="col-md-2" >
              <label class="col-md-10">Khoa</label>
              <select class="form-control" formControlName="ID_khoa" (change)="changeKhoaHoc()" (change)="changeChuyenNganh()">
                  <option value="0">Tất cả</option>
                  <option  *ngFor="let p of ListKhoa; trackBy: trackByKhoa" [value]="p.ID_khoa" [class.hidden]="!selectedHeId">
                      {{  p.Ten_khoa }}
                  </option>
              </select>
          </div>
          <div class="col-md-2">
              <label class="col-md-10">Khóa học</label>
              <select class="form-control" formControlName="Khoa_hoc" (change)="changeChuyenNganh()">
                  <option value="0">Tất cả</option>
                  <option *ngFor="let p of ListKhoaHoc; trackBy: trackByKhoaHoc" [value]="p.Khoa_hoc">
                      {{  p.Khoa_hoc }}
                  </option>

              </select>
          </div>
          <div class="col-md-2">
            <label class="col-md-10">Ngành</label>
            <select class="form-control" formControlName="ID_nganh" (change)="changeChuyenNganh()">
                <option value="0">Tất cả</option>
                <option *ngFor="let p of ListNganh" [value]="p.ID_nganh">
                    {{  p.Ten_nganh }}
                </option>

            </select>
        </div>
          <div class="col-md-2">
              <label class="col-md-10">Chuyên ngành</label>
              <select class="form-control" formControlName="ID_chuyen_nganh">
                  <option value="0">Tất cả</option>
                  <option *ngFor="let p of ListChuyenNganh; trackBy: trackByChuyenNganh" [value]="p.ID_chuyen_nganh">
                      {{  p.Chuyen_nganh }}
                  </option>
              </select>
          </div>
        </div>
     
      </div>
      <!-- /.card-header -->
    </form>
    <!-- <div class="card-body cus-body">
              <div class="card"> -->

    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="col-md-12" style="margin-bottom: 5px">
              <hr />
              <button
                class="btn btn-primary btn-sm"
                (click)="checkAll()"
              >
                Chọn tất cả (Đã chọn: {{ ChonCount }})
              </button>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-12" style="height: 60vh; overflow: scroll;">
              <table
                class="table table-bordered "
              >
                <thead>
                  <tr> 
                    <th scope="col"  class="text-center">Chọn</th>
                    <th scope="col"  class="text-center">Mã học phần</th>
                    <th scope="col"  class="text-center">Tên học phần</th>
                    <th scope="col"  class="text-center">Lớp tín chỉ</th>
                    <th scope="col"  class="text-center">Thời gian học</th>
                    <th scope="col"  class="text-center">Giảng viên</th>
                    <th scope="col"  class="text-center">Số SV đăng ký</th>
                    <th scope="col"  class="text-center">Từ ngày</th>
                    <th scope="col"  class="text-center">Đến ngày</th>
                    <th scope="col"  class="text-center">Đã gửi</th>
                    <th scope="col"  class="text-center">Đã đánh giá</th>
                    <th scope="col"  class="text-center">Tác vụ</th>
                  </tr>
                </thead>
                <tbody >
                  <tr *ngIf="tableDataFilter.length==0">
                  <td colspan="12" style="text-align: center;">Không tìm thấy dữ liệu</td>
                  </tr>
                  <tr *ngFor="let p of tableDataFilter; let i = index">
                    <td style="text-align: center;">
                      <input
                        type="checkbox"
                        [(checked)]="p.Chon"
                        (change)="checkOne(i)"
                      />
                    </td>
                    <td>{{ p.Ky_hieu }}</td>
                    <td>{{ p.Ten_mon }}</td>
                    <td  class="text-center">{{ p.Ky_hieu_lop_tc }}</td>
                    <td  class="text-center">{{ p.Tu_ngay | date:'dd/MM/yyyy'}} - {{ p.Den_ngay | date:'dd/MM/yyyy' }}</td>
                    <td>{{ p.Ho_ten }}</td>
                    <td  class="text-center">{{ p.So_luong }}</td>              
                    <td  class="text-center">{{ p.Ngay_bat_dau | date:'dd/MM/yyyy'}}</td>
                    <td  class="text-center">{{ p.Ngay_ket_thuc | date:'dd/MM/yyyy'}}</td>
                    <td  class="text-center">{{ p.Da_gui }}</td>
                    <td  class="text-center">{{ p.Da_danh_gia }}</td>
                    <td style="text-align: center; white-space: nowrap;">
                      <button title="Xoá phiếu đánh giá" (click)="deleteMulti(p.lstSinhVien)" class="btn btn-sm btn-danger" style="margin-right: 5px">
                        <i class="fas fa-trash-alt"></i></button>
                      <button title="Xem chi tiết" (click)="open(View,'xl',p.lstSinhVien)" class="btn btn-primary btn-sm">
                        <i class="far fa-eye"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- /.tab-content -->
    </div>
    <!-- /.card-body -->
  </div>
</section>

<ng-template #View let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px">
    <h4 class="modal-title h-title w-100">Danh sách phiếu đã gửi</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px">&times;</span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12" style="height: 60vh; overflow: scroll;">
        <div class="row" style="padding: 10px; height: 50px;">
          <div class="col-sm">
            <p class="col-md-10" style="width: 600px; font-size: 19px; text-shadow: 1px 1px 1px rgba(0,0,0,0.2);">{{ tableDetailData.Dot_danh_gia }}</p>
          </div>
          <div class="col-sm">
            <p><span> Ngày bắt đầu: </span> <label>{{ tableDetailData.Ngay_bat_dau }}</label></p>
          </div>
          <div class="col-sm">
            <p><span> Ngày kết thúc: </span> <label>{{ tableDetailData.Ngay_ket_thuc }}</label></p>
          </div>
        </div>
        <hr>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>STT</th>
              <th scope="col">Mã SV</th>
              <th scope="col">Họ Tên</th>
              <th scope="col">Ngày sinh</th>
              <th scope="col">Tên phiếu</th>
              <th scope="col">Ngày gửi</th>
              <th scope="col">Đã đánh giá</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="tableDetailData.ListSingVien.length === 0">
              <td colspan="7" style="text-align: center;">Không tìm thấy dữ liệu</td>
            </tr>
            <tr *ngFor="let p of tableDetailData.ListSingVien; let i = index">
              <td>{{i + 1}}</td>
              <td>{{ p.Ma_sv }}</td>
              <td>{{ p.Ho_ten }}</td>
              <td>{{ p.Ngay_sinh ? (p.Ngay_sinh | date:'dd/MM/yyyy') : '' }}</td>
              <td>{{ p.Ten_phieu }}</td>
              <td>{{ p.Ngay_gui ? (p.Ngay_gui | date:'dd/MM/yyyy hh:mm') : '' }}</td>
              <td style="text-align: center;">
                <i *ngIf="p.Da_danh_gia" class="fa fa-check" aria-hidden="true"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.dismiss('Cross click')">Đóng</button>
  </div>
</ng-template>


import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class SeasonService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }
  getListSeason(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Season/GetAll", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  GuiPhieuDanhGiaCanBo(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Season/DotDanhGiaGuiCanBo",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  SendCoVanHocTap(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Season/CoVanHocTap_PhanPhoiPhieu",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  DeleteSeason(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Season/Delete", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  InsertSeason(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Season/Add", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  UpdateSeason(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Season/Update", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getListClass(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Season/Get_List_Lop", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetHe(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/He_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetKhoa(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/Khoa_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetKhoaHoc(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/KhoaHoc_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetChuyenNganh(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/ChuyenNganh_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getHe(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "He/Danh_sach_he", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getKhoa(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Khoa/Danh_sach_khoa", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getKhoaHoc(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/KhoaHoc_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getChuyenNganh(token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UtilsUrl + "ChuyenNganh/Danh_sach_chuyen_nganh",
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getLop(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Lop/Danh_sach_lop_dang_hoc", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  SendSeasonBySubjects(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Season/DotDanhGiaGuiMH", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  SendSeason(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Season/DotDanhGiaGui", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetListMonHoc(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Season/DanhSachMonHoc", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  SendSeasonDN(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Season/DotDanhGiaGuiDN", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  SendSeasonGV(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Season/DotDanhGiaGuiGV", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ListGV(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Teacher/GetAll", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}

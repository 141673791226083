import { Component, OnInit, OnDestroy, ViewChild, Inject } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { DataTableDirective } from "angular-datatables";
import { SurveyService } from "src/app/Utils/service/survey.service";
import { ClassService } from "src/app/Utils/service/class.service";
import { common } from "src/app/app.common";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AnnounceService } from "src/app/Utils/service/announce.service";
import {
  ResponseClassListAll,
  ClassInfo,
} from "src/app/Models/output.model/ResponseClassListAll";
import { Subject } from "rxjs";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";
import { AppConfig, AppConfiguration } from "src/configuration";
import { LoaiDanhGiaService } from "src/app/Utils/service/loai-danh-gia.service";
import { CauHoiNhomService } from "src/app/Utils/service/cau-hoi-nhom.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.scss"],
})
export class SurveyComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings;
  dtOptionsQuestion: any = {};
  Res: any;
  Survey: any;
  ClassData: any;
  ClassDataQuestion: any;
  ClassDataOption: any;
  DataAddQuestion: any = [];
  lstLoaiDanhGia: any = [];
  lstCauHooiFilter: any = [];
  lstCauHoiNhom = [];
  DataTable: any;
  requestObject: RequestBase = new RequestBase();
  TenPhieu: string;
  NguoiTao: string;
  LoaiDanhGia: number = -1;
  token: any;
  SurveyName: string = "";
  SurveyType: number = 1;
  Content: string = "";
  Note: string = "";
  Ma_phieu_danh_gia: string = "";
  Ten_loai_danh_gia: string = "";
  Phieu_danh_gia: any = "";
  selecttype: number;
  TitleModal: string;
  Id_phieu_danh_gia: number;
  Title: string = "Đang tải danh sách thông báo...";
  TitleClass: string = "spinner-border text-muted";
  TbQuestion: any = {};
  temp: any;
  CheckCauHoi: boolean;
  dataFilterForm: FormGroup;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    private toastr: ToastrService,
    public router: Router,
    public Class: ClassService,
    private SurveyService: SurveyService,
    public route: ActivatedRoute,
    private loaiDanhGiaService: LoaiDanhGiaService,
    private cauHoiNhomService: CauHoiNhomService,
    public spinner: NgxSpinnerService,
  ) {}
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;
  closeResult: string;
  dataquestion = new Array();
  ngOnInit() {
    this.dataFilterForm = new FormGroup({
      Noi_dung_cau_hoi: new FormControl(null),
      Ma_cau_hoi: new FormControl(null),
      Loai_cau_hoi: new FormControl(0),
      Nhom_cau_hoi: new FormControl(-1),
      Tai_khoan_tao: new FormControl(null),
    });
    this.CheckCauHoi = false;
    this.com = new common(this.router);
    this.com.CheckLogin();
    const a = this.com.getUserinfo();
    this.requestObject.UserName = a.Info.UserName;
    this.token = a.access_token;
    this.getLoaiDanhGia();
    this.getNhomCauHoi();
    this.TbQuestion = {
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "Nội dung câu hỏi",
        },
        {
          title: "Tên loại câu hỏi",
        },

        {
          title: "Nhóm câu hỏi",
        },
        {
          title: " Tài khoản tạo ",
        },
        {
          title: "Thao tác",
          width: "14%",
          className: "dt-center",
        },
      ],
    };

    this.dtOptions = {
      // pagingType: 'full_numbers',
      responsive: true,

      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "id_phiếu",
          visible: false,
        },
        {
          title: "Mã phiếu đánh giá",
        },
        {
          title: "Tên phiếu",
        },
        {
          title: "Loại đánh giá",
        },
        {
          title: "Nội dung phiếu",
          visible: false,
        },
        {
          title: "Tài khoản tạo",
        },
        {
          title: "Ngày tạo",
        },
        {
          title: "Tài khoản sửa",
          visible: false,
        },
        {
          title: "Ngày sửa",
        },
        {
          title: "Ghi chú",
          visible: false,
        },
        {
          title: "Thao tác",
          width: "14%",
          className: "dt-center",
        },
        {
          title: "ID_loai_danh_gia",
          visible: false,
        },
      ],
    };

    this.dtOptionsQuestion = {
      // pagingType: 'full_numbers',
      pageLength: 25,
      // ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm....",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "id câu hỏi",
          visible: false,
        },
        {
          title: "Nội dung câu hỏi",
        },
        {
          title: "Loại câu hỏi",
        },
        {
          title: "Nhóm câu hỏi",
          class: "dt-center",
        },
        {
          title: "Tài khoản ",
        },
        {
          title: "Thao tác",
          width: "14%",
        },
      ],
      order: [[0, "desc"]],
    };
    this.getListSurvey(a.access_token);

    let element, name, arr;
    element = document.getElementById("menu1");
    arr = element.className.split(" ");
    name = "menu-open";
    if (arr.indexOf(name) == -1) {
      element.className += " " + name;
    }
  }

  getLoaiDanhGia() {
    this.loaiDanhGiaService.getAll(this.token).subscribe((z) => {
      if (z.Status == 1) {
        this.lstLoaiDanhGia = z.ListData;
      } else {
        this.toastr.error(z.Message);
      }
    });
  }

  getNhomCauHoi() {
    this.cauHoiNhomService.getAll(this.token).subscribe((z) => {
      if (z.Status == 1) {
        this.lstCauHoiNhom = z.ListData;
      } else {
        this.toastr.error(z.Message);
      }
    });
  }

  ResetSurvey() {
    this.SurveyName = "";
    this.SurveyType = 1;
    this.Content = "";
    this.Note = "";
    this.Ma_phieu_danh_gia = "";
    this.DataAddQuestion = [];
  }

  Remove_VN(alias) {
    let str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(
      /!|@@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
      " "
    );
    str = str.replace(/ + /g, " ");
    str = str.trim();
    return str;
  }
  fill() {
    $.fn["dataTable"].ext.search.push((settings, data, dataIndex) => {
      let TenPhieudt = data[2];
      let LoaiDanhGiadt = data[11];
      let NguoiTaodt = data[5];

      let dt1, dt2, dt3;
      if (this.TenPhieu != undefined && this.TenPhieu != null && this.TenPhieu != "") {
        dt1 = this.Remove_VN(TenPhieudt)
          .toLowerCase()
          .includes(this.Remove_VN(this.TenPhieu.trim()).toLowerCase());
      } else {
        dt1 = true;
      }
      if (this.NguoiTao != undefined && this.NguoiTao != null && this.NguoiTao != "") {
        dt2 = this.Remove_VN(NguoiTaodt)
          .toLowerCase()
          .includes(this.Remove_VN(this.NguoiTao.trim()).toLowerCase());
      } else {
        dt2 = true;
      }
      if (this.LoaiDanhGia > -1) {
        dt3 = LoaiDanhGiadt == this.LoaiDanhGia ? true : false;
        // dt3 = this.Remove_VN(LoaiDanhGiadt)
        //   .toLowerCase()
        //   .includes(this.Remove_VN(this.LoaiDanhGia).toLowerCase());
      } else {
        dt3 = true;
      }
      // if (this.LoaiDanhGia == 'Tất cả') {
      //   bool1 = LoaiDanhGiadt
      // }
      if (dt1 & dt2 & dt3) {
        return true;
      }
      return false;
    });
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn["dataTable"].ext.search.pop();
    });
    this.fill();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn["dataTable"].ext.search.pop();
  }
  async viewSurvey(content, propety, dt): Promise<void> {
    this.getListQuestion(this.token);
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        scrollable: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.ResetSurvey();
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.ResetSurvey();
        }
      );
    // let lst = jQuery.grep(this.DataTable.Data, (p: any) => {
    //   return p.ID_phieu_danh_gia == dt.ID_phieu_danh_gia;
    // });
    await this.getSurvey(dt.ID_phieu_danh_gia);
    let t = dt.ID_phieu_danh_gia;
    this.SurveyType = dt.Loai_danh_gia;
    this.SurveyName = dt.Ten_phieu;
    this.Ten_loai_danh_gia= dt.Ten_loai_danh_gia;
    this.Content = dt.Noi_dung_phieu;
    this.Note = dt.Ghi_chu;
    this.Id_phieu_danh_gia = dt.ID_phieu_danh_gia;
    let temp = new Array();
    let op = new Array();
    let list_phuong_an_cau_hoi;
    let rs = "";
    let arr = this.Phieu_danh_gia.Questions;
    for (var i = 0; i < arr.length; i++) {
      temp.push(arr[i].QuestionInfo);
      list_phuong_an_cau_hoi = arr[i].OptionsData;
      list_phuong_an_cau_hoi = this.sortByKeyAsc(
        list_phuong_an_cau_hoi,
        "Stt_cot"
      );
      rs += '<div class="row cus-box cus-fs13" style="padding: 3px;"> ';
      rs +=
        '<label style="color:#365DA4">' +
       (i + 1)+
        ": " +
        arr[i].QuestionInfo.Noi_dung_cau_hoi +
        "" +
        "</label> <br />";
      rs += "</div>";
      if (arr[i].QuestionInfo.Loai_cau_hoi == 1) {
        rs +=' <div class="container-fluid dt-bootstrap4 " style="padding-left: 30px">';
        rs += '<span>' + arr[i].QuestionInfo.Ghi_chu + '</span>'
        for (let j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
          rs +=
            '<input type="radio" class="form-check-input" name="' +
            arr[i].QuestionInfo.ID_cau_hoi +
            '" value="' +
            list_phuong_an_cau_hoi[j].ID_phuong_an +
            '"/>' +
            list_phuong_an_cau_hoi[j].Noi_dung_phuong_an +
            "<br/>";
        }
        rs +=' </div>';
      }
      // 2: Câu hỏi dạng hộp kiểm
      if (arr[i].QuestionInfo.Loai_cau_hoi == 2) {
        rs +=' <div class="container-fluid dt-bootstrap4 form-check" style="padding-left: 30px">';
        rs += '<span>' + arr[i].QuestionInfo.Ghi_chu + '</span>'
        for (let j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
          rs +=
            '<input type="checkbox" class="form-check-input" name="' +
            arr[i].QuestionInfo.ID_cau_hoi +
            '" value="' +
            list_phuong_an_cau_hoi[j].ID_phuong_an +
            '"/>' +
            list_phuong_an_cau_hoi[j].Noi_dung_phuong_an +
            "<br/>";
        }
        rs +=' </div>';
      }
      // //3: câu hỏi dạng menu thả xuống
      if (arr[i].QuestionInfo.Loai_cau_hoi == 3) {
        rs +=
          '    <option selected disabled value="0">Chọn một đáp án</option>';
        rs += '<span>' + arr[i].QuestionInfo.Ghi_chu + '</span>'
        for (let j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
          rs +=
            ' <option value="' +
            arr[i].QuestionInfo.ID_cau_hoi +
            "_" +
            list_phuong_an_cau_hoi[j].ID_phuong_an +
            '">' +
            list_phuong_an_cau_hoi[j].Noi_dung_phuong_an +
            "</option>";
        }
      }
      // 4: câu hỏi dạng lưới trắc nghiệm
      if (arr[i].QuestionInfo.Loai_cau_hoi == 4) {
        rs +=
          ' <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 ">';
        rs +=
          '<table class="table dataTables_wrapper no-footer"><thead ><tr><th style="background-color:#365DA4; color: white"></th>';
        rs += '<span>' + arr[i].QuestionInfo.Ghi_chu + '</span>'
        let list_hang = jQuery.grep(
          list_phuong_an_cau_hoi,
          function (pa: any, i) {
            return pa.La_cot == false;
          }
        );
        let list_cot = jQuery.grep(
          list_phuong_an_cau_hoi,
          function (pa: any, i) {
            return pa.La_cot == true;
          }
        );
        list_cot = this.sortByKeyAsc(list_cot, "Stt_cot");
        list_hang = this.sortByKeyAsc(list_hang, "Stt_cot");
        for (let j = 0; j <= list_cot.length - 1; j++) {
          rs += '<th style="background-color:#365DA4; color: white" >' + list_cot[j].Noi_dung_phuong_an + "</th>";
        }
        rs += "</tr> </thead>  <tbody>";
        for (let j = 0; j <= list_hang.length - 1; j++) {
          rs +=
            ' <tr> <th scope="row">' +
            list_hang[j].Noi_dung_phuong_an +
            "</th>";
          for (let i = 0; i <= list_cot.length - 1; i++) {
            rs +=
              '<td> <input type="radio" class="regular-radio big-radio" name="' +
              list_hang[j].ID_phuong_an +
              '" value="' +
              list_hang[j].ID_phuong_an +
              "_" +
              list_cot[i].ID_phuong_an +
              "view" +
              '" /></td>';
          }
          rs += "</tr>";
        }
        rs += "</tbody> </table> </div>";
      }
      // 5: câu hỏi dạng lưới hộp kiểm
      if (arr[i].QuestionInfo.Loai_cau_hoi == 5) {
        rs +=
          '<table class="table dataTables_wrapper no-footer"><thead ><tr><th style="background-color:#365DA4; color: white" scope="col"></th>';
        rs += '<span>' + arr[i].QuestionInfo.Ghi_chu + '</span>'
        var list_hang = jQuery.grep(
          list_phuong_an_cau_hoi,
          function (pa: any, i) {
            return pa.La_cot == false;
          }
        );
        var list_cot = jQuery.grep(
          list_phuong_an_cau_hoi,
          function (pa: any, i) {
            return pa.La_cot == true;
          }
        );
        list_cot = this.sortByKeyAsc(list_cot, "Stt_cot");
        list_hang = this.sortByKeyAsc(list_hang, "Stt_cot");
        for (let j = 0; j <= list_cot.length - 1; j++) {
          rs += ' <th style="background-color:#365DA4; color: white" scope="col">' + list_cot[j].Noi_dung_phuong_an + "</th>";
        }
        rs += "</tr> </thead>  <tbody>";
        for (let j = 0; j <= list_hang.length - 1; j++) {
          rs +=
            ' <tr> <th scope="row">' +
            list_hang[j].Noi_dung_phuong_an +
            "</th>";
          for (let i = 0; i <= list_cot.length - 1; i++) {
            rs +=
              '<td> <input type="checkbox" name="' +
              list_hang[j].ID_phuong_an +
              '" value="' +
              list_hang[j].ID_phuong_an +
              "_" +
              list_cot[i].ID_phuong_an +
              '" /></td>';
          }
          rs += "</tr>";
        }
        rs += "</tbody> </table>";
      }
      // 6: Câu hỏi dạng văn bản
      if (arr[i].QuestionInfo.Loai_cau_hoi == 6) {
        rs +=
          ' <textarea class="cus-texeria" id="6" rows="5" style=" width: 100%"></textarea>';
      }

      rs+='<br>'
      rs+='<br>'
    }

    $("#ListOption2").html(rs);
  }

  async open(content, propety, dt): Promise<void> {
    this.getListQuestion(this.token);
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        scrollable: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.ResetSurvey();
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.ResetSurvey();
        }
      );
    if (propety == "edit") {
      this.TitleModal = "Sửa phiếu đánh giá";
      // let lst = jQuery.grep(this.DataTable.Data, (p: any) => {
      //   return p.ID_phieu_danh_gia == dt.ID_phieu_danh_gia;
      // });
      await this.getSurvey(dt.ID_phieu_danh_gia);
      if (this.Phieu_danh_gia != "") {
        this.Id_phieu_danh_gia = dt.ID_phieu_danh_gia;
        let temp = new Array();
        let arr = this.Phieu_danh_gia.Questions;
        for (var i = 0; i < arr.length; i++) {
          temp.push(arr[i].QuestionInfo);
        }
        this.DataAddQuestion = temp;
        var t = dt.ID_phieu_danh_gia;
        this.SurveyType = dt.ID_loai_danh_gia;
        this.SurveyName = dt.Ten_phieu;
        this.Content = dt.Noi_dung_phieu;
        this.Ma_phieu_danh_gia = dt.Ma_phieu_danh_gia;
        this.Note = dt.Ghi_chu;
      } else {
        this.modalService.dismissAll();
      }
    } else {
      this.TitleModal = "Thêm phiếu đánh giá";
    }
  }

  modalannounce(AnnounceQuestion, ID_Question) {
    this.modalService
      .open(AnnounceQuestion, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        scrollable: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

    var d = ID_Question;
    this.OptionRen(ID_Question, "add");
  }

  getCauHoiFiler() {
    let filterData = this.dataFilterForm.value;

    this.lstCauHooiFilter = this.ClassDataQuestion;
    if (filterData.Noi_dung_cau_hoi != null) {
      this.lstCauHooiFilter= this.lstCauHooiFilter.filter((obj) => {
        return this.Remove_VN(obj.Noi_dung_cau_hoi).toLowerCase().includes(this.Remove_VN(filterData.Noi_dung_cau_hoi.trim()).toLowerCase())
      });
    }

    if (filterData.Ma_cau_hoi != null) {
      this.lstCauHooiFilter= this.lstCauHooiFilter.filter((obj) => {
        return obj.Ma_cau_hoi != null && this.Remove_VN(obj.Ma_cau_hoi).toLowerCase().includes(this.Remove_VN(filterData.Ma_cau_hoi.trim()).toLowerCase())
      });
    }

    if (filterData.Loai_cau_hoi > 0) {
      this.lstCauHooiFilter= this.lstCauHooiFilter.filter((obj) => {
        return obj.Loai_cau_hoi == filterData.Loai_cau_hoi
      });
    }

    if (filterData.Nhom_cau_hoi > -1) {
      this.lstCauHooiFilter= this.lstCauHooiFilter.filter((obj) => {
        return obj.ID_nhom_cau_hoi == filterData.Nhom_cau_hoi
      });
    }

    if (filterData.Tai_khoan_tao != null) {
      this.lstCauHooiFilter= this.lstCauHooiFilter.filter((obj) => {
        return obj.Tai_khoan_tao == filterData.Tai_khoan_tao
      });
    }

  }

  ModalAdd(AddQuestion) {
    this.getCauHoiFiler();
    this.modalService
      .open(AddQuestion, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        scrollable: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getListSurvey(token) {
    const ls = new Array();
    this.SurveyService.getListPhieuDanhGia(token).subscribe((z) => {
      this.Res = z;
      if (z.Status == 2) {
        this.TitleClass = "";
        this.dtTrigger.next();
      } else {
        for (var i = 0; i < this.Res.Data.length; i++) {
          z.Data[i].Ngay_tao = z.Data[i].Ngay_tao.substring(0, 10)
            .toString()
            .split("-")
            .reverse()
            .join("-");
          if (z.Data[i].Ngay_sua != null) {
            z.Data[i].Ngay_sua = z.Data[i].Ngay_sua.substring(
              0,
              10
            )
              .toString()
              .split("-")
              .reverse()
              .join("-");
          }
          // switch (z.Data[i].Survey.Loai_danh_gia) {
          //   case 1:
          //     z.Data[i].Survey.Loai_danh_gia = "Đánh giá giảng viên";
          //     break;
          //   case 2:
          //     z.Data[i].Survey.Loai_danh_gia = "Đánh giá cơ sở vật chất";
          //     break;
          //   case 3:
          //     z.Data[i].Survey.Loai_danh_gia = "Khảo sát";
          //     break;
          //   case 4:
          //     z.Data[i].Survey.Loai_danh_gia = "Đánh giá doanh nghiệp";
          //     break;
          // }
          ls.push(z.Data[i]);
        }
        this.DataTable = this.Res;
        this.ClassData = ls;
        this.TitleClass = "";
        this.dtTrigger.next();
      }
    });
  }

  getListQuestion(token) {
    const list = new Array();
    // const listoption = new Array();
    if (this.CheckCauHoi == false) {
      this.SurveyService.getListQuestion(token).subscribe((z) => {
        this.Res = z;
        for (var i = 0; i < this.Res.Data.length; i++) {
          list.push(z.Data[i].QuestionInfo);
        }
        this.ClassDataQuestion = list;
        this.ClassDataOption = this.Res;
      });
    } else {
      this.SurveyService.getListQuestionNew(token).subscribe((z) => {
        this.Res = z.Dataquestion;
        for (var i = 0; i < this.Res.length; i++) {
          list.push(z.Dataquestion[i]);
        }
        this.ClassDataQuestion = list;
        this.ClassDataOption = this.Res;
      });
    }
  }
  LocCauHoiChange() {
    this.getListQuestion(this.token);
  }
  sortByKeyAsc(array, key) {
    return array.sort(function (a: any, b: any) {
      var x = parseInt(a[key]);
      var y = parseInt(b[key]);
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
  OptionRen(h, e) {
    let rs = "";
    let HtmlQuestion = "";
    let list_phuong_an_cau_hoi;
    let loaicauhoi;
    for (var i = 0; i < this.ClassDataOption.Data.length; i++) {
      if (this.ClassDataOption.Data[i].QuestionInfo.ID_cau_hoi == h) {
        list_phuong_an_cau_hoi = this.ClassDataOption.Data[i].OptionsData;
        HtmlQuestion +=
          "<label>" +
          this.ClassDataOption.Data[i].QuestionInfo.Noi_dung_cau_hoi +
          "</label> <br />";
        loaicauhoi = this.ClassDataOption.Data[i].QuestionInfo.Loai_cau_hoi;
      }
    }

    list_phuong_an_cau_hoi = this.sortByKeyAsc(
      list_phuong_an_cau_hoi,
      "Stt_cot"
    );
    // 1: Câu hỏi dạng trắc nghiệm
    if (loaicauhoi == 1) {
      for (let j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
        rs +=
          '<input type="radio" style="margin-left: 20%" name="' +
          h.ID_cau_hoi +
          e +
          '" value="' +
          list_phuong_an_cau_hoi[j].ID_phuong_an +
          '"/>' +
          list_phuong_an_cau_hoi[j].Noi_dung_phuong_an +
          "<br/>";
      }
    }
    // 2: Câu hỏi dạng hộp kiểm
    if (loaicauhoi == 2) {
      for (let j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
        rs +=
          '<input type="checkbox" style="margin-left: 20%" name="' +
          h.ID_cau_hoi +
          e +
          '" value="' +
          list_phuong_an_cau_hoi[j].ID_phuong_an +
          '"/>' +
          list_phuong_an_cau_hoi[j].Noi_dung_phuong_an +
          "<br/>";
      }
    }
    // //3: câu hỏi dạng menu thả xuống
    if (loaicauhoi == 3) {
      rs += '    <option selected disabled value="0">Chọn một đáp án</option>';
      for (let j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
        rs +=
          ' <option value="' +
          h.ID_cau_hoi +
          "_" +
          list_phuong_an_cau_hoi[j].ID_phuong_an +
          e +
          '">' +
          list_phuong_an_cau_hoi[j].Noi_dung_phuong_an +
          "</option>";
      }
    }
    // 4: câu hỏi dạng lưới trắc nghiệm
    if (loaicauhoi == 4) {
      rs +=
        '<table class="table-bordered dataTables_wrapper no-footer ""><thead ><tr><th scope="col"></th>';
      let list_hang = jQuery.grep(
        list_phuong_an_cau_hoi,
        function (pa: any, i) {
          return pa.La_cot == false;
        }
      );
      let list_cot = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
        return pa.La_cot == true;
      });
      list_cot = this.sortByKeyAsc(list_cot, "Stt_cot");
      list_hang = this.sortByKeyAsc(list_hang, "Stt_cot");
      for (let j = 0; j <= list_cot.length - 1; j++) {
        rs += ' <th scope="col">' + list_cot[j].Noi_dung_phuong_an + "</th>";
      }
      rs += "</tr> </thead>  <tbody>";
      for (let j = 0; j <= list_hang.length - 1; j++) {
        rs +=
          ' <tr> <th scope="row">' + list_hang[j].Noi_dung_phuong_an + "</th>";
        for (let i = 0; i <= list_cot.length - 1; i++) {
          rs +=
            '<td> <input type="radio" name="' +
            list_hang[j].ID_phuong_an +
            '" value="' +
            list_hang[j].ID_phuong_an +
            "_" +
            list_cot[i].ID_phuong_an +
            e +
            '" /></td>';
        }
        rs += "</tr>";
      }
      rs += "</tbody> </table>";
    }
    // 5: câu hỏi dạng lưới hộp kiểm
    if (loaicauhoi == 5) {
      rs += '<table class="table"><thead ><tr><th scope="col"></th>';

      var list_hang = jQuery.grep(
        list_phuong_an_cau_hoi,
        function (pa: any, i) {
          return pa.La_cot == false;
        }
      );
      var list_cot = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
        return pa.La_cot == true;
      });
      list_cot = this.sortByKeyAsc(list_cot, "Stt_cot");
      list_hang = this.sortByKeyAsc(list_hang, "Stt_cot");
      for (let j = 0; j <= list_cot.length - 1; j++) {
        rs += ' <th scope="col">' + list_cot[j].Noi_dung_phuong_an + "</th>";
      }
      rs += "</tr> </thead>  <tbody>";
      for (let j = 0; j <= list_hang.length - 1; j++) {
        rs +=
          ' <tr> <th scope="row">' + list_hang[j].Noi_dung_phuong_an + "</th>";
        for (let i = 0; i <= list_cot.length - 1; i++) {
          rs +=
            '<td> <input type="checkbox" name="' +
            list_hang[j].ID_phuong_an +
            '" value="' +
            list_hang[j].ID_phuong_an +
            e +
            "_" +
            list_cot[i].ID_phuong_an +
            '" /></td>';
        }
        rs += "</tr>";
      }
      rs += "</tbody> </table>";
    }
    // 6: Câu hỏi dạng văn bản
    if (loaicauhoi == 6) {
      rs +=
        ' <textarea class="cus-texeria" id="6" rows="5" style=" width: 100%"></textarea>';
    }
    $("#NameQuestion").html(HtmlQuestion);
    $("#ListOption").html(rs);
    $("#ListOption2").html(rs);
  }
  Select(id_cau_hoi) {
    if (this.CheckCauHoi == false) {
      for (var i = 0; i < this.ClassDataOption.Data.length; i++) {
        if (
          this.ClassDataOption.Data[i].QuestionInfo.ID_cau_hoi == id_cau_hoi
        ) {
          var t = this.DataAddQuestion.some((dt) => {
            return dt.ID_cau_hoi === id_cau_hoi;
          });
          if (!t) {
            this.DataAddQuestion.push(
              this.ClassDataOption.Data[i].QuestionInfo
            );
          } else {
            this.toastr.warning("Câu hỏi đã thêm trước đó");
          }
        }
      }
    } else {
      for (var i = 0; i < this.ClassDataOption.length; i++) {
        if (this.ClassDataOption[i].ID_cau_hoi == id_cau_hoi) {
          var t = this.DataAddQuestion.some((dt) => {
            return dt.ID_cau_hoi === id_cau_hoi;
          });
          if (!t) {
            this.DataAddQuestion.push(this.ClassDataOption[i]);
          } else {
            this.toastr.warning("Câu hỏi đã thêm trước đó");
          }
        }
      }
    }
  }
  DeleteQuestion(p) {
    const index = this.DataAddQuestion.indexOf(p, 0);
    if (index > -1) {
      this.DataAddQuestion.splice(index, 1);
    }
  }

  SaveSurvey() {
    const SurveyName = this.SurveyName;
    const SurveyType = this.SurveyType;
    const Content = this.Content;
    const Note = this.Note;
    let SurveyData = {
      ID_phieu_danh_gia: this.Id_phieu_danh_gia,
      Ten_phieu: SurveyName,
      ID_loai_danh_gia: SurveyType,
      Noi_dung_phieu: Content,
      Tai_khoan_tao: this.requestObject.UserName,
      Ghi_chu: Note,
      Ma_phieu_danh_gia : this.Ma_phieu_danh_gia
    };
    let Questions = [];
    if (SurveyName.trim() == "") {
      this.toastr.warning(
        "Tên phiếu đánh giá không được để trống",
        "Thao tác thất bại"
      );
      return;
    }
    if (this.DataAddQuestion != undefined) {
      this.DataAddQuestion.forEach((element) => {
        Questions.push(element.ID_cau_hoi);
      });
    }
    if (Questions.length == 0) {
      this.toastr.warning(
        "Phiếu đánh giá chưa chọn câu hỏi",
        "Thao tác thất bại"
      );
      return;
    }
    let Survey = {
      SurveyData: SurveyData,
      Questions: Questions,
    };
    if (Questions.length > 0) {
      if (this.TitleModal == "Sửa phiếu đánh giá") {
        this.EditSurvey(Survey, this.token);
      } else {
        this.AddSurvey(Survey, this.token);
      }
    } else {
      this.toastr.warning(
        "Bạn vui lòng chọn câu hỏi để thêm phiếu đánh giá",
        "Thao tác thất bại"
      );
    }
  }

  changePosition(value: number, index: number) {
    if (
      (index == 0 && value == -1) ||
      (index == this.DataAddQuestion.length - 1 && value == 1)
    ) {
      return;
    }
    let curQuestion = this.DataAddQuestion[index];
    this.DataAddQuestion[index] = this.DataAddQuestion[index + value];
    this.DataAddQuestion[index + value] = curQuestion;
  }

  Xoa(id_phieu) {
    var RequestBase = {
      RequestID: id_phieu,
    };
    var Cf = confirm("Bạn có chắc chắn muốn xóa phiếu này?");
    if (Cf) {
      this.SurveyService.DeleteSurvey(RequestBase, this.token).subscribe(
        (z) => {
          if (z.Status == 1) {
            this.toastr.success(z.Message, "Tác vụ thành công");
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              }
            );
            this.getListSurvey(this.token);
          } else {
            this.toastr.error(z.Message, "Tác vụ thất  bại");
          }
          this.TitleClass = "";
        },
        (error) => {
          if ((error.status = 401)) {
            this.toastr.error("Bạn không có quyển thực hiện chức năng này !");
          } else {
            this.toastr.error(error.Message);
          }
        }
      );
    }
  }

  AddSurvey(m: any, token) {
    this.SurveyService.AddSurvey(m, token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message, "Thao tác thành công");
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.modalService.dismissAll();
          this.getListSurvey(this.token);
        } else {
          this.toastr.error(z.Message, "Thao tác thất bại");
        }
        this.TitleClass = "";
      },
      (error) => {
        if ((error.status = 401)) {
          this.toastr.error("Bạn không có quyển thực hiện chức năng này !");
        } else {
          this.toastr.error(error.Message);
        }
      }
    );
  }

  EditSurvey(m: any, token) {
    this.SurveyService.EditSurvey(m, token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message, "Thao tác thành công");
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.modalService.dismissAll();
          this.getListSurvey(this.token);
        } else {
          this.toastr.error(z.Message, "Thao tác thất bại");
        }
        this.TitleClass = "";
      },
      (error) => {
        if ((error.status = 401)) {
          this.toastr.error("Bạn không có quyển thực hiện chức năng này !");
        } else {
          this.toastr.error(error.Message);
        }
      }
    );
  }
  help() {
    const urlHelp =
      this.appConfig.SurveyURL_Support +
      "?" +
      this.appConfig.document_SurveyURL;
    window.open(urlHelp, "_blank");
  }

  async getSurvey(ID: any): Promise<void> {
    this.spinner.show();
    var req = {
      RequestID: ID
    }
    return new Promise((resolve, reject) => {
      this.SurveyService.GetSurvey(req, this.token).subscribe(
        (z) => {
          if(z.Status == 1){
            this.Phieu_danh_gia = z.Data;
          } else {
            this.toastr.warning(z.Message, "Thao tác thất bại");
            this.Phieu_danh_gia = "";
          }
          resolve(z.Data);
          this.spinner.hide();
        },
        (error) => {
          if ((error.status = 401)) {
            this.toastr.error("Bạn không có quyển thực hiện chức năng này !");
          } else {
            this.toastr.error(error.Message);
          }
          reject(error);
          this.Phieu_danh_gia = "";
          this.spinner.hide();
        });
    });
  }
}

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { AppService } from 'src/app/Utils/service/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PortalService } from 'src/app/Utils/service/portal.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { common } from 'src/app/app.common';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-enterprise-portal',
  templateUrl: './enterprise-portal.component.html',
  styleUrls: ['./enterprise-portal.component.scss']
})
export class EnterprisePortalComponent implements OnInit {
  public PortalInfor: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings
  TableData: any;
  isDisplayButton:any=true
  DotDanhGia: string;
  TrangThaiFT: string = "Chưa đánh giá";
  GiangVien: string;
  PhieuDanhGia: string;
  closeResult: string;
  Token: string;
  DetailData: any;
  Detail: any;
  ListKetQuas = []
  SelectedID: any;
  Nhom_danh_gia: string;
  public CauHois: any;
  public PhuongAns: any;
  public KetQuas: any;
  TenPhieu: any;
  public DataAnwser: any;
  Title: string = "Đang tải danh sách thông báo..."
  TitleClass: string = "spinner-border text-muted"
  constructor(public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    private Ser: PortalService,
    private modalService: NgbModal,
    private renderer: Renderer2
  ) { }
  public com: common;
  dtTrigger = new Subject();
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }

      },
      columns: [
        {
          title: 'Đợt đánh giá',
        },
        {
          title: 'Phiếu đánh giá',
        },
        {
          title: 'Ngày bắt đầu',
        },
        {
          title: 'Ngày kết thúc',
        },
        {
          title: 'Trạng thái',
        },
        {
          title: 'Người gửi yêu cầu',
        },
        {
          title: 'Thao tác',
          width: "5%",
          className: "dt-center"
        },
      ],
    };
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let DotDT = data[0];
      let PhieuDanhGiaDT = data[1];
      let TrangThaiDT = data[4];
      let bool1 = true
      let bool2 = true
      let bool3 = true
      let fillter = true;
      if (this.DotDanhGia != undefined) {
        bool1 = DotDT.includes(this.DotDanhGia)
      }
      if (this.PhieuDanhGia != undefined) {
        bool2 = PhieuDanhGiaDT.includes(this.PhieuDanhGia)
      }
      if (this.TrangThaiFT != "0") {
        bool3 = TrangThaiDT.includes(this.TrangThaiFT)
      }
      fillter = bool1 && bool2 && bool3
      return fillter;
    });
    this.com = new common(this.router);
    this.PortalInfor = this.com.getPortalInfo();
    this.Token = this.PortalInfor.Token;
    var RequestLogin = {
      RequestID: this.PortalInfor.Info.UserID,
      TALoginCode: this.PortalInfor.Token
    }

    this.getAll(RequestLogin);
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  getAll(m) {
    this.Ser.EnterpriseGetList(m)
      .subscribe(z => {
        this.TableData = z.Data
        this.dtTrigger.next();
        this.Title = ""
        this.TitleClass = ""
      })
      ;
  }
  openEdit(content, IDGui, IDPhieuDanhGia, HetHan,trangthai) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.SelectedID = IDGui;
    var req = {
      RequestID: this.PortalInfor.Info.UserID,
      TALoginCode: this.PortalInfor.Token,
      IDGui: IDGui,
      ID_phieu_danh_gia: IDPhieuDanhGia
    };
    this.StudentObjectDetail(req);
    if (!HetHan) {
      $('#GuiDanhGia').prop('disabled', true);
      $("#GuiDanhGia").html("Phiếu đánh giá này đã hết hạn");
    }
    else {
      $("#GuiDanhGia").html("Gửi kết quả đánh giá");
      $('#GuiDanhGia').prop('disabled', false);
    }
    if(trangthai=="Đã đánh giá"){
      this.isDisplayButton=false
    }else{
      this.isDisplayButton=true
    }
  }

  StudentObjectDetail(m) {
    this.Ser.StudentObjectDetail(m)
      .subscribe(z => {
        this.SetData(z);
      });
  }

  SetData(z) {
    (<HTMLInputElement>document.getElementById("TenPhieu")).innerHTML =
      z.Data.SurveyData.Survey.Ten_phieu;
    (<HTMLInputElement>document.getElementById("NoiDung")).innerHTML =
      z.Data.SurveyData.Survey.Noi_dung_phieu;
    (<HTMLInputElement>document.getElementById("GhiChu")).innerHTML =
      z.Data.SurveyData.Survey.Ghi_chu;
    // (<HTMLInputElement>document.getElementById("LoaiDanhGia")).value = z.Data.SurveyData.Questions[0].QuestionInfo.Ten_nhom_cau_hoi;
    let rs = "";
    for (var i = 0; i < z.Data.SurveyData.Questions.length; i++) {
      this.CauHois = z.Data.SurveyData.Questions;
      var tl = jQuery.grep(z.Data.AnwserData, function (pa: any, j) {
        return (
          pa.ID_cau_hoi ==
          z.Data.SurveyData.Questions[i].QuestionInfo.ID_cau_hoi
        );
      });
      rs += this.question_render(z.Data.SurveyData.Questions[i], i + 1, tl);
    }
    document.getElementById("View_Question").innerHTML = rs;

    setTimeout(() => {
      this.setValidateFunction(z.Data.SurveyData.Questions);
    }, 1000);
  }

  setValidateFunction(q) {
    for (let i = 0; i < q.length; i++) {
      const element = q[i];
      if (element.QuestionInfo.Loai_cau_hoi == 5) {
        var list_phuong_an_cau_hoi = q[i].OptionsData;
        var list_hang = jQuery.grep(
          list_phuong_an_cau_hoi,
          function (pa: any, i) {
            return !pa.La_cot;
          }
        );
        var list_cot = jQuery.grep(
          list_phuong_an_cau_hoi,
          function (pa: any, i) {
            return pa.La_cot;
          }
        );

        for (var j = 0; j <= list_hang.length - 1; j++) {
          var strName = list_hang[j].ID_phuong_an + "_luoi";
          for (i = 0; i <= list_cot.length - 1; i++) {
            var strIdInput =
              list_hang[j].ID_phuong_an + "_" + list_cot[i].Stt_cot;
            var ele = document.getElementById(strIdInput);
            if (ele && element.QuestionInfo.Phuong_an_toi_da > 0) {
              // Thêm sự kiện click vào phần tử
              this.renderer.listen(ele, "click", (event) =>
                this.onChangeHandler(
                  event,
                  element.QuestionInfo.Phuong_an_toi_da
                )
              );
            }
          }
        }
      }
    }
  }

  onChangeHandler(event, max) {
    const targetElement = event.target as HTMLInputElement;
    // Kiểm tra xem đối tượng phần tử có phải là HTMLInputElement không
    if (targetElement instanceof HTMLInputElement) {
      const id = targetElement.id;
      const name = targetElement.name;
      const inputs = document.querySelectorAll(`input[name="${name}"]`);

      // Lọc những input được checked
      const checkedInputs = Array.from(inputs).filter(
        (input: HTMLInputElement) => input.checked
      );

      // Đếm số lượng input được checked
      const count = checkedInputs.length;
      if (count > max) {
        this.toastr.warning(
          "Câu hỏi này chỉ được chọn tối đa " + max + " phương án!"
        );
        const checkboxElement = document.getElementById(id) as HTMLInputElement;

        if (checkboxElement) {
          // Sử dụng Renderer2 để thay đổi thuộc tính checked của checkbox
          checkboxElement.checked = false;
        }
      }
    }
  }

  question_render(h, stt, TraLoi) {

    // render câu hỏi
    var rs = '';
    var list_phuong_an_cau_hoi = h.OptionsData;
    var BatBuoc = '';
    if (h.QuestionInfo.Bat_buoc) {
      BatBuoc = '<h5><i><mark>Câu hỏi bắt buộc trả lời</mark></i></h5>';
    }
    // 1: Câu hỏi dạng trắc nghiệm
    if (h.QuestionInfo.Loai_cau_hoi == 1) {
      rs += '<form style="width: 80%;"> <div class="form-group">';
      rs += '<h5 style="text-transform: uppercase; font-weight: bold;"> ' + h.QuestionInfo.Noi_dung_cau_hoi + '</h5> <br />';
      rs += BatBuoc;
      rs += '<p> Ghi chú: ' + h.QuestionInfo.Ghi_chu + '</p> <br />';
      for (var j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
        var check = jQuery.grep(TraLoi, function (pa: any, i) {
          return pa.ID_phuong_an == list_phuong_an_cau_hoi[j].ID_phuong_an;
        });
        var checked = "";
        if (check.length > 0) {
          checked = "checked";
        }
        rs += '<div  style="margin-left: 20%"><input type="radio" ' + checked + '  id="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '" name="' + h.QuestionInfo.ID_cau_hoi + '" value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '"/>' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '<br/></div>';
      }
      rs += '</div></form > <hr/>'
    }
    // 2: Câu hỏi dạng hộp kiểm
    if (h.QuestionInfo.Loai_cau_hoi == 2) {
      rs += '<form style="width: 80%;"> <div class="form-group">';
      rs += '<h5 style="text-transform: uppercase; font-weight: bold;">' + h.QuestionInfo.Noi_dung_cau_hoi + '</h5> <br />';
      rs += BatBuoc;
      rs += '<p> Ghi chú: ' + h.QuestionInfo.Ghi_chu + '</p> <br />';
      for (j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
        var check = jQuery.grep(TraLoi, function (pa: any, i) {
          return pa.ID_phuong_an == list_phuong_an_cau_hoi[j].ID_phuong_an;
        });
        var checked = "";
        if (check.length > 0) {
          checked = "checked";
        }
        rs += '<div  style="margin-left: 20%"><input type="checkbox" ' + checked + '  id="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '" name="' + h.QuestionInfo.ID_cau_hoi + '" value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '"/>' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '<br/></div>';
      }
      rs += '</div></form > <hr/>'
    }
    //3: câu hỏi dạng menu thả xuống
    if (h.QuestionInfo.Loai_cau_hoi == 3) {
      rs += '<form style="width: 80%;"> <div class="form-group">';
      rs += '<h5 style="text-transform: uppercase; font-weight: bold;">' + h.QuestionInfo.Noi_dung_cau_hoi + '</h5>' + BatBuoc +
        ' <p> Ghi chú:  ' + h.QuestionInfo.Ghi_chu + '</p > <br /><select style="margin-left: 4%" id="' + h.QuestionInfo.ID_cau_hoi + '">';
      rs += '    <option selected value="0">Chọn một đáp án</option>';
      for (j = 0; j <= list_phuong_an_cau_hoi.length - 1; j++) {
        var check = jQuery.grep(TraLoi, function (pa: any, i) {
          return pa.ID_phuong_an == list_phuong_an_cau_hoi[j].ID_phuong_an;
        });
        var checked = "";
        if (check.length > 0) {
          checked = "selected";
        }
        rs += ' <option ' + checked + ' value="' + list_phuong_an_cau_hoi[j].ID_phuong_an + '">' + list_phuong_an_cau_hoi[j].Noi_dung_phuong_an + '</option>';
      }
      rs += '</select> </div> </form> <hr/>';
    }
    // 4: câu hỏi dạng lưới trắc nghiệm
    if (h.QuestionInfo.Loai_cau_hoi == 4) {

      rs += '<form> <div class="form-group">';
      rs += '<h5 style="text-transform: uppercase; font-weight: bold;">' + h.QuestionInfo.Noi_dung_cau_hoi + '</h5> <br />';
      rs += BatBuoc;
      rs += '<p> Ghi chú: ' + h.QuestionInfo.Ghi_chu + '</p> <br />';
      rs += '<table class="table table-bordered dataTables_wrapper no-footer"><thead ><tr><th scope="col"></th>';
      var list_hang = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
        return !pa.La_cot;
      });
      var list_cot = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
        return pa.La_cot;
      });
      list_cot = this.sortByKeyAsc(list_cot, "Stt_cot");
      list_hang = this.sortByKeyAsc(list_hang, "Stt_cot");
      for (j = 0; j <= list_cot.length - 1; j++) {
        rs += ' <th scope="col">' + list_cot[j].Noi_dung_phuong_an + '</th>';
      }
      rs += '</tr> </thead>  <tbody>';
      for (j = 0; j <= list_hang.length - 1; j++) {
        rs += ' <tr> <th scope="row">' + list_hang[j].Noi_dung_phuong_an + '</th>';
        for (var i = 0; i <= list_cot.length - 1; i++) {
          var check = jQuery.grep(TraLoi, function (pa: any, x) {
            return pa.ID_phuong_an == list_hang[j].ID_phuong_an && pa.Stt_muc == list_cot[i].Stt_cot;
          });
          var checked = "";
          if (check.length > 0) {
            checked = "checked";
          }
          rs += '<td> <input  type="radio" ' + checked + ' id="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" name="' + list_hang[j].ID_phuong_an+'_luoi' + '" value="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" /></td>';
        }
        rs += '</tr>';
      }
      rs += '</tbody> </table>';
      rs += '</div></form > <hr/>';
    }
    // 5: câu hỏi dạng lưới hộp kiểm
    if (h.QuestionInfo.Loai_cau_hoi == 5) {
      rs += '<form> <div class="form-group">';
      rs += '<h5 style="text-transform: uppercase; font-weight: bold;">' + h.QuestionInfo.Noi_dung_cau_hoi + '</h5> <br />';
      rs += BatBuoc;
      rs += '<p> Ghi chú: ' + h.QuestionInfo.Ghi_chu + '</p> <br />';
      rs += '<table class="table table-bordered dataTables_wrapper no-footer"><thead ><tr><th scope="col"></th>';
      var list_hang = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
        return !pa.La_cot;
      });
      var list_cot = jQuery.grep(list_phuong_an_cau_hoi, function (pa: any, i) {
        return pa.La_cot;
      });
      list_cot = this.sortByKeyAsc(list_cot, "Stt_cot");
      list_hang = this.sortByKeyAsc(list_hang, "Stt_cot");
      for (j = 0; j <= list_cot.length - 1; j++) {
        rs += ' <th scope="col">' + list_cot[j].Noi_dung_phuong_an + '</th>';
      }
      rs += '</tr> </thead>  <tbody>';
      for (j = 0; j <= list_hang.length - 1; j++) {
        rs += ' <tr> <th scope="row">' + list_hang[j].Noi_dung_phuong_an + '</th>';
        for (i = 0; i <= list_cot.length - 1; i++) {
          var check = jQuery.grep(TraLoi, function (pa: any, x) {
            return pa.ID_phuong_an == list_hang[j].ID_phuong_an && pa.Stt_muc == list_cot[i].Stt_cot;
          });
          var checked = "";
          if (check.length > 0) {
            checked = "checked";
          }
          rs += '<td> <input ' + checked + ' type="checkbox" id="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" name="' + list_hang[j].ID_phuong_an+'_luoi' + '" value="' + list_hang[j].ID_phuong_an + '_' + list_cot[i].Stt_cot + '" /></td>';
        }
        rs += '</tr>';
      }
      rs += '</tbody> </table>';
      rs += '</div></form > <hr/>';
    }
    // 6: Câu hỏi dạng văn bản
    if (h.QuestionInfo.Loai_cau_hoi == 6) {
      var check = jQuery.grep(TraLoi, function (pa: any, i) {
        return pa.ID_cau_hoi == h.QuestionInfo.ID_cau_hoi;
      });
      var checked = "";

      if (check.length > 0) {
        checked = check[0].Noi_dung;
      }
      rs += '<form> <div class="form-group">';
      rs += '<h5 style="text-transform: uppercase;font-weight: bold;">' + h.QuestionInfo.Noi_dung_cau_hoi + '</h5> <br />';
      rs += BatBuoc;
      rs += '<p>Ghi chú: ' + h.QuestionInfo.Ghi_chu + '</p> <br />';
      rs += ' <textarea  id="' + h.QuestionInfo.ID_cau_hoi + '" rows="5"  style=" width: 100%">' + checked + '</textarea>';
      rs += '</div></form > <hr/>';
    }
    return rs;
  }
  sortByKeyAsc(array, key) {
    return array.sort(function (a, b) {
      var x = parseInt(a[key]); var y = parseInt(b[key]);
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  ValidateQuestions() {
    var rs = true;
    var ListIDError = [];
    for (var i = 0; i < this.CauHois.length; i++) {
      var ckeck = !this.ValidateQuestion(this.CauHois[i]);
      if (ckeck) {
        rs = false;
        ListIDError.push(i + 1 + ": " + this.CauHois[i].QuestionInfo.Noi_dung_cau_hoi);
      }
    }
    if (!rs) {
      var mess = '';
      for (var i = 0; i < ListIDError.length; i++) {
        mess += (ListIDError[i]) + '\n';
      }
      alert('Vui lòng hoàn thành các câu hỏi sau của chúng tôi! \n' + mess);
    } else {
      var r = confirm('Bạn có muốn gửi kết quả đánh giá đến hệ thống không?');
      if (r) {
        this.GetKetQuas()
      }
    }
    return rs;
  }
  ValidateQuestion(t) {
    var rs;
    if (t.QuestionInfo.Bat_buoc) {
      if (t.QuestionInfo.Loai_cau_hoi <= 2) {
        var dt = $('input[name="' + t.QuestionInfo.ID_cau_hoi + '"]:checked').serialize();
        rs = dt != '';
      }
      if (t.QuestionInfo.Loai_cau_hoi == 3) {
        rs = ($('#' + t.QuestionInfo.ID_cau_hoi).val() == 0) ? false : true;
      }
      if (t.QuestionInfo.Loai_cau_hoi == 4 || t.QuestionInfo.Loai_cau_hoi == 5) {
        var list_hang = jQuery.grep(t.OptionsData, function (pa: any, i) {
          return !pa.La_cot;
        });
        for (var j = 0; j <= list_hang.length - 1; j++) {
          rs = $('input[name="' + list_hang[j].ID_phuong_an+'_luoi' + '"]:checked').serialize();
          if (!rs) {
            break;
          }
        }
      }
      if (t.QuestionInfo.Loai_cau_hoi == 6) {
        rs = ($('#' + t.QuestionInfo.ID_cau_hoi).val() == "") ? false : true;
      }
    }
    else {
      rs = true;
    }
    return rs;
  }
  GetKetQuas() {
    for (var i = 0; i < this.CauHois.length; i++) {
      this.LayKetQua(this.CauHois[i])
    }
    this.SendTraLois();
  }
  SendTraLois() {
    var req = {
      RequestID: this.PortalInfor.Info.UserID,
      TALoginCode: this.PortalInfor.Token,
      IDGui: this.SelectedID,
      Data: this.ListKetQuas
    }
    this.Ser.StudentObjectInsest(req)
      .subscribe(z => {
        if (z.Status == 1) {
          this.modalService.dismissAll()
          this.toastr.success("Kết quả đánh giá đã được lưu thành công!", "Tác vụ thành công");
          var RequestLogin = {
            RequestID: this.PortalInfor.Info.UserID,
            TALoginCode: this.PortalInfor.Token
          }
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          this.getAll(RequestLogin);
        }
        else{
          this.toastr.error(z.Message)
          this.modalService.dismissAll()
        }
      });
  }
  LayKetQua(t) {
    if (t.QuestionInfo.Loai_cau_hoi == 1) {

      var rs = $('input[name="' + t.QuestionInfo.ID_cau_hoi + '"]:checked').val();
      var KetQuaPhuongAn = {
        ID_tra_loi: null,
        ID_gui: this.SelectedID,
        ID_cau_hoi: t.QuestionInfo.ID_cau_hoi,
        ID_phuong_an: rs,
        Stt_muc: 0,
        Noi_dung: "",
        Loai_cau_hoi: ""
      };
      this.ListKetQuas.push(KetQuaPhuongAn);
    }
    if (t.QuestionInfo.Loai_cau_hoi == 2) {
      var id = this.SelectedID;
      var dt = [];
      $.each($("input[name='" + t.QuestionInfo.ID_cau_hoi + "']:checked"), function () {
        var rs = ($(this).val());
        var KetQuaPhuongAn = {
          ID_tra_loi: null,
          ID_gui: id,
          ID_cau_hoi: t.QuestionInfo.ID_cau_hoi,
          ID_phuong_an: rs,
          Stt_muc: 0,
          Noi_dung: "",
          Loai_cau_hoi: ""
        };
        dt.push(KetQuaPhuongAn);
      });
      for (let index = 0; index < dt.length; index++) {
        this.ListKetQuas.push(dt[index])
      }
    }
    if (t.QuestionInfo.Loai_cau_hoi == 3) {
      var rs = $('#' + t.QuestionInfo.ID_cau_hoi).val();
      var KetQuaPhuongAn = {
        ID_tra_loi: null,
        ID_gui: this.SelectedID,
        ID_cau_hoi: t.QuestionInfo.ID_cau_hoi,
        ID_phuong_an: rs,
        Stt_muc: 0,
        Noi_dung: "",
        Loai_cau_hoi: ""
      };
      this.ListKetQuas.push(KetQuaPhuongAn);
    }
    if (t.QuestionInfo.Loai_cau_hoi == 4 || t.QuestionInfo.Loai_cau_hoi == 5) {
      var list_hang = jQuery.grep(t.OptionsData, function (pa: any, i) {
        return !pa.La_cot;
      });
      var list_cot = jQuery.grep(t.OptionsData, function (pa: any, i) {
        return pa.La_cot;
      });
      list_cot = this.sortByKeyAsc(list_cot, "Stt_cot");
      list_hang = this.sortByKeyAsc(list_hang, "Stt_cot");
      var id = this.SelectedID;

      for (var i = 0; i < list_hang.length; i++) {
        var dt = [];
        $.each($("input[name='" + list_hang[i].ID_phuong_an+'_luoi' + "']:checked"), function () {
          var rs = ($(this).val());
          var KetQuaPhuongAn = {
            ID_tra_loi: null,
            ID_gui: id,
            ID_cau_hoi: t.QuestionInfo.ID_cau_hoi,
            ID_phuong_an: rs.toString().substring(0, rs.toString().indexOf("_")),
            Stt_muc: rs.toString().substring(rs.toString().indexOf("_") + 1, rs.toString().length),
            Noi_dung: "",
            Loai_cau_hoi: ""
          };
          dt.push(KetQuaPhuongAn);
        });
        for (let index = 0; index < dt.length; index++) {
          this.ListKetQuas.push(dt[index])
        }
      }
    }
    if (t.QuestionInfo.Loai_cau_hoi == 6) {
      var rs = $('#' + t.QuestionInfo.ID_cau_hoi).val();
      var data = {
        ID_tra_loi: null,
        ID_gui: this.SelectedID,
        ID_cau_hoi: t.QuestionInfo.ID_cau_hoi,
        ID_phuong_an: 0,
        Stt_muc: 0,
        Noi_dung: rs,
        Loai_cau_hoi: ""
      };
      this.ListKetQuas.push(data);
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
import { Component, OnInit, OnDestroy, ViewChild, Inject } from "@angular/core";
import { AppConfig, AppConfiguration } from "src/configuration";
import { ToastrService } from "ngx-toastr";
import { DataTableDirective } from "angular-datatables";
import { AppService } from "src/app/Utils/service/app.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { common } from "src/app/app.common";
import { QuestionsService } from "src/app/Utils/service/questions.service";
import { CauHoiNhomService } from "src/app/Utils/service/cau-hoi-nhom.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-questions-bank",
  templateUrl: "./questions-bank.component.html",
  styleUrls: ["./questions-bank.component.scss"],
})
export class QuestionsBankComponent implements OnInit {
  dtOptions: DataTables.Settings;
  Res: any;
  TableData: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  NoiDung: string;
  MaCauHoi: string;
  LoaiCauHoiFT: string = "0";
  NhomCauHoiFT: number = -1;
  CreateUserName: string;
  lstHangADD = [];
  lstCauHoiNhom = [];
  lstHangEditADD = [];
  lstMucADD = [];
  lstMucEditADD = [];
  list_diem: any;
  LoaiCauHoiCurrent: number = 0;
  EditID: number = 0;
  Title: string = "Đang tải danh sách thông báo...";
  TitleClass: string = "spinner-border text-muted";
  closeResult: string;
  UserName: string;
  token: string;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    private QuestionsService: QuestionsService,
    private cauHoiNhomService: CauHoiNhomService
  ) {
    //
  }
  dtTrigger = new Subject();
  public com: common;
  ngOnInit() {
    let element, name, arr;
    element = document.getElementById("menu1");
    arr = element.className.split(" ");
    name = "menu-open";
    if (arr.indexOf(name) == -1) {
      element.className += " " + name;
    }

    $.fn["dataTable"].ext.search.push((settings, data, dataIndex) => {
      let TieuDedt = data[4];
      let MaCauHoidt = data[1];
      let CreateUserNamedt = data[5];
      let LoaiCauHoidt = data[2];
      let NhomCauHoidt = data[8];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool0 = true;
      let fillter = true;
      if (this.MaCauHoi != undefined) {
        bool0 = this.xoa_dau(MaCauHoidt)
          .toLowerCase()
          .includes(this.xoa_dau(this.MaCauHoi.trim()).toLowerCase());
      }
      if (this.NoiDung != undefined) {
        bool1 = this.xoa_dau(TieuDedt)
          .toLowerCase()
          .includes(this.xoa_dau(this.NoiDung.trim()).toLowerCase());
      }
      if (this.CreateUserName != undefined) {
        bool2 = this.xoa_dau(CreateUserNamedt)
          .toLowerCase()
          .includes(this.xoa_dau(this.CreateUserName.trim()).toLowerCase());
      }
      if (this.LoaiCauHoiFT != "0") {
        bool3 = LoaiCauHoidt.includes(this.LoaiCauHoiFT);
      }
      if (this.NhomCauHoiFT > -1) {
        bool4 = NhomCauHoidt.includes(this.NhomCauHoiFT);
      }
      fillter = bool0 && bool1 && bool2 && bool3 && bool4;
      return fillter;
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.UserName = a.Info.UserName;
    this.token = a.access_token;
    this.getNhomCauHoi();
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "Mã",
          visible: false,
        },
        {
          title: "Mã câu hỏi",
        },
        {
          title: "Loại câu hỏi",
        },
        {
          title: "Nhóm câu hỏi",
        },
        {
          title: "Nội dung câu hỏi",
        },
        {
          title: "Tài khoản tạo",
        },
        {
          title: "Ngày tạo",
        },
        {
          title: "Thao tác",
          width: "15%",
          className: "dt-center",
        },

        {
          title: "ID_nhom_cau_hoi",
          visible: false,
        },
      ],
    };
    this.getAll(a.access_token);
  }

  getNhomCauHoi() {
    this.cauHoiNhomService.getAll(this.token).subscribe((z) => {
      if (z.Status == 1) {
        this.lstCauHoiNhom = z.ListData;
      } else {
        this.toastr.error(z.Message);
      }
    });
  }

  LoaiCauHoiChange() {
    this.lstHangADD = [];
    this.lstMucADD = [];
    this.lstHangEditADD = [];
    this.lstMucEditADD = [];
    var loai_cau_hoi = $("#LoaiCauHoi option:selected").val();
    this.LoaiCauHoiCurrent = Number(loai_cau_hoi);
    document.getElementById("PhuongAn").style.visibility = "hidden";
    document.getElementById("Hang").style.visibility = "hidden";
    document.getElementById("Muc").style.visibility = "hidden";
    // this.lstHangADD = [];
    // this.lstMucADD = [];
    if (loai_cau_hoi == 1 || loai_cau_hoi == 2 || loai_cau_hoi == 3) {
      document.getElementById("PhuongAn").style.visibility = "visible";
      document.getElementById("Hang").style.visibility = "visible";
    } else {
      if (loai_cau_hoi == 4 || loai_cau_hoi == 5) {
        document.getElementById("PhuongAn").style.visibility = "visible";
        document.getElementById("Hang").style.visibility = "visible";
        document.getElementById("Muc").style.visibility = "visible";
      } else {
        document.getElementById("PhuongAn").style.visibility = "hidden";
      }
    }
  }
  LoaiCauHoiEditChange() {
    var loai_cau_hoi = $("#LoaiCauHoiEdit option:selected").val();
    this.LoaiCauHoiCurrent = Number(loai_cau_hoi);
    document.getElementById("PhuongAnEdit").style.visibility = "hidden";
    document.getElementById("HangEdit").style.visibility = "hidden";
    document.getElementById("MucEdit").style.visibility = "hidden";
    // this.lstHangADD = [];
    // this.lstMucADD = [];
    if (loai_cau_hoi == 1 || loai_cau_hoi == 2 || loai_cau_hoi == 3) {
      document.getElementById("PhuongAnEdit").style.visibility = "visible";
      document.getElementById("HangEdit").style.visibility = "visible";
    } else {
      if (loai_cau_hoi == 4 || loai_cau_hoi == 5) {
        document.getElementById("PhuongAnEdit").style.visibility = "visible";
        document.getElementById("HangEdit").style.visibility = "visible";
        document.getElementById("MucEdit").style.visibility = "visible";
      } else {
        document.getElementById("PhuongAnEdit").style.visibility = "hidden";
      }
    }
  }
  xoa_dau(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ|ị/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)
    return str;
  }

  PhuongAnAdd() {
    var data = $("#HangInput").val();
    if (data.toString().trim() == "") {
      this.toastr.warning("Nội dung phương án không được để trống");
      $("#HangInput").val("");
      return false;
    }
    var item = {
      ID_phuong_an: 0,
      ID_cau_hoi: 0,
      Noi_dung_phuong_an: data,
      La_cot: false,
      Stt_cot: 0,
      Dap_an_dung: null,
      Diem: null,
    };

    this.lstHangADD.push(item);
    $("#HangInput").val("");
    $("#HangInput").focus();
    this.updatePhuongAnToiDa();
  }
  updatePhuongAnToiDa() {
    $("#PhuongAnToiDa").val(this.lstHangADD.length);
    $("#PhuongAnToiDaEdit").val(this.lstHangEditADD.length);
  }

  PhuongAnEditAdd() {
    var data = $("#HangEditInput").val();
    var item = {
      ID_phuong_an: 0,
      ID_cau_hoi: 0,
      Noi_dung_phuong_an: data,
      La_cot: false,
      Stt_cot: 0,
      Dap_an_dung: null,
      Diem: null,
    };

    this.lstHangEditADD.push(item);
    $("#HangEditInput").val("");
    $("#HangEditInput").focus();
    this.updatePhuongAnToiDa();
  }

  PhuongAnRemove(index) {
    this.lstHangADD.splice(index, 1);
    this.updatePhuongAnToiDa();
  }

  PhuongAnEditRemove(index) {
    this.lstHangEditADD.splice(index, 1);
    this.updatePhuongAnToiDa();
  }

  PhuongAnRemoveAll() {
    this.lstHangADD = [];
  }

  PhuongAnEditRemoveAll() {
    this.lstHangEditADD = [];
  }

  MucRemoveAll() {
    this.lstMucADD = [];
  }

  MucEditRemoveAll() {
    this.lstMucEditADD = [];
  }

  MucAdd() {
    var data = $("#MucInput").val();
    var item = {
      ID_phuong_an: 0,
      ID_cau_hoi: 0,
      Noi_dung_phuong_an: data,
      La_cot: true,
      Stt_cot: 0,
      Dap_an_dung: null,
      Diem: null,
    };
    this.lstMucADD.push(item);
    $("#MucInput").val("");
    $("#MucInput").focus();
  }

  MucEditAdd() {
    var data = $("#MucEditInput").val();
    var item = {
      ID_phuong_an: 0,
      ID_cau_hoi: 0,
      Noi_dung_phuong_an: data,
      La_cot: true,
      Stt_cot: 0,
      Dap_an_dung: null,
      Diem: null,
    };
    this.lstMucEditADD.push(item);
    $("#MucEditInput").val("");
    $("#MucEditInput").focus();
  }

  MucRemove(index) {
    this.lstMucADD.splice(index, 1);
  }
  MucEditRemove(index) {
    this.lstMucEditADD.splice(index, 1);
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn["dataTable"].ext.search.pop();
  }
  QuestionAdd() {
    var loai_cau_hoi = $("#LoaiCauHoi option:selected").val();
    var Nhom_cau_hoi = $("#NhomCauHoi option:selected").val();
    var Bat_buoc = (<HTMLInputElement>document.getElementById("BatBuoc"))
      .checked;
    let Noi_dung = $("#NoiDung").val();
    let Phuong_an_toi_da = $("#PhuongAnToiDa").val();
    var Ghi_chu = $("#GhiChu").val();
    var Ma_cau_hoi = $("#MaCauHoi").val();
    var QuestionData = {
      ID_cau_hoi: 0,
      Loai_cau_hoi: loai_cau_hoi,
      Ten_loai_cau_hoi: "",
      ID_nhom_cau_hoi: Nhom_cau_hoi,
      Ten_nhom_cau_hoi: "",
      Noi_dung_cau_hoi: Noi_dung,
      Diem: 0,
      Bat_buoc: Bat_buoc,
      Ngay_tao: Date.now(),
      Tai_khoan_tao: this.UserName,
      Ngay_sua: null,
      Tai_khoan_sua: null,
      Ghi_chu: Ghi_chu,
      Ma_cau_hoi: Ma_cau_hoi,
      Phuong_an_toi_da: Phuong_an_toi_da
    };
    var OptionsData = [];
    this.lstHangADD.forEach((element) => {
      OptionsData.push(element);
    });
    for (let index = 0; index < this.lstMucADD.length; index++) {
      this.lstMucADD[index].Stt_cot = index + 1;
      OptionsData.push(this.lstMucADD[index]);
    }
    var RequestQuestionAdd = {
      QuestionData: QuestionData,
      OptionsData: OptionsData,
    };
    var IsValid = false;
    if (Ma_cau_hoi.toString().trim() == "") {
      this.toastr.warning("Vui lòng nhập mã câu hỏi.", "Tác vụ thất bại");
      return;
    }
    if (Noi_dung.toString().trim() == "") {
      this.toastr.warning("Vui lòng nhập nội dung câu hỏi.", "Tác vụ thất bại");
    } else {
      if (Number(loai_cau_hoi) <= 3) {
        if (this.lstHangADD.length < 2) {
          this.toastr.warning(
            "Loại câu hỏi này cần ít nhất 2 phương án",
            "Tác vụ thất bại"
          );
        } else {
          IsValid = true;
        }
      } else {
        if (Number(loai_cau_hoi) <= 5) {
          if (this.lstHangADD.length < 1 || this.lstMucADD.length < 2) {
            this.toastr.warning(
              "Loại câu hỏi này cần ít nhất 1 phương án và 2 mức",
              "Tác vụ thất bại"
            );
          } else {
            IsValid = true;
          }
        } else {
          IsValid = true;
        }
      }
      if (IsValid) {
        this.Add(RequestQuestionAdd, this.token);
      }
    }
  }
  QuestionEdit() {
    var loai_cau_hoi = $("#LoaiCauHoiEdit option:selected").val();
    var Nhom_cau_hoi = $("#NhomCauHoiEdit option:selected").val();
    var Bat_buoc = (<HTMLInputElement>document.getElementById("BatBuocEdit"))
      .checked;
    var Noi_dung = $("#NoiDungEdit").val();
    var Ghi_chu = $("#GhiChuEdit").val();
    let Phuong_an_toi_da = $("#PhuongAnToiDaEdit").val();
    var Ma_cau_hoi = $("#MaCauHoiEdit").val();
    var QuestionData = {
      ID_cau_hoi: this.EditID,
      Loai_cau_hoi: loai_cau_hoi,
      Ten_loai_cau_hoi: "",
      ID_nhom_cau_hoi: Nhom_cau_hoi,
      Ten_nhom_cau_hoi: "",
      Noi_dung_cau_hoi: Noi_dung,
      Diem: 0,
      Bat_buoc: Bat_buoc,
      Ngay_tao: Date.now(),
      Tai_khoan_tao: this.UserName,
      Ngay_sua: Date.now(),
      Tai_khoan_sua: this.UserName,
      Ghi_chu: Ghi_chu,
      Ma_cau_hoi: Ma_cau_hoi,
      Phuong_an_toi_da: Phuong_an_toi_da
    };
    var OptionsData = [];
    this.lstHangEditADD.forEach((element) => {
      OptionsData.push(element);
    });
    for (let index = 0; index < this.lstMucEditADD.length; index++) {
      this.lstMucEditADD[index].Stt_cot = index + 1;
      OptionsData.push(this.lstMucEditADD[index]);
    }
    var RequestQuestionEdit = {
      QuestionData: QuestionData,
      OptionsData: OptionsData,
    };
    var IsValid = false;
    if (Ma_cau_hoi.toString().trim() == "") {
      this.toastr.warning("Vui lòng nhập mã câu hỏi.", "Tác vụ thất bại");
      return;
    }
    if (Noi_dung == "") {
      this.toastr.warning("Vui lòng nhập nội dung câu hỏi.", "Tác vụ thất bại");
    } else {
      if (Number(loai_cau_hoi) <= 3) {
        if (this.lstHangEditADD.length < 2) {
          this.toastr.warning(
            "Loại câu hỏi này cần ít nhất 2 phương án",
            "Tác vụ thất bại"
          );
        } else {
          IsValid = true;
        }
      } else {
        if (Number(loai_cau_hoi) <= 5) {
          if (this.lstHangEditADD.length < 1 || this.lstMucEditADD.length < 2) {
            this.toastr.warning(
              "Loại câu hỏi này cần ít nhất 1 phương án và 2 mức",
              "Tác vụ thất bại"
            );
          } else {
            IsValid = true;
          }
        } else {
          IsValid = true;
        }
      }
      if (IsValid) {
        this.Edit(RequestQuestionEdit, this.token);
      }
    }
  }
  getAll(token) {
    this.QuestionsService.GetAll(token).subscribe((z) => {
      this.Res = z;
      this.TableData = this.Res.Data;
      this.dtTrigger.next();
      this.Title = "Danh sách thông báo";
      this.TitleClass = "";
    });
  }
  async Add(m: any, token) {
    this.QuestionsService.Add(m, token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message, "Tác vụ thành công");
          this.PhuongAnRemoveAll();
          this.MucRemoveAll();
          this.modalService.dismissAll("mymodal");
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getAll(this.token);
        } else {
          this.toastr.error(z.Message, "Tác vụ thất  bại");
        }
        this.TitleClass = "";
      },
      (error) => {
        if ((error.status = 401)) {
          this.toastr.error("Bạn không có quyển thực hiện chức năng này !");
        } else {
          this.toastr.error(error.Message);
        }
      }
    );
  }
  Edit(m: any, token) {
    this.QuestionsService.Edit(m, token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message, "Tác vụ thành công");
          this.modalService.dismissAll("EditModal");
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getAll(this.token);
        } else {
          this.toastr.error(z.Message, "Tác vụ thất  bại");
        }
        this.TitleClass = "";
      },
      (error) => {
        if ((error.status = 401)) {
          this.toastr.error("Bạn không có quyển thực hiện chức năng này !");
        } else {
          this.toastr.error(error.Message);
        }
      }
    );
  }
  QuestionDelete(ID) {
    var RequestBase = {
      RequestID: ID,
    };
    var r = confirm("Bạn có muốn xóa câu hỏi không?");
    if (r) {
      this.Delete(RequestBase, this.token);
    }
  }
  Delete(m: any, token) {
    this.QuestionsService.Delete(m, token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.toastr.success(z.Message, "Tác vụ thành công");
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getAll(this.token);
        } else {
          this.toastr.error(z.Message, "Tác vụ thất  bại");
        }
        this.TitleClass = "";
      },
      (error) => {
        if ((error.status = 401)) {
          this.toastr.error("Bạn không có quyển thực hiện chức năng này !");
        } else {
          this.toastr.error(error.Message);
        }
      }
    );
  }
  open(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        scrollable: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
      this.updatePhuongAnToiDa();
  }
  openEdit(content, ID) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        scrollable: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.EditID = ID;
    this.lstHangEditADD = [];
    this.lstMucEditADD = [];
    var data = this.TableData;
    var lst = jQuery.grep(data, function (a: any) {
      return a.QuestionInfo.ID_cau_hoi == ID;
    });
    var Question = lst[0].QuestionInfo;
    var Options = lst[0].OptionsData;
    this.LoaiCauHoiCurrent= Question.Loai_cau_hoi;
    var lstHang = jQuery.grep(Options, function (a: any) {
      return !a.La_cot;
    });
    var lstCot = jQuery.grep(Options, function (a: any) {
      return a.La_cot;
    });
    lstHang.forEach((element) => {
      this.lstHangEditADD.push(element);
    });
    lstCot.forEach((element) => {
      this.lstMucEditADD.push(element);
    });
    (<HTMLInputElement>document.getElementById("LoaiCauHoiEdit")).value =
      Question.Loai_cau_hoi;
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById("NhomCauHoiEdit")).value =
        Question.ID_nhom_cau_hoi.toString();
    (<HTMLInputElement>document.getElementById("PhuongAnToiDaEdit")).value =
    Question.Phuong_an_toi_da;
    }, 100);
    (<HTMLInputElement>document.getElementById("BatBuocEdit")).checked =
      Question.Bat_buoc;
    (<HTMLInputElement>document.getElementById("NoiDungEdit")).value =
      Question.Noi_dung_cau_hoi;
    (<HTMLInputElement>document.getElementById("GhiChuEdit")).value =
      Question.Ghi_chu;
    (<HTMLInputElement>document.getElementById("MaCauHoiEdit")).value =
      Question.Ma_cau_hoi;

    document.getElementById("PhuongAnEdit").style.visibility = "hidden";
    document.getElementById("HangEdit").style.visibility = "hidden";
    document.getElementById("MucEdit").style.visibility = "hidden";
    // this.lstHangADD = [];
    // this.lstMucADD = [];
    if (
      Question.Loai_cau_hoi == 1 ||
      Question.Loai_cau_hoi == 2 ||
      Question.Loai_cau_hoi == 3
    ) {
      document.getElementById("PhuongAnEdit").style.visibility = "visible";
      document.getElementById("HangEdit").style.visibility = "visible";
    } else {
      if (Question.Loai_cau_hoi == 4 || Question.Loai_cau_hoi == 5) {
        document.getElementById("PhuongAnEdit").style.visibility = "visible";
        document.getElementById("HangEdit").style.visibility = "visible";
        document.getElementById("MucEdit").style.visibility = "visible";
      } else {
        document.getElementById("PhuongAnEdit").style.visibility = "hidden";
      }
    }
  }
  openViewModal(content, ID) {
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        scrollable: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

    var data = this.TableData;
    var lst = jQuery.grep(data, function (a: any) {
      return a.QuestionInfo.ID_cau_hoi == ID;
    });
    var Question = lst[0].QuestionInfo;
    document.getElementById("V_LoaiCauHoi").innerHTML =
      Question.Ten_loai_cau_hoi;
    document.getElementById("V_NhomCauHoi").innerHTML =
      Question.Ten_nhom_cau_hoi;
    document.getElementById("V_BatBuoc").innerHTML = Question.Bat_buoc
      ? "Có bắt buộc"
      : "Không bắt buộc";
    document.getElementById("V_GhiChu").innerHTML = Question.Ghi_chu;
    document.getElementById("V_TaiKhoanTao").innerHTML = Question.Tai_khoan_tao;
    document.getElementById("V_NgayTao").innerHTML = Question.Ngay_tao;
    document.getElementById("V_TaiKhoanSua").innerHTML = Question.Tai_khoan_sua;
    document.getElementById("V_NgaySua").innerHTML = Question.Ngay_sua;
    document.getElementById("V_NoiDung").innerHTML = Question.Noi_dung_cau_hoi;
    var Options = lst[0].OptionsData;
    var lstHang = jQuery.grep(Options, function (a: any) {
      return !a.La_cot;
    });
    var lstCot = jQuery.grep(Options, function (a: any) {
      return a.La_cot;
    });
    this.question_render(Question.Loai_cau_hoi, ID, lstHang, lstCot);
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  question_render(lch, id, list_phuong_an_hang, list_phuong_an_cot) {
    var rs = "";
    // 1: Câu hỏi dạng trắc nghiệm
    if (lch == 1) {
      //rs += '<label>' + h.Noi_dung_cau_hoi + '</label> <br />';
      for (var i = 0; i < list_phuong_an_hang.length; i++) {
        rs +=
          '<input type="radio" name = "' +
          id +
          '"/>' +
          list_phuong_an_hang[i].Noi_dung_phuong_an +
          "<br/>";
      }
    }
    // 2: Câu hỏi dạng hộp kiểm
    if (lch == 2) {
      //rs += '<label>' + h.Noi_dung_cau_hoi + '</label> <br />';
      for (var i = 0; i < list_phuong_an_hang.length; i++) {
        rs +=
          '<input type="checkbox"/>' +
          list_phuong_an_hang[i].Noi_dung_phuong_an +
          "<br/>";
      }
    }
    //3: câu hỏi dạng menu thả xuống
    if (lch == 3) {
      rs += "<select>";
      rs += '    <option selected disabled value="0">Chọn một đáp án</option>';
      for (var i = 0; i < list_phuong_an_hang.length; i++) {
        rs +=
          " <option>" + list_phuong_an_hang[i].Noi_dung_phuong_an + "</option>";
      }
      rs += "</select>";
    }
    // 4: câu hỏi dạng lưới trắc nghiệm
    if (lch == 4) {
      rs +=
        '<table class="table table-bordered dataTables_wrapper no-footer"><thead ><tr><th scope="col"></th>';
      for (var i = 0; i < list_phuong_an_cot.length; i++) {
        rs +=
          ' <th scope="col">' +
          list_phuong_an_cot[i].Noi_dung_phuong_an +
          "</th>";
      }
      rs += "</tr> </thead>  <tbody>";
      for (var j = 0; j < list_phuong_an_hang.length; j++) {
        rs +=
          ' <tr> <th scope="row">' +
          list_phuong_an_hang[j].Noi_dung_phuong_an +
          "</th>";
        for (var i = 0; i < list_phuong_an_cot.length; i++) {
          rs +=
            '<td> <input type="radio" id="' +
            list_phuong_an_hang[j].ID_phuong_an +
            "_" +
            list_phuong_an_cot[i].Stt_cot +
            '" name="' +
            list_phuong_an_hang[j].ID_phuong_an +
            '" value="' +
            list_phuong_an_hang[j].ID_phuong_an +
            "_" +
            list_phuong_an_cot[i].Stt_cot +
            '" /></td>';
        }
        rs += "</tr>";
      }
      rs += "</tbody> </table>";
    }
    // 5: câu hỏi dạng lưới hộp kiểm
    if (lch == 5) {
      rs +=
        '<table class="table table-bordered dataTables_wrapper no-footer"><thead ><tr><th scope="col"></th>';

      for (var i = 0; i < list_phuong_an_cot.length; i++) {
        rs +=
          ' <th scope="col">' +
          list_phuong_an_cot[i].Noi_dung_phuong_an +
          "</th>";
      }
      rs += "</tr> </thead>  <tbody>";
      for (var j = 0; j < list_phuong_an_hang.length; j++) {
        rs +=
          ' <tr> <th scope="row">' +
          list_phuong_an_hang[j].Noi_dung_phuong_an +
          "</th>";
        for (var i = 0; i < list_phuong_an_cot.length; i++) {
          rs += '<td> <input type="checkbox"/></td>';
        }
        rs += "</tr>";
      }
      rs += "</tbody> </table>";
    }
    // 6: Câu hỏi dạng văn bản
    if (lch == 6) {
      rs +=
        ' <textarea class="cus-texeria" id="6" rows="5" style=" width: 100%" ></textarea>';
    }
    document.getElementById("View_Question").innerHTML = "";
    document.getElementById("View_Question").innerHTML = rs;
  }
  help() {
    const urlHelp =
      this.appConfig.SurveyURL_Support +
      "?" +
      this.appConfig.document_Questions;
    window.open(urlHelp, "_blank");
  }
}

import { Inject, Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';


@Injectable({
  providedIn: 'root'
})
export class PhanPhoiLopHanhChinhService  extends BaseService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  getPhanPhoiLopHanhChinh(token, m): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "/phan-phoi-lop-hanh-chinh/get-all",
        m,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  guiPhieuDanhGia(token, m): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "/phan-phoi-lop-hanh-chinh/gui-phieu-danh-gia-lop-hanh-chinh",
        m,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}


<aside #mainSidebar class="main-sidebar sidebar-light-primary elevation-4 cus-text" style="overflow:visible !important">
  <!-- Brand Logo -->
  <!-- <a [routerLink]="['/']" class="brand-link">
    <img
      src="assets/img/AdminLTELogo.png"
      alt="AdminLTE Logo"
      class="brand-image img-circle elevation-3"
      style="opacity: .8"
    />
    <span class="brand-text font-weight-light">AdminLTE 3</span>
  </a> -->
  <a [routerLink]="['/']" class="brand-link">
    <img src="assets/img/UniTeacherLogo.png" alt="UniSurvey" class="brand-image img-circle elevation-3"
      style="opacity: .8">
    <span class="brand-text font-weight-light">UniSurvey</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">

    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li id="menu1" class="nav-item menu-open"  >
          <a class="nav-link cus-bg" (click) = "menu('menu1')" style=" background:#418994;color: white;" >
            <i class="far fa-file-alt"></i>&nbsp;
            <p>
              XÂY DỰNG PHIẾU
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" >
            <li class="nav-item" *ngIf="Loai_danh_gia">
              <a [routerLink]="['/admin/loai-danh-gia']" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" > 
                <p > <i class="fa fa-clone" aria-hidden="true"></i>
                  Loại đánh giá</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Nhom_cau_hoi">
              <a [routerLink]="['/admin/nhom-cau-hoi']" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" > 
                <p > <i class="fa fa-check" aria-hidden="true"></i> Nhóm câu hỏi</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Ngan_hang_cau_hoi">
              <a [routerLink]="['/admin/QuestionsBank']" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" > 
                <p > <i class="fas fa-university"></i> Ngân hàng câu hỏi</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Phieu_danh_gia">
              <a [routerLink]="['/admin/Survey']"  class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"  >
                <p > <i class="fas fa-file-signature"></i>  Phiếu đánh giá</p>
              </a>
            </li>
          </ul>
        </li>
        <li id="menu2" class="nav-item menu-open"  >
          <a  class="nav-link " (click) = "menu('menu2')" style=" background:#418994;color: white;">
            <i class="far fa-address-card"></i>&nbsp;
            <p>
            KHẢO SÁT
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Quan_ly_khao_sat">
              <a [routerLink]="['/admin/Season']" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="fas fa-calendar-check"></i>
                <p style="margin-left: 5px;"> Quản lý khảo sát</p>
              </a>
            </li>
          </ul>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Phan_phoi_tin_chi">
              <a 
              [routerLink]="['/admin/phan-phoi-tin-chi']" 
              class="nav-link"  
              routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact:true}"
              title="Phân phối phiếu đánh giá cho sinh viên trong lớp tín chỉ"
              >
                <i class="fa fa-paper-plane"></i>
                <p style="margin-left: 5px;"> Phân phối lớp tín chỉ</p>
              </a>
            </li>
          </ul>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Khao_sat_lop_hanh_chinh">
              <a 
              [routerLink]="['/admin/phan-phoi-phieu-lop-hanh-chinh']" 
              class="nav-link"  routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact:true}"
              title="Phân phối phiếu đánh giá cho sinh viên trong lớp hành chính"
              >
                <i class="fa fa-users" aria-hidden="true"></i>
                <p style="margin-left: 5px;"> Khảo sát SV lớp hành chính</p>
              </a>
            </li>
          </ul>

          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Khao_sat_lop_nien_che">
              <a 
              [routerLink]="['/admin/phan-phoi-phieu-lop-nien-che']" 
              class="nav-link"  routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact:true}"
              title="Phân phối phiếu đánh giá cho sinh viên trong lớp niên chế"
              >
                <i class="fas fa-briefcase"></i>
                <p style="margin-left: 5px;"> Phân phối lớp niên chế</p>
              </a>
            </li>
          </ul>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Danh_gia_co_van_hoc_tap">
              <a 
              [routerLink]="['/admin/khao-sat-co-van-hoc-tap']" 
              class="nav-link"  routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact:true}"
              title="Phân phối phiếu đánh giá cho sinh viên trong lớp hành chính để đánh giá cố vấn học tập của lớp"
              >
                <i class="far fa-user"></i>
                <p style="margin-left: 5px;">Phân phối khảo sát CVHT</p>
              </a>
            </li>
          </ul>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Khao_sat_giang_vien">
              <a 
              [routerLink]="['/admin/khao-sat-giang-vien']" 
              class="nav-link" 
              routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact:true}"
              title="Phân phối phiếu đánh giá cho giảng viên"
              >
                <i class="fa fa-share-square" aria-hidden="true"></i>
                <p style="margin-left: 5px;"> Khảo sát giảng viên</p>
              </a>
            </li>
          </ul>

          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Khao_sat_can_bo">
              <a 
              [routerLink]="['/admin/khao-sat-can-bo']" 
              class="nav-link"  routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact:true}"
              title="Phân phối phiếu đánh giá cho cán bộ"
              >
                <i class="fa fa-rocket" aria-hidden="true"></i>
                <p style="margin-left: 5px;"> Khảo sát cán bộ</p>
              </a>
            </li>
          </ul>

          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Khao_sat_doanh_nghiep">
              <a 
              [routerLink]="['/admin/khao-sat-doanh-nghiep']" 
              class="nav-link"  routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact:true}"
              title="Phân phối phiếu đánh giá cho doanh nghiệp"
              >
                <i class="fa fa-share" aria-hidden="true"></i>
                <p style="margin-left: 5px;"> Khảo sát doanh nghiệp</p>
              </a>
            </li>
          </ul>
         
        
        </li>
        <li id="menu5" class="nav-item menu-open"  >
          <a  class="nav-link " (click) = "menu('menu5')" style=" background:#418994;color: white;">
            <i class="far fa-address-card"></i>&nbsp;
            <p>
            TRA CỨU PHÂN PHỐI
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
   
            <li class="nav-item" *ngIf="Tra_cuu_phieu_phan_phoi">
              <a [routerLink]="['/admin/SearchObject']" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="fab fa-searchengin"></i>
                <p style="margin-left: 5px;"> Tra cứu phiếu phân phối</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Tra_cuu_phieu_phan_phoi">
              <a [routerLink]="['/admin/search-users']" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <i class="fas fa-calendar-check"></i>
                <p style="margin-left: 5px;"> Tra cứu phân phối cán bộ</p>
              </a>
            </li>
          </ul>
        </li>
        <li id="menu3" class="nav-item menu-open"  >
          <a  class="nav-link " (click) = "menu('menu3')"  style=" background:#418994;color: white;">
            <i class="far fa-chart-bar"></i>&nbsp;
            <p>
            PHÂN TÍCH SỐ LIỆU
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
        <li class="nav-item" *ngIf="Bao_cao_tong_hop">
          <a [routerLink]="['/admin/Report']" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-chart-line"></i>
            <p style="margin-left: 5px;"> Báo cáo tổng hợp</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="Quan_ly_bao_cao">
          <a [routerLink]="['/admin/ReportManagement']" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-chart-line"></i>
            <p style="margin-left: 5px;"> Quản lý báo cáo</p>
          </a>
        </li>
      </ul>
    </li>
    <li id="menu4" class="nav-item menu-open"  >
      <a  class="nav-link " (click) = "menu('menu4')"  style=" background:#418994;color: white;">
        <i class="fas fa-archway"></i>&nbsp;
        <p>
        Doanh nghiệp
          <i class="right fa fa-angle-left"></i>
        </p>
      </a>
      <ul class="nav nav-treeview">
        <li class="nav-item" *ngIf="Quan_ly_doanh_nghiep">
          <a [routerLink]="['/admin/Enterprise']" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas fa-address-card"></i>
            <p style="margin-left: 5px;"> Quản lý doanh nghiệp</p>
          </a>
        </li>
      </ul>
      </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>
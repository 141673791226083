import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class SurveyService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }
  getListSurvey(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Survey/GetAll", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getListQuestion(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Question/GetAll", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getListQuestionNew(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Question/GetQuestionNew", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AddSurvey(data: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Survey/Add", data, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  EditSurvey(data: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Survey/Edit", data, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  DeleteSurvey(data: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Survey/Delete", data, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getListPhieuDanhGia(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Survey/GetPhieuDanhGia", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  GetSurvey(data: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Survey/Get", data, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}

<section class="content-header" style="padding:5px !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a [routerLink]="['/portal/Student']">Cổng đánh giá</a></li>
                <li class="breadcrumb-item active">Doanh nghiệp</li>
            </ol>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <label class="col-md-10">Đợt đánh giá:</label>
                    <input class="form-control" [(ngModel)]="DotDanhGia" (keyup)="filterById()" placeholder="Nhập nội dung đợt đánh giá" type="text">
                </div>
                <div class="col-md-3">
                    <label class="col-md-10">Phiếu đánh giá:</label>
                    <input class="form-control" [(ngModel)]="PhieuDanhGia" (keyup)="filterById()" placeholder="Nhập nội dung đợt đánh giá" type="text">
                </div>
                <div class="col-md-3">
                    <label class="col-md-10">Trạng thái:</label>
                    <select  class="form-control" (change)="filterById()" [(ngModel)]="TrangThaiFT">
                        <option value="0">Tất cả</option>
                        <option value="Đã đánh giá">Đã đánh giá</option>
                        <option value="Chưa đánh giá">Chưa đánh giá</option>
                    </select>
                </div>
            </div>
            <hr>
        </div><!-- /.card-header -->
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class=" table-bordered dataTables_wrapper no-footer ">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of TableData;let i=index;">
                                            <td>{{ p.Ten_dot }}</td>
                                            <td>{{ p.Ten_phieu }}</td>
                                            <td>{{ p.Ngay_bat_dau }}</td>
                                            <td>{{ p.Ngay_ket_thuc }}</td>
                                            <td>{{ p.TrangThai }}</td>
                                            <td>{{ p.Tai_khoan_gui }}</td>
                                            <td>
                                               <button class="btn btn-sm btn-warning" (click)="openEdit(EditModal,p.ID_gui,p.ID_phieu_danh_gia,p.HetHan,p.TrangThai)" ><i class="fas fa-edit" title="Đánh giá"></i></button> 
                                           </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
<ng-template #EditModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Thông tin đánh giá</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="cus-box">
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="NoiDung">Tên phiếu</label>
                    <br />
                    <textarea id="TenPhieu" name=""
                        style="width:100%;padding:12px 20px;box-sizing:border-box;border:2px solid #ccc;border-radius:4px;resize:none;font-size:14px"></textarea>

                </div>
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Loại đánh giá</label>
                    <br />
                    <input id="Loại đánh giá" class="form-control" id="LoaiDanhGia" name="" />

                </div>

            </div>
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="NoiDung">Nội dung</label>
                    <br />
                    <textarea id="NoiDung" readonly name=""
                        style="width:100%;padding:12px 20px;box-sizing:border-box;border:2px solid #ccc;border-radius:4px;resize:none;font-size:14px"></textarea>

                </div>
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Ghi chú</label>
                    <br />
                    <textarea id="GhiChu" readonly name=""
                        style="width:100%;padding:12px 20px;box-sizing:border-box;border:2px solid #ccc;border-radius:4px;resize:none;font-size:14px"></textarea>
                </div>

            </div>
        </div>
        <div class="container" id="View_Question">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="GuiDanhGia" class="btn btn-primary" *ngIf="isDisplayButton" (click)="ValidateQuestions()">Gửi đánh giá</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Đóng</button>
    </div>
</ng-template>
import { Inject, Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChuyenNganhService extends BaseService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  getChuyenNganh(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "chuyen-nganh/get-all", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">

            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['/']">Trang chủ</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="['/message']">Tin nhắn</a></li>
                    <li class="breadcrumb-item active">Chi tiết tin nhắn</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <a [routerLink]="['/message']" class="btn btn-primary btn-block mb-3"><i class="fas fa-arrow-left"></i> Quay lại</a>
            </div> 
            <div class=" col-md-3">
                <div class="{{TitleClass}}"></div>
            </div>
            
        </div>
    </div>
    <div class="row">
        <!-- /.col -->
        <div class="col-md-12">
            <div class="card card-primary card-outline">
                <!-- /.card-header -->
                <div class="card-body p-0">
                    <div class="mailbox-read-info">
                        <h5>Tiêu đề: {{Title}}</h5>
                        <h6>
                            <span id="FromUserName"> From: {{FromUserName}}</span> <br />
                            <span id="ToUserName"> To: {{ToUserName}}</span>
                            <span class="mailbox-read-time float-right">Thời gian gửi: {{SendDate}}</span>
                        </h6>
                    </div>
                    <div class="mailbox-read-message" [innerHTML]="Content">
                        Đang tải nội dung thư...
                    </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer bg-white">
                    <ul class="mailbox-attachments clearfix">
                        <ng-container *ngIf="AttachmentName?.length>0">
                            <li><span class="mailbox-attachment-icon"><i class="fas fa-file-alt"></i></span>
                                <div class="mailbox-attachment-info">
                                    <a href="{{Attachment}}"  class="mailbox-attachment-name">
                                        <i class="fa fa-paperclip"></i> {{AttachmentName}}</a>
                                </div>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="AttachmentName?.length==0">
                            
                        </ng-container>
                       
                    </ul>
                </div>
                <!-- /.card-footer -->
                <div class="card-footer">
                    <div class="float-right">
                        <button type="button" class="btn btn-default" > <a [routerLink]="['/create']" [queryParams]="{ID: ID,Category:1}" queryParamsHandling="merge"><i class="fa fa-reply"></i>Trả lời</a></button>
                    </div>
                </div>
                <!-- /.card-footer -->
            </div>
            <!-- /. box -->
        </div>
        <!-- /.col -->
    </div>
    <!-- /.row -->
</section>
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { BoMonService } from 'src/app/Utils/service/bo-mon.service';
import { KhoaService } from 'src/app/Utils/service/khoa.service';
import { LoaiDanhGiaService } from 'src/app/Utils/service/loai-danh-gia.service';
import { PhanPhoiGiangVienService } from 'src/app/Utils/service/phan-phoi-giang-vien.service';
import { PhieuDanhGiaGuiService } from 'src/app/Utils/service/phieu-danh-gia-gui.service';
import { PhieuDanhGiaService } from 'src/app/Utils/service/phieu-danh-gia.service';
import { SearchObjectService } from 'src/app/Utils/service/search-object.service';

import { SeasonService } from 'src/app/Utils/service/season.service';
import { SurveyService } from 'src/app/Utils/service/survey.service';
import { common } from 'src/app/app.common';

@Component({
  selector: 'app-khao-sat-giang-vien',
  templateUrl: './khao-sat-giang-vien.component.html',
  styleUrls: ['./khao-sat-giang-vien.component.scss']
})
export class KhaoSatGiangVienComponent implements OnInit {

  Token: any;
  UserInfo: any;
  dotDanhGia: any;
  loaiDanhGia: any;
  phieuDanhGia: any
  phieuDanhGiaFilter: any
  boMon: any
  khoa: any;
  phanPhoiGiangVien: any;
  phieuDanhGiaDaGui: any;
  phieuDanhGiaMotGV: any;
  listGuiPhieuDanhGia: any
  public com: common
  phanPhoiTinChiService: any;
  ChonCount: any = 0;
  closeResult: string;
  Fillter = new FormGroup({
    ID_dot_danh_gia: new FormControl(0),
    ID_phieu_danh_gia: new FormControl(0),
    Ma_giang_vien: new FormControl(null),
    Ten_giang_vien: new FormControl(null),
    ID_bo_mon: new FormControl(0),
    ID_khoa: new FormControl(0),
    Loai_giang_vien: new FormControl(0),
    ID_loai_danh_gia: new FormControl(-1)
  })
  constructor(
    public router: Router,
    private dotDanhGiaSv: SeasonService,
    private loaiDanhGiaSv: LoaiDanhGiaService,
    private phieuDanhGiaSv: PhieuDanhGiaService,
    private boMomSv: BoMonService,
    private khoaSv: KhoaService,
    private phanPhoiGiangVienSv: PhanPhoiGiangVienService,
    private searchObjectService: SearchObjectService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private phieuDanhGiaGuiSv: PhieuDanhGiaGuiService,
    private toastr: ToastrService,

  ) { }


  async ngOnInit(): Promise<void> {
    // get UserData
    this.com = new common(this.router);
    this.com.CheckLogin();
    var saveData = this.com.getUserinfo();
    this.UserInfo = saveData.Info.UserName;
    this.Token = saveData.access_token;

    await this.getDotDanhGia();
    this.getLoaiDanhGia();
    this.getPhieuDanhGia();
    this.getBoMon();
    this.getKhoa();
    this.getPhanPhoiGiangVien();
   
  }
  getDotDanhGia(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dotDanhGiaSv.getListSeason(this.Token).subscribe(res => {
        this.dotDanhGia = res.Data;
        this.Fillter.patchValue({
          ID_dot_danh_gia: this.dotDanhGia.length > 0 ? this.dotDanhGia[0].ID_dot_danh_gia : 0
        })

        resolve(); // Gọi resolve() khi hàm hoàn thành
      }, error => {
        reject(error); // Gọi reject() nếu có lỗi xảy ra
      });
    });
  }
  getLoaiDanhGia() {
    this.loaiDanhGiaSv.getAll(this.Token).subscribe(res => {
      if (res.Status == 1) {
        this.loaiDanhGia = res.ListData;
      }

    })
  }
  getPhieuDanhGia() {

    this.phieuDanhGiaSv.getPhieuDanhGia(this.Token, this.Fillter.value.ID_loai_danh_gia).subscribe(res => {
      this.phieuDanhGia = res.ListData
      this.phieuDanhGiaFilter = res.ListData

    });


  }
  filterPhieuDanhGia() {
    this.phieuDanhGiaFilter = this.phieuDanhGia.filter((obj) => {
      return (
        obj.ID_loai_danh_gia == this.Fillter.value.ID_loai_danh_gia ||
        this.Fillter.value.ID_loai_danh_gia == -1
      );
    });
  }

  getBoMon() {
    this.boMomSv.getBoMon(this.Token).subscribe(res => {
      this.boMon = res.ListData;

    })
  }

  getKhoa() {
    this.khoaSv.getKhoa(this.Token).subscribe(res => {
      this.khoa = res.ListData

    })
  }

  getPhanPhoiGiangVien() {
    var data = this.Fillter.value;
    this.spinner.show();
    this.phanPhoiGiangVienSv
      .getPhanPhoiGiangVien(this.Token, data)
      .subscribe((z) => {
        this.spinner.hide();
        this.phanPhoiGiangVien = z.Data;
        this.ChonCount = 0;
    

      });
  }

  insertDotDanhGiaGui(data) {
    this.spinner.show();
    this.phieuDanhGiaGuiSv.insertDotDanhGiaGui(data, this.Token).subscribe(res => {
      this.spinner.hide();
      this.getPhanPhoiGiangVien();
      if (res.ListData.length == data.ID_giao_vien.length) {
        this.toastr.success(res.Message);
      } else {
        this.toastr.warning(res.Message);
      }
    })
  }

  checkOne(index) {

    this.phanPhoiGiangVien[index].Chon = !this.phanPhoiGiangVien[index].Chon;
    this.ChonCount = this.phanPhoiGiangVien.filter((obj) => {
      return obj.Chon == true;
    }).length;
  }

  checkAll() {
    var value = false;
    var ChonCount = this.phanPhoiGiangVien.filter((obj) => {
      return obj.Chon == true
    }).length;

    if (ChonCount < this.phanPhoiGiangVien.length) {
      value = true
    }
    for (let i = 0; i < this.phanPhoiGiangVien.length; i++) {
      this.phanPhoiGiangVien[i].Chon = value
    }
    this.ChonCount = this.phanPhoiGiangVien.filter((obj) => {
      return obj.Chon == true
    }).length

  }

  open(content, phieuDanhGiaGui) {
    if (phieuDanhGiaGui.ID_dot_danh_gia == null) {
      this.toastr.warning("Không có dữ liệu");
    } else {
      // Tạo đối tượng req
      var req = {
        ID_dot_danh_gia: phieuDanhGiaGui.ID_dot_danh_gia,
        Ten_dot: '',
        Ngay_bat_dau: new Date(phieuDanhGiaGui.Ngay_bat_dau).toLocaleDateString('vi-VN'),
        Ngay_ket_thuc: new Date(phieuDanhGiaGui.Ngay_ket_thuc).toLocaleDateString('vi-VN'),
        Phieu_danh_gia: phieuDanhGiaGui.lstPhieu
      };
  
      // Tìm tên đợt đánh giá
      for (let i = 0; i < this.dotDanhGia.length; i++) {
        if (this.dotDanhGia[i].ID_dot_danh_gia == phieuDanhGiaGui.ID_dot_danh_gia) {
          req.Ten_dot = this.dotDanhGia[i].Ten_dot;
        }
      }
  
      // Gán giá trị cho phieuDanhGiaMotGV
      this.phieuDanhGiaMotGV = req;
  
      // Mở modal
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  guiPhieu() {
    var r = confirm("Bạn có muốn gửi không?");
    if (r) {
      this.guiPhieuDanhGia()


    }
  }

  guiPhieuDanhGia() {
    this.listGuiPhieuDanhGia = []
    var ChonCount = this.phanPhoiGiangVien.filter((obj) => {
      return obj.Chon == true
    }).length;

    if (this.Fillter.value.ID_dot_danh_gia == 0 || this.Fillter.value.ID_phieu_danh_gia == 0 || ChonCount == 0) {
      this.toastr.warning("Chọn đọt đánh giá, phiếu đánh giá và giảng viên cần gửi");
    } else {
      var data = {
        ID_dot_danh_gia: this.Fillter.value.ID_dot_danh_gia,
        ID_phieu_danh_gia: this.Fillter.value.ID_phieu_danh_gia,
        ID_giao_vien: []
      }
      for (let i = 0; i < this.phanPhoiGiangVien.length; i++) {

        if (this.phanPhoiGiangVien[i].Chon == true) {
          data.ID_giao_vien.push(this.phanPhoiGiangVien[i].ID_cb)

          this.listGuiPhieuDanhGia = data
        }
      }

      this.insertDotDanhGiaGui(this.listGuiPhieuDanhGia);

      for (let i = 0; i < this.phanPhoiGiangVien.length; i++) {
        this.phanPhoiGiangVien[i].Chon = false
      }
      this.ChonCount = 0
    }
  }

  deleteMulti(data: any) {
    if(data == undefined || data == null || data.length == 0){
      this.toastr.warning("Không có dữ liệu");
      return;
    }
    else{
      let listID: number[] = data.map(item => item.ID_gui);
      var r = confirm("Bạn có muốn xóa phiếu đã được gửi đến danh sách lớp đã chọn không?")
      if (!r) {
        return;
      }
      this.spinner.show();
      this.searchObjectService.DeleteMulti(listID, this.Token).subscribe((z) => {
        this.spinner.hide();
        this.toastr.success(z.Message);
        this.getPhanPhoiGiangVien();
      });
    }
  }
}

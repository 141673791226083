<ng-template #ViewSurveymodal let-modal>
  <div
    class="modal-header m-header"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <!-- <h4 class="modal-title h-title w-100 text-center">
      Thông tin phiếu đánh giá
    </h4> -->
    <p
      type="button"
      class="close"
      aria-label="Close"
      style="font-size: 30px"
      data-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </p>
  </div>
  <div class="modal-body" style="padding: 3vw;">
    <div class="default-font">
      <div class="row">
        <div class="col-md-12 ">
          <h4 class="text-center" style="font-weight: bold;"> {{ SurveyName }} </h4>
          <br>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 ">
          <h6 class="text-left">{{ Content }} </h6>
          <br>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 ">
          <h6 class="text-left">{{ Note }} </h6>
          <hr>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-9">
          <!-- <label class="control-label" for="inputSuccess"
            ><i class="fas fa-poll-h"></i>&nbsp; Tên phiếu đánh giá:
            {{ SurveyName }}
          </label> -->
          <!-- <input
            class="form-control cus-fs13"
            readonly
            [(ngModel)]="SurveyName"
            id="SurveyName"
            placeholder="Nhập tên phiếu..."
            type="text"
          /> -->
        </div>
        <!-- <div class="form-group col-md-3 form-group">
          <label class="control-label" for="inputSuccess"
            ><i class="fas fa-sort-amount-down"></i>&nbsp; Loại đánh giá
            :</label
          >
          <select
            disabled="disabled"
            class="form-control cus-fs13"
            [(ngModel)]="SurveyType"
            id="SurveyType"
          >
            <option
              *ngFor="let p of lstLoaiDanhGia"
              [value]="p.ID_loai_danh_gia"
            >
              {{ p.Ten_loai_danh_gia }}
            </option>
            <option value="0">Chưa phân loại</option>
          </select>
        </div> -->
      </div>
      <!-- <div class="row">
        <div class="col-md-6 form-group">
          <label class="control-label text-left usfont_lable" for="inputSuccess"
            ><i class="fas fa-file-alt"></i>&nbsp;Nội dung</label
          >
          <br />
          <textarea
            name="Content"
            readonly
            [(ngModel)]="Content"
            class="cus-texeria"
            id="Content"
            placeholder="Nhập nội dung ... "
          ></textarea>
        </div>
        <div class="col-md-6 form-group">
          <label class="control-label text-left usfont_lable" for="inputSuccess"
            ><i class="far fa-comment-alt"></i>&nbsp;Ghi chú</label
          >
          <br />
          <textarea
            id="Note"
            [(ngModel)]="Note"
            class="cus-texeria"
            readonly
            placeholder="Nhập ghi chú ... "
          ></textarea>
        </div>
      </div> -->
    </div>
    <div class="tab-content">
      <div class="active tab-pane">
        <div class="row" style="margin-top: 10px">
          <div class="col-md-12">
            <div
              class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              
            >
              <div id="ListOption2"></div>
              <!-- /.table -->
            </div>
          </div>
          <!-- /.col -->
        </div>
      </div>
    </div>
    <!-- /.tab-content -->
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-danger"
      (click)="modal.close('Save click')"
    >
      <i class="far fa-times-circle"></i> Đóng
    </button>
  </div>
</ng-template>

<ng-template #AddQuestion let-modal>
  <div
    class="modal-header m-header"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100" style="text-align: center">
      Thêm câu hỏi
    </h4>
    <p
      type="button"
      class="close"
      aria-label="Close"
      style="font-size: 30px"
      data-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </p>
  </div>
  <div class="modal-body" style="padding-bottom: 0px">
    <div class="card">
      <div class="card-header">
        <form [formGroup]="dataFilterForm">
          <div class="row">
            <div class="form-group col-sm">
              <label class="control-label" for="inputSuccess"
                ><i class="fas fa-poll-h"></i>&nbsp; Mã câu hỏi:
              </label>
              <input
                (change)="getCauHoiFiler()"
                formControlName="Ma_cau_hoi"
                class="form-control cus-fs13"
                placeholder="Nhập mã câu hỏi..."
                type="text"
              />
            </div>
            <div class="form-group col-sm">
              <label class="control-label" for="inputSuccess"
                ><i class="fas fa-poll-h"></i>&nbsp; Nội dung câu hỏi:
              </label>
              <input
                (change)="getCauHoiFiler()"
                formControlName="Noi_dung_cau_hoi"
                class="form-control cus-fs13"
                placeholder="Nhập nội dung câu hỏi..."
                type="text"
              />
            </div>
            <div class="form-group col-sm">
              <label class="control-label" for="inputSuccess"
                ><i class="fas fa-sort-amount-down"></i>&nbsp; Loại câu
                hỏi:</label
              >
              <select
                (change)="getCauHoiFiler()"
                formControlName="Loai_cau_hoi"
                class="form-control cus-fs13"
              >
                <option value="0">Tất cả</option>
                <option value="1">Trắc nghiệm</option>
                <option value="2">Hộp kiểm</option>
                <option value="3">Menu thả</option>
                <option value="4">Lưới trắc nghiệm</option>
                <option value="5">Lưới hộp kiểm</option>
                <option value="6">Văn bản</option>
              </select>
            </div>
            <div class="form-group col-sm">
              <label class="control-label" for="inputSuccess"
                ><i class="fas fa-sort-amount-down"></i>&nbsp; Nhóm câu
                hỏi:</label
              >
              <select
                (change)="getCauHoiFiler()"
                formControlName="Nhom_cau_hoi"
                class="form-control cus-fs13"
              >
                <option value="-1">Tất cả</option>
                <option
                  *ngFor="let p of lstCauHoiNhom"
                  [value]="p.ID_nhom_cau_hoi"
                >
                  {{ p.Ten_nhom_cau_hoi }}
                </option>
                <option value="0">Chưa phân loại</option>
              </select>
            </div>
            <div class="form-group col-sm">
              <label class="control-label" for="inputSuccess"
                ><i class="fas fa-poll-h"></i>&nbsp; Người tạo :
              </label>
              <input
                formControlName="Tai_khoan_tao"
                (change)="getCauHoiFiler()"
                class="form-control cus-fs13"
                placeholder="Nhập người tạo..."
                type="text"
              />
            </div>
          </div>
        </form>

        <!-- <div class="row">
                    <div class="col-md-12">
                        <div class="form-check" style="margin-left: 30px;text-align: center;">
                            <input class="form-check-input" (change)="LocCauHoiChange()"  [(ngModel)]="CheckCauHoi" type="checkbox" >
                            <label class="form-check-label" >
                                Loại bỏ câu hỏi cũ
                            </label>
                        </div>
                    </div>
                </div> -->
      </div>
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
                >
                  <table
                    class="table table-bordered dataTables_wrapper no-footer"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Mã câu hỏi</th>
                        <th scope="col">Nội dung câu hỏi</th>
                        <th scope="col">Tên loại câu hỏi</th>
                        <th scope="col">Nhóm câu hỏi</th>
                        <th scope="col">Tài khoản tạo</th>
                        <th scope="col">Thao tác</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let p of lstCauHooiFilter">
                        <td>{{ p.Ma_cau_hoi }}</td>
                        <td>{{ p.Noi_dung_cau_hoi }}</td>
                        <td>{{ p.Ten_loai_cau_hoi }}</td>
                        <td>{{ p.Ten_nhom_cau_hoi }}</td>
                        <td>{{ p.Tai_khoan_tao }}</td>
                        <td width="15%">
                          <a
                            class="btn btn-primary btn-sm"
                            (click)="
                              modalannounce(AnnounceQuestion, p.ID_cau_hoi)
                            "
                          >
                            <i
                              class="fas fa-search"
                              ngbTooltip="Xem chi tiết"
                            ></i>
                          </a>
                          <a
                            class="btn btn-success btn-sm cus-ml"
                            (click)="Select(p.ID_cau_hoi)"
                          >
                            <i class="fas fa-plus-circle" ngbTooltip="Chọn"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- /.col -->
            </div>
          </div>
        </div>
        <!-- /.tab-content -->
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-danger"
      (click)="modal.close('Save click')"
    >
      Đóng
    </button>
  </div>
</ng-template>

<ng-template #AnnounceQuestion let-modal>
  <div
    class="modal-header m-header"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100">Thông tin phương án</h4>
    <p
      type="button"
      class="close"
      aria-label="Close"
      style="font-size: 30px"
      data-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </p>
  </div>
  <div class="modal-body" style="padding-top: 0px; padding-bottom: 0px">
    <div class="card">
      <div class="card-header">
        <div class="cus-box cus-fs13" id="NameQuestion"></div>
      </div>
      <div
        class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
      >
        <table
          class="table table-bordered dataTables_wrapper no-footer"
          id="ListOption"
        ></table>
        <!-- /.table -->
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Đóng
    </button>
  </div>
</ng-template>

<ng-template #CreateSurveymodal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100">{{ TitleModal }}</h4>
    <p
      type="button"
      class="close"
      aria-label="Close"
      style="font-size: 30px"
      data-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </p>
  </div>
  <div class="modal-body" style="padding-top: 5px; padding-bottom: 0px">
    <div class="cus-box">
      <div class="row">
        <div class="form-group col-md-12">
          <label class="control-label require" for="inputSuccess"
            ><i class="fas fa-poll-h"></i>&nbsp; Tên phiếu đánh giá
          </label>
          <input
            class="form-control cus-fs13"
            [(ngModel)]="SurveyName"
            id="SurveyName"
            placeholder="Nhập tên phiếu..."
            type="text"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label require" for="inputSuccess"
            ><i class="fas fa-poll-h"></i>&nbsp; Mã phiếu đánh giá
          </label>
          <input
            class="form-control cus-fs13"
            [(ngModel)]="Ma_phieu_danh_gia"
            id="Ma_phieu_danh_gia"
            placeholder="Nhập mã phiếu..."
            type="text"
          />
        </div>
        <div class="form-group col-md-6 form-group">
          <label class="control-label" for="inputSuccess"
            ><i class="fas fa-sort-amount-down"></i>&nbsp; Loại đánh giá
            :</label
          >
          <select
            class="form-control cus-fs13"
            [(ngModel)]="SurveyType"
            id="SurveyType"
          >
            <option
              *ngFor="let p of lstLoaiDanhGia"
              [value]="p.ID_loai_danh_gia"
            >
              {{ p.Ten_loai_danh_gia }}
            </option>
            <option value="0">Chưa phân loại</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label class="control-label text-left usfont_lable" for="inputSuccess"
            ><i class="fas fa-file-alt"></i>&nbsp;Nội dung</label
          >
          <br />
          <textarea
            name="Content"
            [(ngModel)]="Content"
            class="cus-texeria"
            id="Content"
            placeholder="Nhập nội dung ... "
          ></textarea>
        </div>
        <div class="col-md-6 form-group">
          <label class="control-label text-left usfont_lable" for="inputSuccess"
            ><i class="far fa-comment-alt"></i>&nbsp;Ghi chú</label
          >
          <br />
          <textarea
            id="Note"
            [(ngModel)]="Note"
            class="cus-texeria"
            placeholder="Nhập ghi chú ... "
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 10px; margin-bottom: 10px">
      <div class="col-md-12">
        <button
          class="btn btn-success btn-sm"
          (click)="ModalAdd(AddQuestion)"
          style="float: right"
        >
          <i class="fas fa-plus-circle"></i> Thêm câu hỏi
        </button>
      </div>
    </div>
    <div class="tab-content">
      <div class="active tab-pane">
        <div class="row">
          <div
            class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
          >
            <table class="table table-bordered dataTables_wrapper no-footer">
              <thead>
                <tr>
                  <th scope="col">Nội dung câu hỏi</th>
                  <th scope="col">Tên loại câu hỏi</th>
                  <th scope="col">Nhóm câu hỏi</th>
                  <th scope="col" style="text-align: center">Vị trí</th>
                  <th style="text-align: center">Thao tác</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of DataAddQuestion; let i = index">
                  <td>{{ p.Noi_dung_cau_hoi }}</td>
                  <td>{{ p.Ten_loai_cau_hoi }}</td>
                  <td>{{ p.Ten_nhom_cau_hoi }}</td>
                  <td style="text-align: center">
                    <button class="btn btn-sm" (click)="changePosition(-1, i)">
                      <i class="fa fa-arrow-up" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-sm" (click)="changePosition(1, i)">
                      <i class="fa fa-arrow-down" aria-hidden="true"></i>
                    </button>
                  </td>
                  <td align="center">
                    <a
                      class="btn btn-primary btn-sm"
                      (click)="modalannounce(AnnounceQuestion, p.ID_cau_hoi)"
                    >
                      <i class="fas fa-search" ngbTooltip="Xem chi tiết"></i>
                    </a>
                    <a
                      class="btn btn-sm btn-warning cus-ml"
                      (click)="DeleteQuestion(p)"
                    >
                      <i class="far fa-times-circle" ngbTooltip="Xóa"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- /.tab-content -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-success" (click)="SaveSurvey()">
      <i class="fas fa-save"></i> Lưu
    </button>
    <button
      type="button"
      class="btn btn-sm btn-danger"
      (click)="modal.close('Save click')"
    >
      <i class="far fa-times-circle"></i> Đóng
    </button>
  </div>
</ng-template>
<section class="content-header" style="padding: 5px !important">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Phiếu đánh giá &nbsp;</li>
          <div class="{{ TitleClass }}"></div>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="open(CreateSurveymodal, 'add', 'add')"
          style="float: right"
        >
          <i class="fas fa-plus-circle"></i> Tạo phiếu đánh giá
        </button>
        <button
          class="btn btn-warning btn-sm"
          (click)="help()"
          type="submit"
          style="float: right; margin-right: 5px"
        >
          <i class="fas fa-info-circle"></i>
          Hướng dẫn
        </button>
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <label>Tên phiếu:</label>
          <input
            class="form-control"
            placeholder="Nhập tên phiếu..."
            type="text"
            [(ngModel)]="TenPhieu"
            (keyup)="filterById()"
          />
        </div>
        <div class="col-md-4">
          <label>Loại đánh giá :</label>
          <select
            class="form-control"
            [(ngModel)]="LoaiDanhGia"
            (change)="filterById()"
          >
            <option value="-1">Tất cả</option>
            <option
              *ngFor="let p of lstLoaiDanhGia"
              [value]="p.ID_loai_danh_gia"
            >
              {{ p.Ten_loai_danh_gia }}
            </option>
            <option value="0">Chưa phân loại</option>
          </select>
        </div>
        <div class="col-md-4">
          <label>Tài khoản tạo :</label>
          <input
            class="form-control"
            placeholder="Nhập người tạo..."
            type="text"
            [(ngModel)]="NguoiTao"
            (keyup)="filterById()"
          />
        </div>
      </div>
    </div>
    <!-- /.card-header -->
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of ClassData">
                      <td>{{ p.ID_phieu_danh_gia }}</td>
                      <td>{{ p.Ma_phieu_danh_gia }}</td>
                      <td>{{ p.Ten_phieu }}</td>
                      <td>{{ p.Ten_loai_danh_gia }}</td>
                      <td>{{ p.Noi_dung_phieu }}</td>
                      <td>{{ p.Tai_khoan_tao }}</td>
                      <td style="white-space: nowrap">{{ p.Ngay_tao }}</td>
                      <td>{{ p.Tai_khoan_sua }}</td>
                      <td style="white-space: nowrap">{{ p.Ngay_sua }}</td>
                      <td>{{ p.Ghi_chu }}</td>
                      <td>
                        <button
                          class="btn btn-primary btn-sm"
                          (click)="viewSurvey(ViewSurveymodal, 'view', p)"
                        >
                          <i
                            class="fas fa-search"
                            ngbTooltip="Xem chi tiết"
                          ></i>
                        </button>
                        <button
                          class="btn btn-warning btn-sm cus-ml"
                          (click)="open(CreateSurveymodal, 'edit', p)"
                        >
                          <i
                            class="fas fa-edit"
                            style="color: white"
                            ngbTooltip="Sửa"
                          ></i>
                        </button>
                        <button
                          class="btn btn-danger btn-sm cus-ml"
                          (click)="Xoa(p.ID_phieu_danh_gia)"
                        >
                          <i class="far fa-trash-alt" ngbTooltip="Xóa"></i>
                        </button>
                      </td>
                      <td>{{ p.ID_loai_danh_gia }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card-body -->
  </div>
</section>

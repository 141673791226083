import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class DashboardService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  GetDashboard(token): Observable<any> {
    console.log(this.access_code);
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Dashboard/GetDashboard", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetDashboard_ThongKe(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Dashboard/GetDashboard_ThongKe",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetDashboard_BieuDo(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "Dashboard/GetDashboard_BieuDo",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}

import { Inject, Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';


@Injectable({
  providedIn: 'root'
})
export class PhieuDanhGiaService extends BaseService{

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  getPhieuDanhGia(token, ID_loai_danh_gia: any): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.SurveyURL + "/phieu-danh-gia/get-phieu-danh-gia?ID_loai_danh_gia="+ ID_loai_danh_gia,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getPhieuDanhGiaDaGui(token, ID_dot_danh_gia: any): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.SurveyURL + "/phieu-danh-gia/get-phieu-da-gui?ID_dot_danh_gia="+ ID_dot_danh_gia,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

import {
  Component,
  OnInit,
  AfterViewInit,
  Renderer2,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { common } from "src/app/app.common";
import { AppService } from "src/app/Utils/service/app.service";

@Component({
  selector: "app-menu-sidebar",
  templateUrl: "./menu-sidebar.component.html",
  styleUrls: ["./menu-sidebar.component.scss"],
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild("mainSidebar", { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();

  styleLi: string = "nav-item ";
  Ngan_hang_cau_hoi: boolean;
  Phieu_danh_gia: boolean;
  Quan_ly_khao_sat: boolean;
  Tra_cuu_phieu_phan_phoi: boolean;
  Bao_cao_tong_hop: boolean;
  Quan_ly_bao_cao: boolean;
  Quan_ly_doanh_nghiep: boolean;
  Phan_phoi_tin_chi: boolean;
  Khao_sat_giang_vien:boolean;
  Khao_sat_can_bo:boolean;
  Khao_sat_doanh_nghiep:boolean;
  Danh_gia_co_van_hoc_tap:boolean;
  Khao_sat_lop_hanh_chinh:boolean;
  Khao_sat_lop_nien_che:boolean;
  Loai_danh_gia: boolean;
  Nhom_cau_hoi: boolean;
  constructor(
    public appService: AppService,
    private router: Router,
    private toastr: ToastrService
  ) {}
  com: common;
  Roles: any;
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var UserData = this.com.getUserinfo();
    this.Roles = UserData.Roles;
    if (this.Roles) {
      if (this.Roles.length == 0) {
        this.toastr.warning(
          "Bạn chưa được cấp bất kỳ quyền nào! Vui lòng liên hệ với quản trị viên để được hỗ trợ"
        );
      } else {
        this.CreateMenu(this.Roles);
        
      }
    }
  }
  changeStyle($event) {
    this.styleLi =
      $event.type == "mouseover" ? "nav-item  menu-open" : "nav-item ";
  }

  menu(id: string) {
    let element, name, arr;
    element = document.getElementById(id);
    arr = element.className.split(" ");
    name = "menu-open";
    if (arr.indexOf(name) == -1) {
      element.className += " " + name;
    } else {
      element.className = "nav-item";
    }
  }
  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }

  CreateMenu(dt) {
    this.Ngan_hang_cau_hoi = dt.some((x) => x.ID_quyen == 1201);
    this.Phieu_danh_gia = dt.some((x) => x.ID_quyen == 1202);
    this.Quan_ly_khao_sat = dt.some((x) => x.ID_quyen == 1203);
    this.Tra_cuu_phieu_phan_phoi = dt.some((x) => x.ID_quyen == 1204);
    this.Bao_cao_tong_hop = dt.some((x) => x.ID_quyen == 1205);
    this.Quan_ly_bao_cao = dt.some((x) => x.ID_quyen == 1206);
    this.Quan_ly_doanh_nghiep = dt.some((x) => x.ID_quyen == 1207);
    this.Loai_danh_gia = dt.some((x) => x.ID_quyen == 1209);
    this.Nhom_cau_hoi = dt.some((x) => x.ID_quyen == 1208);
    this.Phan_phoi_tin_chi = dt.some((x) => x.ID_quyen == 1218);
    this.Khao_sat_giang_vien = dt.some((x) => x.ID_quyen == 1219);
    this.Khao_sat_can_bo = dt.some((x) => x.ID_quyen == 1220);
    this.Khao_sat_doanh_nghiep = dt.some((x) => x.ID_quyen == 1221);
    this.Danh_gia_co_van_hoc_tap= dt.some((x) => x.ID_quyen == 1222);
    this.Khao_sat_lop_hanh_chinh= dt.some((x) => x.ID_quyen == 1223);
    this.Khao_sat_lop_nien_che= dt.some((x) => x.ID_quyen == 1224);
  }
}

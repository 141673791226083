import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DanhGiaCoVanHocTapService } from "src/app/Utils/service/danh-gia-co-van-hoc-tap.service";
import { LoaiDanhGiaService } from "src/app/Utils/service/loai-danh-gia.service";
import { PhieuDanhGiaService } from "src/app/Utils/service/phieu-danh-gia.service";
import { common } from "src/app/app.common";
import { SeasonService } from "src/app/Utils/service/season.service";
import { PhanPhoiLopHanhChinhService } from "src/app/Utils/service/phan-phoi-lop-hanh-chinh.service";
import { KhoaHocService } from "src/app/Utils/service/khoa-hoc.service";
import { LopHocService } from "src/app/Utils/service/lop-hoc.service";
import { ChuyenNganhService } from "src/app/Utils/service/chuyen-nganh.service";
import { SearchObjectService } from "src/app/Utils/service/search-object.service";

@Component({
  selector: "app-phan-phoi-phieu-lop-hanh-chinh",
  templateUrl: "./phan-phoi-phieu-lop-hanh-chinh.component.html",
  styleUrls: ["./phan-phoi-phieu-lop-hanh-chinh.component.scss"],
})
export class PhanPhoiPhieuLopHanhChinhComponent implements OnInit {
  Fillter = new FormGroup({
    ID_dot_danh_gia: new FormControl(0),
    ID_phieu_danh_gia: new FormControl(0),
    ID_loai_danh_gia: new FormControl(-1),
    ID_he: new FormControl(0),
    ID_khoa: new FormControl(0),
    Khoa_hoc: new FormControl(0),
    ID_chuyen_nganh: new FormControl(0),
    ID_lop: new FormControl(0),
    Da_tot_nghiep: new FormControl(0),
    Da_ra_truong: new FormControl(false),
  });
  public com: common;
  Token: any;
  UserInfo: any;
  loaiDanhGia: any;
  dotDanhGia: any;
  phieuDanhGiaFilter: any;
  phieuDanhGia: any;
  ListHe: any;
  closeResult: string;
  ChonCount = 0;
  ListKhoa = [];
  ListKhoaHoc = [];
  ListKhoaHocBase = [];
  listLopHanhChinh: any;
  ListChuyenNganh = [];
  listLopRoadmap = [];
  listSinhVienDanhGia: any;

  constructor(
    public router: Router,
    private loaiDanhGiaSv: LoaiDanhGiaService,
    private phieuDanhGiaSv: PhieuDanhGiaService,
    public spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private SeasonService: SeasonService,
    private KhoaHocSV: KhoaHocService,
    private phanPhoiLopHanhChinhSV: PhanPhoiLopHanhChinhService,
    private ChuyenNganhSV: ChuyenNganhService,
    private lopHocService: LopHocService,
    private searchObjectService: SearchObjectService
  ) {}

  async ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var saveData = this.com.getUserinfo();
    this.UserInfo = saveData.Info.UserName;
    this.Token = saveData.access_token;

    this.getLopRoadMap();
    await this.getDotDanhGia();
    this.getLoaiDanhGia();
    this.getPhieuDanhGia();
    this.getHe();
    this.getKhoa();
    this.getKhoaHoc();
    this.getChuyenNganh();
    this.getLopHanhChinh();
  }

  getDotDanhGia(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.SeasonService.getListSeason(this.Token).subscribe(
        (res) => {
          this.dotDanhGia = res.Data;
          this.Fillter.patchValue({
            ID_dot_danh_gia:
              this.dotDanhGia.length > 0
                ? this.dotDanhGia[0].ID_dot_danh_gia
                : 0,
          });

          resolve(); // Gọi resolve() khi hàm hoàn thành
        },
        (error) => {
          reject(error); // Gọi reject() nếu có lỗi xảy ra
        }
      );
    });
  }

  getLopRoadMap(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.lopHocService.getRoadmap(this.Token).subscribe(
        (res) => {
          this.listLopRoadmap = res.ListData;
          resolve(); // Gọi resolve() khi hàm hoàn thành
        },
        (error) => {
          reject(error); // Gọi reject() nếu có lỗi xảy ra
        }
      );
    });
  }

  getLoaiDanhGia() {
    this.loaiDanhGiaSv.getAll(this.Token).subscribe((res) => {
      if (res.Status == 1) {
        this.loaiDanhGia = res.ListData;
      }
    });
  }

  getPhieuDanhGia() {
    this.phieuDanhGiaSv
      .getPhieuDanhGia(this.Token, this.Fillter.value.ID_loai_danh_gia)
      .subscribe((res) => {
        this.phieuDanhGia = res.ListData;
        this.phieuDanhGiaFilter = res.ListData;
      });
  }

  filterPhieuDanhGia() {
    this.phieuDanhGiaFilter = this.phieuDanhGia.filter((obj) => {
      return (
        obj.ID_loai_danh_gia == this.Fillter.value.ID_loai_danh_gia ||
        this.Fillter.value.ID_loai_danh_gia == -1
      );
    });
  }

  getHe() {
    this.SeasonService.GetHe(this.Token).subscribe((z) => {
      this.ListHe = z.Data;
    });
  }

  getKhoa() {
    this.SeasonService.GetKhoa(this.Token).subscribe((z) => {
      this.ListKhoa = z.Data.filter((x) => x.ID_he == this.Fillter.value.ID_he);
    });

    this.Fillter.patchValue({
      ID_lop: 0,
      ID_khoa: 0,
      Khoa_hoc: 0,
      ID_chuyen_nganh: 0,
    });
    this.getChuyenNganh();
  }

  getKhoaHoc() {
    this.KhoaHocSV.getKhoaHoc(this.Token).subscribe((z) => {
      this.ListKhoaHoc = z.ListData;
      this.ListKhoaHocBase = z.ListData;
    });

    this.Fillter.patchValue({
      ID_lop: 0,
      Khoa_hoc: 0,
      ID_chuyen_nganh: 0,
    });
  }

  fillterKhoaHoc() {
    this.ListKhoaHoc = this.getUniqueCourses(
      this.listLopRoadmap,
      this.Fillter.value.ID_he,
      this.Fillter.value.ID_khoa
    );
  }

  getUniqueCourses(objects: any[], id_he: number, id_khoa: number): any[] {
    const filteredObjects = objects.filter(
      (obj) =>
        (obj.ID_he == id_he || id_he == 0) &&
        (obj.ID_khoa == id_khoa || id_khoa == 0)
    );
    const courses = filteredObjects.map((obj) => obj.Khoa_hoc);
    const uniqueCourses = Array.from(new Set(courses)).sort((a, b) => a - b);
    const result = uniqueCourses.map((course) => ({ Khoa_hoc: course }));
    return result;
  }

  getChuyenNganh() {
    this.ChuyenNganhSV.getChuyenNganh(this.Token).subscribe((z) => {
      this.ListChuyenNganh = z.ListData.filter(
        (x) =>
          x.ID_he == this.Fillter.value.ID_he &&
          x.ID_khoa == this.Fillter.value.ID_khoa
      );
    });
    this.Fillter.patchValue({
      ID_chuyen_nganh: 0,
      ID_lop: 0,
    });
    this.fillterKhoaHoc();
  }

  getLopHanhChinh() {
    this.spinner.show();
    this.phanPhoiLopHanhChinhSV
      .getPhanPhoiLopHanhChinh(this.Token, this.Fillter.value)
      .subscribe((res) => {
        this.listLopHanhChinh = res.Data;
        this.spinner.hide();

        this.ChonCount = 0;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  checkOne(index) {
    this.listLopHanhChinh[index].Chon = !this.listLopHanhChinh[index].Chon;
    this.ChonCount = this.listLopHanhChinh.filter((obj) => {
      return obj.Chon == true;
    }).length;
  }

  checkAll() {
    var value = false;
    var ChonCount = this.listLopHanhChinh.filter((obj) => {
      return obj.Chon == true;
    }).length;

    if (ChonCount < this.listLopHanhChinh.length) {
      value = true;
    }
    for (let index = 0; index < this.listLopHanhChinh.length; index++) {
      this.listLopHanhChinh[index].Chon = value;
    }
    this.ChonCount = this.listLopHanhChinh.filter((obj) => {
      return obj.Chon == true;
    }).length;
  }

  open(content, lopDanhGia) {
    if (lopDanhGia.lstSinhVien.length === 0) {
      this.toastr.warning("Không có dữ liệu");
    } else {
      this.listSinhVienDanhGia = {
        ID_dot_danh_gia: lopDanhGia.ID_dot_danh_gia,
        Ten_dot: "",
        Ngay_bat_dau: new Date(lopDanhGia.Ngay_bat_dau).toLocaleDateString("vi-VN"),
        Ngay_ket_thuc: new Date(lopDanhGia.Ngay_ket_thuc).toLocaleDateString("vi-VN"),
        lstSinhVien: lopDanhGia.lstSinhVien,
      };
  
      for (let i = 0; i < this.dotDanhGia.length; i++) {
        if (this.dotDanhGia[i].ID_dot_danh_gia === lopDanhGia.ID_dot_danh_gia) {
          this.listSinhVienDanhGia.Ten_dot = this.dotDanhGia[i].Ten_dot;
          break;
        }
      }
  
      this.modalService
        .open(content, {
          ariaLabelledBy: "modal-basic-title",
          size: "xl",
          scrollable: true,
        })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }
  

  guiPhieuDanhGia() {
    if (
      this.Fillter.value.ID_phieu_danh_gia == 0 ||
      this.Fillter.value.ID_dot_danh_gia == 0 ||
      this.ChonCount == 0
    ) {
      this.toastr.warning(
        "Vui lòng chọn đợt đánh giá, phiếu đánh giá và lớp cần gửi phiếu"
      );
      return;
    }

    var data = {
      ds_lop: [],
      ID_phieu_danh_gia: this.Fillter.value.ID_phieu_danh_gia,
      ID_dot_danh_gia: this.Fillter.value.ID_dot_danh_gia,
      Da_tot_nghiep: this.Fillter.value.Da_tot_nghiep,
    };
    for (let i = 0; i < this.listLopHanhChinh.length; i++) {
      if (this.listLopHanhChinh[i].Chon == true) {
        data.ds_lop.push(this.listLopHanhChinh[i].ID_lop);
      }
    }
    var r = confirm("Bạn có muốn gửi phiếu đến danh sách lớp đã chọn không?");
    if (!r) {
      return;
    }
    this.spinner.show();
    this.phanPhoiLopHanhChinhSV
      .guiPhieuDanhGia(this.Token, data)
      .subscribe((z) => {
        this.spinner.hide();
        this.toastr.success(z.Message);
        this.getLopHanhChinh();
        this.ChonCount = 0;
      });
  }
  daRaTruongChange() {
    if (this.Fillter.value.Da_ra_truong) {
      this.Fillter.get("Da_tot_nghiep").setValue(true);
      this.Fillter.get("Da_tot_nghiep").disable();
    } else {
      this.Fillter.get("Da_tot_nghiep").enable();
    }
    this.getLopHanhChinh();
  }

  deleteMulti(data: any) {
    if(data == undefined || data == null || data.length == 0){
      this.toastr.warning("Không có dữ liệu");
      return;
    }
    else{
      let listID: number[] = data.map(item => item.ID_gui);
      var r = confirm("Bạn có muốn xóa phiếu đã được gửi đến danh sách lớp đã chọn không")
      if (!r) {
        return;
      }
      this.spinner.show();
      this.searchObjectService.DeleteMulti(listID, this.Token).subscribe((z) => {
        this.spinner.hide();
        this.toastr.success(z.Message);
        this.getLopHanhChinh();
      });
    }
  }
}

<section class="content-header" style="padding:5px !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Ngân hàng câu hỏi</li>
                    <div class="{{TitleClass}}"></div>
                </ol>
            </div>
            <div class="col-md-6">
                <button class="btn btn-success btn-sm" (click)="open(mymodal)"
                    style="float: right;"><i class="fas fa-plus-circle"></i> Tạo câu hỏi</button>
                    <button class="btn btn-warning btn-sm" (click)=help()  type="submit" style="float: right;margin-right: 5px; ">
                        <i class="fas fa-info-circle"></i>
                        Hướng dẫn
                    </button>
            </div>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm">
                    <label class="col-md-10">Mã câu hỏi:</label>
                    <input class="form-control" placeholder="Nhập mã câu hỏi..." type="text"
                        [(ngModel)]="MaCauHoi" (keyup)="filterById()">
                </div>
                <div class="col-sm">
                    <label class="col-md-10">Nội dung câu hỏi:</label>
                    <input class="form-control" placeholder="Nhập nội dung câu hỏi..." type="text"
                        [(ngModel)]="NoiDung" (keyup)="filterById()">
                </div>
                <div class="col-sm">
                    <label class="col-md-10">Loại câu hỏi:</label>
                    <select  class="form-control"(change)="filterById()" [(ngModel)]="LoaiCauHoiFT">
                        <option value="0">Tất cả</option>
                        <option value="Trắc nghiệm">Trắc nghiệm</option>
                        <option value="Hộp kiểm">Hộp kiểm</option>
                        <option value="Menu thả">Menu thả </option>
                        <option value="Lưới trắc nghiệm">Lưới trắc nghiệm </option>
                        <option value="Lưới hộp kiểm">Lưới hộp kiểm</option>
                        <option value="Văn bản">Văn bản </option>
                    </select>
                </div>
                <div class="col-sm">
                    <label class="col-md-10">Nhóm câu hỏi:</label>
                    <select  class="form-control" (change)="filterById()" [(ngModel)]="NhomCauHoiFT">
                        <option value="-1">Tất cả</option>
                        <option *ngFor="let p of lstCauHoiNhom" [value]="p.ID_nhom_cau_hoi">{{p.Ten_nhom_cau_hoi}}</option>
                        <option value="0">Chưa phân loại</option>
                    </select>
                </div>
                <div class="col-sm">
                    <label class="col-md-10">Tài khoản tạo</label>
                    <input class="form-control" placeholder="Nhập tài khoản tạo..." type="text"
                        [(ngModel)]="CreateUserName" (keyup)="filterById()">
                </div>
            </div>
        </div><!-- /.card-header -->
    </div>
</section>
<section class="content cus-body">

    <div class="card">

        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class=" table-bordered dataTables_wrapper no-footer ">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of TableData;let i=index;">
                                            <td>{{ p.QuestionInfo.ID_cau_hoi }}</td>
                                            <td>{{ p.QuestionInfo.Ma_cau_hoi }}</td>
                                            <td>{{ p.QuestionInfo.Ten_loai_cau_hoi }}</td>
                                            <td>{{ p.QuestionInfo.Ten_nhom_cau_hoi }}</td>
                                            <td>{{ p.QuestionInfo.Noi_dung_cau_hoi }}</td>
                                            <td>{{ p.QuestionInfo.Tai_khoan_tao }}</td>
                                            
                                            <!-- <td>{{ p.QuestionInfo.Ngay_tao }}</td> -->
                                            <td>{{p.QuestionInfo.Ngay_tao | date: 'dd/MM/yyyy'}}</td>
                                            <td>
                                                <button class="btn btn-sm btn-primary" (click)="openViewModal(ViewModal,p.QuestionInfo.ID_cau_hoi)" style="margin-right: 4px;" ><i class="fas fa-eye" title="Xem câu hỏi"></i></button> 
                                               <button class="btn btn-sm btn-warning" (click)="openEdit(EditModal,p.QuestionInfo.ID_cau_hoi)" ><i class="fas fa-edit" title="Sửa câu hỏi"></i></button> 
                                                
                                              <button class="btn btn-sm btn-danger"  style="margin-left: 4px;" (click)= "QuestionDelete(p.QuestionInfo.ID_cau_hoi)"><i class="fas fa-trash-alt" title="Xóa câu hỏi"></i></button>  
                                            </td>
                                            
                                            <td>{{ p.QuestionInfo.ID_nhom_cau_hoi }}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Tạo câu hỏi</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Mã câu hỏi</label>
                    <input type="text"  class="form-control" id="MaCauHoi" />
                </div>
                <div class="col-sm" >
                    <label class="control-label" for="inputSuccess">Loại câu hỏi</label>
                    <select  class="form-control" id="LoaiCauHoi" (change)="LoaiCauHoiChange()">
                        <option value="1" selected>Trắc nghiệm</option>
                        <option value="2">Hộp kiểm</option>
                        <option value="3">Menu thả </option>
                        <option value="4">Lưới trắc nghiệm </option>
                        <option value="5">Lưới hộp kiểm</option>
                        <option value="6">Văn bản </option>
                    </select>
                </div>
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Nhóm câu hỏi</label>
                    <select  class="form-control" id="NhomCauHoi" onChange="">
                        <option *ngFor="let p of lstCauHoiNhom" [value]="p.ID_nhom_cau_hoi">{{p.Ten_nhom_cau_hoi}}</option>
                        <option value="0">Chưa phân loại</option>
                    </select>
                </div>
                <div class="col-sm" *ngIf="LoaiCauHoiCurrent==5">
                    <label class="control-label" for="inputSuccess">Số phương án tối đa</label>
                    <input type="number"  class="form-control" id="PhuongAnToiDa" />
                </div>
                <div class="col-sm">
                    <label class="control-label">Bắt buộc</label>
                    <br />
                    <input id="BatBuoc"  type="checkbox" style="margin-left:3px; transform:scale(1.5);">
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="NoiDung">Nội dung câu hỏi</label>
                    <br />
                    <textarea id="NoiDung" name="NoiDung"
                        style="width:100%;padding:12px 20px;box-sizing:border-box;border:2px solid #ccc;border-radius:4px;resize:none;font-size:14px"></textarea>

                </div>
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Ghi chú</label>
                    <br />
                    <textarea id="GhiChu" name="GhiChu"
                        style="width:100%;padding:12px 20px;box-sizing:border-box;border:2px solid #ccc;border-radius:4px;resize:none;font-size:14px"></textarea>

                </div>

            </div>
            <div class="row" id="PhuongAn">
                <div class="col-sm" id="Hang">
                    <label class="control-label">Phương án</label>
                    <div class="container">
                        <div class="row">
                            <div class="input-group mb-3">
                                <input type="text" id="HangInput" (keyup.enter)="PhuongAnAdd()" class="form-control"
                                    placeholder="Nhập nội dung phương án..." aria-label="Recipient's username"
                                    aria-describedby="basic-addon2">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="PhuongAnAdd()"
                                        type="button">Thêm</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <table id="table_hang" class="ui selectable table">
                                <thead style="background-color:#3c8dbc;color:#fff">
                                    <th>Danh sách phương án</th>
                                    <th><button style="float: right;" (click)="PhuongAnRemoveAll()" class="btn btn-sm btn-warning">Xóa tất cả</button></th>
                                </thead>
                                <tbody id="PhuongAn_hang" *ngFor="let p of lstHangADD;let i=index;">
                                    <tr>
                                        <td>
                                            {{ p.Noi_dung_phuong_an }}
                                        </td>
                                        <td >
                                            <i (click)="PhuongAnRemove(i)" style="float: right;color:salmon;" class="far fa-window-close"> </i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="col-sm" id="Muc" style="visibility:hidden">
                    <label class="control-label">Mức</label>
                    <div class="container">
                        <div class="row">
                            <div class="input-group mb-3">
                                <input type="text" id="MucInput" (keyup.enter)="MucAdd()" class="form-control" placeholder="Nhập mức..."
                                    aria-label="Recipient's username" aria-describedby="basic-addon2">
                                <div class="input-group-append">
                                    <button  class="btn btn-outline-secondary" (click)="MucAdd()" type="button">Thêm</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <table id="table_hang" class="ui selectable table">
                                <thead style="background-color:#3c8dbc;color:#fff">
                                    <th>Danh sách mức</th>
                                    <th><button style="float: right;" (click)="MucRemoveAll()"  class="btn btn-sm btn-warning">Xóa tất cả</button></th>
                                </thead>
                                <tbody  *ngFor="let p of lstMucADD;let i=index;">
                                    <tr>
                                        <td>
                                            {{ p.Noi_dung_phuong_an }}
                                        </td>
                                        <td >
                                            <i (click)="MucRemove(i)" style="float: right;color:salmon;" class="far fa-window-close"> </i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-primary" (click)="QuestionAdd()">Lưu</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Đóng</button> -->

        <button type="button" class="btn btn-sm btn-success" (click)="QuestionAdd()"><i class="fas fa-save"></i>
            Lưu</button>
        <button type="button" class="btn btn-sm btn-danger" (click)="modal.close('Save click')"> <i
                class="far fa-times-circle"></i> Đóng</button>
    </div>
</ng-template>
<ng-template #EditModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Sửa câu hỏi</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Mã câu hỏi</label>
                    <input type="text"  class="form-control" id="MaCauHoiEdit" />
                </div>
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Loại câu hỏi</label>
                    <select  class="form-control" id="LoaiCauHoiEdit" (change)="LoaiCauHoiEditChange()">
                        <option value="1">Trắc nghiệm</option>
                        <option value="2">Hộp kiểm</option>
                        <option value="3">Menu thả </option>
                        <option value="4">Lưới trắc nghiệm </option>
                        <option value="5">Lưới hộp kiểm</option>
                        <option value="6">Văn bản </option>
                    </select>
                </div>
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Nhóm câu hỏi</label>
                    <select  class="form-control" id="NhomCauHoiEdit">
                        <option *ngFor="let p of lstCauHoiNhom" value="{{p.ID_nhom_cau_hoi}}">{{p.Ten_nhom_cau_hoi}}</option>
                        <option value="0">Chưa phân loại</option>
                    </select>
                </div>
                <div class="col-sm" *ngIf="LoaiCauHoiCurrent==5">
                    <label class="control-label" for="inputSuccess">Số phương án tối đa</label>
                    <input type="number"  class="form-control" id="PhuongAnToiDaEdit" />
                </div>
                <div class="col-sm">
                    <label class="control-label">Bắt buộc</label>
                    <br />
                    <input id="BatBuocEdit"  type="checkbox" style="margin-left:3px; transform:scale(1.5);">
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="NoiDung">Nội dung câu hỏi</label>
                    <br />
                    <textarea id="NoiDungEdit" name="NoiDung"
                        style="width:100%;padding:12px 20px;box-sizing:border-box;border:2px solid #ccc;border-radius:4px;resize:none;font-size:14px"></textarea>

                </div>
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Ghi chú</label>
                    <br />
                    <textarea id="GhiChuEdit" name="GhiChuEdit"
                        style="width:100%;padding:12px 20px;box-sizing:border-box;border:2px solid #ccc;border-radius:4px;resize:none;font-size:14px"></textarea>

                </div>

            </div>
            <div class="row" id="PhuongAnEdit">
                <div class="col-sm" id="HangEdit">
                    <label class="control-label">Phương án</label>
                    <div class="container">
                        <div class="row">
                            <div class="input-group mb-3">
                                <input type="text" id="HangEditInput" (keyup.enter)="PhuongAnEditAdd()" class="form-control"
                                    placeholder="Nhập nội dung phương án..." aria-label="Recipient's username"
                                    aria-describedby="basic-addon2">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="PhuongAnEditAdd()"
                                        type="button">Thêm</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <table id="table_hang" class="ui selectable table">
                                <thead style="background-color:#3c8dbc;color:#fff">
                                    <th>Danh sách phương án</th>
                                    <th style="width: 20%;"><button style="float: right;" (click)="PhuongAnEditRemoveAll()" class="btn btn-sm btn-warning">Xóa tất cả</button></th>
                                </thead>
                                <tbody id="PhuongAn_hang" *ngFor="let p of lstHangEditADD;let i=index;">
                                    <tr>
                                        <td>
                                            {{ p.Noi_dung_phuong_an }}
                                        </td>
                                        <td >
                                            <i (click)="PhuongAnEditRemove(i)" style="float: right;color:salmon;" class="far fa-window-close"> </i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="col-sm" id="MucEdit" style="visibility:hidden">
                    <label class="control-label">Mức</label>
                    <div class="container">
                        <div class="row">
                            <div class="input-group mb-3">
                                <input type="text" id="MucEditInput" (keyup.enter)="MucEditAdd()" class="form-control" placeholder="Nhập mức..."
                                    aria-label="Recipient's username" aria-describedby="basic-addon2">
                                <div class="input-group-append">
                                    <button  class="btn btn-outline-secondary" (click)="MucEditAdd()" type="button">Thêm</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <table id="table_hang" class="ui selectable table">
                                <thead style="background-color:#3c8dbc;color:#fff">
                                    <th>Danh sách mức</th>
                                    <th style="width: 20%;"><button style="float: right;" (click)="MucEditRemoveAll()"  class="btn btn-sm btn-warning">Xóa tất cả</button></th>
                                </thead>
                                <tbody  *ngFor="let p of lstMucEditADD;let i=index;">
                                    <tr>
                                        <td>
                                            {{ p.Noi_dung_phuong_an }}
                                        </td>
                                        <td >
                                            <i (click)="MucEditRemove(i)" style="float: right;color:salmon;" class="far fa-window-close"> </i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-primary" (click)="QuestionEdit()">Lưu</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Đóng</button> -->
        <button type="button" class="btn btn-sm btn-success" (click)="QuestionEdit()"><i class="fas fa-save"></i>
            Lưu</button>
        <button type="button" class="btn btn-sm btn-danger" (click)="modal.close('Save click')"> <i
                class="far fa-times-circle"></i> Đóng</button>
    </div>
</ng-template>
<ng-template #ViewModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Xem câu hỏi</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="NoiDung">Nội dung câu hỏi</label>
                     <p class="form-control" id="V_NoiDung"></p>
                </div>
            </div>
            <div class="row" >
                <div class="col-sm" >
                    <label class="control-label">Phương án</label>
                    <div class="container" id="View_Question">
                      
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Ghi chú</label>
                    <textarea class="form-control" readonly id="V_GhiChu"></textarea>
                </div>
                
            </div>
            <div class="row">
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Loại câu hỏi</label>
                    <p class="form-control" id="V_LoaiCauHoi"></p>
                </div>
                <div class="col-sm">
                    <label class="control-label" for="inputSuccess">Nhóm câu hỏi</label>
                    <p class="form-control" id="V_NhomCauHoi"></p>
                </div>
                <div class="col-sm">
                    <label class="control-label">Bắt buộc</label>
                    <p class="form-control" id="V_BatBuoc"></p>
                </div>
                
            </div>
            <div class="row">
                <div class="col-sm">
                    <label class="control-label">Tài khoản tạo</label>
                    <p class="form-control" id="V_TaiKhoanTao"></p>
                </div>
                <div class="col-sm">
                    <label class="control-label">Ngày tạo</label>
                    <p class="form-control" id="V_NgayTao"></p>
                </div>
                <div class="col-sm">
                    <label class="control-label">Tài khoản sửa</label>
                    <p class="form-control" id="V_TaiKhoanSua"></p>
                </div>
                <div class="col-sm">
                    <label class="control-label">Ngày sửa</label>
                    <p class="form-control" id="V_NgaySua"></p>
                </div>

            </div>
            
            
        </div>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Đóng</button> -->
        <button type="button" class="btn btn-sm btn-danger" (click)="modal.close('Save click')"> <i
            class="far fa-times-circle"></i> Đóng</button>
    </div>
</ng-template>


<section class="content-header" style="padding:5px !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> Trang chủ</a></li>
                    <li class="breadcrumb-item active">Quản lý báo cáo &nbsp;</li>
                </ol>
            </div>

        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content cus-body">

    <div class="card">

        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" >
                    <div class="row">
                        <div class="col-md-12">
                            <div class="{{TitleClass}}"></div>
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions"
                                    class=" table-bordered dataTables_wrapper no-footer ">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ListReport">
                                            <td>{{ p.Ten_Bao_Cao }}</td>
                                            <td>
                                                <ng-container *ngIf="p.Loai_Phieu==4">
                                                    <label >Khảo sát giảng viên</label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Loai_Phieu==3">
                                                    <label>Khảo sát doanh nghiệp</label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Loai_Phieu==2">
                                                    <label >Khảo sát sinh viên</label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Loai_Phieu==1">
                                                    <label >Thống kê giảng viên</label>
                                                </ng-container>
                                            </td>
                                            <td >
                                                <ng-container *ngIf="p.Is_Change==1">
                                                    <label class="switch"><input id="toggeleswith" (click)="replaceSurvey(p)"  type="checkbox" checked><span class="slider round"></span></label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Is_Change==0">
                                                    <label class="switch"><input id="toggeleswith" (click)="replaceSurvey(p)" type="checkbox" ><span class="slider round"></span></label>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- /.card-body -->
    </div>
</section>

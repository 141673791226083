import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from 'src/app/Utils/service/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { common } from 'src/app/app.common';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { MessageService } from 'src/app/Utils/service/message.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnDestroy, OnInit {
  dtOptions: DataTables.Settings
  Res: any
  SendColumnTitle: string;
  ClassData: any
  requestObject: RequestBase = new RequestBase()
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  TieuDe: string;
  Title: string;
  TitleClass: string = "spinner-border text-muted"
  constructor(
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    private MessageService: MessageService
  ) {
    // 

  }
  dtTrigger = new Subject();
  public com: common;
  ngOnInit() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let TieuDedt = data[5];
      let bool1 = true
      let fillter = true;
      if (this.TieuDe != undefined) {
        bool1 = TieuDedt.includes(this.TieuDe)
      }
      fillter = bool1
      return fillter;
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    //var rs=this.Class.ClassListAll(a.Info.UserName, a.access_token)
    this.requestObject.UserName = a.Info.UserName
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
        {
          visible: false
        },
        {
          title: 'Tiêu đề',
        },
        {
          title: 'Nội dung',
          visible: false
        },
        {
        },
        {
          title: 'Đã nhận',
        },
        {
          title: 'Trạng thái',
        },
        {
          title: 'Tác vụ',
        },
      ],
    };
    this.getListInboxfirst(this.requestObject, a.access_token)
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }
  getListInboxfirst(req: RequestBase, token) {
    this.Title = "Đang tải tin nhắn đến"
    this.TitleClass="spinner-border text-mute"
    this.MessageService.ListInboxAll(req, token)
      .subscribe(z => {
        this.Res = z
        this.ClassData = this.Res.Data
        this.dtTrigger.next();
        this.Title = "Danh sách tin nhắn đến"
        this.SendColumnTitle = "Người gửi"
        this.TitleClass = ""
      });
  }
  getListInbox() {
    this.Title = "Đang tải tin nhắn đến"
    this.TitleClass="spinner-border text-mute"
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.ClassData = []
      var User = this.com.getUserinfo();
      this.MessageService.ListInboxAll(this.requestObject, User.Token)
        .subscribe(z => {
          this.Res = z
          this.ClassData = this.Res.Data
          this.Title = "Danh sách tin nhắn đến"
          this.TitleClass = ""
          this.SendColumnTitle = "Người gửi"
          this.dtTrigger.next();
        });

    });
  }
  getListSend() {
    this.Title = "Đang tải tin nhắn đã gửi"
    this.TitleClass="spinner-border text-mute"
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.ClassData = []
      var User = this.com.getUserinfo();
      this.MessageService.ListSendAll(this.requestObject, User.Token)
        .subscribe(z => {
          this.Res = z
          this.ClassData = this.Res.Data
          this.SendColumnTitle = "Người nhận"
          this.Title = "Danh sách tin nhắn đã gửi"
          this.TitleClass = ""
          this.dtTrigger.next();
        });

    });
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
}

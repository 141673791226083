import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { common } from "src/app/app.common";
import { Router } from "@angular/router";
import { AppConfig, AppConfiguration } from "src/configuration";
import { AppService } from "src/app/Utils/service/app.service";

@Component({
  selector: "app-header-portal",
  templateUrl: "./header-portal.component.html",
  styleUrls: ["./header-portal.component.scss"],
})
export class HeaderPortalComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
  public searchForm: FormGroup;
  com: common;
  UserName: string = "Đang lấy dữ liệu...";
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration, 
    private appService: AppService, public router: Router) {}

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLoginPortal();
    const a = this.com.getPortalInfo();
    this.UserName = a.Info.FullName;
  }

  logout() {
    var r = confirm("Bạn có muốn đăng xuất không?");
    if (r) {
      if (
        this.appConfig.AuthorizeType == 0 ||
        (this.appConfig.sso == null && this.appConfig.AuthorizeType == null)
      ) {
        localStorage.removeItem("PortalInfo");
        this.router.navigate(["/login-portal"]);
      }

      if (this.appConfig.AuthorizeType == 2) {
        localStorage.removeItem("PortalInfo");
        window.location.href = this.appConfig.CAS.Logout;
      }
    }
  }
}

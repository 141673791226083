<aside #mainSidebar class="main-sidebar sidebar-light-primary elevation-4 cus-text">
    <!-- Brand Logo -->
    <!-- <a [routerLink]="['/']" class="brand-link">
      <img
        src="assets/img/AdminLTELogo.png"
        alt="AdminLTE Logo"
        class="brand-image img-circle elevation-3"
        style="opacity: .8"
      />
      <span class="brand-text font-weight-light">AdminLTE 3</span>
    </a> -->
    <a [routerLink]="['/']" class="brand-link">
      <img src="assets/img/UniTeacherLogo.png" alt="UniSurvey" class="brand-image img-circle elevation-3"
        style="opacity: .8">
      <span class="brand-text font-weight-light">UniSurvey</span>
    </a>
  
    <!-- Sidebar -->
    <div class="sidebar">
      <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
            src="assets/img/user2-160x160.jpg"
            class="img-circle elevation-2"
            alt="User Image"
          />
        </div>
        <div class="info">
          <a [routerLink]="['/profile']" class="d-block">Alexander Pierce</a>
        </div>
      </div> -->
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item">
            <!-- <a [routerLink]="['/portal/Student']" class="nav-link">
              <i class="fas fa-file-signature"></i>
              <p style="margin-left: 5px;"> Đánh giá khảo sát</p>
            </a> -->
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class CauHoiNhomService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  getAll(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "/nhom-cau-hoi/get-all", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  insert(token, m): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "/nhom-cau-hoi/insert", m, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  update(token, m): Observable<any> {
    return this.http
      .put<any>(this.appConfig.SurveyURL + "/nhom-cau-hoi/update", m, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  delete(token, ID_nhom_cau_hoi): Observable<any> {
    return this.http
      .delete<any>(this.appConfig.SurveyURL + "/nhom-cau-hoi/delete?ID_nhom_cau_hoi="+ID_nhom_cau_hoi, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}

import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
@Injectable({
  providedIn: "root",
})
export class SsoService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {}

  authorizationCode(code): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UtilsUrl + "/SSO/AuthorizationCode?code=" + code,
        {}
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  refreshToken(refresh_token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UtilsUrl +
          "/SSO/RefreshToken?refresh_token=" +
          refresh_token,
        {}
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  RedirectSSO() {
    window.location.href =
      this.appConfig.sso.ssoServer +
      "connect/authorize" +
      "?client_id=" +
      this.appConfig.sso.client_id +
      "&redirect_uri=" +
      this.appConfig.sso.redirect_uri +
      "&response_type=" +
      this.appConfig.sso.response_type +
      "&scope=" +
      this.appConfig.sso.scope +
      "&code_challenge_method=" +
      this.appConfig.sso.code_challenge_method +
      "&response_mode=" +
      this.appConfig.sso.response_mode;
  }
}

import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class EnterpriseService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  getListEnterprise(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "Enterprise/GetAll", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  InsertEnterprise(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Enterprise/Add", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DeleteEnterPrise(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Enterprise/Delete", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  UpdateEnterprise(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Enterprise/Edit", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Export(req: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Enterprise/Export", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Import(req: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "Enterprise/Import", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}

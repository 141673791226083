import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class CoreService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }
  GetLopHoc(Hoc_ky, Nam_hoc, ID_cb, ID_mon, ID_khoa, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.SurveyURL +
          "Common/GetLopHoc?Hoc_ky=" +
          Hoc_ky +
          "&Nam_hoc=" +
          Nam_hoc +
          "&ID_cb=" +
          ID_cb +
          "&ID_mon=" +
          ID_mon +
          "&ID_khoa=" +
          ID_khoa,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetMonHoc(Hoc_ky, Nam_hoc, ID_cb, ID_lop, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.SurveyURL +
          "Common/GetMonHoc?Hoc_ky=" +
          Hoc_ky +
          "&Nam_hoc=" +
          Nam_hoc +
          "&ID_cb=" +
          ID_cb +
          "&ID_lop=" +
          ID_lop,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetThamSoQuyChe(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.UtilsUrl + "Core/GetThamSoQuyChe", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetHe(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/He_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetKhoa(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/Khoa_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetKhoaHoc(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/KhoaHoc_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetNganh(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Nganh/Danh_sach_nganh", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetChuyenNganh(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/ChuyenNganh_Load_List", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}

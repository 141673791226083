import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class SearchObjectService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  SearchObject(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "SurveySend/SearchObject", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  // SearchObject_Student_Teacher(Ma_sv,Ma_cb,Da_danh_gia,ID_phieu,ID_dot,token): Observable<any> {
  //   return this.http.get<any>(this.appConfig.SurveyURL + 'SurveySend/Search_Student_Teacher?Ma_sv='+ Ma_sv + "&Ma_cb="+ Ma_cb + "&Da_danh_gia="+ Da_danh_gia + "&ID_phieu="+ ID_phieu + "&ID_dot="+ ID_dot, {
  //     headers: new HttpHeaders()
  //               .set('Authorization', `Bearer ${token}`)
  //.set('Access_code',this.access_code)
  //   }).pipe(map(z => { return z }))
  // }

  getObject_Student_Teacher_List(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "SurveySend/Search_Student_Teacher",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getTraCuuCanBo(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "SurveySend/SearchUsers",
        req,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getCount(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "SurveySend/Count", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getCountDotPhieu(token: any, req: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "SurveySend/CountDotPhieu", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getCountCanBo(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "SurveySend/CountUser", {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getCountFilter(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "SurveySend/CountFilter", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getCountFilterCanBo(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "SurveySend/CountFilterUser", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  Excel(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "SurveySend/Excel", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  ExcelUser(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "SurveySend/ExcelUser", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Delete(ID, token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.SurveyURL + "SurveySend/Delete?ID=" + ID, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DeleteMulti(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.SurveyURL + "SurveySend/DeleteMulti", req, {
        headers: new HttpHeaders()
          .set("Authorization", `Bearer ${token}`)
          .set("Access_code", this.access_code),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}

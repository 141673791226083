<section class="content-header" style="padding: 0.5% !important">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Quản lý khảo sát</li>
          <div class="{{ TitleClass }}"></div>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-success btn-sm"
          (click)="open(InsertModal, 'xl', 'Them', '')"
          style="float: right"
        >
          <i class="fas fa-plus-circle"></i> Thêm đợt đánh giá
        </button>
        <button
          class="btn btn-warning btn-sm"
          (click)="help()"
          type="submit"
          style="float: right; margin-right: 5px"
        >
          <i class="fas fa-info-circle"></i>
          Hướng dẫn
        </button>
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content cus-body">
  <div class="card">
    <form class="form-horizontal" [formGroup]="Fillter">
      <div class="card-header p-2" style="align-content: flex-end">
        <div class="row" style="padding-left: 10px; padding-right: 10px">
          <div class="col-md-4">
            <label class="col-md-10">Mã đợt:</label>
            <input
              class="form-control"
              type="text"
              id="MaDot"
              formControlName="MaDot"
              (keyup)="filterById()"
              [(ngModel)]="MaDot"
              placeholder="Nhập mã đợt.."
            />
          </div>
          <div class="col-md-4">
            <label class="col-md-10">Tên đợt:</label>
            <input
              class="form-control"
              type="text"
              id="TenDot"
              formControlName="TenDot"
              (keyup)="filterById()"
              [(ngModel)]="TenDot"
              placeholder="Nhập tên đợt.."
            />
          </div>
          <div class="col-md-4">
            <label class="col-md-10">Tài khoản tạo:</label>
            <input
              class="form-control"
              type="text"
              id="NguoiTao"
              formControlName="NguoiTao"
              (keyup)="filterById()"
              [(ngModel)]="NguoiTao"
              placeholder="Nhập tài khoản tạo.."
            />
          </div>
        </div>
        <hr />
      </div>
      <!-- /.card-header -->
    </form>
    <!-- <div class="card-body cus-body">
            <div class="card"> -->

    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
              >
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of ClassData">
                      <td>{{ p.ID_dot_danh_gia }}</td>
                      <td>{{ p.Ten_dot }}</td>
                      <td>{{ p.Hoc_ky }}</td>
                      <td>{{ p.Nam_hoc }}</td>
                      <td>{{ p.Ngay_bat_dau | date:'dd/MM/yyyy HH:mm' }}</td>
                      <td>{{ p.Ngay_ket_thuc | date:'dd/MM/yyyy HH:mm' }}</td>
                      <td>{{ p.Ngay_gia_han | date:'dd/MM/yyyy HH:mm' }}</td>
                      <!-- <td>{{ !p.Ngay_gia_han?'':p.Ngay_gia_han|date:'dd/MM/yyyy' }}</td> -->
                      <td>{{ p.Tai_khoan_tao }}</td>
                      <td>{{ p.Ngay_tao | date:'dd/MM/yyyy' }}</td>
                      <td>{{ p.Tai_khoan_sua }}</td>
                      <td>{{ p.Ngay_sua }}</td>
                      <td>{{ p.Ghi_chu }}</td>
                      <td style="white-space: nowrap">
                        <!--&nbsp;&nbsp; -->
                        <button
                          class="btn btn-sm btn-warning"
                          (click)="open(EditModal, 'xl', 'Sua', p)"
                          style="margin-right: 7px"
                          title="Sửa đợt đánh giá"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-danger"
                          (click)="Delete(p)"
                          style="margin-right: 7px"
                          title="Xoá đợt đánh giá"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.col -->
          </div>
        </div>
      </div>
      <!-- /.tab-content -->
    </div>
    <!-- /.card-body -->
  </div>
</section>
<!-- /.content -->
<ng-template #InsertModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100">Thêm đợt đánh giá</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="SeasonFormInsert">
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Tên đợt:</label>
          <input
            class="form-control"
            type="text"
            id="Ten_dot_insert"
            formControlName="Ten_dot_insert"
            placeholder="Nhập tên đợt.."
          />
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Ngày bắt đầu:</label>
          <input
            class="form-control"
            type="datetime-local"
            id="Ngay_bat_dau_insert"
            formControlName="Ngay_bat_dau_insert"
            placeholder="Chọn ngày bắt đầu"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Học kỳ:</label>
          <select class="form-control" id="Hoc_ky_insert">
            <option value="1">Học kỳ 1</option>
            <option value="2">Học kỳ 2</option>
            <option value="3">Học kỳ 3</option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Ngày kết thúc:</label>
          <input
            class="form-control"
            type="datetime-local"
            id="Ngay_ket_thuc_insert"
            formControlName="Ngay_ket_thuc_insert"
            placeholder="Nhập mã đợt.."
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Năm học:</label>
          <select class="form-control" id="Nam_hoc_insert">
            <option *ngFor="let p of ListNamHoc" [value]="p">{{ p }}</option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Ghi chú:</label>
          <input
            class="form-control"
            type="text"
            id="Ghi_chu_insert"
            formControlName="Ghi_chu_insert"
            placeholder="Nhập ghi chú.."
          />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-between">
    <button
      type="button"
      class="btn btn-default"
      (click)="modal.dismiss('Cross click')"
    >
      Đóng
    </button>
    <button type="button" class="btn btn-primary" (click)="Insert()">
      Lưu
    </button>
  </div>
</ng-template>
<ng-template #EditModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100">Sửa đợt đánh giá</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="SeasonForm">
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Tên đợt:</label>
          <input
            class="form-control"
            type="text"
            id="Ten_dot_edit"
            formControlName="Ten_dot_edit"
            placeholder="Nhập tên đợt.."
          />
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Ngày bắt đầu:</label>
          <input
            class="form-control"
            type="datetime-local"
            id="Ngay_bat_dau_edit"
            formControlName="Ngay_bat_dau_edit"
            placeholder="Chọn ngày bắt đầu"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Ngày kết thúc:</label>
          <input
            class="form-control"
            type="datetime-local"
            id="Ngay_ket_thuc_edit"
            formControlName="Ngay_ket_thuc_edit"
            placeholder="Nhập mã đợt.."
          />
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Ngày gia hạn:</label>
          <input
            class="form-control"
            type="datetime-local"
            formControlName="Ngay_gia_han"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Học kỳ:</label>
          <select
            class="form-control"
            id="Hoc_ky_edit"
            formControlName="Hoc_ky_edit"
          >
            <option value="1">Học kỳ 1</option>
            <option value="2">Học kỳ 2</option>
            <option value="3">Học kỳ 3</option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Năm học:</label>
          <select
            class="form-control"
            id="Nam_hoc_edit"
            formControlName="Nam_hoc_edit"
          >
            <option *ngFor="let p of ListNamHoc" [value]="p">{{ p }}</option>
          </select>
          <!-- <input class="form-control" type="text" id="Nam_hoc_edit" formControlName="Nam_hoc_edit"
                        placeholder="Nhập năm học.."> -->
        </div>
        <div class="col-md-12">
          <label class="col-md-10">Ghi chú:</label>
          <input
            class="form-control"
            type="text"
            id="Ghi_chu_edit"
            formControlName="Ghi_chu_edit"
            placeholder="Nhập ghi chú.."
          />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-between">
    <button
      type="button"
      class="btn btn-default"
      (click)="modal.dismiss('Cross click')"
    >
      Đóng
    </button>
    <button type="button" class="btn btn-primary" (click)="Update()">
      Lưu
    </button>
  </div>
</ng-template>

<ng-template #SendModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100">Gửi đợt đánh giá</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="SeasonForm">
      <div class="row">
        <div class="col-md-3">
          <label class="col-md-10">Hệ:</label>
          <select
            class="form-control"
            formControlName="ID_he"
            (change)="HeChange()"
          >
            <option value="">Chọn hệ</option>
            <option *ngFor="let p of ListHe" [value]="p.ID_he">
              {{ p.Ten_he }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Khoa:</label>
          <select
            class="form-control"
            formControlName="ID_khoa"
            (change)="KhoaChange()"
          >
            <option value="">Chọn Khoa</option>
            <option *ngFor="let p of ListKhoa" [value]="p.ID_khoa">
              {{ p.Ten_khoa }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Khoá học:</label>
          <select
            class="form-control"
            formControlName="Khoa_hoc"
            (change)="KhoahocChange()"
          >
            <option value="">Chọn Khóa học</option>
            <option *ngFor="let p of ListKhoaHoc" [value]="p.Khoa_hoc">
              {{ p.Khoa_hoc }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Chuyên ngành:</label>
          <select class="form-control" formControlName="ID_chuyen_nganh">
            <option value="">Chọn chuyên ngành</option>
            <option
              *ngFor="let p of ListChuyenNganh"
              [value]="p.ID_chuyen_nganh"
            >
              {{ p.Chuyen_nganh }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label class="col-md-10">Loại đánh giá:</label>
          <!-- <select
            class="form-control"
            id="LoaiPhieuDanhGia"
            (change)="FillPhieu()"
          >
            <option value="1">Đánh giá giảng viên</option>
            <option value="2">Đánh giá cơ sở vật chất</option>
            <option value="3">Khảo sát</option>
          </select> -->
          <select
            (change)="FillPhieu()"
            id="LoaiPhieuDanhGia"
            class="form-control cus-fs13"
          >
            <option value="-1" selected>Tất cả</option>
            <option
              *ngFor="let p of lstLoaiDanhGia"
              [value]="p.ID_loai_danh_gia"
            >
              {{ p.Ten_loai_danh_gia }}
            </option>
            <option value="0">Chưa phân loại</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Đối tượng đánh giá:</label>
          <select
            class="form-control"
            id="DoiTuongDanhGia"
            onchange=""
            (change)="ChangeDTDG()"
          >
            <option value="0" selected>Vui lòng chọn</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Phiếu đánh giá:</label>
          <select class="form-control" id="PhieuDanhGia"></select>
        </div>
        <div
          class="form-check col-md-3"
          id="DaTotNghiepDiv"
          style="margin-top: 30px; display: none; text-align: center"
        >
          <input type="checkbox" class="form-check-input" id="DaTotNghiep" />
          <label class="form-check-label" for="exampleCheck1"
            >Đã tốt nghiệp</label
          >
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="isButton"
      (click)="open(DSMonHoc, 'lg', 'getMH', '')"
    >
      Gửi phiếu theo môn học
    </button>
    <button type="button" class="btn btn-primary" (click)="Send()">
      Gửi phiếu
    </button>
    <button
      type="button"
      class="btn btn-default"
      (click)="modal.dismiss('Cross click')"
    >
      Đóng
    </button>
  </div>
</ng-template>

<ng-template #SendCoVanModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100">Gửi đánh giá cố vấn học tập</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="SeasonFormCoVan">
      <div class="row">
        <div class="col-md-3">
          <label class="col-md-10">Hệ:</label>
          <select
            class="form-control"
            formControlName="ID_he"
            (change)="HeChangeCV()"
          >
            <option value="">Chọn hệ</option>
            <option *ngFor="let p of ListHe" [value]="p.ID_he">
              {{ p.Ten_he }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Khoa:</label>
          <select
            class="form-control"
            formControlName="ID_khoa"
            (change)="KhoaChangeCV()"
          >
            <option value="">Chọn Khoa</option>
            <option *ngFor="let p of ListKhoa" [value]="p.ID_khoa">
              {{ p.Ten_khoa }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Khoá học:</label>
          <select
            class="form-control"
            formControlName="Khoa_hoc"
            (change)="KhoahocChangeCV()"
          >
            <option value="">Chọn Khóa học</option>
            <option *ngFor="let p of ListKhoaHoc" [value]="p.Khoa_hoc">
              {{ p.Khoa_hoc }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Chuyên ngành:</label>
          <select class="form-control" formControlName="ID_chuyen_nganh">
            <option value="">Chọn chuyên ngành</option>
            <option
              *ngFor="let p of ListChuyenNganh"
              [value]="p.ID_chuyen_nganh"
            >
              {{ p.Chuyen_nganh }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label class="col-md-10">Loại phiếu đánh giá:</label>
          <!-- <select
            class="form-control"
            id="LoaiPhieuDanhGia"
            (change)="FillPhieu()"
          >
            <option value="1">Đánh giá giảng viên</option>
            <option value="2">Đánh giá cơ sở vật chất</option>
            <option value="3">Khảo sát</option>
          </select> -->

          <select
          (change)="FillPhieu()"
          id="LoaiPhieuDanhGia"
          class="form-control cus-fs13"
        >
          <option value="-1">Tất cả</option>
          <option
            *ngFor="let p of lstLoaiDanhGia"
            [value]="p.ID_loai_danh_gia"
          >
            {{ p.Ten_loai_danh_gia }}
          </option>
          <option value="0">Chưa phân loại</option>
        </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Đối tượng đánh giá:</label>
          <select
            class="form-control"
            id="DoiTuongDanhGia"
            onchange=""
            (change)="ChangeDTDG()"
          >
            <option value="0" selected>Vui lòng chọn</option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Phiếu đánh giá:</label>
          <select class="form-control" id="PhieuDanhGia"></select>
        </div>
        <div
          class="form-check col-md-3"
          id="DaTotNghiepDiv"
          style="margin-top: 30px; display: none; text-align: center"
        >
          <input type="checkbox" class="form-check-input" id="DaTotNghiep" />
          <label class="form-check-label" for="exampleCheck1"
            >Đã tốt nghiệp</label
          >
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="SendCoVanHocTap()">
      Gửi phiếu
    </button>
    <button
      type="button"
      class="btn btn-default"
      (click)="modal.dismiss('Cross click')"
    >
      Đóng
    </button>
  </div>
</ng-template>

<ng-template #SendUserModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100">Gửi đánh giá cán bộ</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <ngb-tabset>
      <ngb-tab title="Chọn phiếu đánh giá (Đã chọn: {{ PhieuChonCount }})">
        <ng-template ngbTabContent>
          <div class="card-body">
            <div class="tab-content">
              <div class="row">
                <div class="col-md-12">
                  <form [formGroup]="dataFilterForm" style="width: 100%">
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label class="control-label" for="inputSuccess"
                          ><i class="fas fa-poll-h"></i>&nbsp; Mã phiếu đánh
                          giá:
                        </label>
                        <input
                          (change)="getPhieuFiler()"
                          formControlName="Ma_phieu_danh_gia"
                          class="form-control cus-fs13"
                          placeholder="Nhập mã phiếu đánh giá"
                          type="text"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label class="control-label" for="inputSuccess"
                          ><i class="fas fa-poll-h"></i>&nbsp; Tên phiếu đánh
                          giá:
                        </label>
                        <input
                          (change)="getPhieuFiler()"
                          formControlName="Ten_phieu_danh_gia"
                          class="form-control cus-fs13"
                          placeholder="Nhập tên phiếu đánh giá"
                          type="text"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label class="control-label" for="inputSuccess"
                          ><i class="fas fa-sort-amount-down"></i>&nbsp; Loại
                          đánh giá:</label
                        >
                        <select
                          (change)="getPhieuFiler()"
                          formControlName="ID_loai_danh_gia"
                          class="form-control cus-fs13"
                        >
                          <option value="-1">Tất cả</option>
                          <option
                            *ngFor="let p of lstLoaiDanhGia"
                            [value]="p.ID_loai_danh_gia"
                          >
                            {{ p.Ten_loai_danh_gia }}
                          </option>
                          <option value="0">Chưa phân loại</option>
                        </select>
                      </div>
                      <div class="form-group col-md-3">
                        <label class="control-label" for="inputSuccess"
                          ><i class="fa fa-user" aria-hidden="true"></i>&nbsp;
                          Người tạo :
                        </label>
                        <input
                          formControlName="Tai_khoan_tao"
                          (change)="getPhieuFiler()"
                          class="form-control cus-fs13"
                          placeholder="Nhập người tạo..."
                          type="text"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="margin-bottom: 5px">
                  <hr />
                  <button
                    class="btn btn-primary btn-sm"
                    (click)="PhieuUserCheckAll()"
                  >
                    Chọn tất cả
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="height: 50vh; overflow: scroll">
                  <table
                    class="table table-bordered dataTables_wrapper no-footer"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Chọn</th>
                        <th scope="col">Mã phiếu đánh giá</th>
                        <th scope="col">Tên phiếu đánh giá</th>
                        <th scope="col">Nội dung phiếu</th>
                        <th scope="col">Loại đánh giá</th>
                        <th scope="col">Tài khoản tạo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let p of lstPhieuUserFilter; let i = index">
                        <td>
                          <input
                            type="checkbox"
                            [(checked)]="p.Chon"
                            (change)="PhieuUserChange(i)"
                          />
                        </td>
                        <td>{{ p.Ma_phieu_danh_gia }}</td>
                        <td>{{ p.Ten_phieu }}</td>
                        <td>{{ p.Noi_dung_phieu }}</td>
                        <td>{{ p.Ten_loai_danh_gia }}</td>
                        <td>{{ p.Tai_khoan_tao }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Chọn cán bộ nhận phiếu (Đã chọn: {{ UserChonCount }})">
        <ng-template ngbTabContent>
          <div class="card-body">
            <div class="tab-content">
              <div class="row">
                <div class="col-md-12">
                  <form [formGroup]="dataFilterCanBoForm" style="width: 100%">
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label class="control-label" for="inputSuccess"
                          ><i class="fa fa-user" aria-hidden="true"></i>&nbsp;
                          Họ tên:
                        </label>
                        <input
                          (change)="getUserFilter()"
                          formControlName="Ho_ten"
                          class="form-control cus-fs13"
                          placeholder="Nhập họ tên cán bộ..."
                          type="text"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label class="control-label" for="inputSuccess"
                          ><i class="fas fa-poll-h"></i>&nbsp; Mã cán bộ:
                        </label>
                        <input
                          formControlName="Ma_can_bo"
                          (change)="getUserFilter()"
                          class="form-control cus-fs13"
                          placeholder="Nhập mã cán bộ..."
                          type="text"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label class="control-label" for="inputSuccess"
                          ><i class="fas fa-sort-amount-down"></i>&nbsp; Phòng
                          ban:</label
                        >
                        <select
                          (change)="getUserFilter()"
                          formControlName="ID_phong_ban"
                          class="form-control cus-fs13"
                        >
                          <option value="0">Tất cả</option>
                          <option
                            *ngFor="let p of lstPhongBan"
                            [value]="p.ID_phong > 0 ? p.ID_phong : p.ID_Phong"
                          >
                            {{ p.Phong }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-3">
                        <label class="control-label" for="inputSuccess"
                          ><i class="fas fa-sort-amount-down"></i>&nbsp;
                          Khoa:</label
                        >
                        <select
                          (change)="getUserFilter()"
                          formControlName="ID_khoa"
                          class="form-control cus-fs13"
                        >
                          <option value="0">Tất cả</option>
                          <option
                            *ngFor="let p of lstKhoas"
                            [value]="p.ID_khoa"
                          >
                            {{ p.Ten_khoa }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="margin-bottom: 5px">
                  <hr />
                  <button
                    class="btn btn-primary btn-sm"
                    (click)="UserCheckAll()"
                  >
                    Chọn tất cả
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="height: 50vh; overflow: scroll">
                  <table
                    class="table table-bordered dataTables_wrapper no-footer"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Chọn</th>
                        <th scope="col">Mã cán bộ</th>
                        <th scope="col">Họ tên</th>
                        <th scope="col">Phòng ban</th>
                        <th scope="col">Khoa</th>
                        <th scope="col">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let p of lstUserFilter; let i = index">
                        <td>
                          <input
                            type="checkbox"
                            [(checked)]="p.Chon"
                            (change)="UserCheckChange(i)"
                          />
                        </td>
                        <td>{{ p.UserName }}</td>
                        <td>{{ p.FullName }}</td>
                        <td>{{ p.Phong }}</td>
                        <td>{{ p.Ten_khoa }}</td>
                        <td>{{ p.Email }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="guiDanhGiaCanBo()">
      Gửi phiếu
    </button>
    <button
      type="button"
      class="btn btn-default"
      (click)="modal.dismiss('Cross click')"
    >
      Đóng
    </button>
  </div>
</ng-template>
<ng-template #SendDNModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100">Gửi đánh giá doanh nghiệp</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-4">
        <label class="col-md-10">Loại phiếu đánh giá:</label>
        <select (change)="FillPhieuDoanhNghiep()" class="form-control" id="LoaiPhieuDanhGia_DN">
          <option value="-1">Tất cả</option>
          <option *ngFor="let p of lstLoaiDanhGia" [value]="p.ID_loai_danh_gia">
            {{ p.Ten_loai_danh_gia }}
          </option>
          <option value="0">Chưa phân loại</option>
        </select>
      </div>
      <div class="col-md-4">
        <label class="col-md-10">Phiếu đánh giá:</label>
        <select class="form-control" id="PhieuDanhGia_DN"></select>
      </div>
      <div class="col-md-4">
        <label class="col-md-10">&nbsp;</label>
        <button
          class="btn btn-block btn-primary btn-sm"
          (click)="open(mymodal, 'lg', '', '')"
          style="margin-right: 7px"
        >
          <i class="fas fa-share-square"></i> Thêm doanh nghiệp
        </button>
      </div>
    </div>
    <div class="row" style="margin-top: 25px">
      <label style="font-size: 15px">Gửi đến : </label>
      <ul id="ToUser_DN" *ngFor="let p of SelectDN" style="padding-left: 5px">
        <span class="btn btn-block btn-default btn-xs">
          <label> {{ p.Ten_doanh_nghiep }} </label>&nbsp;
          <a (click)="Remove(p)"
            ><i class="far fa-window-close" style="color: red"></i
          ></a>
        </span>
      </ul>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="SendDN()">
      Gửi phiếu
    </button>
    <button
      type="button"
      class="btn btn-default"
      (click)="modal.dismiss('Cross click')"
    >
      Đóng
    </button>
  </div>
</ng-template>
<ng-template #DeleteModal let-modal>
  <div class="modal-body">
    <i
      class="fas fa-times-circle"
      style="font-size: 250px; text-align: center; color: #c82333"
    ></i>
    <p style="text-align: center; font-weight: bold">
      Bạn có muốn xoá đợt đánh giá này
    </p>
  </div>
  <div class="modal-footer justify-content-between">
    <button
      type="button"
      class="btn btn-default"
      (click)="modal.dismiss('Cross click')"
    >
      Đóng
    </button>
    <button type="button" class="btn btn-danger" (click)="Delete()">Xoá</button>
  </div>
</ng-template>
<ng-template #mymodal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100">Danh sách người nhận</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px"
      >&times;</span
    >
  </div>
  <div class="modal-body" style="height: 400px">
    <div style="max-height: 400px; overflow: scroll">
      <ul id="ToUser" *ngFor="let p of ListDN">
        <li>
          <div class="row">
            <div class="col-md-8" style="max-width: 500px">
              {{ p.Ten_doanh_nghiep }}
            </div>
            <div class="col-md-4">
              <button class="btn btn-primary" (click)="Select(p)" type="button">
                Chọn
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Đóng
    </button>
  </div>
</ng-template>
<ng-template #DSMonHoc let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100">Danh sách môn học</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px"
      >&times;</span
    >
  </div>
  <div class="modal-body" style="height: 600px">
    <div
      style="
        max-height: 100px;
        overflow: scroll;
        border-bottom: 1px solid black;
      "
    >
      <label style="font-size: 15px">Tên môn : </label>
      <ul>
        <li
          *ngFor="let p of ListMHChon"
          style="padding-left: 5px; float: left; list-style: none"
        >
          <span class="btn btn-block btn-default btn-xs">
            <label> {{ p.Ten_mon }} ({{ p.Ky_hieu }}) </label>&nbsp;
            <a (click)="RemoveMH(p)"
              ><i class="far fa-window-close" style="color: red"></i
            ></a>
          </span>
        </li>
      </ul>
    </div>
    <div class="row" style="margin-top: 10px">
      <label class="col-md-2">Tên môn:</label>
      <input
        type="text"
        class="form-control col-md-4"
        id="ten_mon_ft"
        (keyup)="ft_monhoc()"
      />
      <label class="col-md-2">Mã môn:</label>
      <input
        type="text"
        class="form-control col-md-4"
        id="ma_mon_ft"
        (keyup)="ft_monhoc()"
      />
    </div>
    <div style="max-height: 420px; overflow: scroll; margin-top: 20px">
      <ul *ngFor="let p of ListMH_Ft">
        <li>
          <div class="row">
            <div class="col-md-8" style="max-width: 500px">
              {{ p.Ten_mon }} ({{ p.Ky_hieu }})
            </div>
            <div class="col-md-4">
              <button
                class="btn btn-primary"
                (click)="SelectMH(p)"
                type="button"
              >
                Chọn
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="SendMH()">
      Gửi phiếu
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Đóng
    </button>
  </div>
</ng-template>
<ng-template #SendGVModal let-modal>
  <div
    class="modal-header m-header text-center"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <h4 class="modal-title h-title w-100">Danh sách giảng viên</h4>
    <span
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
      style="font-size: 25px"
      >&times;</span
    >
  </div>
  <div class="modal-body">
    <form [formGroup]="SeasonGvForm">
      <div class="row">
        <div class="col-md-6">
          <label class="col-md-10">Loại phiếu đánh giá:</label>
          <!-- <select
          class="form-control"
          id="LoaiPhieuDanhGia_GV"
          (change)="FillPhieuGiangVien()"
        >
          <option value="5">Khảo sát giảng viên</option>
        </select> -->

          <select
            (change)="FillPhieuGiangVien()"
            id="LoaiPhieuDanhGia_GV"
            class="form-control cus-fs13"
          >
            <option value="-1">Tất cả</option>
            <option
              *ngFor="let p of lstLoaiDanhGia"
              [value]="p.ID_loai_danh_gia"
            >
              {{ p.Ten_loai_danh_gia }}
            </option>
            <option value="0">Chưa phân loại</option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="col-md-10">Phiếu đánh giá:</label>
          <select class="form-control" id="PhieuDanhGia_GV"></select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label class="col-md-10">Mã giảng viên:</label>
          <input
            type="text"
            class="form-control"
            id="Ma_GV_ft"
            (keyup)="filterGV()"
          />
        </div>
        <div class="col-md-4">
          <label class="col-md-10">Tên giảng viên:</label>
          <input
            type="text"
            class="form-control"
            id="Ten_GV_ft"
            (keyup)="filterGV()"
          />
        </div>
        <div class="col-md-4">
          <label class="col-md-10">Bộ môn:</label>
          <input
            type="text"
            class="form-control"
            id="Mon_GV_ft"
            (keyup)="filterGV()"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label class="col-md-10">Hệ:</label>
          <select
            class="form-control"
            formControlName="ID_he"
            (change)="HeChangeGVft()"
          >
            <option value="0">Chọn hệ</option>
            <option *ngFor="let p of ListHe" [value]="p.ID_he">
              {{ p.Ten_he }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="col-md-10">Khoa:</label>
          <select
            class="form-control"
            formControlName="ID_khoa"
            (change)="filterGV()"
          >
            <option value="0">Chọn Khoa</option>
            <option *ngFor="let p of ListKhoa" [value]="p.ID_khoa">
              {{ p.Ten_khoa }}
            </option>
          </select>
        </div>
        <div
          class="col-md-3 mt-10"
          style="margin-top: 36px; text-align: center"
        >
          <input
            type="radio"
            class="form-check-input"
            (change)="filterGV()"
            id="co_huu_ft"
            name="loai-hop-dong"
          />
          <label>Cơ hữu</label>
        </div>
        <div
          class="col-md-3 form-check"
          style="margin-top: 36px; text-align: center"
        >
          <input
            class="form-check-input"
            (change)="filterGV()"
            id="thinh_giang_ft"
            type="radio"
            name="loai-hop-dong"
          />
          <label
            class="form-check-label"
            style="font-weight: bold"
            for="flexCheckChecked"
          >
            Thỉnh giảng
          </label>
        </div>
      </div>
    </form>
    <div class="row" style="font-family: sans-serif">
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane">
            <div class="row">
              <div class="col-md-6">
                Hiển thị
                <select (change)="handlePageSizeChange($event)">
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                kết quả
              </div>
            </div>
            <div
              class="table-responsive"
              style="max-height: 50vh; margin-bottom: 15px"
            >
              <table class="table table-bordered" id="chitietdt">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        id="SelectALL"
                        (change)="checkAllCheckBox($event)"
                        [checked]="isAllCheckBoxChecked()"
                        value="SelectAll"
                      />
                    </th>

                    <th>Mã giảng viên</th>
                    <th>Tên giảng viên</th>
                    <th>Giới tính</th>
                    <th>Bộ môn</th>
                    <th>Khoa</th>
                    <th>Đối tượng</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of ListGVFT; let i = index; let odd = odd">
                    <td>
                      <input
                        type="checkbox"
                        value="{{ p.ID_gv }}"
                        [checked]="p.checked"
                        [(ngModel)]="ListGVFT[i].checked"
                      />
                    </td>

                    <td>{{ p.Ma_gv }}</td>
                    <td>{{ p.Ten_gv }}</td>
                    <td>{{ p.Gioi_tinh }}</td>
                    <td>{{ p.Bo_mon }}</td>
                    <td>{{ p.Ten_khoa }}</td>
                    <td>
                      <span *ngIf="p.Co_huu"> Cơ hữu </span>
                      <span *ngIf="p.Thinh_giang"> Thỉnh giảng </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p>
                  <b>Tổng số bản ghi: {{ totalRecord }}</b>
                </p>
              </div>
              <div class="col-md-6">
                <nav aria-label="Page navigation example" style="float: right">
                  <ul class="pagination">
                    <li class="page-item" [class.disabled]="page == 1">
                      <a
                        style="font-size: 14px !important"
                        class="page-link"
                        (click)="changepage(1)"
                        >Đầu</a
                      >
                    </li>
                    <li class="page-item" [class.disabled]="page_sv == 1">
                      <a
                        style="font-size: 14px !important"
                        class="page-link"
                        (click)="changepage('pre')"
                        >Trước</a
                      >
                    </li>
                    <ng-container *ngIf="page_sv > 4">
                      <li class="page-item">
                        <a style="font-size: 14px !important" class="page-link"
                          >...</a
                        >
                      </li>
                    </ng-container>
                    <ng-container *ngFor="let element of arrNumberPage_child">
                      <li class="page-item" [class.active]="page == element">
                        <a
                          style="font-size: 14px !important"
                          class="page-link"
                          (click)="changepage(element)"
                          >{{ element }}</a
                        >
                      </li>
                    </ng-container>
                    <ng-container *ngIf="page < numberPage - 4">
                      <li class="page-item">
                        <a style="font-size: 14px !important" class="page-link"
                          >...</a
                        >
                      </li>
                    </ng-container>
                    <li class="page-item" [class.disabled]="page == numberPage">
                      <a
                        style="font-size: 14px !important"
                        class="page-link"
                        (click)="changepage('next')"
                        >Sau</a
                      >
                    </li>
                    <li class="page-item" [class.disabled]="page == numberPage">
                      <a
                        style="font-size: 14px !important"
                        class="page-link"
                        (click)="changepage(numberPage)"
                        >Cuối</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="SendGV()">
      Gửi phiếu
    </button>
    <button
      type="button"
      class="btn btn-default"
      (click)="modal.dismiss('Cross click')"
    >
      Đóng
    </button>
  </div>
</ng-template>
<ng-template #LoadModal let-modal>
  <div class="loadingio-spinner-cube-3p98jy7i84o">
    <div class="ldio-x95p7sxsl2i">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <p style="text-align: center">Đang gửi đánh giá</p>
  <style type="text/css">
    /* generated by https://loading.io/ */
  </style>
</ng-template>

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ResponseLogin } from 'src/app/Models/output.model/ResponseLogin';
import { AppComponent } from 'src/app/app.component';
import { common } from 'src/app/app.common';
import { Router } from '@angular/router';
import { RequestTeachingScheduleList } from 'src/app/Models/input.model/RequestTeachingScheduleList';
import { ToastrService } from 'ngx-toastr';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { DashboardService } from 'src/app/Utils/service/dashboard.service';
import { ResponseClassListAll, ClassInfo, StudentTruant } from 'src/app/Models/output.model/ResponseClassListAll';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as CanvasJS from './canvasjs.min.js';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dtOptions: DataTables.Settings
  Res: ResponseClassListAll = new ResponseClassListAll()
  ClassDataTruant: [];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  // HocKy: number;
  // NamHoc: string;
  // MonHoc: string;
  Title: string = "Đang tải danh sách lớp..."
  TitleClass: string = "spinner-border text-muted"
  public LoginResult: ResponseLogin;
  public com: common;
  eventsModel: any
  EventsData: any;
  Token: any;
  ClassData: any;
  DotThongKe: any;
  PhieuThongKe: any;
  PhieuBieuDo: any;
  DotBieuDo: any;
  QuestionsNumber: any;
  SurveysNumber: string;
  SendsNumber: string;
  AnsNumber: string;
  StudentsNumberOnline: string;
  requestObject: RequestTeachingScheduleList = new RequestTeachingScheduleList()
  request: RequestBase = new RequestBase()
  closeResult: string;
  constructor(
    public router: Router,
    private toastr: ToastrService,
    public cookieService: CookieService,
    private ClassService: ClassService,
    private DashboardServ: DashboardService,
    private modalService: NgbModal
  ) { }

  dtTrigger = new Subject();
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    let a = this.com.getUserinfo();
    this.Token = a.access_token;
    // this.request.UserName = "admin";
    this.request.UserName = a.Info.UserName;
    this.GetDashboard(this.Token)
    // this.getListClassOnline(this.request,this.Token)
    // this.getListStudentTruant(this.request,this.Token)
    this.QuestionsNumber = "..."
    this.SurveysNumber = "..."
    this.SendsNumber = "..."
    this.AnsNumber = "..."

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [


        {
          title: 'Khoa',
        },

        {
          title: 'Khóa',

        },
        {
          title: 'Số phiếu',
        },
        {
          title: 'Phản hồi',
        },

      ],
      searching: false
    };



  }
  renderChart(dtpGiangVien, dtpKhaoSat, tieude) {
    let chart = new CanvasJS.Chart("chartContainer", {
      animationEnabled: true,
      exportEnabled: true,
      title: {
        text: tieude,
        fontFamily: "arial",
        fontSize: 20,
      },
      axisY: {
        title: "Số lượng chọn",
        titleFontColor: "#4F81BC",
        lineColor: "#4F81BC",
        labelFontColor: "#4F81BC",
        tickColor: "#4F81BC"
      },
      axisY2: {
        title: "Số lượng chọn",
        titleFontColor: "#C0504E",
        lineColor: "#C0504E",
        labelFontColor: "#C0504E",
        tickColor: "#C0504E"
      },
      toolTip: {
        shared: true
      },
      legend: {
        cursor: "pointer",
      },
      data: [{
        type: "column",
        name: "Đánh giá giảng viên (lượt chọn)",
        legendText: "Đánh giá giảng viên",
        showInLegend: true,
        dataPoints: dtpGiangVien
        // [
        //   { label: "Hoàn toàn đồng ý", y: 266 },
        //   { label: "Đồng ý", y: 302 },
        //   { label: "Bình thường", y: 157 },
        //   { label: "Không đồng ý", y: 148 },
        //   { label: "Rất không đồng ý", y: 101 },
        // ]
      },
      {
        type: "column",
        name: "Số lượt đánh giá (lượt chọn)",
        legendText: "Số lượt đánh giá",
        axisYType: "secondary",
        showInLegend: true,
        dataPoints: dtpKhaoSat
        // [
        //   { label: "Hoàn toàn đồng ý", y: 250 },
        //   { label: "Đồng ý", y: 340 },
        //   { label: "Bình thường", y: 180 },
        //   { label: "Không đồng ý", y: 150 },
        //   { label: "Rất không đồng ý", y: 90},
        // ]
      }]
    });
    chart.render();
  }
  open(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  GetDashboard(token) {
    this.DashboardServ.GetDashboard(token)
      .subscribe(z => 
        {
        if (z.Status == 1) {
          this.ClassData = z.ThongKe
          this.QuestionsNumber = z.CauHoi
          this.SurveysNumber = z.Phieu
          this.AnsNumber = z.TraLoi
          this.SendsNumber = z.PhanPhoi

          this.TitleClass = ""
          this.dtTrigger.next()
          this.DotThongKe = z.Dot;
          this.DotBieuDo = z.Dot;
          this.PhieuThongKe = z.Dot[z.Dot.length - 1].Phieus
          this.PhieuBieuDo = z.Dot[z.Dot.length - 1].Phieus
          var dtpGiangVien = []
          z.BieuDo.GiangVien.forEach(element => {
            var dtp = {
              label: element.Stt_muc,
              y: element.SoLuong
            }
            dtpGiangVien.push(dtp)
          });
          var dtpKhaoSat = []
          z.BieuDo.KhaoSat.forEach(element => {
            var dtp = {
              label: element.Stt_muc,
              y: element.SoLuong
            }
            dtpKhaoSat.push(dtp)
          });
          this.renderChart(dtpGiangVien, dtpKhaoSat, z.Dot[0].Phieus[0].Ten_Phieu)
        }
      },
      error=>{
        if(error.status = 401)
        {
          this.toastr.error("Bạn không có quyển thực hiện chức năng này !")
        }else{
          this.toastr.error(error.Message)
        }
      })
      ;
  }
  DotThongKeChange() {
    var ID = $('#DotThongKe').val();
    var p = jQuery.grep(this.DotThongKe, function (pa: any, i) {
      return pa.ID_Dot == ID;
    });
    this.PhieuThongKe = p[0].Phieus
  }
  DotBieuDoChange() {
    var ID = $('#DotBieuDo').val();
    var p = jQuery.grep(this.DotBieuDo, function (pa: any, i) {
      return pa.ID_Dot == ID;
    });
    this.PhieuBieuDo = p[0].Phieus
  }
  ThongKe() {
    var IDDot = $('#DotThongKe').val();
    var IDPhieu = $('#PhieuThongKe').val();
    var req = {
      IDDot: IDDot,
      IDPhieu: IDPhieu
    }
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.GetDashboard_ThongKe(req,this.Token)
  }
  BieuDo() {
    var IDDot = $('#DotBieuDo').val();
    var IDPhieu = $('#PhieuBieuDo').val();
    
    var req = {
      IDDot: IDDot,
      IDPhieu: IDPhieu
    }
    this.GetDashboard_BieuDo(req,this.Token,$("#PhieuBieuDo option:selected").html())
  }
  GetDashboard_ThongKe(req,token) {
    this.DashboardServ.GetDashboard_ThongKe(req,token)
      .subscribe(z => {
        if (z.Status == 1) {
          this.ClassData = z.ThongKe
          this.TitleClass = ""
          this.dtTrigger.next()
        }
      })
      ;
  }
  GetDashboard_BieuDo(req,token,TieuDe) {
    this.DashboardServ.GetDashboard_BieuDo(req,token)
      .subscribe(z => {
        error=>{
          if(error.status = 401)
          {
            this.toastr.error("Bạn không có quyển thực hiện chức năng này !")
          }else{
            this.toastr.error(error.Message)
          }
        }
        if (z.Status == 1) {
          this.TitleClass = ""
          var dtpGiangVien = []
          z.BieuDo.GiangVien.forEach(element => {
            var dtp = {
              label: element.Stt_muc,
              y: element.SoLuong
            }
            dtpGiangVien.push(dtp)
          });
          var dtpKhaoSat = []
          z.BieuDo.KhaoSat.forEach(element => {
            var dtp = {
              label: element.Stt_muc,
              y: element.SoLuong
            }
            dtpKhaoSat.push(dtp)
          });
          this.renderChart(dtpGiangVien, dtpKhaoSat, TieuDe)
        }
      })
      ;
  }
}

<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row ">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['/']">Trang chủ</a></li>
                    <li class="breadcrumb-item active">Tin nhắn</li>
                </ol>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>

<section class="content cus-body" >
    <div class="container-fluid">
    <div class="row">
        <div class="col-md-4">
            <div class="card">
                <div class="card-body p-0">
                    <ul class="nav nav-pills flex-column">
                        <a [routerLink]="['/create']" class="btn btn-block btn-default btn-sm"><i class="fas fa-envelope-open-text"></i> &nbsp; Soạn thư</a>
                    </ul>
                </div>
                <!-- /.card-body -->
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-body p-0">
                    <ul class="nav nav-pills flex-column">
                        <li class="nav-item active" (click)="getListInbox()">
                            <a class="btn btn-block btn-default btn-sm">
                                <i class="fa fa-inbox"></i> Tin nhắn đến
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- /.card-body -->
            </div>

        </div> 
        <div class="col-md-4">
            <div class="card">
                <div class="card-body p-0">
                    <ul class="nav nav-pills flex-column">
                        <li class="nav-item" (click)="getListSend()">
                            <a class="btn btn-block btn-default btn-sm">
                                <i class="fa fa-envelope"></i> Tin đã gửi
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- /.card-body -->
            </div>

        </div>
    </div>
    <div class="row">

        <!-- /.col -->
        <div class="col-md-12">
            <div class="card">
                <div class="card-header" >
                </div>
                <!-- /.card-header -->
                <div class="card-body p-0" >
                    <div class="table-responsive mailbox-messages" style="padding: 4px;">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                            <thead>
                              <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>{{SendColumnTitle}}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let p of ClassData">
                                    <td>{{ p.ID }}</td>
                                    <td>{{ p.Title }}</td>
                                    <td>{{ p.Content }}</td>
                                    <td>{{ p.FromUser }}</td>
                                    <td>{{ p.SendDate }}</td>
                                    <td>{{ p.Viewed ?"Đã xem": "Chưa xem" }}</td>
                                    <td><a [routerLink]="['/read']" [queryParams]="{ID: p.ID}" queryParamsHandling="merge">
                                        <i class="fas fa-eye" title="Xem chi tiết" ></i>
                                    </a></td>
                                </tr>
                            </tbody>
                                
                          </table>
                    </div>
                    <!-- /.mail-box-messages -->
                </div>
                <!-- /.card-body -->
            </div>
            <!-- /. box -->
        </div>
        <!-- /.col -->
    </div>
    <!-- /.row -->
    </div>
</section>
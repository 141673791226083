import { Component } from '@angular/core';
import { ResponseLogin } from 'src/app/models/output.model/ResponseLogin';
import { CookieService } from 'ngx-cookie-service';
import * as XLSX from 'xlsx';  

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  public LoginResult: ResponseLogin;

  title = 'UniTeacher';
  constructor(
    public cookieService: CookieService,
  ) { }
  
}


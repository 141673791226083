import { ResponseBase } from './ResponseBase';

export class ResponseListSchedule extends ResponseBase{
    Data: ClassData[];
}
export class ClassData {
    Nam_hoc: string;
    Hoc_ky: string;
    Den_ngay: string;
    Tu_ngay: string;
    Phong_hoc: string;
    UserName: string;
    Giao_vien: string;
    Den_tiet: string;
    Tu_tiet: string;
    Ca_hoc: string;
    Thu: string;
    Ten_lop: string;
    Loai_lop: string;
    ID_lop: string;
    Ten_mon: string;
    Ky_hieu: string;
    ID_lich_day: string;
    Tu_gio: string;
    Den_gio: string;
}
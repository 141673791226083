<div class="wrappe cus-fs" >
    <app-header-portal (toggleMenuSidebar)="toggleMenuSidebar()" ></app-header-portal>
  
    <!-- <app-menu-sidebar-portal
      (mainSidebarHeight)="mainSidebarHeight($event)"
    ></app-menu-sidebar-portal> -->
  
    <div #contentWrapper class="content-wrapper" style="margin-left: 10px !important;">
      <router-outlet></router-outlet>
    </div>

    <app-footer-portal></app-footer-portal>
    <aside class="control-sidebar control-sidebar-dark"></aside>
    <div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>
  </div>

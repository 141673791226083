<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row">
            <ol class="breadcrumb float-sm-left">
                <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                </li>
                <li class="breadcrumb-item active">Thống kê</li>
            </ol>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content cus-body">
    <div class="card">
        <form class="form-horizontal">
            <div class="card-header p-2 " style="align-content:flex-end ">
                <div class="row" style="padding-left:10px; padding-right:10px">

                    <div class="col-md-3">
                        <label class="col-md-10">Loại đánh giá:</label>
                        <select class="form-control" id="loaidanhgia" (change)="ChangeThongKe()">
                            <option value="1" selected>Khảo sát sinh viên</option>
                            <option value="2">Đánh giá giảng viên</option>
                            <option value="3">Khảo sát doanh nghiệp</option>
                            <option value="4">Khảo sát giảng viên</option>
                            <option value="5">Khảo sát cán bộ</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-md-10">Năm học:</label>
                        <select class="form-control" id="namhoc" (change)="NamHocChange()">

                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-md-10">Học kỳ:</label>
                        <select class="form-control" id="hocky" (change)="HocKyChange()">

                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-md-10">Đợt đánh giá:</label>
                        <select class="form-control" id="dotdanhgia" (change)="DotDanhGiaChange()">
                        </select>
                    </div>

                </div>
                <div class="row" style="padding-left:10px; padding-right:10px">
                    <div class="col-md-3">
                        <label class="col-md-10">Hệ:</label>
                        <select class="form-control" id="He" (change)="HeChange()">
                            <option *ngFor="let p of ListHe" [value]="p.ID_he">{{p.Ten_he}}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-md-10">Khoa:</label>
                        <select class="form-control" id="Khoa" (change)="KhoaChange()">

                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-md-10">Khoá:</label>
                        <select class="form-control" id="KhoaHoc" (change)="KhoahocChange()">

                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-md-10">Chuyên ngành:</label>
                        <select class="form-control" id="ChuyenNganh">

                        </select>
                    </div>
                </div>
                <div class="row" style="padding-left:10px; padding-right:10px">
                    <div class="col-sm">
                        <label class="col-md-10">Loại phiếu đánh giá</label>
                        <select class="form-control" [(ngModel)] = "idLoaiDanhGia" [ngModelOptions]="{standalone: true}">
                            <option [value]="0">Tất cả</option>
                            <option *ngFor="let p of loaiDanhGia" [value]="p.ID_loai_danh_gia">
                                {{ p.Ten_loai_danh_gia }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-md-10">Phiếu đánh giá:</label>
                        <select class="form-control" id="phieudanhgia">

                        </select>
                    </div>
                    <div class="col-md-3">
                        <label class="col-md-10">Đơn vị GV:</label>
                        <select class="form-control" id="Don_vi_GV" (change)="KhoaGVChange()">

                        </select>
                    </div>
                    <div class="col-md-3 gvinput">
                        <label class="col-md-10">Giảng viên:</label>
                            <select class="form-control" id="GiangVien"  (change)="GiangVienChange()">
                                <option  value="0">Tất cả</option>
                                <option *ngFor="let p of ListGV" [value]="p.Id_Cb">{{p.Ma_Cb}} - {{p.Ten_Cb}}</option>
                            </select>
                    </div>
                    <div class="col-md-3 gvinput" style="display: none">
                            <label class="col-md-10">Lớp hành chính</label>
                            <select id="Lop_hoc"  (change)="LopHocChange()" class="form-control">
                            <option  value="0">Tất cả</option>
                            <option *ngFor="let p of Lop_hanh_chinhs" [value]="p.ID_lop">{{p.Ten_lop}}</option>
                            </select>
                    </div>
                    <div class="col-md-3 gvinput" style="display: none">
                        <label class="col-md-10">Môn học</label>
                        <select class="form-control" id="Mon_hoc">
                        <option  value="0">Tất cả</option>
                        <option *ngFor="let p of Mon_hocs" [value]="p.ID_mon">{{p.Ten_mon}}</option>
                        </select>
                    </div>
                </div>

            </div><!-- /.card-header -->
        </form>
    </div>
    <div class="card">
            <div class="card-body  cus-body">
                <div class="tab-content">
                    <div class="active tab-pane">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class=" table-bordered dataTables_wrapper no-footer ">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let p of ClassData">
                                                <td>{{ p.Id_Bao_Cao}}</td>
                                                <td>{{ p.Ten_Bao_Cao }}</td>
                                                <td>
                                                    <button class="btn btn-sm btn-warning"
                                                        (click)="TaiBaoCao(p)"
                                                        style="margin-right: 7px;"> <i class="fas fa-download"
                                                            title="Tải báo cáo"></i></button>

                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>


                                </div>
                            </div>
                            <!-- /.col -->
                        </div>
                    </div>
                </div>
                <!-- /.tab-content -->
            </div><!-- /.card-body -->
    </div><!-- /.card-body -->
</section>
<!-- /.content -->
<ng-template #LoadModal let-modal>
    <div class="loadingio-spinner-cube-3p98jy7i84o">
        <div class="ldio-x95p7sxsl2i ">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <p style="text-align: center;">Đang tạo file báo cáo</p>
    <style type="text/css">

        /* generated by https://loading.io/ */
    </style>
</ng-template>

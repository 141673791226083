<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-md-6">
            <ol class="breadcrumb ">
              <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i>Trang chủ</a></li>
              <li class="breadcrumb-item active">Tra cứu phân phối cán bộ</li>
            </ol>
          </div>
        <div class="col-md-6">
   
        
            <button class="btn btn-success btn-sm" style="float: right; margin-right: 7px;" (click)="Search()">
                <i class="fas fa-search"></i><span
                style="font-weight: bold;"> Tìm kiếm</span>
              </button>
              <button class="btn btn-success btn-sm" style="float: right; margin-right: 7px;" (click)="XuatExcel()">
                <i class="fas fa-file-excel"></i> Xuất excel
              </button>
        </div>
      </div>
    </div>
  </section>
  <section class="content cus-body" *ngIf="isDisplay">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="filterForm">
        <div class="row" style="padding-left:10px; padding-right:10px">
          <div class="col-sm">
            <label>Mã cán bộ</label>
            <input type="text" (change)="Search()" class="form-control" formControlName="Ma_cb"/>
          </div>
          <div class="col-sm">
            <label>Họ tên</label>
            <input type="text" (change)="Search()" class="form-control" formControlName="Ho_ten" />
          </div>
          <div class="col-sm">
            <label>Trạng thái</label>
            <select class="form-control" (change)="Search()" formControlName="Danh_gia">
              <option value="0">Tất cả</option>
              <option value="2">Chưa đánh giá</option>
              <option value="1">Đã đánh giá</option>
            </select>
          </div>
          <div class="col-sm">
            <label class="col-md-10">Phòng ban:</label>
            <select (change)="Search()" class="form-control" formControlName="ID_phong">
              <option value="0">Tất cả</option>
              <option *ngFor="let p of lstPhongBan" [value]="p.ID_phong > 0 ? p.ID_phong : p.ID_Phong">{{ p.Phong }}</option>
            </select>
          </div>
          <div class="col-sm">
            <label class="col-md-10">Khoa:</label>
            <select (change)="Search()" class="form-control" formControlName="ID_khoa">
              <option value="0">Tất cả</option>
              <option
                *ngFor="let p of lstKhoas"
                [value]="p.ID_khoa"
              >
                {{ p.Ten_khoa }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" style="padding:10px">
          <div class="col-sm">
            <label>Đợt đánh giá</label>
            <select (change)="Search()" name="" class="form-control" formControlName="ID_dot">
              <option value="0">Tất cả</option>
              <option *ngFor="let p of ListSeason" [value]="p.ID_dot_danh_gia">{{p.Ten_dot}}</option>
            </select>
          </div>
          <div class="col-md-6">
            <nz-form-item nz-col [nzSpan]="24">
              <label>Phiếu đánh giá</label>
              <nz-form-control>
                <nz-select (change)="Search()" class="custom-nz-select" formControlName="ID_phieu" nzShowSearch nzAllowClear nzDropdownMatchSelectWidth="false" nzSize="large">
                  <nz-option [nzValue]="0" nzLabel="Tất cả" style="width: 100%;"></nz-option>
                  <nz-option *ngFor="let p of ListPhieuDanhGia" [nzValue]="p.ID_phieu_danh_gia" [nzLabel]="p.Ten_phieu"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
    </form>
      </div>
    </div>
  </section>
  <section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane">
            <div class="row">
              <div class="col-md-6">
                Hiển thị
                <select (change)="handlePageSizeChange($event)">
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                kết quả
              </div>
              <div class="col-md-6">
                <button title="Ẩn/hiện bộ lọc" style="float: right; margin: 0 0 5px 5px;" (click)="buttonFilter()">
                  <i class="fas fa-filter"></i>
                </button>
                <button title="Tải lại" style="float: right; margin-bottom: 5px;" (click)="refesh()">
                  <i class="fas fa-redo-alt"></i>
                </button>
              </div>
            </div>
            <div class="table-responsive" style="max-height: 60vh; margin-bottom: 15px;">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Tên đợt</th>
                    <th>Tên phiếu</th>
                    <th>Mã cán bộ</th>
                    <th>Họ tên</th>
                    <th>Phòng ban</th>
                    <th>Ngày phát phiếu</th>
                    <th>Ngày đánh giá</th>
                    <th>Thao tác</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of dataTable; let i=index; let odd = odd">
                    <td>{{ p.Ten_dot }}</td>
                    <td>{{ p.Ten_phieu }}</td>
                    <td>{{ p.UserName }}</td>
                    <td style="white-space: nowrap;">{{ p.FullName }}</td>
                    <td>{{p.Phong }}</td>
                    <td>{{ p.Ngay_gui | date: 'dd/MM/yyyy hh:mm'}}</td>
                    <td>{{ p.Ngay_danh_gia | date: 'dd/MM/yyyy hh:mm'}}</td>
                    <td style="text-align: center;">
                      <!-- <button (click)="open(View,'xl','View',p)" class="btn btn-primary btn-sm"><i
                        class="far fa-eye"></i></button> -->
                      <button class="btn btn-sm btn-danger" style="margin-right: 7px;"
                        (click)="open(DeleteModal, 'xs', 'Delete', p)">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
  
            </div>
            <div class="row">
              <div class="col-md-6">
                <p style="float: left; font-weight: bold;">
                  Tổng số {{ totalItem|number }} kết quả, đã lọc được
                  {{ totalItemFilter|number }} kết quả
                </p>
              </div>
              <div class="col-md-6">
                <nav aria-label="Page navigation example" style="float: right;">
                  <ul class="pagination">
                    <li class="page-item" [class.disabled]="page == 1">
                      <a class="page-link" (click)="changPage(1)">Đầu</a>
                    </li>
                    <li class="page-item" [class.disabled]="page == 1">
                      <a class="page-link" (click)="changPage('pre')">Trước</a>
                    </li>
                    <ng-container *ngIf="page > 4">
                      <li class="page-item"><a class="page-link">...</a></li>
                    </ng-container>
                    <ng-container *ngFor="let element of arrNumberPage_chil">
                      <li class="page-item" [class.active]="page == element">
                        <a class="page-link" (click)="changPage(element)">{{ element }}</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="page < numberPage - 4">
                      <li class="page-item"><a class="page-link">...</a></li>
                    </ng-container>
                    <li class="page-item" [class.disabled]="page == numberPage">
                      <a class="page-link" (click)="changPage('next')">Sau</a>
                    </li>
                    <li class="page-item" [class.disabled]="page == numberPage">
                      <a class="page-link" (click)="changPage(numberPage)">Cuối</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ng-template #DeleteModal let-modal>
    <div class="modal-content">
      <div class="modal-body">
        <div class="icon-box" style="text-align: center;">
          <i class="fas fa-trash-alt" style="
              color: #f15e5e;
              font-size: 46px;
              display: inline-block;
              margin-top: 13px;
            "></i>
        </div>
        <p style="
            color: inherit;
            text-decoration: none;
            font-size: 20px;
            text-align: center;
            font-weight: 600;
            margin-top: 3em;
          ">
          Bạn có chắc chắn muốn xóa phiếu đánh giá này không? Thao tác này sẽ không thể khôi phục!!!
        </p>
        <div class="text-center">
          <button type="button" class="btn btn-md btn-success" (click)="Delete()">
            Đồng ý
          </button>
          <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
            style="margin-left: 1em;">
            Hủy bỏ
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #View let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px;padding-bottom: 0px;">
        <h4 class="modal-title h-title w-100">Kết quả đánh giá</h4>
        <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body">
       
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-danger" (click)="modal.close('Save click')"> <i
                class="far fa-times-circle"></i> Đóng</button>
    </div>
</ng-template>
import { Component, OnInit, OnDestroy, ViewChild, Inject } from "@angular/core";
import { AppConfig, AppConfiguration } from "src/configuration";
import { AppService } from "src/app/Utils/service/app.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
//import { Class } from 'src/app/Utils/service/Class.service';
import { common } from "src/app/app.common";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { ClassService } from "src/app/Utils/service/class.service";
import { SeasonService } from "src/app/Utils/service/season.service";
import {
  ResponseClassListAll,
  ClassInfo,
} from "src/app/Models/output.model/ResponseClassListAll";
import {
  ResponseListSchedule,
  ClassData,
} from "src/app/Models/output.model/ResponseListSchedule";
import { Subject, forkJoin } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { RequestTeachingScheduleList } from "src/app/Models/input.model/RequestTeachingScheduleList";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { SurveyService } from "src/app/Utils/service/survey.service";
import { EnterpriseService } from "src/app/Utils/service/enterprise.service";
import { NgxSpinnerService } from "ngx-spinner";
import { LoaiDanhGiaService } from "src/app/Utils/service/loai-danh-gia.service";
import { CanBoService } from "src/app/Utils/service/can-bo.service";
import { PhongBanService } from "src/app/Utils/service/phong-ban.service";

@Component({
  selector: "app-season",
  templateUrl: "./season.component.html",
  styleUrls: ["./season.component.scss"],
})
export class SeasonComponent implements OnInit {
  dtOptions: DataTables.Settings;
  dtOptions2: any;
  Res: ResponseListSchedule = new ResponseListSchedule();
  ClassData: ClassData[];
  requestObject: RequestTeachingScheduleList =
    new RequestTeachingScheduleList();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  HocKy: number;
  NamHoc: string;
  MonHoc: string;
  LopsSelected: any;
  SelectDN = [];
  SelectIDDN = [];
  SelectGV = [];
  SelectIDGV = [];
  ListNamHoc = [];
  closeResult: string;
  ListMH: any;
  ListMH_Ft: any = [];
  ListMHChon = [];
  ListIDMHChon = [];
  isButton: any = true;
  lstIDGV: any = [];
  Title: string = "Đang tải danh sách đợt đánh giá...";
  TitleClass: string = "spinner-border text-muted";
  //public Datetime: FormGroup;
  public MaDot: string;
  public TenDot: string;
  public NguoiTao: string;
  public ID_dot_danh_gia: any;
  public SeasonData: any;
  public ListPhieu: any = [];
  public ListLop: any;
  public KhoasF: any;
  public KhoaHocsF: any;
  public ChuyenNganhsF: any;
  public PhieusF: any = [];
  public ListDN: any;
  dataHe: any;
  dataKhoa: any;
  dataKhoa2: any;
  dataKhoaHoc: any;
  dataKhoaHoc2: any;
  dataChuyenNganh: any;
  dataChuyenNganh2: any;
  dataLop: any;
  dataLop2: any;
  ListGV: any;
  ListChuyenNganh: any = [];
  ListChuyenNganh_Filter: any = [];
  ListKhoaHoc: any = [];
  ListKhoaHoc_filter: any = [];
  ListKhoa: any = [];
  ListKhoa_Filter: any = [];
  lstLoaiDanhGia: any = [];
  ListGVFT: any = [];
  beforeFilter: any = [];
  ListHe: any = [];
  ListHe_Filter: any = [];
  lstPhieuUserFilter: any = [];
  lstPhieuUserFilterBase: any = [];
  lstPhongBan: any = [];
  lstKhoas: any = [];
  lstUserFilter: any = [];
  lstUserFilterBase: any = [];
  Token: any;
  totalRecord: any = 0;
  numberPage: any;
  pageSize: any = 10;
  page: any = 1;
  PhieuChonCount: number = 0;
  UserChonCount: number = 0;
  public pageSizes: any = [10, 20, 50, 100, 200, 500, 1000, "Tất cả"];
  arrNumberPage_child: any;
  Fillter = new FormGroup({
    MaDot: new FormControl(null),
    TenDot: new FormControl(null),
    NguoiTao: new FormControl(null),
  });
  SeasonForm = new FormGroup({
    Ten_dot_edit: new FormControl(null),
    Ngay_bat_dau_edit: new FormControl(null),
    Hoc_ky_edit: new FormControl(null),
    Ngay_ket_thuc_edit: new FormControl(null),
    Ngay_gia_han: new FormControl(null),
    Nam_hoc_edit: new FormControl(null),
    Ghi_chu_edit: new FormControl(null),
    ID_he: new FormControl(""),
    ID_khoa: new FormControl(""),
    ID_chuyen_nganh: new FormControl(""),
    Khoa_hoc: new FormControl(""),
  });
  SeasonGvForm = new FormGroup({
    ID_he: new FormControl(0),
    ID_khoa: new FormControl(0),
    Co_huu: new FormControl(false),
    Thinh_giang: new FormControl(false),
  });
  SeasonFormCoVan = new FormGroup({
    Ten_dot_edit: new FormControl(null),
    Ngay_bat_dau_edit: new FormControl(null),
    Hoc_ky_edit: new FormControl(null),
    Ngay_ket_thuc_edit: new FormControl(null),
    Ngay_gia_han: new FormControl(null),
    Nam_hoc_edit: new FormControl(null),
    Ghi_chu_edit: new FormControl(null),
    ID_he: new FormControl(""),
    ID_khoa: new FormControl(""),
    ID_chuyen_nganh: new FormControl(""),
    Khoa_hoc: new FormControl(""),
  });
  SeasonFormInsert = new FormGroup({
    Ten_dot_insert: new FormControl(null),
    Ngay_bat_dau_insert: new FormControl(null),
    // Hoc_ky_insert: new FormControl(null),
    Ngay_ket_thuc_insert: new FormControl(null),
    // Nam_hoc_insert: new FormControl(null),
    Ghi_chu_insert: new FormControl(null),
  });
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    private modalService: NgbModal,
    public SurveyService: SurveyService,
    private ClassService: ClassService,
    private SeasonService: SeasonService,
    private EnterpriseService: EnterpriseService,
    public spinner: NgxSpinnerService,
    private loaiDanhGiaService: LoaiDanhGiaService,
    private phongBanService: PhongBanService,
    private canBoService: CanBoService
  ) {
    //
  }

  dtTrigger = new Subject();
  public com: common;
  dataFilterForm: FormGroup;
  dataFilterCanBoForm: FormGroup;
  ngOnInit() {
    this.dataFilterForm = new FormGroup({
      Ten_phieu_danh_gia: new FormControl(null),
      Ma_phieu_danh_gia: new FormControl(null),
      ID_loai_danh_gia: new FormControl(-1),
      Tai_khoan_tao: new FormControl(null),
    });

    this.dataFilterCanBoForm = new FormGroup({
      Ho_ten: new FormControl(null),
      Ma_can_bo: new FormControl(null),
      ID_phong_ban: new FormControl(0),
      ID_khoa: new FormControl(0),
    });

    let element, name, arr;
    element = document.getElementById("menu2");
    arr = element.className.split(" ");
    name = "menu-open";
    if (arr.indexOf(name) == -1) {
      element.className += " " + name;
    }

    $.fn["dataTable"].ext.search.push((settings, data, dataIndex) => {
      let MaDotdt = data[0];
      let TenDotdt = data[1];
      let NguoiTaodt = data[7];
      let bool1 = true;
      let fillter = true;
      if (this.MaDot != undefined) {
        bool1 = MaDotdt.includes(this.MaDot);
      }
      if (this.TenDot != undefined) {
        bool1 = this.xoa_dau(TenDotdt)
          .toLowerCase()
          .includes(this.xoa_dau(this.TenDot).toLowerCase());
      }
      if (this.NguoiTao != undefined) {
        bool1 = NguoiTaodt.includes(this.NguoiTao);
      }
      fillter = bool1;
      return fillter;
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    //var rs=this.Class.ClassListAll(a.Info.UserName, a.access_token)
    this.requestObject.UserName = a.Info.UserName;
    this.Token = a.access_token;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 25,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "Mã đợt",
          className: "dt-center",
        },
        {
          title: "Tên đợt",
        },
        {
          title: "Học kỳ",
        },
        {
          title: "Năm học",
        },
        {
          title: "Ngày bắt đầu",
        },
        {
          title: "Ngày kết thúc",
        },
        {
          title: "Ngày gia hạn",
        },
        {
          title: "Tài khoản tạo",
          className: "dt-center",
        },
        {
          title: "Ngày tạo",
        },
        {
          title: "Tài khoản sửa",
          visible: false,
        },
        {
          title: "Ngày sửa",
          visible: false,
        },
        {
          title: "Ghi chú",
          visible: false,
        },
        {
          title: "Thao tác",
          width: "18%",
          className: "dt-center",
        },
      ],
      order: [[0, "desc"]],
    };
    this.dtOptions2 = {
      pagingType: "full_numbers",
      pageLength: 10,
      responsive: true,
      searching: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {},
        {
          title: "ID Giảng viên",
          visible: false,
        },
        {
          title: "Mã Giảng viên",
        },
        {
          title: "Tên giảng viên",
        },
        {
          title: "Giới tính",
        },
        {
          title: "Bộ môn",
        },
      ],
      order: [[1, "desc"]],
    };
    // this.spinner.show();

    // this.SeasonService.getListClass(a.access_token).subscribe((z) => {
    //   this.ListLop = z.Data;
    // });
    // this.SurveyService.getListSurvey(a.access_token).subscribe((z) => {
    //   this.ListPhieu = z.Data;
    //   this.lstPhieuUserFilter = this.getlstPhieuUserFilter(z.Data);
    //   this.lstPhieuUserFilterBase = this.getlstPhieuUserFilter(z.Data);
    // });
    // this.SeasonService.GetChuyenNganh(a.access_token).subscribe((z) => {
    //   this.ListChuyenNganh = z.Data;
    //   this.ListChuyenNganh_Filter = this.ListChuyenNganh;
    //   this.SeasonService.GetKhoa(a.access_token).subscribe((z) => {
    //     this.ListKhoa = z.Data;
    //     this.ListKhoa_Filter = this.ListKhoa;
    //     this.SeasonService.GetKhoaHoc(a.access_token).subscribe((z) => {
    //       this.ListKhoaHoc = z.Data;
    //       this.ListKhoaHoc_filter = this.ListKhoaHoc;
    //       this.SeasonService.GetHe(a.access_token).subscribe((z) => {
    //         this.ListHe = z.Data;
    //         this.ListHe_Filter = this.ListHe;
    //         this.spinner.hide();
    //       });
    //     });
    //   });
    // });

    this.spinner.show();

    const listClass$ = this.SeasonService.getListClass(a.access_token);
    const listSurvey$ = this.SurveyService.getListPhieuDanhGia(a.access_token);
    const getChuyenNganh$ = this.SeasonService.GetChuyenNganh(a.access_token);
    const getKhoa$ = this.SeasonService.GetKhoa(a.access_token);
    const getKhoaHoc$ = this.SeasonService.GetKhoaHoc(a.access_token);
    const getHe$ = this.SeasonService.GetHe(a.access_token);

    forkJoin([listClass$, listSurvey$, getChuyenNganh$, getKhoa$, getKhoaHoc$, getHe$]).subscribe(results => {
      this.ListLop = results[0].Data;
      this.ListPhieu = results[1].Data;
      this.lstPhieuUserFilter = this.getlstPhieuUserFilter(results[1].Data);
      this.lstPhieuUserFilterBase = this.getlstPhieuUserFilter(results[1].Data);
      this.ListChuyenNganh = results[2].Data;
      this.ListChuyenNganh_Filter = this.ListChuyenNganh;
      this.ListKhoa = results[3].Data;
      this.ListKhoa_Filter = this.ListKhoa;
      this.ListKhoaHoc = results[4].Data;
      this.ListKhoaHoc_filter = this.ListKhoaHoc;
      this.ListHe = results[5].Data;
      this.ListHe_Filter = this.ListHe;

      this.spinner.hide();
    });

    this.getListSeason(a.access_token);
    this.getLoaiDanhGia();
    this.getCanBo();
    this.getPhongBan();
    this.getKhoa();
    var d = new Date();
    var n = d.getFullYear() + 1;
    for (var i = 0; i < 10; i++) {
      var rs = n - 1 + "-" + n;
      // if (rs == "")
      //     html += '<option value="' + rs + '" selected >' + rs + '</option>';
      // else
      //     html += '<option value="' + rs + '">' + rs + '</option>';
      this.ListNamHoc.push(rs);
      n--;
    }
  }

  UserCheckChange(index) {
    this.lstUserFilter[index].Chon = !this.lstUserFilter[index].Chon;
    this.mapUserFilter();
  }

  PhieuUserChange(index) {
    this.lstPhieuUserFilter[index].Chon = !this.lstPhieuUserFilter[index].Chon;
    this.mapPhieuUserFilter();
  }

  getlstPhieuUserFilter(m) {
    let arrdt = [];
    for (let index = 0; index < m.length; index++) {
      var element = m[index];
      element.Chon = false;
      arrdt.push(element);
    }
    return arrdt;
  }

  getlstUserFilter(m) {
    let arrdt = [];
    for (let index = 0; index < m.length; index++) {
      var element = m[index];
      element.Chon = false;
      arrdt.push(element);
    }
    return arrdt;
  }

  PhieuUserCheckAll() {
    var value = false;
    this.PhieuChonCount = this.lstPhieuUserFilter.filter((obj) => {
      return obj.Chon == true;
    }).length;
    if (this.PhieuChonCount < this.lstPhieuUserFilter.length) {
      value = true;
    }
    for (let index = 0; index < this.lstPhieuUserFilter.length; index++) {
      this.lstPhieuUserFilter[index].Chon = value;
    }
    this.mapPhieuUserFilter();
  }

  UserCheckAll() {
    var value = false;
    this.UserChonCount = this.lstUserFilter.filter((obj) => {
      return obj.Chon == true;
    }).length;
    if (this.UserChonCount < this.lstUserFilter.length) {
      value = true;
    }
    for (let index = 0; index < this.lstUserFilter.length; index++) {
      this.lstUserFilter[index].Chon = value;
    }
    this.mapUserFilter();
  }

  getLoaiDanhGia() {
    this.loaiDanhGiaService.getAll(this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.lstLoaiDanhGia = z.ListData;
      } else {
        this.toastr.error(z.Message);
      }
    });
  }

  getCanBo() {
    this.canBoService.getAll(this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.lstUserFilter = this.getlstUserFilter(z.ListData);
        
        
        this.lstUserFilterBase = this.getlstUserFilter(z.ListData);
      } else {
        this.toastr.error(z.Message);
      }
    });
  }

  getPhongBan() {
    this.phongBanService.getAll(this.Token).subscribe((z) => {
      this.lstPhongBan = z.sort((a, b) => (a.Phong > b.Phong ? 1 : -1));
    });
  }
  getKhoa() {
    this.SeasonService.getKhoa(this.Token).subscribe((z) => {
      this.lstKhoas = z.sort((a, b) => (a.Ten_khoa > b.Ten_khoa ? 1 : -1));
    });
  }

  getPhieuFiler() {
    this.lstPhieuUserFilter = this.lstPhieuUserFilterBase;
    var filterData = this.dataFilterForm.value;

    if (filterData.Ten_phieu_danh_gia != null) {
      this.lstPhieuUserFilter = this.lstPhieuUserFilter.filter((obj) => {
        return this.xoa_dau(obj.Ten_phieu)
          .toLowerCase()
          .includes(
            this.xoa_dau(filterData.Ten_phieu_danh_gia.trim()).toLowerCase()
          );
      });
    }
    
    if (filterData.Ma_phieu_danh_gia && filterData.Ma_phieu_danh_gia.length >0) {
      this.lstPhieuUserFilter = this.lstPhieuUserFilter.filter((obj) => {
        return obj.Ma_phieu_danh_gia && this.xoa_dau(obj.Ma_phieu_danh_gia)
          .toLowerCase()
          .includes(
            this.xoa_dau(filterData.Ma_phieu_danh_gia.trim()).toLowerCase()
          );
      });
    }

    if (filterData.ID_loai_danh_gia > -1) {
      this.lstPhieuUserFilter = this.lstPhieuUserFilter.filter((obj) => {
        return obj.ID_loai_danh_gia == filterData.ID_loai_danh_gia;
      });
    }

    if (filterData.Tai_khoan_tao != null) {
      this.lstPhieuUserFilter = this.lstPhieuUserFilter.filter((obj) => {
        return this.xoa_dau(obj.Tai_khoan_tao)
          .toLowerCase()
          .includes(
            this.xoa_dau(filterData.Tai_khoan_tao.trim()).toLowerCase()
          );
      });
    }
  }

  getUserFilter() {
    this.lstUserFilter = this.lstUserFilterBase;
    var filterData = this.dataFilterCanBoForm.value;
    if (filterData.Ho_ten != null) {
      this.lstUserFilter = this.lstUserFilter.filter((obj) => {
        return this.xoa_dau(obj.FullName)
          .toLowerCase()
          .includes(this.xoa_dau(filterData.Ho_ten.trim()).toLowerCase());
      });
    }

    if (filterData.Ma_can_bo != null) {
      this.lstUserFilter = this.lstUserFilter.filter((obj) => {
        return this.xoa_dau(obj.UserName)
          .toLowerCase()
          .includes(this.xoa_dau(filterData.Ma_can_bo.trim()).toLowerCase());
      });
    }
    
    if (filterData.ID_phong_ban >0) {
      this.lstUserFilter = this.lstUserFilter.filter((obj) => {
        return obj.ID_phong == filterData.ID_phong_ban || obj.ID_Phong == filterData.ID_phong_ban;
      });
    }
    
    if (filterData.ID_khoa >0) {
      this.lstUserFilter = this.lstUserFilter.filter((obj) => {
        return obj.ID_khoa == filterData.ID_khoa;
      });
    }
  }

  guiDanhGiaCanBo() {
    var data = this.getDataGuiPhieuCanBo();
    if (data.ID_phieus.length == 0) {
      this.toastr.warning("Bạn cần chọn phiếu trước khi gửi");
      return;
    }
    if (data.Users.length == 0) {
      this.toastr.warning("Bạn cần chọn cán bộ nhận phiếu trước khi gửi");
      return;
    }
    data.ID_dot = this.ID_dot_danh_gia;
    this.spinner.show();
    this.SeasonService.GuiPhieuDanhGiaCanBo(data, this.Token).subscribe((z) => {
      this.spinner.hide();
      this.toastr.success(z.Message);
    });
  }

  getDataGuiPhieuCanBo() {
    var data = {
      ID_phieus: [],
      Users: [],
      ID_dot: 0,
    };

    for (let i = 0; i < this.lstPhieuUserFilterBase.length; i++) {
      const element = this.lstPhieuUserFilterBase[i];
      if (element.Chon) {
        data.ID_phieus.push(element.ID_phieu_danh_gia);
      }
    }

    for (let i = 0; i < this.lstUserFilterBase.length; i++) {
      const element = this.lstUserFilterBase[i];
      if (element.Chon) {
        data.Users.push({
          UserID: element.UserID,
          UserName: element.UserName,
          Email: element.Email
        });
      }
    }
    return data;
  }

  mapPhieuUserFilter() {
    for (let index = 0; index < this.lstPhieuUserFilter.length; index++) {
      const element = this.lstPhieuUserFilter[index];
      for (let index = 0; index < this.lstPhieuUserFilterBase.length; index++) {
        if (
          element.ID_phieu_danh_gia ==
          this.lstPhieuUserFilterBase[index].ID_phieu_danh_gia
        ) {
          this.lstPhieuUserFilterBase[index].Chon = element.Chon;
        }
      }
    }
    this.PhieuChonCount = this.lstPhieuUserFilterBase.filter((obj) => {
      return obj.Chon == true;
    }).length;
  }

  mapUserFilter() {
    for (let index = 0; index < this.lstUserFilter.length; index++) {
      const element = this.lstUserFilter[index];
      for (let index = 0; index < this.lstUserFilterBase.length; index++) {
        if (element.UserID == this.lstUserFilterBase[index].UserID) {
          this.lstUserFilterBase[index].Chon = element.Chon;
        }
      }
    }
    this.UserChonCount = this.lstUserFilterBase.filter((obj) => {
      return obj.Chon == true;
    }).length;
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  FillCoVanHocTap() {}
  SendCoVanHocTap() {
    const PhieuDanhGia = $("#PhieuDanhGia").val();
    const DoiTuongDanhGia = $("#DoiTuongDanhGia").val();
    if (
      PhieuDanhGia == null ||
      PhieuDanhGia == undefined ||
      DoiTuongDanhGia == null ||
      DoiTuongDanhGia == undefined
    ) {
      this.toastr.warning("Vui lòng chọn đợt và phiếu đánh giá !");
      return false;
    }
    const req = {
      ID_dot_danh_gia: this.ID_dot_danh_gia,
      ID_phieu_danh_gia: $("#PhieuDanhGia").val(),
      Tai_khoan_gui: this.com.getUserinfo().Info.UserName,
      ID_he: this.SeasonFormCoVan.value.ID_he,
      Khoa_hoc: this.SeasonFormCoVan.value.Khoa_hoc,
      ID_khoa: this.SeasonFormCoVan.value.ID_khoa,
      ID_chuyen_nganh: this.SeasonFormCoVan.value.ID_chuyen_nganh,
    };
    this.SeasonService.SendCoVanHocTap(req, this.Token).subscribe((z) => {
      this.toastr.success(z.Message);
      this.modalService.dismissAll();
    });
  }
  getListSeason(token) {
    this.SeasonService.getListSeason(token).subscribe((z) => {
      for (let i = 0; i < Object.values(z.Data).length; i++) {
        z.Data[i].Ngay_bat_dau = z.Data[i].Ngay_bat_dau;
        z.Data[i].Ngay_ket_thuc = z.Data[i].Ngay_ket_thuc;
        if (z.Data[i].Ngay_gia_han != null) {
          z.Data[i].Ngay_gia_han = z.Data[i].Ngay_gia_han;
        }
        z.Data[i].Ngay_tao = z.Data[i].Ngay_tao;
      }
      this.Res = z;
      this.ClassData = this.Res.Data;
      this.dtTrigger.next();
      this.Title = "";
      this.TitleClass = "";
      this.EnterpriseService.getListEnterprise(token).subscribe((z) => {
        this.ListDN = z.Data;
      });
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn["dataTable"].ext.search.pop();
  }
  open(content, sizm, Loai, Data) {
    this.SeasonForm.get("Ngay_gia_han").setValue(null);
    if(Data.ID_dot_danh_gia)
    {
      this.ID_dot_danh_gia = Data.ID_dot_danh_gia;
    }
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Loai == "Sua") {
      this.SeasonForm.get("Ten_dot_edit").setValue(Data.Ten_dot);
      this.SeasonForm.get("Ngay_bat_dau_edit").setValue(Data.Ngay_bat_dau);
      this.SeasonForm.get("Hoc_ky_edit").setValue(Data.Hoc_ky);
      this.SeasonForm.get("Ngay_ket_thuc_edit").setValue(Data.Ngay_ket_thuc);
      this.SeasonForm.get("Ngay_gia_han").setValue(Data.Ngay_gia_han);
      this.SeasonForm.get("Nam_hoc_edit").setValue(Data.Nam_hoc);
      this.SeasonForm.get("Ghi_chu_edit").setValue(Data.Ghi_chu);
      this.ID_dot_danh_gia = Data.ID_dot_danh_gia;
    } else if (Loai == "Xoa") {
      this.ID_dot_danh_gia = Data.ID_dot_danh_gia;
    } else if (Loai == "Gui") {
      this.ChangeDTDG();
      this.ListMHChon = [];
      this.ID_dot_danh_gia = Data.ID_dot_danh_gia;
    } else if (Loai == "GuiGV") {
      this.ID_dot_danh_gia = Data.ID_dot_danh_gia;
      this.spinner.show();
      this.SeasonService.ListGV(this.Token).subscribe((z) => {
        this.beforeFilter = z.Data;
        this.ListGV = z.Data;
        this.totalRecord = z.Data.length;
        this.beforeFilter = z.Data;
        this.pageSize = 10;
        this.page = 1;
        this.numberPage = this.createNumberPage(
          this.beforeFilter.length,
          this.pageSize
        ).numberPage;
        this.arrNumberPage_child = this.createNumberPage(
          this.beforeFilter.length,
          this.pageSize
        ).arrNumberPage_chil;
        this.ListGVFT = z.Data.filter(
          (x) => 0 < x.STT && x.STT <= this.pageSize
        );
        this.spinner.hide();
      });
    } else if (Loai == "getMH") {
      this.spinner.show();
      var DoiTuongDanhGia_val = $("#DoiTuongDanhGia").val();
      var ID_Phieu = $("#PhieuDanhGia").val();
      var DaTotNghiep = $("#DaTotNghiep").prop("checked");
      var doituong;
      var LoaiPhieuDanhGia_val = $("#LoaiPhieuDanhGia").val();
      if (DoiTuongDanhGia_val != 0) {
        if (DoiTuongDanhGia_val == 1) {
          doituong = "HPLT";
        }
        if (DoiTuongDanhGia_val == 2) {
          doituong = "HPTH";
        }
        if (DoiTuongDanhGia_val == 3) {
          doituong = "LopHC";
        }
        if (DoiTuongDanhGia_val == 6) {
          doituong = "KS";
        };
        if (ID_Phieu != 0) {
          this.GetLopsSelected();
          this.com = new common(this.router);
          this.com.CheckLogin();
          var a = this.com.getUserinfo();
          let req = {
            ID_dot: this.ID_dot_danh_gia,
            ID_phieu: ID_Phieu,
            dsID_lop: this.GetStringIDLops(this.LopsSelected),
            DoiTuongDanhGia: doituong,
            DaTotNghiep: DaTotNghiep,
            TaiKhoanGui: a.Info.UserName,
          };
          
          this.SeasonService.GetListMonHoc(req, this.Token).subscribe((z) => {
            this.ListMH = z.Data;
            this.ListMH_Ft = z.Data;
            this.spinner.hide();
          });
        }
      }
    }
    $("#TypeClassDiv").hide();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  Insert() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let req = {
      Ten_dot: $("#Ten_dot_insert").val(),
      Hoc_ky: $("#Hoc_ky_insert").val(),
      Nam_hoc: $("#Nam_hoc_insert").val(),
      Ngay_bat_dau: $("#Ngay_bat_dau_insert").val(),
      Ngay_ket_thuc: $("#Ngay_ket_thuc_insert").val(),
      Tai_khoan_tao: a.Info.UserName,
      // Ngay_sua:$('#Ten_dot_insert').val(),
      Ghi_chu: $("#Ghi_chu_insert").val(),
    };
    this.SeasonService.InsertSeason(req, a.access_token).subscribe(
      (z) => {
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success("Tạo đợt thành công");
        } else {
          this.toastr.error(z.Message);
        }
        this.modalService.dismissAll("InsertModal");
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getListSeason(a.access_token);
        this.SeasonForm.get("Ten_dot_insert").setValue("");
        this.SeasonForm.get("Hoc_ky_insert").setValue("");
        this.SeasonForm.get("Nam_hoc_insert").setValue("");
        this.SeasonForm.get("Ngay_bat_dau_insert").setValue("");
        this.SeasonForm.get("Ngay_ket_thuc_insert").setValue("");
        this.SeasonForm.get("Ghi_chu_insert").setValue("");
      },
      (error) => {
        if ((error.status = 401)) {
          this.toastr.error("Bạn không có quyển thực hiện chức năng này !");
        } else {
          this.toastr.error(error.Message);
        }
      }
    );
  }
  Update() {
    if (
      this.SeasonForm.value.Ngay_gia_han <=
        this.SeasonForm.value.Ngay_ket_thuc_edit &&
      this.SeasonForm.value.Ngay_gia_han != null
    ) {
      this.toastr.warning("Ngày gia hạn phải lớn hơn ngày kết thúc");
      return false;
    }
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    let req = {
      ID_dot_danh_gia: this.ID_dot_danh_gia,
      Ten_dot: $("#Ten_dot_edit").val(),
      Hoc_ky: $("#Hoc_ky_edit").val(),
      Nam_hoc: $("#Nam_hoc_edit").val(),
      Ngay_bat_dau: $("#Ngay_bat_dau_edit").val(),
      Ngay_ket_thuc: $("#Ngay_ket_thuc_edit").val(),
      Ngay_gia_han: this.SeasonForm.value.Ngay_gia_han,
      Tai_khoan_sua: a.Info.UserName,
      // Ngay_sua:$('#Ten_dot_insert').val(),
      Ghi_chu: $("#Ghi_chu_edit").val(),
    };
    this.SeasonService.UpdateSeason(req, a.access_token).subscribe((z) => {
      this.ID_dot_danh_gia = "";
      if (z.Status == 2) {
        this.toastr.warning(z.Message);
      } else if (z.Status == 1) {
        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
      this.modalService.dismissAll("EditModal");
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.getListSeason(a.access_token);
    });
  }
  Delete(data) {
    var r = confirm("Bạn có muốn xóa đợt đánh giá này?");
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      let req = {
        ID_dot_danh_gia: data.ID_dot_danh_gia,
      };
      this.SeasonService.DeleteSeason(req, a.access_token).subscribe((z) => {
        this.ID_dot_danh_gia = "";
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else {
          this.toastr.error(z.Message);
        }
        this.modalService.dismissAll("DeleteModal");
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getListSeason(a.access_token);
      });
    }
  }
  Send() {
    if (this.SeasonForm.value.ID_he == "") {
      this.toastr.warning("Bạn phải chọn hệ!");
      return false;
    }
    if (this.SeasonForm.value.ID_khoa == "") {
      this.toastr.warning("Bạn phải khoa!");
      return false;
    }
    if (this.SeasonForm.value.Khoa_hoc == "") {
      this.toastr.warning("Bạn phải khóa học!");
      return false;
    }
    var DoiTuongDanhGia_val = $("#DoiTuongDanhGia").val();
    var ID_Phieu = $("#PhieuDanhGia").val();
    var DaTotNghiep = $("#DaTotNghiep").prop("checked");
    var doituong;
    var LoaiPhieuDanhGia_val = $("#LoaiPhieuDanhGia").val();
    
    if (DoiTuongDanhGia_val != 0) {
      if (DoiTuongDanhGia_val == 1) {
        doituong = "HPLT";
      }
      if (DoiTuongDanhGia_val == 2) {
        doituong = "HPTH";
      }
      if (DoiTuongDanhGia_val == 3) {
        doituong = "LopHC";
      }
      if (DoiTuongDanhGia_val == 4) {
        doituong = "LopHCLT";
      }
      if (DoiTuongDanhGia_val == 5) {
        doituong = "LopHCTH";
      };
  
      if (DoiTuongDanhGia_val == 6) {
        doituong = "KS";
      };

      if (ID_Phieu != 0) {
        this.GetLopsSelected();
        this.com = new common(this.router);
        this.com.CheckLogin();
        var a = this.com.getUserinfo();
        let req = {
          ID_dot: this.ID_dot_danh_gia,
          ID_phieu: ID_Phieu,
          dsID_lop: this.GetStringIDLops(this.LopsSelected),
          DoiTuongDanhGia: doituong,
          DaTotNghiep: DaTotNghiep,
          TaiKhoanGui: a.Info.UserName,
          ID_he: this.SeasonForm.value.ID_he,
          ID_khoa: this.SeasonForm.value.ID_khoa,
          Khoa_hoc: this.SeasonForm.value.Khoa_hoc,
          ID_chuyen_nganh: this.SeasonForm.value.ID_chuyen_nganh,
        };
        this.spinner.show();
        this.SeasonService.SendSeason(req, a.access_token).subscribe((z) => {
          this.spinner.hide();
          this.modalService.dismissAll("SendModal");
          this.ID_dot_danh_gia = "";
          var SuccessDatas = z.SuccessDatas.length;
          var ExsitDatas = z.ExsitDatas.length;
          var IncorrectEmailDatas = z.IncorrectEmailDatas.length;
          var ErrorServer = z.ErrorServer.length;
          var title = "";
          if (z.Result != null) {
            title += z.Result;
          }
          title +=
            "Gửi thành công: " +
            SuccessDatas +
            ". Trùng: " +
            ExsitDatas +
            ". Email không đúng định dạng: " +
            IncorrectEmailDatas +
            ". Lỗi phát sinh: " +
            ErrorServer +
            ".";
          alert(title);
        });
      } else {
        this.toastr.warning("Không có phiếu phù hợp,không thể gửi!");
      }
    } else {
      this.toastr.warning("Vui lòng chọn đối tượng đánh giá!");
    }
  }
  ClearSelect(id) {
    var html = "";
    $("#" + id + "").html(html);
  }
  HeChange() {
    this.ListKhoa = this.ListKhoa_Filter.filter(
      (x) => x.ID_he == this.SeasonForm.value.ID_he
    );
  }
  HeChangeGVft() {
    this.ListKhoa = this.ListKhoa_Filter.filter(
      (x) => x.ID_he == this.SeasonGvForm.value.ID_he
    );
  }
  KhoaChange() {
    this.ListKhoaHoc = this.ListKhoaHoc_filter.filter(
      (x) =>
        x.ID_khoa == this.SeasonForm.value.ID_khoa &&
        x.ID_he == this.SeasonForm.value.ID_he
    );
  }
  KhoahocChange() {
    this.ListChuyenNganh = this.ListChuyenNganh_Filter.filter(
      (x) =>
        x.Khoa_hoc == this.SeasonForm.value.Khoa_hoc &&
        x.ID_khoa == this.SeasonForm.value.ID_khoa &&
        x.ID_he == this.SeasonForm.value.ID_he
    );
  }

  HeChangeCV() {
    this.ListKhoa = this.ListKhoa_Filter.filter(
      (x) => x.ID_he == this.SeasonFormCoVan.value.ID_he
    );
  }
  KhoaChangeCV() {
    this.ListKhoaHoc = this.ListKhoaHoc_filter.filter(
      (x) => x.ID_khoa == this.SeasonFormCoVan.value.ID_khoa
    );
  }
  KhoahocChangeCV() {
    this.ListChuyenNganh = this.ListChuyenNganh_Filter.filter(
      (x) => x.Khoa_hoc == this.SeasonFormCoVan.value.Khoa_hoc
    );
  }
  ChangeDTDG() {
    var DTDG = $("#DoiTuongDanhGia").val();
    var PDG = $("#LoaiPhieuDanhGia").val();
    if (DTDG == 1 || DTDG == 2) {
      this.isButton = true;
    } else {
      this.isButton = false;
    }
  }
  FillPhieu() {
    var LoaiPhieuDanhGia_select = $("#LoaiPhieuDanhGia").val();
    var html = "";
    html += '<option value="1">Lớp học phần lý thuyết</option>';
    html += '<option value="2">Lớp học phần thực hành</option>';
    html += '<option value="3">Lớp hành chính</option>';
    html += '<option value="4">Lớp hành chính - lý thuyết</option>';
    html += '<option value="5">Lớp hành chính - thực hành</option>';
    html += '<option value="6">Khảo sát</option>';
    $("#DoiTuongDanhGia").html(html);
    this.ChangeDTDG();
    this.LoaiDanhGiaChange();
    // this.PhieusF = jQuery.grep(this.ListPhieu, function (pa: any, i) {
    //   return pa.Survey.ID_loai_danh_gia == LoaiPhieuDanhGia_select || LoaiPhieuDanhGia_select == -1;
    // });

    this.PhieusF= this.ListPhieu;
    if(LoaiPhieuDanhGia_select != -1){
      this.PhieusF = jQuery.grep(this.PhieusF, function (pa: any, i) {
        return pa.Survey.ID_loai_danh_gia == LoaiPhieuDanhGia_select;
      });
    }
    var html = "";
    if (Object.values(this.PhieusF).length > 0) {
      for (var k in this.PhieusF) {
        html +=
          '<option value="' +
          this.PhieusF[k].Survey.ID_phieu_danh_gia +
          '">' + (this.PhieusF[k].Survey.Ma_phieu_danh_gia ? (
          '['+this.PhieusF[k].Survey.Ma_phieu_danh_gia+'] ') :"") +this.PhieusF[k].Survey.Ten_phieu +
          "</option>";
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#PhieuDanhGia").html(html);
  }
  FillPhieuDoanhNghiep() {
    this.SelectIDDN = [];
    this.SelectDN = [];
    var LoaiPhieuDanhGia_select = $("#LoaiPhieuDanhGia_DN").val();
    var html = "";
    this.PhieusF= this.ListPhieu;
    if(LoaiPhieuDanhGia_select != -1){
      this.PhieusF = jQuery.grep(this.PhieusF, function (pa: any, i) {
        return pa.Survey.ID_loai_danh_gia == LoaiPhieuDanhGia_select;
      });
    }

    html = "";
    if (Object.values(this.PhieusF).length > 0) {
      for (var k in this.PhieusF) {
        html +=
          '<option value="' +
          this.PhieusF[k].Survey.ID_phieu_danh_gia +
          '">' +
          this.PhieusF[k].Survey.Ten_phieu +
          "</option>";
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#PhieuDanhGia_DN").html(html);
  }
  FillPhieuGiangVien() {
    this.SelectIDGV = [];
    this.SelectGV = [];
    var LoaiPhieuDanhGia_select = $("#LoaiPhieuDanhGia_GV").val();
    var html = "";
    this.PhieusF = jQuery.grep(this.ListPhieu, function (pa: any, i) {
      return pa.Survey.ID_loai_danh_gia == LoaiPhieuDanhGia_select || LoaiPhieuDanhGia_select==-1;
    });
    html = "";
    if (Object.values(this.PhieusF).length > 0) {
      for (var k in this.PhieusF) {
        html +=
          '<option value="' +
          this.PhieusF[k].Survey.ID_phieu_danh_gia +
          '">' +(this.PhieusF[k].Survey.Ma_phieu_danh_gia ? (
            '['+this.PhieusF[k].Survey.Ma_phieu_danh_gia+'] ') :"")+
          this.PhieusF[k].Survey.Ten_phieu +
          "</option>";
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#PhieuDanhGia_GV").html(html);
  }
  FillKhoaHoc(data) {
    var html = "";
    if (data.length > 0) {
      html += '<option value="0"> Tất cả </option>';
      for (var k in data) {
        html +=
          '<option value="' +
          data[k].Khoa_hoc +
          '">' +
          data[k].Khoa_hoc +
          "</option>";
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#KhoaHoc").html(html);
  }
  FillKhoa(data) {
    var html = "";
    if (data.length > 0) {
      html += '<option value="0"> Tất cả </option>';
      for (var k in data) {
        html +=
          '<option value="' +
          data[k].ID_khoa +
          '">' +
          data[k].Ten_khoa +
          "</option>";
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#Khoa").html(html);
  }
  FillChuyenNganh(data) {
    var html = "";
    if (data.length > 0) {
      html += '<option value="0"> Tất cả </option>';
      for (var k in data) {
        html +=
          '<option value="' +
          data[k].ID_chuyen_nganh +
          '">' +
          data[k].Chuyen_nganh +
          "</option>";
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#ChuyenNganh").html(html);
  }
  LoaiDanhGiaChange() {
    var LoaiPhieuDanhGia_select = $("#LoaiPhieuDanhGia").val();
    if (LoaiPhieuDanhGia_select == 1) {
      $("#DaTotNghiepDiv").hide();
    } else {
      $("#DaTotNghiepDiv").show();
    }
  }
  GetLopsSelected() {
    var ChuyenNganhVal = this.SeasonForm.value.ID_chuyen_nganh;
    var KhoaHocVal = this.SeasonForm.value.Khoa_hoc;
    var KhoaVal = this.SeasonForm.value.ID_khoa;
    var HeVal = this.SeasonForm.value.ID_he;
    this.LopsSelected = this.ListLop;
    if (HeVal > 0) {
      this.LopsSelected = jQuery.grep(this.LopsSelected, function (pa: any, i) {
        return pa.ID_he == HeVal;
      });
      if (KhoaVal > 0) {
        this.LopsSelected = jQuery.grep(
          this.LopsSelected,
          function (pa: any, i) {
            return pa.ID_khoa == KhoaVal;
          }
        );
        if (KhoaHocVal > 0) {
          this.LopsSelected = jQuery.grep(
            this.LopsSelected,
            function (pa: any, i) {
              return pa.Khoa_hoc == KhoaHocVal;
            }
          );
          if (ChuyenNganhVal > 0) {
            this.LopsSelected = jQuery.grep(
              this.LopsSelected,
              function (pa: any, i) {
                return pa.ID_chuyen_nganh == ChuyenNganhVal;
              }
            );
          }
        }
      }
    }
  }

  GetLopsSelectedCV() {
    var ChuyenNganhVal = this.SeasonFormCoVan.value.ID_chuyen_nganh;
    var KhoaHocVal = this.SeasonFormCoVan.value.Khoa_hoc;
    var KhoaVal = this.SeasonFormCoVan.value.ID_khoa;
    var HeVal = this.SeasonFormCoVan.value.ID_he;
    this.LopsSelected = this.ListLop;
    if (HeVal > 0) {
      this.LopsSelected = jQuery.grep(this.LopsSelected, function (pa: any, i) {
        return pa.ID_he == HeVal;
      });
      if (KhoaVal > 0) {
        this.LopsSelected = jQuery.grep(
          this.LopsSelected,
          function (pa: any, i) {
            return pa.ID_khoa == KhoaVal;
          }
        );
        if (KhoaHocVal > 0) {
          this.LopsSelected = jQuery.grep(
            this.LopsSelected,
            function (pa: any, i) {
              return pa.Khoa_hoc == KhoaHocVal;
            }
          );
          if (ChuyenNganhVal > 0) {
            this.LopsSelected = jQuery.grep(
              this.LopsSelected,
              function (pa: any, i) {
                return pa.ID_chuyen_nganh == ChuyenNganhVal;
              }
            );
          }
        }
      }
    }
  }
  GetStringIDLops(m) {
    var rs = "";
    for (var k = 0; k < m.length; k++) {
      if (k == 0) {
        rs += m[k].ID_lop;
      } else {
        rs += "," + m[k].ID_lop;
      }
    }
    return rs;
  }
  GetStringIDMons(m) {
    var rs = "";
    for (var k = 0; k < m.length; k++) {
      if (k == 0) {
        rs += m[k].ID_mon;
      } else {
        rs += "," + m[k].ID_mon;
      }
    }
    return rs;
  }
  GetStringIDDN(m) {
    var rs = "";
    for (var k = 0; k < m.length; k++) {
      if (k == 0) {
        rs += m[k];
      } else {
        rs += "," + m[k];
      }
    }
    return rs;
  }
  GetStringIDGV(m) {
    var rs = "";
    for (var k = 0; k < m.length; k++) {
      if (k == 0) {
        rs += m[k].ID_gv;
      } else {
        rs += "," + m[k].ID_gv;
      }
    }
    return rs;
  }
  Select(p) {
    let index = this.SelectDN.indexOf(p, 0);
    if (index == -1) {
      this.SelectDN.push(p);
      this.SelectIDDN.push(p.ID_doanh_nghiep);
    } else {
      this.toastr.warning("Doanh nghiệp đã được thêm trước đó");
    }
  }
  SelectMH(p) {
    let index = this.ListMHChon.indexOf(p, 0);
    if (index == -1) {
      this.ListMHChon.push(p);
      this.ListIDMHChon.push(p.ID_mon);
    } else {
      this.toastr.warning("Môn học đã được thêm trước đó");
    }
  }
  Remove(p) {
    const index = this.SelectDN.indexOf(p, 0);
    if (index > -1) {
      this.SelectDN.splice(index, 1);
      this.SelectIDDN.splice(index, 1);
    }
  }
  RemoveMH(p) {
    const index = this.ListMHChon.indexOf(p, 0);
    if (index > -1) {
      this.ListMHChon.splice(index, 1);
      this.ListIDMHChon.splice(index, 1);
    }
  }
  SendDN() {
    var DoiTuongDanhGia_val = $("#DoiTuongDanhGia_DN").val();
    var ID_Phieu = $("#PhieuDanhGia_DN").val();
    var DaTotNghiep = false;
    var doituong;
    var LoaiPhieuDanhGia_val = $("#LoaiPhieuDanhGia").val();
    if (DoiTuongDanhGia_val != 0) {
      doituong = "KhaoSatDN";
      if (ID_Phieu != 0) {
        this.com = new common(this.router);
        this.com.CheckLogin();
        var a = this.com.getUserinfo();
        let req = {
          ID_dot: this.ID_dot_danh_gia,
          ID_phieu: ID_Phieu,
          dsID_lop: this.GetStringIDDN(this.SelectIDDN),
          DoiTuongDanhGia: doituong,
          DaTotNghiep: DaTotNghiep,
          TaiKhoanGui: a.Info.UserName,
        };
        this.spinner.show();
        this.SeasonService.SendSeasonDN(req, a.access_token).subscribe((z) => {
          this.spinner.hide();
          this.modalService.dismissAll("SendDNModal");
          this.ID_dot_danh_gia = "";
          var SuccessDatas = z.SuccessDatas.length;
          var ExsitDatas = z.ExsitDatas.length;
          var IncorrectEmailDatas = z.IncorrectEmailDatas.length;
          var ErrorServer = z.ErrorServer.length;
          var title = "";
          if (z.Result != null) {
            title += z.Result;
          }
          title += " Gửi thành công: " + SuccessDatas;
          // + '. Trùng: ' + ExsitDatas + '. Email không đúng định dạng: ' + IncorrectEmailDatas + '. Lỗi phát sinh: ' + ErrorServer + '.';
          alert(title);
        });
      } else {
        this.toastr.warning("Không có phiếu phù hợp,không thể gửi!");
      }
    } else {
      this.toastr.warning("Vui lòng chọn đối tượng đánh giá!");
    }
  }
  SendGV() {
    var DoiTuongDanhGia_val = $("#DoiTuongDanhGia_GV").val();
    var ID_Phieu = $("#PhieuDanhGia_GV").val();
    var DaTotNghiep = false;
    var doituong;
    var LoaiPhieuDanhGia_val = $("#LoaiPhieuDanhGia").val();
    if (DoiTuongDanhGia_val != 0) {
      let lst = this.ListGV.filter((x) => x.checked == true);
      doituong = "KhaoSatGV";
      if (ID_Phieu != 0) {
        this.com = new common(this.router);
        this.com.CheckLogin();
        var a = this.com.getUserinfo();
        let req = {
          ID_dot: this.ID_dot_danh_gia,
          ID_phieu: ID_Phieu,
          dsID_lop: this.GetStringIDGV(lst),
          DoiTuongDanhGia: doituong,
          DaTotNghiep: DaTotNghiep,
          TaiKhoanGui: a.Info.UserName,
        };
        this.spinner.show();
        this.SeasonService.SendSeasonGV(req, a.access_token).subscribe((z) => {
          this.spinner.hide();
          this.modalService.dismissAll("SendGVModal");
          this.ID_dot_danh_gia = "";
          var SuccessDatas = z.SuccessDatas.length;
          var ExsitDatas = z.ExsitDatas.length;
          var IncorrectEmailDatas = z.IncorrectEmailDatas.length;
          var ErrorServer = z.ErrorServer.length;
          var title = "";
          if (z.Result != null) {
            title += z.Result;
          }
          title += " Gửi thành công: " + SuccessDatas;
          // + '. Trùng: ' + ExsitDatas + '. Email không đúng định dạng: ' + IncorrectEmailDatas + '. Lỗi phát sinh: ' + ErrorServer + '.';
          // alert(title);
          this.toastr.success(z.Message);
        });
      } else {
        this.toastr.warning("Không có phiếu phù hợp,không thể gửi!");
      }
    } else {
      this.toastr.warning("Vui lòng chọn đối tượng đánh giá!");
    }
  }
  CheckAll() {
    var datatable = $("#chitietdt")
      .DataTable()
      .rows({ filter: "applied" })
      .data();
    var length = datatable.rows({ filter: "applied" }).nodes().length;
    var lstID = [];
    for (var i = 0; i < length; i++) {
      var ID_Phong = parseInt(datatable[i][1]);
      lstID.push(ID_Phong);
    }
    for (let index = 0; index < this.ListGV.length; index++) {
      if (lstID.includes(this.ListGV[index].ID_gv)) {
        this.ListGV[index].Selected = $("#SelectALL").prop("checked");
      }
    }
  }

  SendMH() {
    var DoiTuongDanhGia_val = $("#DoiTuongDanhGia").val();
    var ID_Phieu = $("#PhieuDanhGia").val();
    var DaTotNghiep = $("#DaTotNghiep").prop("checked");
    var doituong;
    var LoaiPhieuDanhGia_val = $("#LoaiPhieuDanhGia").val();
    if (DoiTuongDanhGia_val != 0) {
      if (DoiTuongDanhGia_val == 1) {
        doituong = "HPLT";
      }
      if (DoiTuongDanhGia_val == 2) {
        doituong = "HPTH";
      }
      if (DoiTuongDanhGia_val == 3) {
        doituong = "LopHC";
      }
      if (DoiTuongDanhGia_val == 6) {
        doituong = "KS";
      };
      if (ID_Phieu != 0) {
        this.GetLopsSelected();
        this.com = new common(this.router);
        this.com.CheckLogin();
        var a = this.com.getUserinfo();
        let req = {
          ID_dot: this.ID_dot_danh_gia,
          ID_phieu: ID_Phieu,
          dsID_lop: this.GetStringIDLops(this.LopsSelected),
          dsMonHoc: this.GetStringIDMons(this.ListMHChon),
          DoiTuongDanhGia: doituong,
          DaTotNghiep: DaTotNghiep,
          TaiKhoanGui: a.Info.UserName,
        };
        this.spinner.show();
        this.SeasonService.SendSeasonBySubjects(req, a.access_token).subscribe(
          (z) => {
            this.spinner.hide();
            this.modalService.dismissAll("SendModal");
            this.ID_dot_danh_gia = "";
            var SuccessDatas = z.SuccessDatas.length;
            var ExsitDatas = z.ExsitDatas.length;
            var IncorrectEmailDatas = z.IncorrectEmailDatas.length;
            var ErrorServer = z.ErrorServer.length;
            var title = "";
            if (z.Result != null) {
              title += z.Result;
            }
            title +=
              "Gửi thành công: " +
              SuccessDatas +
              ". Trùng: " +
              ExsitDatas +
              ". Email không đúng định dạng: " +
              IncorrectEmailDatas +
              ". Lỗi phát sinh: " +
              ErrorServer +
              ".";
            alert(title);
          },
          (error) => {
            if ((error.status = 401)) {
              this.toastr.error("Bạn không có quyển thực hiện chức năng này !");
            } else {
              this.toastr.error(error.Message);
            }
          }
        );
      } else {
        this.toastr.warning("Không có phiếu phù hợp,không thể gửi!");
      }
    } else {
      this.toastr.warning("Vui lòng chọn đối tượng đánh giá!");
    }
  }
  ft_monhoc() {
    let tenmon_ft = $("#ten_mon_ft").val();
    let mamon_ft = $("#ma_mon_ft").val();
    let list_ft = this.ListMH;
    if (tenmon_ft.toString().trim() != "") {
      list_ft = this.ListMH.filter((x) => x.Ten_mon.includes(tenmon_ft));
    }
    if (mamon_ft.toString().trim() != "") {
      list_ft = this.ListMH.filter((x) => x.Ky_hieu.includes(mamon_ft));
    }
    this.ListMH_Ft = list_ft;
  }
  filterGV() {
    this.ListGV = this.beforeFilter;
    if ($("#Ma_GV_ft").val() != "") {
      this.ListGV = this.ListGV.filter((x) =>
        x.Ma_gv.includes($("#Ma_GV_ft").val())
      );
    }
    if ($("#Ten_GV_ft").val() != "") {
      this.ListGV = this.ListGV.filter((x) =>
        x.Ten_gv.includes($("#Ten_GV_ft").val())
      );
    }
    if ($("#Mon_GV_ft").val() != "") {
      this.ListGV = this.ListGV.filter((x) =>
        x.Bo_mon.includes($("#Mon_GV_ft").val())
      );
    }
   
    if ( $('#co_huu_ft:checked').val()) {
      this.ListGV = this.ListGV.filter((x) =>
        x.Co_huu
      );
    }

    if ( $('#thinh_giang_ft:checked').val()) {
      this.ListGV = this.ListGV.filter((x) =>
        x.Thinh_giang
      );
    }

    if(this.SeasonGvForm.value.ID_khoa> 0)
    {
      this.ListGV = this.ListGV.filter((x) =>
        x.ID_khoa == this.SeasonGvForm.value.ID_khoa
      );
    }
    
    for (var j = 0; j < this.ListGV.length; j++) {
      this.ListGV[j].STT = j + 1;
    }
    this.totalRecord = this.ListGV.length;
    this.numberPage = this.createNumberPage(
      this.ListGV.length,
      this.pageSize
    ).numberPage;
    this.arrNumberPage_child = this.createNumberPage(
      this.ListGV.length,
      this.pageSize
    ).arrNumberPage_chil;
    this.changepage(1);
  }
  handlePageSizeChange(event) {
    this.page = 1;
    if (event.target.value == "Tất cả") {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.numberPage = this.createNumberPage(
      this.beforeFilter.length,
      this.pageSize
    ).numberPage;
    this.arrNumberPage_child = this.createNumberPage(
      this.beforeFilter.length,
      this.pageSize
    ).arrNumberPage_chil;
    this.changepage(1);
  }
  changepage(event: any) {
    // this.ListGV=this.beforeFilter
    // if($('#Ma_GV_ft').val()!=''){
    //   this.ListGV= this.ListGV.filter(x=>x.Ma_gv.includes($('#Ma_GV_ft').val()))
    // }
    // if($('#Ten_GV_ft').val()!=''){
    //   this.ListGV= this.ListGV.filter(x=>x.Ten_gv.includes($('#Ten_GV_ft').val()))
    // }
    // if($('#Mon_GV_ft').val()!=''){
    //   this.ListGV= this.ListGV.filter(x=>x.Bo_mon.includes($('#Mon_GV_ft').val()))
    // }
    this.spinner.show();
    if (event == "pre") {
      this.page = this.page - 1;
    } else if (event == "next") {
      this.page = this.page + 1;
    } else {
      this.page = event;
      this.arrNumberPage_child = [];
      for (var i = event - 3; i <= event + 3; i++) {
        if (i > 0 && i <= this.numberPage) {
          this.arrNumberPage_child.push(i);
        }
      }
    }
    this.ListGVFT = this.ListGV.filter(
      (x) =>
        this.pageSize * (this.page - 1) < x.STT &&
        x.STT <= this.pageSize * this.page
    );

    this.spinner.hide();
  }
  createNumberPage(totalItem, pageSize) {
    let numberPage = 0;
    let arrNumberPage = [];
    if (totalItem % pageSize == 0) {
      numberPage = Math.floor(totalItem / pageSize);
    } else {
      numberPage = Math.floor(totalItem / pageSize) + 1;
    }
    for (var i = 1; i <= numberPage; i++) {
      arrNumberPage.push(i);
    }
    let arrNumberPage_chil = [];
    if (arrNumberPage.length > 4) {
      for (var i = 1; i <= 4; i++) {
        arrNumberPage_chil.push(i);
      }
    } else {
      arrNumberPage_chil = arrNumberPage;
    }
    return {
      numberPage: numberPage,
      arrNumberPage_chil: arrNumberPage_chil,
    };
  }
  SelectRow(ID) {
    for (let index = 0; index < this.ListGV.length; index++) {
      if (this.ListGV[index].ID_gv == ID) {
        if ($("#" + ID).prop("checked")) {
          {
            this.ListGV[index].Selected = true;
          }
        } else {
          this.ListGV[index].Selected = false;
        }
        break;
      }
    }
  }

  checkAllCheckBox(ev) {
    this.ListGV.forEach((x) => (x.checked = ev.target.checked));
  }
  isAllCheckBoxChecked() {
    if (this.ListGV != undefined) return this.ListGV.every((p) => p.checked);
  }

  xoa_dau(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ|ị/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)
    return str;
  }
  help() {
    const urlHelp =
      this.appConfig.SurveyURL_Support +
      "?" +
      this.appConfig.document_SeasonURL;
    window.open(urlHelp, "_blank");
  }
}

import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
  Renderer2
} from '@angular/core';
import { common } from 'src/app/app.common';
import { Router } from '@angular/router';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { MessageService } from 'src/app/Utils/service/message.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-messages-dropdown-menu',
  templateUrl: './messages-dropdown-menu.component.html',
  styleUrls: ['./messages-dropdown-menu.component.scss']
})
export class MessagesDropdownMenuComponent implements OnInit {
  @ViewChild('dropdownMenu', { static: false }) dropdownMenu;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hideDropdownMenu();
    }
  }
  requestObject: RequestBase = new RequestBase()
  com: common;
  UnReadNum: number;
  MessageLst: []
  constructor(public elementRef: ElementRef, public renderer: Renderer2,
    public router: Router, public MessageService: MessageService, public toastr: ToastrService, ) { }

  ngOnInit() {
    this.com = new common(this.router);
    var User = this.com.getUserinfo();
    this.requestObject.UserName = User.Info.UserName
    this.getListInboxUnRead(this.requestObject, User.Token)
    setInterval(() => this.getListInboxUnRead(this.requestObject, User.Token), 10000);
  }

  toggleDropdownMenu() {
    if (this.dropdownMenu.nativeElement.classList.contains('show')) {
      this.hideDropdownMenu();
    } else {
      this.showDropdownMenu();
    }
  }
  getListInboxUnRead(req: any, token: any) {
    this.MessageService.ListInboxUnRead(req, token)
      .subscribe(z => {
        if (z.Status == 1) {
          this.MessageLst = z.Data
          this.UnReadNum=z.Data.length
        }
        else {
          this.toastr.error(z.Message, 'Tác vụ thất bại')
        }
      });
  }
  showDropdownMenu() {
    this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
  }

  hideDropdownMenu() {
    this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
  }
}

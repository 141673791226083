import { Injectable, Inject } from "@angular/core"
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class PhanPhoiTinChiService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  LopTinChi_LoadList(token, m): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "/SurveySend/LopTinChi_LoadList",
        m,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  GuiPhieuTinChi(token, m): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.SurveyURL + "/SurveySend/gui-phieu-lop-tin-chi",
        m,
        {
          headers: new HttpHeaders()
            .set("Authorization", `Bearer ${token}`)
            .set("Access_code", this.access_code),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }
}
